import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

function ExpansionPanel({ title, children }) {
   return (
      <div className="ExpansionPanel">
         <Accordion className="ExpansionPanelPaper">
            <AccordionSummary
               className="ExpansionPanelSummary TitleText"
               expandIcon={<ExpandMore className="ExpandMoreIcon" />}
               aria-controls="expansion-panel-content"
               >
               { title }
            </AccordionSummary>
            <AccordionDetails
               className="ExpansionPanelDetails"
            >
               { children }
            </AccordionDetails>
         </Accordion>
      </div>
   )
}
export default ExpansionPanel