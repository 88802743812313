import React, { Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import { depthColors } from "../../Atoms/A_Reusable/CalculatorDepthColors";

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingTop: "10px",
    paddingBottom: "10px",
    cursor: "default",
    backgroundColor: "#fafafa",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    padding: "2px 12px"
  },
  depthTextContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  depthText: {
    marginLeft: "3px",
    marginRight: "1px",
    marginBottom: "0px",
    marginTop: "0px",
    fontSize: "70%",
  },
  title: {
    marginLeft: "10px",
    marginRight: "5px",
    marginBottom: "2px",
    marginTop: "5px",
    fontSize: "80%",
    fontWeight: "bold",
  },
  squareColor: {
    width: "15px",
    height: "15px",
  },
  color: {
    width: "15px",
    height: "15px",
    border: "1px solid #686869",
  },
}));

const DepthPointsScale = () => {
  const styles = useStyles();

  const buildText = (depth) => {
    const min = depth.minLimit ? (depth.minLimit + 1) : 0;
    const max = depth.maxLimit ? depth.maxLimit : depth.minLimit;
    let result = `${min} - ${max}`;
    if (!depth.maxLimit) {
      result = `> ${min}`;
    }
    return result;
  };

  const buildItem = (depth, index) => {
    const depthText = buildText(depth);

    return (
      <Grid item key={index}>
          <div className={styles.itemContainer}>
            <div className={styles.squareColor}>
              <div
                className={styles.color}
                style={{ backgroundColor: depth.color }}
              ></div>
            </div>
            <div className={styles.depthTextContainer}>
              <p className={styles.depthText}>{depthText}</p>
            </div>
          </div>
      </Grid>
    );
  };

  return (
    <Fragment>
      <p className={styles.title}>Profundidad en kms</p>
      <Grid className={styles.grid} container justifyContent="space-evenly">
        {depthColors.map((depthItem, index) => buildItem(depthItem, index))}
      </Grid>
    </Fragment>
  );
};

export default DepthPointsScale;
