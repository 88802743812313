import { TableRow, TableCell } from '@mui/material'
import React from 'react'
import BuildDepth from "Components/Molecules/M_Reusable/BuildDepth";
import BuildLocalization from '../M_Reusable/BuildLocalization';
// import EQoriginTabs from 'Components/Organisms/O_EQDetails/EQoriginTabs';

export const EQOriginBodyTable = ({ origin, setState }) => (

    origin.map( element  => {

        return (

            <TableRow key = { element.id }>

                <TableCell>
                    <span
                        className = "material-icons vieworigen"
                    >
                        {
                            element.prefered ? (
                                <span
                                  className="material-icons preferedorigin"
                                  style={{ fontSize: "33px" }}
                                >
                                  done
                                </span>
                            ) : ""
                        }
                        zoom_in
                    </span>
                </TableCell>
                <TableCell>
                    { element.source }
                </TableCell>
                <TableCell>
                    { element.properties.magnitude }
                </TableCell>
                <TableCell>
                    {`${element.properties.utctime} HL`}
                    <br></br>
                    {`${element.properties.utctime} UTC`}
                </TableCell>
                <TableCell>
                    <BuildDepth
                        status = { element.status }
                        depth = { element.properties.depth }
                    />
                </TableCell>
                <TableCell>
                    <BuildLocalization
                        eventList = { false }
                        status = { element.status }
                        latitude = { element.properties.latitude }
                        longitude = { element.properties.longitude }
                    />
                </TableCell>
                <TableCell>
                    { element.status }
                </TableCell>
                <TableCell 
                    className = "cell-right"
                >
                    { element.sourceDescription }
                </TableCell>

            </TableRow>
        )
    }) 
);


// LINE 17
/*
  onClick = {
      () => setState (
          <EQoriginTabs
              origin = {
                  origin.filter( e => e.id === element.id )
              }
          />
      )
  }
*/