const PopupReportMarker = ({ municipality, intensity, towncenter, reports }) => {

    return (
         <div className="MapPopup EQPopup">
            <div className="PopupHeader">
               <h6 className="PopupTitle Title">{towncenter} - </h6>
               <h6 className="PopupTitle Title">{municipality}</h6>
            </div>
            <div className="EQPopupContent">
               <p>Intensidad: {intensity}</p>
               <p>N&#176; Reportes: {reports}</p>
            </div>
         </div>
    );

};

export default PopupReportMarker;
