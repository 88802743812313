import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import InstrumentalOne from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalOne.svg";
import InstrumentalTwoThree from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalTwoThree.svg";
import InstrumentalFour from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalFour.svg";
import InstrumentalFive from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalFive.svg";
import InstrumentalSix from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalSix.svg";
import InstrumentalSeven from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalSeven.svg";
import InstrumentalEight from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalEight.svg";
import InstrumentalNine from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalNine.svg";
import InstrumentalTen from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalTen.svg";

const BuildMmi = ({ mmi, selectOption }) => {

  const iconStyle = selectOption ? 'dates-icons-option': 'dates-icons'
  
  const cases = {
    case1: (
      <Tooltip title = "">
        <img
          alt = "dates-icons default"
          style = {{ display: "none" }}
          className={ iconStyle } />
      </Tooltip>
    ),
    case2: (
      <Tooltip title = "Intensidad Instrumental - Débil">
        <img
          alt = "dates-icons debil"
          className={ iconStyle }
          src = { InstrumentalOne }/>
      </Tooltip>
    ),
    case3: (
      <Tooltip title = "Intensidad Instrumental - Débil">
        <img
          alt = "Instrumental"
          className={ iconStyle }
          src = { InstrumentalTwoThree }/>
      </Tooltip>
    ),
    case4: (
      <Tooltip title = "Intensidad Instrumental - Leve">
        <img
          alt = "instrumental intensity"
          className={ iconStyle }
          src = { InstrumentalFour }/>
      </Tooltip>
    ),
    case5: (
      <Tooltip title = "Intensidad Instrumental - Moderado">
        <img
          alt = "moderado"
          className={ iconStyle }
          src = { InstrumentalFive }/>
      </Tooltip>
    ),
    case6: (
      <Tooltip title = "Intensidad Instrumental - Fuerte">
        <img
          alt = "Fuerte"
          className={ iconStyle }
          src = { InstrumentalSix }/>
      </Tooltip>
    ),
    case7: (
      <Tooltip title = "Intensidad Instrumental - Muy Fuerte">
        <img
          alt = "Muy fuerte"
          className={ iconStyle }
          src = { InstrumentalSeven }/>
      </Tooltip>
    ),
    case8: (
      <Tooltip title = "Intensidad Instrumental - Severo">
        <img
          alt = "severo"
          className={ iconStyle }
          src = { InstrumentalEight }/>
      </Tooltip>
    ),
    case9: (
      <Tooltip title = "Intensidad Instrumental - Violento">
        <img
          alt = "violento"
          className={ iconStyle }
          src = { InstrumentalNine }/>
      </Tooltip>
    ),
    case10: (
      <Tooltip title = "Intensidad Instrumental - Extremo">
        <img
          alt = "extremo"
          className={ iconStyle }
          src = { InstrumentalTen }/>
      </Tooltip>
    )
  }
  return cases[`case${ Math.round(mmi) }`] || (
    <Tooltip title="">
      <img
        alt = "default"
        style = {{ display: "none" }}
        className = "dates-icons"/>
    </Tooltip>
  );
}

export default BuildMmi;