import { AppBar, Tabs, Tab } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

function a11yProps(index) {
   return {
     id: `tab-${index}`,
     "aria-controls": `tabpanel-${index}`,
   };
 }

export function TabViews({ tabs, views, link, fullWidth = false }) {

   const theme = useTheme();
   const [value, setValue] = useState(0);
 
   const handleChange = (event, newValue) => {
     if ( newValue !== -1 ) setValue(newValue);
   };
 
   const handleChangeIndex = (index) => {
     setValue(index);
   };

   useEffect( ()=>{ // this is for responsiveness
      var element = document.querySelector('[aria-label="scrollable auto tabs"]');
      const resizeObserver = new ResizeObserver( (entries) => {
         const isOverflowing = element.clientWidth < element.scrollWidth;
         if ( isOverflowing ){
            element.classList.remove("centered");
         } else {
            element.classList.add("centered");
         }
      } );
   
      resizeObserver.observe( element );
   }, [] )

   return (
      <>
         <AppBar position="static" color="transparent" className="TabsBar">
            <Tabs 
                  className="TabsGroup"
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  {
                     ...fullWidth ? { variant: "fullWidth" } : { variant: "scrollable" }
                  }
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="scrollable auto tabs"
                  
            >
               {
                  tabs?.map( (tab,i) => (
                     ( tab.validation === undefined || tab.validation ) &&
                     <Tab key={`tab-${i}`} className="Tab" label={ tab.label } {...a11yProps(i)} />
                  ) )
               }
               {
                  link && (
                     <Tab 
                        className="TabLink" label="Más Información" 
                        href={link} target="_blank" value={-1}
                        title="Click para ver más información" 
                     />
                  )
               }
            </Tabs>
         </AppBar>

         <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
         >
            {
               views?.map( (view, i) => (
                  <TabPanel key={`view-${i}`} value={value} index={i} dir={theme.direction}>
                     { view }
                  </TabPanel>
               ) )
            }
         </SwipeableViews>
      </>
   )
}

export function TabPanel(props) {
   const { children, value, index, ...other } = props;
  
   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`full-width-tabpanel-${index}`}
         aria-labelledby={`full-width-tab-${index}`}
         {...other}
      >
         {value === index && <Box p={3}>{children}</Box>}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
 };
 