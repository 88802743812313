import React, { useEffect } from "react";
import { connect } from "react-redux";
import { show_menu, changeMenuIcon, resizeEvent } from "redux/actionsCreators";
// import logo from "assets/icons/Reusable/Logo_SGC.svg";
import logoWhite from "assets/icons/Reusable/logoWhite.svg";
import QueryIcon from "assets/icons/EQViewer/Icons/query.svg";
import mapIconA from "assets/icons/Reusable/colombia_mapa_1.svg";
import Burger from 'react-css-burger';
import info_activate from "assets/icons/EQViewer/Icons/Active/act_botones-iconos-05.svg";
import services_sgc from 'assets/icons/Reusable/Viewer_services/services_sgc_on.svg';
import { SGCPopup } from "Components/Atoms/A_EQDetails/SGCPopup";
import SgcServices from "Components/Molecules/M_Reusable/SgcServices";
import { AccessibilityTemplate } from "Components/Molecules/M_Reusable/AccessibilityTemplate";
import white_accessibility from 'assets/icons/EQViewer/Icons/accesibilidad_verde.svg';

import Tooltip from '@material-ui/core/Tooltip';
import { Link, useLocation } from "react-router-dom";

const EQDetailHeader = (
	{
		menuIcon,
		resizeEvent,
		show_menu,
		changeMenuIcon,
		resize,
		trigger
	}
) => {

	const location = useLocation();

	const resize_window = () => (
		window.screen.width <= 1024
			? resizeEvent({ burgerColor: "#ffffff", burgerScale: 0.8 })
			: resizeEvent({ burgerColor: "#667F00", burgerScale: 0.8 })
	)

	const defineRoute = () => {
		if (location.pathname.includes('detallesismo')) {
			return '/sismos'
		} else if (location.pathname.includes('detallevolcan')) {
			return '/volcanes'
		}
		return '/catalogo';
	}

	const BACK_ROUTE = defineRoute();

	useEffect(() => {
		window.addEventListener('resize', resize_window);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
			<div className="EQButtonActions">
				<div className="menubutton" onClick={show_menu}>
					<Burger
						onClick={() => changeMenuIcon(!menuIcon)}
						active={menuIcon}
						burger="squeeze"
						hoverOpacity={0.8}
						scale={resize.burgerScale}
						marginTop="0px"
						marginLeft="0px"
					/>
				</div>
				<div className="sgc-logo-container">
					<Link to="/" className="sgc-logo-a">
						<img src={logoWhite} alt="SGC" />
					</Link>
					<Link to="/" className="sgc-logo-b">
                  {/* mobile logo */}
						<img src={logoWhite} alt="SGC" /> 
					</Link>
				</div>
				<div className="container-buttons">
					<ul className="list-buttons">
						<li className="item-buttons-list">
							<span id="accessibility_icon" >
								<Tooltip title="Accesibilidad">
									<img
										className="activate_services"
										src={white_accessibility}
										alt="button-services"
									/>
								</Tooltip>
							</span>
							<SGCPopup
								Component={<AccessibilityTemplate trigger={trigger} />}
								id="accesibility_services"
								styles={{ "top": "103%" }}
								trigger={
									{ id: 'accessibility_icon', classes: 'Services_icon_on' }
								}
							/>
						</li>
						<li className="item-buttons-list">
							<Link
								id="mapLink"
								to={BACK_ROUTE}
							>
								<Tooltip title={BACK_ROUTE === '/catalogo' ? 'Consulta' : 'Ver mapa'}>
									<img
										className="button-header"
										src={BACK_ROUTE === '/catalogo' ? QueryIcon : mapIconA}
										alt="button-map"
									/>
								</Tooltip>
							</Link>
						</li>
						<li
							className="modes-view-item item-buttons-list"
							id="services_container"
							data-id="services_container"
						>
							<span id="MapservicesCrt" >
								<Tooltip title="Servicios">
									<img
										className="activate_services"
										id="services_icon"
										src={services_sgc}
										alt="button-services"
									/>
								</Tooltip>
							</span>
							<SGCPopup
								Component={<SgcServices />}
								id="MapServices"
								styles={{ "top": "0" }}
								trigger={
									{
										id: 'MapservicesCrt',
										classes: 'Services_icon_on'
									}
								}
							/>
						</li>
						<li
							className="item-buttons-list"
							id="info_button"
							style={{ "display": "none" }}
						>
							<Tooltip title="Más Información">
								<img
									className="button-header"
									src={info_activate}
									alt="info"
								/>
							</Tooltip>

						</li>
					</ul>
				</div>

			</div>
	);

}

const mapStateToProps = (
	{ detailEvent, showMenu, menuIcon, resizeEvent }
) => ({
	eventDetail: detailEvent,
	showMenu: showMenu,
	menuIcon: menuIcon,
	resize: resizeEvent
});

const mapDispatchToProps = { show_menu, changeMenuIcon, resizeEvent }

export default connect(mapStateToProps, mapDispatchToProps)(EQDetailHeader);