import { useState } from 'react';
import MUIDataTable from "mui-datatables";
import CustomSmTable from './CustomSmTable';
import { useLightgallery, LightgalleryItem } from "react-lightgallery";

const ImageItem = ({ image, group }) => (
    <div style={{ maxWidth: "250px", width: "200px", padding: "5px" }}>
        <LightgalleryItem group={group} src={image}>
            <img alt="img" src={image} style={{ width: "100%", display: 'none' }} />
        </LightgalleryItem>
    </div>
);

const BuildSmTable = ({
    acelerations,
    index,
    name,
}) => {
    const [imagesGallery, setImagesGallery] = useState([]);
    const { openGallery } = useLightgallery();
    const orderingTable = CustomSmTable();

    const defineColumns = () => {
        return index === 2
            ? acelerations.filter(({ ASCIIFile }) => {
                return ASCIIFile !== null
            }) : acelerations
    }

    const changeGallery = async (images) => {
        setImagesGallery(images);
    }

    const renderGallery = async (images, index) => {
        await changeGallery([]);
        await changeGallery(images);
        openGallery('group', index);
    };

    const buildElement = (fieldsData, columns, options = null) => {
        return {
            data: defineColumns().map((element) => {
                let out = {};
                fieldsData.forEach(({ field, render }, index) => {
                    out[field] = render
                        ? render(element, renderGallery, index)
                        : element[field]
                });
                return out;
            }),
            options: options || {
                selectableRows: 'none',
                filter: true,
                responsive: "standard",
                fixedSelectColumn: true,
                fixedHeader: true,
                filterType: 'multiselect',
                sortOrder: {
                    name: 'distanceEpicentral',
                    direction: 'asc'
                },
                textLabels: {
                    body: {
                        noMatch: "Lo sentimos, no se encontraron registros coincidentes",
                        toolTip: "Ordenar",
                        columnHeaderTooltip: column => `Ordenar por ${column.label}`
                    },
                    pagination: {
                        next: "Sig. Pag.",
                        previous: "Ant. Pag",
                        rowsPerPage: "Filas por pag.:",
                        displayRows: "de",
                    },
                    toolbar: {
                        search: "Buscar",
                        downloadCsv: "Descargar CSV",
                        print: "Imprimir",
                        viewColumns: "Columnas",
                        filterTable: "Filtrar Tabla",
                    },
                    filter: {
                        all: "Todos",
                        title: "FILTROS",
                        reset: "REINICIAR",
                    },
                    viewColumns: {
                        title: "Mostar Columnas",
                        titleAria: "Mostar/Ocultar Columnas",
                    },
                    selectedRows: {
                        text: "Fila(s) seleccionadas",
                        delete: "Eliminar",
                        deleteAria: "Eliminar Filas Seleccionadas",
                    },
                }
            },
            columns: columns.map(({ name, label, options }) => {
                return {
                    name,
                    label,
                    options: options || {
                        filter: true,
                        sort: true
                    }
                }
            })
        }

    }

    const dataTable = buildElement(
        orderingTable[index].fields, orderingTable[index].columns
    );

    return (
      <div 
         id={`${index}-${name}`} 
         aria-label={ name }
      >
            <div className="SgcMUIDataTable">
                <MUIDataTable
                    data={dataTable.data}
                    columns={dataTable.columns}
                    options={dataTable.options}
                />
            </div>
            {imagesGallery.map((image, index) => (
              <ImageItem key={index} image={image} group="group" />
            ))}
      </div>
    )
}

export default BuildSmTable;