import { Carousel } from 'react-responsive-carousel';

const arrowStylesCarrousel = {
    position: 'absolute',
    zIndex: 2,
    cursor: 'pointer'
};

export const VolcanoCarrousel = ({ images }) => {
    return (
        <Carousel 
            renderArrowPrev = { 
                ( onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <div 
                        className = "crrBtnBack" 
                        onClick = { onClickHandler } 
                        title = { label } 
                        style = { { ...arrowStylesCarrousel, left: 15 } }
                    >
                        
                    </div>
                )
            }
            renderArrowNext = { ( onClickHandler, hasNext, label ) =>
                hasNext && (
                    <div 
                        className = "crrBtnNext" 
                        onClick = { onClickHandler } 
                        title = { label } 
                        style = { { ...arrowStylesCarrousel, right: 15 } }
                    >
                        
                    </div>
                )
            }
            infiniteLoop = { true }
            showThumbs = { false }
            height = {`100%`}
            width = {`100%`}
            showStatus = { false }
            className = "VTopSlider"
        >
            
            {
                images.map(( volcanoImg, index ) => {
                    return (
                        <div key = { index }>
                            <img src = { volcanoImg } alt = "Volcano"/>
                        </div>
                    )
                })
            }
            
        </Carousel>
    )
}
    