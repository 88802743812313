import { FormControlLabel, FormControl, Radio, RadioGroup, Fade } from '@mui/material';
import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton } from '@mui/material';

const searchOptions = [
   "Búsqueda de información geocientífica",
   "Buscar en este sitio"
]

function HmSearchList({ onChange, searchPointRef }) {

   const [ search, setSearch ] = useState( searchPointRef.current );
   const [ showList, setShowList ] = useState( false );

   const handleOpenList = () => setShowList( prev => !prev );

   const handleChange = ({ target }) => {
      setSearch( target.value );
      onChange( parseInt( target.value ) );
      handleOpenList();
   }

   return (
      <div className = "HomeSearchOption" >
         <div className = "SearchOptionText" tabIndex="0" onClick = { handleOpenList }>
				<span>{ searchOptions[ search ] }</span>
			</div>
         <IconButton className="SearchOptionIcon" size="small" onClick={handleOpenList}>
            <ArrowDropDownIcon 
               fontSize="large" 
               titleAccess="Seleccionar"   
            />
         </IconButton>
         <Fade in={ showList } className="SearchList">
            <FormControl>
               <RadioGroup
                  aria-labelledby="select-search-list"
                  role="radiogroup"
                  name="select-search-list"
                  className="radio-group"
                  value={search}
                  onChange={handleChange}
               >
                  {
                     searchOptions.map( (option, index) => (
                        <FormControlLabel aria-label={option} key={ index } className="control-label"
                           value={ index }  control={<Radio size="small" />} label={ option }
                        />
                     ) )
                  }
               </RadioGroup>
            </FormControl>
         </Fade>
      </div>
   )

}
export default HmSearchList