import React from 'react';

const SettingsPopup = ({ event }) => {
    
    const build_state = () => {
        switch ( event.state ) {
            case 1:
                return "Inactiva";
            case 2:
                return "Activa";
            case 3:
                return "Próxima a instalar";
            default:
                return "Retirada"
        }
    }
    
    return (
        <div className = "EQPopup MapPopup">

            <div className = "PopupHeader">
               <div className="PopupTitleContainer">
                  <h4 className="PopupTitle Title">
                     { event.station_name }
                  </h4>
               </div>
                <div>
                    <strong>Código: </strong>
                    <span>
                        { event.stationID }
                    </span>
                </div>
                <div>
                    <strong>Localización: </strong>
                    <span>
                        {`${event.latitude}°, ${event.longitude}°`}<br />
                        {`${event.municipality}, ${event.deparment}`}
                    </span>
                </div>
                <div>
                    <strong>Elevación: </strong>
                    <span>
                        { `${ event.elevation } (msnm)` }
                    </span>
                </div>
            </div>

            <div className = "EQPopupContent">
               <div className="PopupInfoGrid">
                  
                <div>
                    <strong>Agencia: </strong>
                    <span>
                        { event.agency }
                    </span>
                </div>

                <div>
                    <strong>Fecha de instalación: </strong>
                    <span>
                        { event.installation_date }
                    </span>
                </div>
                {
                    event.uninstallation_date && (
                        <div>
                            <strong>Fecha de retiro: </strong>
                            <span>
                                { event.uninstallation_date }
                            </span>
                        </div>

                     )
                  }
                  <div>
                     <strong>Estado: </strong>
                     <span>
                           { build_state() }
                     </span>
                  </div>
               </div>
            </div>

            <div className = "EQButtons">
                <a 
                    href={`${event.link}`}
                    className="InfoButton"
                >
                    Más información
                </a>
            </div>

        </div>
    )
}
export default SettingsPopup;