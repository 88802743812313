import { HOME_BANNER, HOME_EVENTS, HOME_MENU, HOME_NEWS, HOME_SECTIONS, HOME_SERVICES, HOME_TOPIC } from "utils/urls"
import { reqResApi } from "../reqRes"

export const HomeService = {
   getMenu: () => {
      return reqResApi.get( HOME_MENU )
   },
   getBanner: () => {
      return reqResApi.get( HOME_BANNER )
   },
   getEvents: () =>{
      return reqResApi.get( HOME_EVENTS )
   },
   getSections: () =>{
      return reqResApi.get( HOME_SECTIONS )
   },
   getServices: () => {
      return reqResApi.get( HOME_SERVICES )
   },
   getMonthlyTopic: () => {
      return reqResApi.get( HOME_TOPIC )
   },
   getNews: () => {
      return reqResApi.get( HOME_NEWS )
   }
}