import React from 'react';

const BuildLocalization = (
  { status, latitude, longitude, eventList, eventpadding }
) => {

  const lat = latitude.toFixed(2);
  const lng = longitude.toFixed(2);

  const buildStatus = () => (
    status === 'automatic' ? (
      <p className = "info-text" style = {{ display: "none" }}>
        <span>Localización: </span>
        { lat }&#176;,{ lng }&#176;
      </p>
    ) : (
      <p className = "info-text">
        <span>Localización: </span>
        { lat }&#176;,{ lng }&#176;
      </p>
    )
  )

  const buildDefualt = () => (
    eventpadding ? (
      <>
        { lat }&#176;
        <br />
        { lng }&#176;
      </>
    ) : (
      <>
        { lat }&#176;,{ lng }&#176;
      </>
    )
  )


  return eventList ? buildStatus() : buildDefualt()
}

export default BuildLocalization;