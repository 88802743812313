import React from 'react';
import { analyticsEventTracker, EVENT_CATEGORIES } from 'hooks/UseAnalyticsTracker';

const CustomSmTable = () => {

    return [
        {
            fields: [
                { field: "description" },
                { field: "networkCode" },
                { field: "stationCode" },
                { field: "latitude" },
                { field: "longitude" },
                { field: "locationCode" },
                { field: "elevation" },
                { field: "distanceEpicentral" },
                { field: "distanceHipocentral" },
                { field: "pgaE" },
                { field: "pgaN" },
                { field: "pgaZ" },
            ],
            columns: [
                {
                    name: "description",
                    label: "Nombre Estación",
                    options: {
                     setCellProps: () => ({ style: { minWidth: "185px", } })
                    }
                },
                {
                    name: "networkCode",
                    label: "Red"
                },
                {
                    name: "stationCode",
                    label: "Código"
                },
                {
                    name: "latitude",
                    label: "Latitud(°)",
                },
                {
                    name: "longitude",
                    label: "Longitud(°)",
                },
                {
                    name: "locationCode",
                    label: "Localizador"
                },
                {
                    name: "elevation",
                    label: "Elevación (msnm)",
                },
                {
                    name: "distanceEpicentral",
                    label: "Dist.Epi (km)",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "distanceHipocentral",
                    label: "Dist.Hip (km)"
                },
                {
                    name: "pgaE",
                    label: "PGA EW (cm/s^2)",
                },
                {
                    name: "pgaN",
                    label: "PGA NS (cm/s^2)",
                },
                {
                    name: "pgaZ",
                    label: "PGA Z (cm/s^2)"
                },
            ]
        }, {
            fields: [
                { field: "description" },
                { field: "networkCode" },
                { field: "stationCode" },
                { field: "locationCode" },
                { field: "distanceEpicentral" },
                {
                    field: "drsImage",
                    render: (item, actions, index) => {
                        return (
                            <img
                                src={item.drsImage}
                                className="EQSmTabImg"
                                alt="Acceleration tabs"
                                key={`TableImg${index}`}
                                onClick={
                                    () => actions(
                                        [item.drsImage, item.psaImage, item.tracesImage],
                                        0
                                    )
                                }
                                width="100px"
                            />
                        );
                    }
                },
                {
                    field: "psaImage",
                    render: (item, actions, index) => {
                        return (
                            <img
                                src={item.psaImage}
                                className="EQSmTabImg"
                                alt="Accelerations tabs"
                                key={`TableImg${index}`}
                                onClick={
                                    () => actions(
                                        [item.drsImage, item.psaImage, item.tracesImage],
                                        1
                                    )
                                }
                                width="100px"
                            />
                        );
                    }
                },
                {
                    field: "tracesImage",
                    render: (item, actions, index) => {
                        return (
                            <img
                                src={item.tracesImage}
                                className="EQSmTabImg"
                                key={`TableImg${index}`}
                                alt="Accelerations tabs"
                                onClick={
                                    () => actions(
                                        [item.drsImage, item.psaImage, item.tracesImage],
                                        2
                                    )
                                }
                                width="100px"
                            />
                        );
                    }
                },
            ],
            columns: [
                {
                    name: "description",
                    label: "Nombre Estación",
                    options: {
                     setCellHeaderProps: () => ({ style: { minWidth: "185px", } })
                    }
                },
                {
                    name: "networkCode",
                    label: "Red",
                },
                {
                    name: "stationCode",
                    label: "Código",
                },
                {
                    name: "locationCode",
                    label: "Localizador",
                },
                {
                    name: "distanceEpicentral",
                    label: "Dist.Epi (km)",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "drsImage",
                    label: "Gráfica DRS",
                    options: {
                        filter: false,
                        sort: false
                    }
                },
                {
                    name: "psaImage",
                    label: "Gráfica PSA",
                    options: {
                        filter: false,
                        sort: false
                    }
                },
                {
                    name: "tracesImage",
                    label: "Gráfica Trazas",
                    options: {
                        filter: false,
                        sort: false
                    }
                },
            ]
        }, {
            fields: [
                { field: "description" },
                { field: "networkCode" },
                { field: "stationCode" },
                { field: "latitude" },
                { field: "longitude" },
                { field: "locationCode" },
                { field: "elevation" },
                { field: "distanceEpicentral" },
                { field: "distanceHipocentral" },
                { field: "pgaE" },
                { field: "pgaN" },
                { field: "pgaZ" },
                {
                    field: "ASCIIFile",
                    render: (item, render = null, index) => (
                        <a
                            href={item.ASCIIFile}
                            target="_blank"
                            key={`href${index}`}
                            rel="noopener noreferrer"
                            onClick = { e =>
                                analyticsEventTracker({
                                    category: EVENT_CATEGORIES.sm,
                                    action: 'Descargas' 
                                }) 
                            }
                        >
                            Descargas
                        </a>
                    )
                },
            ],
            columns: [
                {
                    name: "description",
                    label: "Nombre Estación",
                    options: {
                     setCellProps: () => ({ style: { minWidth: "185px", } })
                    }
                },
                {
                    name: "networkCode",
                    label: "Red",
                },
                {
                    name: "stationCode",
                    label: "Código",
                },
                {
                    name: "latitude",
                    label: "Latitud(°)",
                },
                {
                    name: "longitude",
                    label: "Longitud(°)",
                },
                {
                    name: "locationCode",
                    label: "Localizador",
                },
                {
                    name: "elevation",
                    label: "Elevación (msnm)",
                },
                {
                    name: "distanceEpicentral",
                    label: "Dist.Epi (km)",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "distanceHipocentral",
                    label: "Dist.Hip (km)",
                },
                {
                    name: "pgaE",
                    label: "PGA EW (cm/s^2)",
                },
                {
                    name: "pgaN",
                    label: "PGA NS (cm/s^2)"
                },
                {
                    name: "pgaZ",
                    label: "PGA Z (cm/s^2)",
                },
                {
                    name: "ASCIIFile",
                    label: "ANC",
                    options: {
                     filter: false,
                     sort: false,
                  }
                }
            ]
        }
    ]
}

export default CustomSmTable;