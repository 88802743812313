import React, { useState, useEffect, useContext } from 'react';
import { QLoad } from 'Components/Atoms/A_Query/QLoad';
import { QLocation } from 'Components/Molecules/M_Query/QLocation';
import { QTime } from 'Components/Molecules/M_Query/QTime';
import { QInputs } from 'Components/Molecules/M_Query/QInputs';
import { QBooleans } from 'Components/Molecules/M_Query/QBooleans';
import { getQuery } from 'Components/Atoms/A_Query/QActive';
import {
    Accordion, AccordionSummary, AccordionDetails, Button
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomAlert from 'Components/Atoms/A_Reusable/CustomAlert';
import { UseDialog } from 'hooks/UseDialog';
import { QCoordinates } from 'Components/Molecules/M_Query/QCoordinates';
import { QueryContext } from 'context/QueryContext';
import { initState, restartState } from 'Components/Atoms/A_Query/QInitState';
import tooltips from "data/QueryTooltips.json";
import { useNavigate, useLocation } from 'react-router-dom';
import { QClearForm } from 'Components/Atoms/A_Query/QClearForm';
import { analyticsEventTracker, EVENT_CATEGORIES } from 'hooks/UseAnalyticsTracker';

const PATH_QUERY_MAP = '/catalogo-mapa';
const PATH_QUERY_FORM = '/catalogo';
export const MOBILE_SIZE = 800;

export const QueryBody = ({ updateUserQuery, handleCloseModal }) => {

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { loadSelectedEvent, loadQuery } = useContext(QueryContext);
    const {
        location, loading, isErrors, preparePost, clearFormulary, map_options
    } = getQuery();
    const [ open, setOpen ] = useState( false );
    const [ isRequest, showRequest ] = useState( false );
    const [ reqLoading, setLoading ] = useState( true );
    const [ clearInfo, setClear ] = useState( false );

    const handleClearComponent = status => setClear( status );

    const clearForm = () => {
        sessionStorage.setItem('query', JSON.stringify({}));
        sessionStorage.clear();
        handleClearComponent( true );
        restartState();
        clearFormulary(initState());
        if (pathname === PATH_QUERY_MAP) {
            window.location = PATH_QUERY_FORM
        }
    }

    const handleClose = () => {
        setOpen(false);
        window.location.reload()
    };


    const handleRequest = async () => {
        // Analytics Tracker
        analyticsEventTracker({ 
            category: EVENT_CATEGORIES.query,
            action: `Consultar eventos`,
        }); 

        showRequest( true );
        const out = await preparePost();
        if ( out ) {
            updateUserQuery && updateUserQuery();
            window.innerWidth < MOBILE_SIZE
                ? loadQuery({ activeEvents: out.features })
                : loadSelectedEvent({
                    selectedEvent: out.features[0],
                    activeEvents: out.features
                });
            pathname !== PATH_QUERY_MAP
                ? navigate( PATH_QUERY_MAP )
                : handleCloseModal();
        } else { setOpen(true); showRequest(false); }
    }

    const disabledRequest = isErrors || isRequest;
    const classBody = !reqLoading
        ? "QBodyContent"
        : "QBodyContent QBodyDisabled";

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading ? <QLoad /> : (
        <div className={classBody}>
            <UseDialog
                open={open}
                onClose={handleClose}
                title="Lo Sentimos"
                message="Ha ocurrido un error, los datos correspondientes a tu búsqueda no están disponibles, por favor recarga la página"
                btn={{
                    action: handleClose,
                    text: "Recargar Página"
                }}
            />
            <div className="infoBodyContent">
                <p>
                    El catálogo de búsqueda proporciona información de todos los eventos sísmicos localizados en el territorio nacional entre el 16 de enero de 1644 hasta la actualidad. Seleccione "Opciones avanzadas" si desea realizar una consulta con mayor detalle.
                </p>
            </div>
            {
                location.departments.actual_id !== 'department' && 
                <CustomAlert closable >
                    Recuerde que al seleccionar un departamento o municipio no podrá hacer uso de la consulta por área.
                </CustomAlert>
            }
            {
                map_options.area !== null && map_options.area !== 'empty' && 
                <CustomAlert closable >
                    Recuerde que para seleccionar un departamento o municipio deberá deshabilitar la consulta por área circular o rectangular.
                </CustomAlert>
            }
            <div>
                <div className="Q_main">
                    <QLocation
                        reqLoading={reqLoading}
                        setLoading={setLoading}
                        tooltips={{
                            depa: tooltips.depa,
                            muni: tooltips.muni,
                        }}
                    />
                    <QTime
                        tooltips={{
                            initialDate: tooltips.initialDate,
                            endDate: tooltips.endDate
                        }}
                    />
                </div>
                <div className="Q_advanced">
                    <Accordion>
                        <AccordionSummary
                            expandIcon = { <ExpandMoreIcon style = {{ color: "#667f00" }} /> }
                        >
                            <h4 style={{ textAlign: "center", width: "100%" }}>
                                Opciones avanzadas
                            </h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                clearInfo ? (
                                    <QClearForm
                                        renderComponent = { handleClearComponent }
                                    />        
                                ) : (
                                    <>
                                        <QInputs
                                            tooltips={{
                                                min_magnitude: tooltips.min_magnitude,
                                                max_magnitude: tooltips.max_magnitude,
                                                min_depth: tooltips.min_depth,
                                                max_depth: tooltips.max_depth,
                                                min_rms: tooltips.min_rms,
                                                max_rms: tooltips.max_rms,
                                                min_gap: tooltips.min_gap,
                                                max_gap: tooltips.max_gap,
                                                min_mag_error: tooltips.min_mag_error,
                                                max_mag_error: tooltips.max_mag_error,
                                                min_depth_error: tooltips.min_depth_error,
                                                max_depth_error: tooltips.max_depth_error,
                                                min_latitude_error: tooltips.min_latitude_error,
                                                max_latitude_error: tooltips.max_latitude_error,
                                                min_longitude_error: tooltips.min_longitude_error,
                                                max_longitude_error: tooltips.max_longitude_error
                                            }}
                                        />
                                        <QBooleans />
                                        {
                                            location.departments.actual_id === 'department'
                                                && location.munis.actual_id === 'select' ? (
                                                <QCoordinates />
                                            ) : (
                                                <>
                                                    <div className="QBodyDisabled">
                                                        <QCoordinates />
                                                    </div>
                                                    <CustomAlert>
                                                        Para hacer uso de la consulta por área, se requiere que no seleccione un departamento ni municipio.
                                                    </CustomAlert>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="Q_action_form">
                    <div className="Q_action_form_text">
                        {/* <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt quisquam, mollitia animi beatae eligendi eum harum provident reprehenderit aperiam blanditiis nostrum excepturi reiciendis exercitationem assumenda doloribus dolores. Quaerat, sed corporis.
                        </p> */}
                    </div>
                    <div className="Q_action_form_buttons RegularText">
                        <Button
                            variant="contained"
                            onClick={clearForm}
                        >
                            Limpiar
                        </Button>
                        <Button
                            disabled={disabledRequest}
                            variant="contained"
                            id="QConsult"
                            className={disabledRequest ? "QConsultDiabled" : "QConsult"}
                            type="button"
                            onClick={handleRequest}
                        >
                            CONSULTAR
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}