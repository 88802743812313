import { useState } from "react";
import { Settings as SettingsIcon } from "@mui/icons-material";
import { Fade } from "@mui/material";
import CloseButton from "Components/Atoms/A_Reusable/CloseButton";

import SettingsMapLayers from "Components/Molecules/M_SeismicitySummary/SeismicitySettings/SettingsMapLayers";
import SettingsFilterDate from "Components/Molecules/M_SeismicitySummary/SeismicitySettings/SettingsFilterDate";

function SeismicitySettings({ title }) {

   const [ openPanel, setOpenPanel ] = useState( false );

   const handleClickIcon = () => {
      setOpenPanel( prev => !prev )
   }

   return (
      <>
         <div onClick={ handleClickIcon } className={`SettingsIcon ${ openPanel ? "active" : "" }`} title={ title } >
            <SettingsIcon />
         </div>

         <Fade in={ openPanel } unmountOnExit>
            <div className="SettingsPanel MapPanel">

               <div className="PanelTitle">
                  <p>Configuración</p>
                  <CloseButton onClick={ handleClickIcon } />
               </div>
               
               <hr className="Divider" />
               <SettingsMapLayers 
                  title="Capas del Mapa"
               />

               <hr className="Divider" />
               <SettingsFilterDate 
                  title="Rango de Fechas de Sismicidad"
               />

            </div>
         </Fade>
         </>
   )
}
export default SeismicitySettings

