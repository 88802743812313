import React from 'react';
import Epicenter from "assets/icons/EQViewer/PerceivedIntensity/Epicenter.svg";

const InteractiveMapLegend = (rangos) => {
    return(
        <div className="main-interactive-legend">
            <div className="title-interactive-legend">
                <h6>Epicentro<img src={Epicenter} alt="epicenter"></img></h6>
                <h6>PGA cm/s^2</h6>
                
            </div>
            <div className="colors-interactive-legend">
                <div className="colorsOne">
                    <ul>
                        <li><div style={{background: "#6C9023"}}></div><p>{rangos.rangos[0][0].toExponential(2)} - {rangos.rangos[0][1].toExponential(2)}</p></li>
                        <li><div style={{background: "#A5A31E"}}></div><p>{rangos.rangos[2][0].toExponential(2)} - {rangos.rangos[2][1].toExponential(2)}</p></li>
                        <li><div style={{background: "#BA8016"}}></div><p>{rangos.rangos[4][0].toExponential(2)} - {rangos.rangos[4][1].toExponential(2)}</p></li>
                        <li><div style={{background: "#CF480C"}}></div><p>{rangos.rangos[6][0].toExponential(2)} - {rangos.rangos[6][1].toExponential(2)}</p></li>
                        <li><div style={{background: "#F50D00"}}></div><p>{rangos.rangos[8][0].toExponential(2)} - {rangos.rangos[8][1].toExponential(2)}</p></li>
                    </ul>
                </div>
                <div className="colorsTwo">
                    <ul>
                        <li><div style={{background: "#6C9023"}}></div><p>{rangos.rangos[1][0].toExponential(2)} - {rangos.rangos[1][1].toExponential(2)}</p></li>
                        <li><div style={{background: "#A5A31E"}}></div><p>{rangos.rangos[3][0].toExponential(2)} - {rangos.rangos[3][1].toExponential(2)}</p></li>
                        <li><div style={{background: "#BA8016"}}></div><p>{rangos.rangos[5][0].toExponential(2)} - {rangos.rangos[5][1].toExponential(2)}</p></li>
                        <li><div style={{background: "#F50D00"}}></div><p>{rangos.rangos[7][0].toExponential(2)} - {rangos.rangos[7][1].toExponential(2)}</p></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default InteractiveMapLegend;
