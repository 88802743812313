export const mag_chart = ( info, keyY, yTitle, chartTitle ) => {
    let out = {
        categories:[],
        display:[]
    }
    info.forEach((element, index)=> {
        let find = out.display.findIndex(dat => dat.name === element[keyY])
        find < 0 ? out.display.push({
            name:element[keyY],
            data:[[index+1, element[keyY]]]
        }) : out.display[find].data.push([index+1, element[keyY]])
        out.categories.push(element.localPublishTime)
    })
    return {
        chart_options: {
            chart: {
                height: 500,
                type: 'scatter',
                zoom: {
                    enabled: true,
                    type: 'xy'
                }
            },
            xaxis: {
                tickAmount: out.display.length,
                title: {
                    text: 'Tiempo de Publicación'
                },
                categories: out.categories
            },
            yaxis: {
                tickAmount: 10,
                title: {
                    text: yTitle
                }
            },
            title: {
                text: chartTitle,
                align: 'center'
            }
        },
        type:'scatter',
        height: 500,
        data: out.display
    }

}