import { Box, Paper } from "@mui/material"
import logo_sgc_color from "assets/icons/Reusable/Logo_SGC.svg";

function SeismHeader({ eventDate, hideCounter }) {
   return (
      <Box name="Hora local de eventos" position="absolute" left={12} top={10} zIndex={2}>
         <Paper elevation={1} className="seismicity-header">
            <img 
               src={ logo_sgc_color } 
               title="SGC" 
               alt="SGC-logo"
            />
         {
            eventDate !== null && !hideCounter &&
               <div className="seismicity-counter">
                  <span>{ eventDate } Hora Local</span>
               </div>
         }
         </Paper>
      </Box>
   )
}
export default SeismHeader