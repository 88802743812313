import { OriginDetails } from "Components/Molecules/M_EQDetails/OriginDetails";
import PhasesOrigin from "Components/Molecules/M_EQDetails/PhasesOrigin";
import OriginTrace from "Components/Molecules/M_Reusable/OriginTrace";
import { TabViews } from "Components/UI/TabViews";

export default function EQoriginTabs({origin}) {

  return (
    <div className="GrayBordered">
      <TabViews
         tabs={[ 
            { label: "Detalles" },
            { label: "Fases" },
            { label: "Trazas", validation: origin[0].traces != null },
         ]}
         views={[
            <OriginDetails
              updateTime={origin[0].updateTime}
              properties={origin[0].properties}
              status={origin[0].status}
            />,
            <PhasesOrigin 
               phases={origin[0].phases} 
            />,
            origin[0].traces ? 
               <OriginTrace trace={origin[0].traces} /> : <></>,
         ]}

      />
    </div>
  );
}
