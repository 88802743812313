import { useRef, useEffect } from 'react';
import { TileLayer } from "react-leaflet";
import DraggableMap from 'Components/Atoms/A_EQDetails/DraggableMap';
import { storageJSON } from 'Components/Atoms/A_Query/QInitState';
import { Viewer } from 'Components/Templates/T_Viewer/Viewer';
import { initialLatCircle, initialLngCircle } from 'Components/Atoms/A_Query/QInitState';
import DraggableCircleLayer from '../O_Reusable/DraggableCircleLayer';

export const QCircle = ({ circle, handleStorage, validateFullScreen }) => {

    const propsRef = useRef (
        storageJSON( 'map_circle') || { 
            zoom: 4.3, position: [ 8.1544, -74.3078 ]
        }
    );

    const layerRef = useRef({
        lat: circle.lat || initialLatCircle,
        lng: circle.lng || initialLngCircle,
        radius: circle.radio
    });

    const saveToStorage = () => {
        sessionStorage.setItem (
            'map_circle',
            JSON.stringify( propsRef.current )
        );
    }

    // it is handled from draggableLayer
    const handleSetArea = ({ lat, lng, radius }) => {

      layerRef.current.lat.data = lat;
      layerRef.current.lng.data = lng;
      layerRef.current.radius = radius;

      handleStorage ({
         type: "geo_distance", 
         geometry : {
             lat: lat,
             lon: lng
         },
         distance: `${ circle.radio }km`
      }, 'circle', {
         lat: layerRef.current.lat,
         lng: layerRef.current.lng,
         radio: layerRef.current.radius
     });
    }
    
    const updateZoom = zoom => {
        propsRef.current.zoom = zoom.target._zoom;
        saveToStorage();
    }

    const updatePositionMap = ( map, place ) => {
        propsRef.current.position = place;
        saveToStorage();
    }

    useEffect(() => {
      DraggableMap();
        return () => {
            saveToStorage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    return (
        <div className = "QMapContent">
            <div className = "QMapBody">
                <Viewer
                    id = "map"
                    initPosition = { propsRef.current.position }
                    initialZoom = { propsRef.current.zoom }
                    mapOptions = {{ maxZoom: 13, minZoom: 4 }}
                    onMoveEnd = { updatePositionMap }
                    onzoomend = { updateZoom }
                    Tiles = {(
                     <>
                        <TileLayer
                            url = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                            attribution = ''
                        />
                        <DraggableCircleLayer 
                           area={{
                              lat: circle.lat.data,
                              lng: circle.lng.data,
                              radius: circle.radio
                           }}
                           setArea={ handleSetArea }
                        />
                     </>
                    )}
                    isFullScreen = { validateFullScreen }
                />
            </div>
        </div>
    )
}