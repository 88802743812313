import { CircularProgress, Box } from "@mui/material"

function SGCMiniLoader({ size=40, boxHeight, text="" }) {
   return (
      <Box 
         height={ boxHeight ?? "auto" } 
         display="flex" alignItems="center" 
         justifyContent="center" flexDirection="column"
      >
         <CircularProgress size={size} thickness={5} className="SGCProgress" />
         <p className="mt-1">{ text }</p>
      </Box>
   )
}
export default SGCMiniLoader