import { useState } from 'react';
import pdf from '../../../assets/icons/Reusable/pdf.svg'
// import sgc_logo from "assets/icons/Reusable/logoWhite.svg";
import arrow_down from "assets/icons/Volcanos/down_arrow.svg";
import arrow_up from "assets/icons/Volcanos/up_arrow.svg";

export const DivNews = ({ event, volcanoName }) => {

    const [ div, setDiv ] = useState( false );
    const isActiveDiv = !div
        ? "div-content-new hidden_legend_volcano" 
        : "div-content-new show_legend_volcano"
    return (
        <div className = { isActiveDiv } id = "div-content-new">
            <div className = "VArrow">
                <div onClick = { () => setDiv( !div ) }>
                    {
                        !div ? (
                            <img src = { arrow_up } alt = "up" />
                        ) : (
                            <img src = { arrow_down } alt = "down" />
                        ) 
                    }
                </div>
            </div>
            <div className = "VNewsContainer">
                <div className = "VNewsContent">
                    <div className="VNewsDescription">
                        <span className = "VNewsDate">
                            { event.date }
                        </span>
                        <h2>
                            { event.type }
                        </h2>
                        <span>
                            { volcanoName }
                        </span>
                        <span>
                            { event.description }
                        </span>
                    </div>
                </div>
                {
                    event.files && (
                        <div className = "VNewsPfd">
                            {
                                event.files.map(( file, idx ) => (
                                    <a href = { file } key = {`pdf${ idx }`}>
                                        <div>
                                            <img width = "30" src = { pdf } alt = "pdf" />
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}