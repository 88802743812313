import { useEffect } from 'react';
import { CatalogNav } from "Components/Molecules/M_Reusable/CatalogNav";
import EQViewerFooter from "Components/Templates/T_EQViewer/EQViewerFooter";
import { Query } from './Query';

function QueryContainer() {

    useEffect(() => {
      document.title = "SGC - Catálogo de Sismos";
    }, []);

    return (
        <div className = 'ui-viewer-container SgcContent'>
            <CatalogNav queryView />
            <main
                id = "view-modes"
                className = "content mode-catalog TitleText"
                style = {{ 
                    display: 'block', 
                    overflowY: 'scroll' 
                }}
            >
                <div className = "catalog">
                    <Query legal />
                </div>
            </main>
            <footer className = "footer">
                <EQViewerFooter />
            </footer>

        </div>
    )         
}

export default QueryContainer;