import {
  LOAD_LIST_FIVE_DAYS_2,
  LOAD_LIST_FIVE_DAYS_ALL,
  LOAD_LIST_THIRTY_DAYS_IMPORTANT,
  LOAD_LIST_SIXTY_DAYS_4,
  LOAD_ACTIVE_EVENTS,
  LOAD_ACTIVE_EVENTS_DOWN,
  APPEND_ACTIVE_EVENTS_DOWN,
  
  LOAD_DETAILS_EVENT,
  LOAD_HOME_EVENT,
  LOAD_SM_EVENT,
  RESIZE_EVENT,
  MODAL_IMG,
  LOAD_QUERY_MAP,
  SET_DEPARTMENTS,
  SET_MUNICIPALITIES,
  SET_STATIONS,
  SET_QUERY,

  NETWORK_DATA,
  NETWORK_FILTERS,
  MAKE_FILTER_NETWORK, 

  EVENT_STRING_FILTER_DATA,
  UPDATE_ACTIVE_EVENTS,
  SELECT_EVENTS_LIST,
  SELECT_EVENTS_LIST_TITLE,
  SELECT_EVENT,
  SET_MAP_LAYER,
  SET_FILTER_BOUNDS,
  SET_MAP_BOUNDS,
  SHOW_MENU,
  LOAD_CDI, 
  LOAD_LIST_INTERNATIONAL_EVENTS,
  CHANGE_MENU_ICON,

  LOAD_NOTIFICATIONS,
  LOAD_VOLCANOS,
  LOAD_VOLCANO_LEVELS,
  LOAD_VOLCANOS_SORT,
  LOAD_VOLCANOS_FILTER,
  ORDER_BY,
  FILTER_BY,
  FILTER_EVENTS_BY,
  
  LOAD_ACELERATIONS,
  LOAD_VOLCANO_ACTUAL,
  SELECT_VOLCANO,
  VISIBLE_NOTIFICATIONS,

  SET_SHOWN_VOLCANOS, 
  SET_SHOWN_EVENTS,
  SET_ACTIVE_FILTER,
  SET_BADGE,

  LOAD_NEWS_LIST,
  LOAD_NEW_ACTUAL,
  LOAD_ALL_NOTIFICATIONS,
  START_EVENTS,
  YEAR_EVENT_DETAIL,
  IS_VALID_CONSULTATION,

  LOAD_SEGMENTO_ACTUAL,
  LOAD_SEGMENTO_NUEVO,
  DISABLED_FORM_INPUT

} from "./actions";

const setDisabledFormInput = event => ({
	type: DISABLED_FORM_INPUT,
	event,
});

const loadSegmentoActual = event => ({
	type: LOAD_SEGMENTO_ACTUAL,
	event,
});

const loadSegmentoNuevo = event => ({
	type: LOAD_SEGMENTO_NUEVO,
	event,
});

const isValidConsultation = event => ({
	type: IS_VALID_CONSULTATION,
	event,
});

const setYearEventDetail = id => ({
	type: YEAR_EVENT_DETAIL,
	id,
});

const setStartEvents= events => ({
	type: START_EVENTS,
	events,
});

const setBadge= events => ({
	type: SET_BADGE,
	events,
});

const setVisibleNotifications= event => ({
	type: VISIBLE_NOTIFICATIONS,
	event,
});

const setEventStringFilterData = ( string, filterCode ) => ({
  type: EVENT_STRING_FILTER_DATA,
  string,
  filterCode
});

const loadNotifications = events => ({
  type: LOAD_NOTIFICATIONS,
  events,
});

const loadVolcanos = events => ({
  type: LOAD_VOLCANOS,
  events,
});

const loadVolcanoLevels = event => ({
   type: LOAD_VOLCANO_LEVELS,
   event,
 });

const loadVolcanosSort = events => ({
  type: LOAD_VOLCANOS_SORT,
  events,
});


const loadVolcanosFilter = events => ({
  type: LOAD_VOLCANOS_FILTER,
  events,
});

const loadList_five_days_2 = events => ({
  type: LOAD_LIST_FIVE_DAYS_2,
  events,
});

const loadList_five_days_all = events => ({
  type: LOAD_LIST_FIVE_DAYS_ALL,
  events,
});

const loadList_thirty_days_importan = events => ({
  type: LOAD_LIST_THIRTY_DAYS_IMPORTANT,
  events,
});

const loadList_sixty_days_4 = events => ({
  type: LOAD_LIST_SIXTY_DAYS_4,
  events,
});

const loadListActiveEvents = events => ({
  type: LOAD_ACTIVE_EVENTS,
  events,
});

const loadListActiveEventsDown = events => ({
  type: LOAD_ACTIVE_EVENTS_DOWN,
  events,
});

const appendActiveEventsDown = events => ({
  type: APPEND_ACTIVE_EVENTS_DOWN,
  events,
});


const loadDetailEvent = event => ({
  type: LOAD_DETAILS_EVENT,
  event,
});


const loadHomeEvent = event => ({
  type:LOAD_HOME_EVENT,
  event
})

const loadSmEvent = events => ({
  type:LOAD_SM_EVENT,
  events
})

const resizeEvent = events => ({
  type:RESIZE_EVENT,
  events
})

const modalImg = event => ({
  type:MODAL_IMG,
  event
})

const queryMap = event => ({
  type: LOAD_QUERY_MAP,
  event
});

const setDepartment = event => ({
  type: SET_DEPARTMENTS,
  event
});

const setMunicipalities = event => ({
  type: SET_MUNICIPALITIES,
  event
});

const setStations = event => ({
  type: SET_STATIONS,
  event
});

const setQuery = event => ({
  type: SET_QUERY,
  event
})

const loadNetworksData = event => ({
  type: NETWORK_DATA,
  event
});

const loadNetworkFilters = event => ({
  type: NETWORK_FILTERS,
  event
})

const makeFilterNetwork = event => ({
  type: MAKE_FILTER_NETWORK,
  event
})

const updateActiveEvents = events => ({
  type: UPDATE_ACTIVE_EVENTS,
  events,
});

const selectEventsListTitle = (title) => ({
  type: SELECT_EVENTS_LIST_TITLE,
  title,
});

const selectEventsList = code => ({
  type: SELECT_EVENTS_LIST,
  code,
});

const selectEvent = event => ({
  type: SELECT_EVENT,
  event,
});

const setMapLayer = layer => ({
  type: SET_MAP_LAYER,
  layer,
});

const setFilterBounds = ( magBounds, depthBounds, dateBounds ) => ({
  type: SET_FILTER_BOUNDS,
  magBounds,
  depthBounds,
  dateBounds
});

const setMapBounds = mapBounds => ({
  type: SET_MAP_BOUNDS,
  mapBounds
});

const show_menu = () => ({
  type: SHOW_MENU,
});

const loadCdiData = cdiData => ({
  type: LOAD_CDI,
  cdiData,
});

const loadListInternationalEvents = events => ({
  type: LOAD_LIST_INTERNATIONAL_EVENTS,
  events,
});

const changeMenuIcon = active => ({
  type: CHANGE_MENU_ICON,
  active,
});

const changeOrderBy = order => ({
  type: ORDER_BY,
  order,
});

const changeFilterBy = filter => ({
  type: FILTER_BY,
  filter,
});

const changeFilterEventsBy = filter => ({
  type: FILTER_EVENTS_BY,
  filter,
});

const loadAcelerations = array => ({
  type: LOAD_ACELERATIONS,
  array,
});


const loadVolcanoActual = array => ({
  type: LOAD_VOLCANO_ACTUAL,
  array,
});
const selectVolcano = volcano => ({
  type: SELECT_VOLCANO,
  volcano,
});

const setShownVolcanos = volcanos => ({ 
  type: SET_SHOWN_VOLCANOS, 
  volcanos, 
}) 

const setShownEvents = events => ({ 
  type: SET_SHOWN_EVENTS, 
  events, 
}) 

const setActiveFilter = events => ({ 
  type: SET_ACTIVE_FILTER, 
  events, 
}) 

const loadNewsList = event => ({ 
  type: LOAD_NEWS_LIST, 
  event, 
}) 

const loadNewActual = event => ({ 
  type: LOAD_NEW_ACTUAL, 
  event, 
})

const loadAllNotifications = event => ({ 
  type: LOAD_ALL_NOTIFICATIONS, 
  event, 
})



export {
  loadList_five_days_2,
  loadList_five_days_all,
  loadList_thirty_days_importan,
  loadList_sixty_days_4,
  loadListActiveEvents,
  loadListActiveEventsDown,
  appendActiveEventsDown,
  
  loadDetailEvent,
  loadHomeEvent,
  loadSmEvent,
  resizeEvent,
  modalImg,
  queryMap,
  setDepartment,
  setMunicipalities,
  setStations,
  setQuery,

  loadNetworksData,
  loadNetworkFilters,
  makeFilterNetwork,
  setEventStringFilterData,
  updateActiveEvents,
  selectEventsList,
  selectEventsListTitle,
  selectEvent,
  setMapLayer,
  setFilterBounds,
  setMapBounds,
  show_menu,
  loadCdiData,
  loadListInternationalEvents,
  changeMenuIcon,
  loadVolcanos,
  loadVolcanoLevels,
  loadVolcanosSort,
  changeOrderBy,
  changeFilterBy,
  changeFilterEventsBy,
  loadAcelerations,
  loadVolcanoActual,
  loadVolcanosFilter,
  selectVolcano,
  setVisibleNotifications,
  setShownVolcanos, 
  setShownEvents, 
  setActiveFilter,
  loadNotifications,
  setBadge,
  loadNewActual,
  loadNewsList,
  loadAllNotifications,
  setStartEvents,
  setYearEventDetail,
  isValidConsultation,
  loadSegmentoActual,
  loadSegmentoNuevo,
  setDisabledFormInput
};
