import React from 'react';
import PopupReportMarker from 'Components/Atoms/A_EQDetails/PopupReportMarker';
import { Marker, Popup} from "react-leaflet";
import L from 'leaflet';

const ReportMarker = ({report, isSelected, onSelect}) => {
    const lat = report.latitude;
    const lng = report.longitude;
    const level = report.intensity;

    const towncenter = report.towncenter;
    const municipality = report.municipality;
    const intensity = report.intensity;
    const reports = report.reports
    
    const handlePopupOpen = () => {
        onSelect(report);
    };

    const handlePopupClose = () => {
        onSelect(null);
    };
   
    var color = "#000000"
    var zindex = 100
    
    if(level === 2){
        color = "#D9D9F2"
        zindex = 200
        
    }
    else if(level === 3){
        color = "#9ED4FF"
        zindex = 300
        
    }
    else if(level === 4){
        color = "#00FFFF"
        zindex = 400
        
    }
    else if(level === 5){
        color = "#00FF00"
        zindex = 500
        
    }
    else if(level === 6){
        color = "#FFFF00"
        zindex = 600
        
    }
    else if(level === 7){
        color = "#FFAA00"
        zindex = 700
        
    }
    else if(level > 7){
        color = "#FF0000"
        zindex = 800
        
    }

    var stationIcon = L.Icon.extend({
        options: {
            iconSize: new L.Point(44, 44),
            className: 'leaflet-markercluster-icon',
        },
    
        createIcon: function () {
            var e = document.createElement('canvas');
            this._setIconStyles(e, 'icon');
            
            e.width = 150;
            e.height = 150;
            this.draw(e.getContext('2d'), 150, 150);
            return e;
        },
    
        createShadow: function () {
            return null;
        },
    
        draw: function (canvas) {
            canvas.fillStyle = "white";
            canvas.lineWidth = 10;
            canvas.beginPath();
            canvas.arc(60, 60, 50, 0, 2 * Math.PI);
            canvas.closePath();
            canvas.stroke();

            canvas.fillStyle = color;
            canvas.beginPath();
            canvas.arc(60, 60, 50, 0, 2 * Math.PI);
            canvas.closePath();
            canvas.fill(); 
    
           
        }
    });

    var icon = new stationIcon();

    return (

        
        <Marker
            position={[lat, lng]}
            icon={icon}
            zIndexOffset={zindex}
            eventHandlers={{ click: handlePopupOpen, popupclose: handlePopupClose }}
        >
            <Popup closeButton={false} autoPan={true}>
                <PopupReportMarker
                    towncenter={towncenter}
                    municipality={municipality}
                    intensity={intensity}
                    reports={reports}
                />
            </Popup>
        </Marker>
    );

};

export default ReportMarker;
