import { useEffect } from "react";
import { useSelector } from "react-redux";
import EQtmptabs from "Components/Molecules/M_EQDetails/EQtmpTabs";

function EQtm () {

   const event = useSelector( state => state.detailEvent[0] );

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  },[]);

  return (
    <div className = "EQtmp">
      <div className = "EQSectionContainer GrayBordered">
         <EQtmptabs tmp = { event.properties.products.momenttensor } />
      </div>
    </div>
  )
}

export default EQtm;
