import React, { useRef } from 'react'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';
import { FORMAT, FORMAT_ERROR_MESSAGE, MAX_DATE_ERROR_MESSAGE, MIN_DATE_ERROR_MESSAGE } from './DateFilter';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles( theme => ({
    base: {
        padding: "0px",
        width: "100%",
    },
    item: {
        width: "100%",
        padding: "4px 0px 4px 0px",
    },
    datePicker: {
        margin: "0px",
    },
}));

export const CatalogDateFilter = ({ minDate, maxDate, startDate, endDate, onChange, onError, onClick }) => {
    
    const styles = useStyles();
    const errorsRef = useRef([false, false]);

    const handleStartDateChange = date => onChange(
        [ date, endDate || maxDate ]
    )

    const handleEndDateChange = date => onChange(
        [ startDate || minDate, date ]
    )

    const handleErrors = (err, index) =>{
        if (err !== ""){
            errorsRef.current[index] = true; 
        } else {
            errorsRef.current[index] = false; 
        }
        onError( !errorsRef.current.every( value => value === false ))
    }
    
    return (
        <MuiPickersUtilsProvider
            utils = { DateFnsUtils }
            locale = { esLocale }
        >
            <Grid container className = { styles.base }>
                <Grid
                    item
                    className = { styles.item }
                    xs = { 12 }
                >
                    <KeyboardDateTimePicker
                        onOpen={ onClick }
                        openTo = "year"
                        required
                        views = {[ "year", "month", "date" ]}
                        autoOk
                        id = "startDate"
                        label = "Fecha Inicial"
                        minDate = { minDate }
                        maxDate = { maxDate }
                        value = { startDate || minDate }
                        maxDateMessage = { MAX_DATE_ERROR_MESSAGE }
                        minDateMessage = { MIN_DATE_ERROR_MESSAGE }
                        onChange = { handleStartDateChange }
                        format = { FORMAT }
                        strictCompareDates = { true }
                        margin = "normal"
                        className = { styles.datePicker }
                        invalidDateMessage = { FORMAT_ERROR_MESSAGE }
                        onError = { e => handleErrors(e, 0) }
                    />
                </Grid>
                <Grid
                    item
                    className = { styles.item }
                    xs = { 12 }
                >
                    <KeyboardDateTimePicker
                        openTo = "year"
                        onOpen={ onClick }
                        required
                        views = {[ "year", "month", "date" ]}
                        autoOk
                        className = { styles.datePicker }
                        invalidDateMessage = { FORMAT_ERROR_MESSAGE }
                        margin = "normal"
                        format = { FORMAT }
                        id = "endDate"
                        label = "Fecha Final"
                        maxDateMessage = { MAX_DATE_ERROR_MESSAGE }
                        minDateMessage = { MIN_DATE_ERROR_MESSAGE }
                        minDate = { startDate || minDate }
                        value = { endDate || maxDate }
                        strictCompareDates = { true }
                        maxDate = { maxDate }
                        onChange = { handleEndDateChange }
                        onError = { e => handleErrors(e, 1) }
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    )
}
