import React, { Component } from "react";

class BuildPlace extends Component {
  render() {
	var mag = this.props.mag;
	var felt = this.props.felt;
	var place = this.props.place;

	var caso = "default";
    if (felt > 0 && mag < 4) {
      caso = "caso1";
    } else if (mag >= 5) {
      caso = "caso2";
    } else if (mag >= 4) {
      caso = "caso3";
    }

    switch (caso) {
      case "caso1":
        return (
          <h2 className="header-text">
            <div>{place}</div>
          </h2>
        );
      case "caso2":
        return (
          <h2 className="header-text" >
            <div>{place}</div>
          </h2>
        );
      case "caso3":
        return (
          <h2 className="header-text">
            <div>{place}</div>
          </h2>
        );
      default:
        return <h2 className="header-text"><div>{place}</div></h2>;
	}
	}
}

export default BuildPlace;