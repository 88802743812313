import HmSectionHeader from "Components/Atoms/A_Home/HmSectionHeader";
import MoreInfoButton from "Components/Atoms/A_Home/MoreInfoButton"
import AnimateOnScroll from "Components/Atoms/A_Reusable/AnimateIn"
import { HomeService } from "api/Services/ReqHome";
import { useEffect, useState } from "react"

const splitTitle = ( text ) => {

   if ( !text ) return null;

   const divisor = Math.trunc(text.length / 2);

   for ( let i = divisor; i<text.length; i++ ){
      if ( text[i] === " " ){
         return [ text.slice(0, i), text.slice(i+1) ]
      }
   }
   return [ text ];
}

function HomeTopic({ title, description }) {

   const [ topic, setTopic ] = useState([]);

   const loadTopic = async () => {
      await HomeService.getMonthlyTopic()
      .then( res => {
         res.data && setTopic( res.data );
      })
      .catch( e => null );
      
   }

   useEffect(() => {
      loadTopic();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      topic[0] &&
      <AnimateOnScroll>
         <HmSectionHeader
            title={ title || "Tema del mes" }
            description={ description }
            ariaLevel={ 4 }
         />
         
         <div className="SectionContent HmTopicContainer">
            <img 
               className="HmTopicBg" 
               src={ topic[0].img } 
               alt="Img Tema del Mes"
            />
            <div className="HmTopicContent" style={{ justifyContent: topic[0].title ? "center" : "end" }}>
               <div className="HmTopicTitle Title" role="banner">
                  { splitTitle( topic[0].title )?.map((txt, i) => <p key={`txt`+i}>{ txt }</p>) }
               </div>
               <div className="EventAction">
                  <MoreInfoButton 
                     variant="filled"
                     href={ topic[0].link }
                  />
               </div>
            </div>
         </div>


      </AnimateOnScroll>
   )
}
export default HomeTopic