import React, { useState } from 'react';
import { analyticsEventTracker, EVENT_CATEGORIES } from 'hooks/UseAnalyticsTracker';
import { Box, Button, Grow, Paper, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import MapLegend from '../Components/Molecules/M_Reusable/MapLegend';
import DepthPointsScale from '../Components/Molecules/M_Reusable/DepthPointsScale';
import EarthquakePointsScale from '../Components/Molecules/M_Reusable/EarthquakePointsScale';

const useLegendStyles = new makeStyles( theme => ({
   button: {
     backgroundColor: "#fafafa",
     zIndex: 1000,
     fontSize: "100%",
   },
    legendBox: {
       zIndex: 1000, position: "absolute", bottom:"30px",
    },
    legendContainer: {
       overflowY: 'auto', overflowX: 'hidden',
    },
   legendLabel: {
     fontSize: "80%",
   },
 }));

function UseMapLegend({ name, align }) {

   const [ openLegend, setOpenLegend ] = useState( false );
   const legendId = openLegend ? "legend" : undefined;
   const styles = useLegendStyles();

   const handleOnClickLegend = () => { 
      const trackerName = name ? name : 'Catálogo de sismos';
      analyticsEventTracker({
        category: EVENT_CATEGORIES.eartquakes,
        action: `Mostrar Leyenda en ${ trackerName }`,
      }) 
      setOpenLegend( !openLegend );
    }

   return (
      <div id = "mapLegendContainerCatalogo" style={{ justifyContent: align ?? "center" }}>
         <Button
            aria-describedby = { legendId }
            size = "small"
            onClick = { handleOnClickLegend }
            className = { styles.button }
            variant = "contained"
         >
         <Typography className = { styles.legendLabel }>
            { openLegend ? "Ocultar Leyenda" : "Mostrar Leyenda" }
         </Typography>
         </Button>
         {
            openLegend &&
               <Box className = { styles.legendBox } id = { legendId } >
                  <Grow in = { openLegend } >
                     <Paper elevation={4} className={ styles.legendContainer }>
                        <MapLegend
                           DepthPointsScale = { <DepthPointsScale /> }
                           EarthquakePointsScale = { <EarthquakePointsScale /> }
                           // VolcanoIconScale={<VolcanoIconScale />}
                        />
                     </Paper>
                  </Grow>
               </Box>
         }
      </div>
   )
}

export default UseMapLegend;