import React, { useEffect } from "react";
import HomeFooter from "Components/Templates/T_Home/HomeFooter";
import { QueryBody } from "Components/Organisms/O_Query/QueryBody";
import { initState, saveInit } from "Components/Atoms/A_Query/QInitState";
import { setQuery } from "Components/Atoms/A_Query/QActive";
import { QueryBanner } from "Components/Organisms/O_Query/QueryBanner";

/**
 * 
 * @param {legal} param0 validate if the header is going to rendered
 * @param {updateUserQuery} param1 update user filters on ths state 
 */

export const Query = ({ legal, updateUserQuery, handleCloseModal, className = null }) => {

    setQuery( initState() );

    const { Header, Footer } = legal ? {
        Header: (
            <QueryBanner/>
        ),
        Footer: (
            <footer className = "HmFooter">
                <HomeFooter/>
            </footer>
        )
    } : { Header: <></>, Footer: <></> }

    useEffect(() => {
        return () => saveInit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className = { `QueryContainer ${ className || 'defaultMarginCatalog' }` }>
            { Header }
            <div className = "QueryBody">
                <QueryBody
                    updateUserQuery = { updateUserQuery }
                    handleCloseModal = { handleCloseModal }
                />
            </div>
            { Footer }
        </div>
    )
}