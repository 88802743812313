import React, { useEffect, useState } from 'react';
import { QLoad } from 'Components/Atoms/A_Query/QLoad';
import { getQuery } from 'Components/Atoms/A_Query/QActive';
import { reqQuery } from 'api/ReqResApi';
import { QinitialLocation } from 'Components/Atoms/A_Query/QInitState';
import { UseDialog } from 'hooks/UseDialog';
import { UseTooltip } from 'hooks/UseTooltip';
import { Autocomplete, TextField } from '@mui/material';

export const QLocation = ({ reqLoading, setLoading, tooltips }) => {
    
    const {
        location, map_options, handlePlace
    } = getQuery();

    const { departments, munis } = location;
    const [ open, setOpen ] = useState( false );
    const [{ depa, municipality }, setPlace ] = useState({
        depa: {
            values: [],
            disabled: true
        },
        municipality: {
            values: [],
            disabled: munis.selected !== 'select'
        }
    });

    const validateMapArea = () => {
        if (reqLoading === false){
            return map_options.area === null ||
            map_options.area === 'null' 
            || map_options.area === 'empty';
        } return true;
    }

    const loadMunis = async id_department => {
        setLoading( true );
        try {
            const { data } = await reqQuery.get (
                `api/search/entities/municipio/${ id_department }`
            );
            setLoading( false );
            return data ? [{
                sort: ["SELECCIONAR TODO"],
                _id: "70d0",
                _index: "municipio",
                _source: {
                    MPIO_CNMBR: "SELECCIONAR TODO",
                    MPIO_CCNCT: "9876543210"
                },
                _score: null
            }, ...data.hits.hits ] : []
        } catch {
            setOpen( true );
        }
    }

    const unSelectedDepa = () => {
        setLoading( false );
        return [];
    }

    const loadDepartments = async () => {
        try {
            const { data } = await reqQuery.get (
                'api/search/entities/departamento'
            );
            if ( data ) {
                const values = departments.actual_id !== 'department'
                    ? await loadMunis( departments.actual_id )
                    : unSelectedDepa();
                setPlace({
                    depa: {
                        values: [{
                            sort: ["COLOMBIA"],
                            _id: "c0l0mb14",
                            _index: "departamento",
                            _score: null,
                            _source: {
                                DPTO_CCDGO: "1234567890",
                                DPTO_CNMBR: "SELECCIONAR TODO"
                            },
                            _type: "_doc"
                        }, ...data.hits.hits ],
                        disabled: false
                    }, municipality: {
                        values, disabled: values[0] === undefined
                    }
                });
            } else {
                setOpen( true );
            }
        } catch {
            setOpen( true );
        }
    }


    const unSelectMuni = () => {
        handlePlace({
            type: "geo_shape",
            index: "departamento",
            value: departments.selected._source.DPTO_CCDGO
        }, {
            departments, munis: QinitialLocation.munis
        });
    }

    const selectMuni = event => {
        handlePlace({
            type: "geo_shape",
            index: "municipio",
            value: event._source.MPIO_CCNCT
        },{
            departments, munis: {
                actual_id: event._source.MPIO_CCNCT,
                selected: event
            }
        });
    }

    const unSelectDepa = () => {
        setPlace({
            depa, municipality: {
                values: municipality.values, disabled: true
            }
        });
        handlePlace({}, {
            departments: QinitialLocation.departments,
            munis: QinitialLocation.munis
        });
    }

    const selectDepa = async ( event ) => {
       const depaSelected = depa.values.find( obj => {
           return obj._source.DPTO_CCDGO === event
       });
       handlePlace({
         type: "geo_shape",
         index: "departamento",
         value: depaSelected._source.DPTO_CCDGO
      }, {
            departments: {
               actual_id: event, selected: depaSelected
            }, munis: QinitialLocation.munis
      });

      const values = await loadMunis( event );
      setPlace({
         depa, municipality: {
               values, disabled: values[0] === undefined
         }
      });

    }

    const handleMunis = ( event ) => {
        event._id === '70d0'
        ? unSelectMuni()
        : selectMuni( event );
    }

    const handleDepartment = event => {
        event === "1234567890" ? unSelectDepa() : selectDepa( event );
    }
    
    const handleClose = () => {
        setOpen( false );
        window.location.reload();
    }

    const mapValidator = validateMapArea();

    useEffect(() => {
        loadDepartments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <>
            <div className = "Qselections">
                <div className = "Qselector selector1" id="Qdepartment">
                    <div className = "Qselector_input">
                        <UseTooltip
                            title = { tooltips.depa }
                            classes = "spaceBottom_tooltip"
                        />
                        <Autocomplete
                           blurOnSelect={ true }
                            disabled = {
                                 depa.disabled || !mapValidator
                            }
                            id = "departments" 
                            disableClearable
                            options = { depa.values }
                            noOptionsText = { 'No hay opciones' }
                            getOptionLabel = {
                                option => option._source.DPTO_CNMBR
                            }
                            isOptionEqualToValue = {
                                ( option, value ) => option._id === value._id
                            }
                            value = { departments.selected }
                            onChange = {
                                ( event, newValue ) => {
                                    handleDepartment( newValue._source.DPTO_CCDGO )
                                }
                            }
                            renderInput = {
                                    params => (
                                    <TextField
                                        { ...params }
                                        variant = "outlined"
                                        label = "Departamentos"
                                    />
                                )
                            }
                        />
                    </div>
                </div>
                <div className = "Qselector" id = "Qmuni">
                    <div className = "Qselector_input">
                        <UseTooltip
                            title = { tooltips.muni }
                            classes = "spaceBottom_tooltip"
                        />
                        {
                            reqLoading ? <QLoad/> : (
                                <Autocomplete
                                    disabled = {
                                        !mapValidator || municipality.disabled
                                    }
                                    id = "municipalities"
                                    disableClearable
                                    options = { municipality.values }
                                    getOptionLabel = {
                                        option => option._source.MPIO_CNMBR
                                    }
                                    isOptionEqualToValue = {
                                        ( option, value ) => option._id === value._id
                                    }
                                    noOptionsText = { 'No hay opciones' }
                                    value = { munis.selected }
                                    onChange = { ( event, index ) => handleMunis( index ) }
                                    renderInput = { params => (
                                        <TextField
                                            { ...params }
                                            variant = "outlined"
                                            label = "Municipios"
                                        />
                                    )}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            <UseDialog
                open = { mapValidator ? open : false }
                onClose = { handleClose }
                title = "Lo Sentimos"
                message = "Ha ocurrido un error, los datos correspondientes a departamentos y municipios no están disponibles, por favor recarga la página"
                btn = {{
                    action: handleClose,
                    text: "Recargar Página"
                }}
            />
        </>
    )

}
