import { useState, useEffect, useRef } from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { initialQuery } from 'Components/Atoms/A_Query/QinitQuery';
import { queryUrl } from 'utils/urls';
import { UseModal } from 'hooks/UseModal';
import { analyticsEventTracker, EVENT_CATEGORIES } from 'hooks/UseAnalyticsTracker';

export const ExcelModal = ({ getModalRef }) => {

    const linkRef = useRef();
    const [ openModal, setOpen ] = useState( false );

    const handleOpen = value => setOpen( value );

    const handleFile = async route => {
        
        analyticsEventTracker({
            category: EVENT_CATEGORIES.eventsTable,
            action: `Exportar como ${ route }`
        });

        try {
            const resp = await fetch (
                `${ queryUrl }api/export/${ route }`,
                {
                    method: "POST",
                    headers: new Headers({ "Content-Type": "text/plain;charset=UTF-8'"}),
                    mode: "cors",
                    cache: "default",
                    body: JSON.stringify({
                        source: [3],
                        pager: { from: 0, size: 50000 },
                        filter: initialQuery()
                    })
                }
            );
            const disposition = resp
                .headers.get( "content-disposition" );
            const parts = disposition.split(';');
            const filename = parts[1].split('=')[1].replace(
                new RegExp('"', 'g'), ''
            );
            const blob = await resp.blob();
            linkRef.current.href = window.URL.createObjectURL( blob );
            linkRef.current.download = filename;
            linkRef.current.click();
        } catch ( err ) { console.log( err ) }
    }

    useEffect(() => {
        linkRef.current = document.createElement('a');
        getModalRef( handleOpen );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <UseModal
            open = { openModal }
            handleClose = { () => setOpen( false ) }
            styles = "ExcelModal"
            component = {
                <div className = "QExcelModal">
                    <div className = "QExcelIcon">
                        <CloudDownloadIcon/>
                        <span>Descargar</span>
                    </div>
                    <div className = "QExcelBody">
                        <p>
                        Los resultados de la consulta están disponibles para descarga en los siguientes formatos: <br/>
                        <span><small>Para facilitar el manejo de datos, el máximo número de registros disponibles para descarga es 50.000</small></span>
                        </p>
                        <div>
                            <div
                                className = "QDownload"
                                onClick = { () => handleFile( 'csv' ) }
                            >
                                <span>CSV</span>
                                <span className = "QDownloadLink">
                                    { queryUrl }api/export/csv
                                </span>
                            </div>
                            <span className = "QDownloadDesc">
                                (Para hojas de cálculo)
                            </span>
                        </div>
                        <div>
                            <div
                                className = "QDownload"
                                onClick = { () => handleFile( 'geojson' ) }
                            >
                                <span>GeoJson</span>
                                <span className = "QDownloadLink">
                                    { queryUrl }api/export/geojson
                                </span>
                            </div>
                            <span className = "QDownloadDesc">
                                (Para desarrolladores de aplicaciones)
                            </span>
                        </div>
                        <div>
                            <div
                                className = "QDownload"
                                onClick = { () => handleFile( 'kml' ) }
                            >
                                <span>KML</span>
                                <span className = "QDownloadLink">
                                    { queryUrl }api/export/kml
                                </span>
                            </div>
                            <span className = "QDownloadDesc">
                                (Para Google Earth)
                            </span>
                        </div>
                        <div>
                            <div
                                className = "QDownload"
                                onClick = { () => handleFile( 'excel' ) }
                            >
                                <span>XLS</span>
                                <span className = "QDownloadLink">
                                    { queryUrl }api/export/excel
                                </span>
                            </div>
                            <span className = "QDownloadDesc">
                                (Para hojas de cálculo)
                            </span>
                        </div>
                    </div>
                    <div className="QExcelClose">
                        <span onClick = { () => setOpen( false ) }>CERRAR</span>
                    </div>
                </div>
            }
        />
    )
}