import { useEffect, useRef } from 'react';
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Stats from "three/examples/jsm/libs/stats.module.js";

export const Volcano3D = ({ volcano3d }) => {

    const containerRef = useRef( document.getElementById('volcan3D_img') );
    const widthRef = useRef(0);
    const heightRef = useRef(0);

    const loadAnimation = () => {

        const scene = new THREE.Scene();
        scene.background = new THREE.Color( 0xdddddd );
        const camera = new THREE.PerspectiveCamera(
            45, widthRef.current / heightRef.current, 10, 10000
        );
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize( widthRef.current, heightRef.current );
        containerRef.current.appendChild( renderer.domElement );
        
        const grid = new THREE.GridHelper(
            2000, 20, 0x000000, 0x000000
        );
        grid.material.opacity = 0.2;
        grid.material.transparent = true;
        scene.add(grid);
        
        const light = new THREE.HemisphereLight( 0xffffff, 0x444444 );
        light.position.set( 0, 300, 0 );
        scene.add( light );

        const dirLight = new THREE.DirectionalLight( 0xffffff );
        dirLight.position.set( 75, 300, -75 );
        scene.add( dirLight );

        new FBXLoader().load( volcano3d, result => {
            result.position.set(0,20,0);
            result.rotateY( 180 );
            result.traverse( child => {
                if ( child.isMesh ) {
                    const oldMat = child.material;
                    child.material = new THREE.MeshStandardMaterial({  
                        color: oldMat.color,
                        map: oldMat.map,
                        metalness: 1
                    });
                    child.castShadow = true;
                    child.receiveShadow = false;
                    child.flatshading = true;
                }
            })
            scene.add( result );
            animate();
        })
        

        const controls = new OrbitControls(
            camera, renderer.domElement
        );
        controls.target.set( 0, 0, 0 );

        const stats = new Stats();
        containerRef.current.appendChild( stats.dom )

        camera.position.z = 100;
        camera.position.y = 20;

        const animate = () => {
            requestAnimationFrame(animate);
            renderer.render( scene, camera );
            stats.update();
        };
        const onWindowResize = () => {
            widthRef.current = containerRef.current.clientWidth;
            heightRef.current = containerRef.current.clientHeight;
            camera.aspect = widthRef.current / heightRef.current;
            camera.updateProjectionMatrix();
            renderer.setSize( widthRef.current, heightRef.current );
            containerRef.current.appendChild( renderer.domElement );
        }
        animate();
        window.addEventListener( 'resize', onWindowResize );
    }

    useEffect(() => {
        containerRef.current = document.getElementById('volcan3D_img');
        widthRef.current = containerRef.current.clientWidth;
        heightRef.current = containerRef.current.clientHeight;
        containerRef.current && loadAnimation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    return (
        <div id = "volcan3D_img"/>
    )
}