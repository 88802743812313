import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import { loadAcelerations } from "redux/actionsCreators";
// import ChartSm from "./ChartSm";
import BuildSmTable from "Components/Atoms/A_EQDetails/BuildSmTable";
import { SmMap } from "Components/Molecules/M_EQDetails/SmMap";
import { SmTable } from "Components/Molecules/M_EQDetails/SmTable";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import HighChartSm from "./HighChartSm";
import { TabViews } from "Components/UI/TabViews";


const EQsmTabs = ({ loadSm, handleNotEvent }) => {

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const event = useSelector( state => state.detailEvent );
  const accelerations = useSelector( state => state.aceleraciones );
  
  const currentLocation = location.pathname.includes( 'detalleevento' )
    ? 'detalleevento' : 'detallesismo';

  const loadData = async () => {
    await axios.get( event[0].properties.products.sm )
    .then(({ data }) => {
      loadSm( data.sm );
    }).catch( err => {
      handleNotEvent( params.product );
      navigate( `/${ currentLocation }/${ params.id }/noData` )
    });
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <TabViews 
         tabs={[
            { label: "Aceleración por Estación" },
            { label: "Gráficas" },
            { label: "Distribución PGA" },
            { label: "Mapa Interactivo" },
            { label: "Descargas" },
            { label: "Descripción General" },
         ]}
         views={[
            // Aceleración por estación
            <BuildSmTable
               acelerations = { accelerations }
               name="Por estación"
               index = { 0 }
            />,
            // Gráficas
            <BuildSmTable
               acelerations = { accelerations }
               name="Gráficas"
               index = { 1 }
            />,
            // Distribución PGA
            <HighChartSm 
               accelerations={ accelerations } 
            />,
            // Mapa Interactivo
            <SmMap
               event = { event }
               accelerations = { accelerations }
            />,
            // Descargas
            <BuildSmTable
               acelerations = { accelerations }
               name="Descargas"
               index = { 2 }
            />,
            // Descripción general
            <SmTable/>,
         ]}
      />
  )

}

const mapDispatchToProps = dispatch => ({
  loadSm(array){
    dispatch(loadAcelerations(array))
  }
});

export default connect( null, mapDispatchToProps )( EQsmTabs );
