import { useState, useRef, useEffect } from 'react';
import { initialQuery, restartQuery } from './QinitQuery';
import { Qlimits } from './QueryMaxMin';
import { QRequest } from './QRequest';
import { QState } from './QInitState';

export const saveToStorage = ( key, value ) => {
    sessionStorage.setItem( key, value );
}

export const verifyStorage = key => {
    const value = sessionStorage.getItem( key );
    return value ? JSON.parse( value ): {}
}

const tmQueries = {
    swift: "moment_tensor_swift",
    scmtv: "moment_tensor_scmtv",
    phasew: "moment_tensor_phasew",
    isola: "moment_tensor_isola_solution"
};

export const tmApprobed = {
    moment_tensor_scmtv: { in: 'SCMTV', out: 'scmtv' },
    moment_tensor_swift: { in: 'SWIFT', out: 'swift' },
    moment_tensor_phasew: { in: 'FASE W', out: 'fasew' },
    moment_tensor_isola_solution: { in: 'ISOLA', out: 'isola' }
}

export const tmKeys = ( key, query ) => {
    return query.find(element => element.text === tmApprobed[key].in)
        ? tmApprobed[key].out : false
}

export const formatLocalTime = dateTime => {
    const month = dateTime.getMonth();
    const day = dateTime.getDate();
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    return `${dateTime.getFullYear()}-${month > 8 ? (month + 1) : 0 + `${month + 1}`}-${day > 9 ? day : 0 + `${day}`} ${hours > 9 ? hours : 0 + `${hours}`}:${minutes > 9 ? minutes : 0 + `${minutes}`}`;
}

export const QueryControl = initialState => {

    const queryErrors = useRef({});
    const isErrors = useRef(false);
    const [ state, setState ] = useState( initialState );

    const queryRef = useRef({});
    const stateRef = useRef({});
    const sortRef = useRef({});
    
    const saveStateRef = () => sessionStorage.setItem(
        'QState', JSON.stringify( stateRef.current )
    );

    const saveQueryRef = () => {
        sessionStorage.setItem(
        'query', JSON.stringify(queryRef.current)
    )
        };

    const handleSm = ( value, triggerFalse ) => {
        if ( value === false ) {
            const { queryRest, newState, refState } = triggerFalse(
                queryRef.current, state, stateRef.current
            );
            queryRef.current = queryRest;
            stateRef.current = refState;
            saveQueryRef();
            saveStateRef();
            setState({ ...newState });
        } else {
            stateRef.current.sm = value;
            queryRef.current[ 'acceleration' ] = {};
            saveStateRef();
            saveQueryRef();
            updateState( 'sm', value );
        }
    }

   const cdiActions = {
      cdi: {
         f: function(){
            delete stateRef.current[ "cdi" ];
            delete stateRef.current[ "cdi_infographic" ];
            delete queryRef.current[ "macroseismic_data_point_set" ];
            state.cdi = false;
            state.cdi_infographic = false;
            return state;
         },
         t: function( value ){
            stateRef.current["cdi"] = value;
            queryRef.current[ "macroseismic_data_point_set" ] = {}
         }
      },
      cdi_infographic: {
         f: function(){
            delete stateRef.current[ "cdi_infographic" ];
            delete queryRef.current[ "macroseismic_data_point_set" ].infographic;
         },
         t: function( value ){
            stateRef.current["cdi_infographic"] = value;
            queryRef.current[ "macroseismic_data_point_set" ].infographic = { value: 1 };
         }
      }
   }

   const handleCdiBooleans = ( key, value ) => {
      if ( value === false ){
         const newState = cdiActions[ key ].f();
         newState ? setState({ ...newState }) : updateState(key, value);
      } else {
         cdiActions[ key ].t( value );
         updateState(key, value);
      }
      saveQueryRef();
      saveStateRef();
   }

   const handleBooleans = ( key, value, triggerFalse = null ) => {
      if ( value === false && triggerFalse ) {
         const { queryRest, newState, refState } = triggerFalse(
               queryRef.current, state, stateRef.current
         );
         queryRef.current = queryRest;
         stateRef.current = refState;
         saveQueryRef();
         saveStateRef();
         setState({ ...newState });
      } else {
         stateRef.current[key] = value;
         saveStateRef();
         updateState(key, value);
      }
   }

    const handlePlace = (filter, value) => {
        queryRef.current.event.location = filter;
        stateRef.current.location = value;
        const { map_options, ...rest } = stateRef.current;
        stateRef.current = { ...rest };
        saveQueryRef();
        saveStateRef();
        updateState('location', value);
    }

    const organizeGeometry = filter => {
        const { geometry } = filter;
        const {
            top_left_lat,
            bottom_right_lat
        } = geometry.top_left.lat > geometry.bottom_right.lat
                ? {
                    top_left_lat: geometry.top_left.lat,
                    bottom_right_lat: geometry.bottom_right.lat
                } : {
                    top_left_lat: geometry.bottom_right.lat,
                    bottom_right_lat: geometry.top_left.lat
                };
        const {
            bottom_right_lon,
            top_left_lon
        } = geometry.top_left.lon > geometry.bottom_right.lon
                ? {
                    top_left_lon: geometry.bottom_right.lon,
                    bottom_right_lon: geometry.top_left.lon
                } : {
                    top_left_lon: geometry.top_left.lon,
                    bottom_right_lon: geometry.bottom_right.lon
                }
        return {
            ...filter,
            geometry: {
                ...geometry,
                bottom_right: {
                    lat: bottom_right_lat,
                    lon: bottom_right_lon
                },
                top_left: {
                    lat: top_left_lat,
                    lon: top_left_lon
                }
            }
        }
    }

    const handleArea = (filter, value) => {
        if (filter.geometry && filter.geometry.bottom_right) {
            filter = organizeGeometry(filter);
        }
        queryRef.current.event.location = filter;
        stateRef.current.map_options = value;
        const { location, ...rest } = stateRef.current;
        stateRef.current = { ...rest };
        saveQueryRef();
        saveStateRef();
        updateState('map_options', value);
    }
    const handleTime = ( queryKey, storeKey, value ) => {
        const actualDate = new Date( value.data.getTime() - 18000000 );
        if ( queryKey === 'min' ) {
            const maxDate = new Date( state.endTime.data.getTime() - 18000000 );
            queryRef.current.event.local_date = {
                min: actualDate, max: maxDate
            }
            stateRef.current = {
                ...stateRef.current,
                startTime: actualDate, endTime: maxDate
            }
        } else {
            const minDate = new Date( state.startTime.data.getTime() - 18000000 );
            queryRef.current.event.local_date = {
                min: minDate, max: actualDate
            }
            stateRef.current = {
                ...stateRef.current,
                startTime: minDate, endTime: actualDate
            }
        }
        saveQueryRef();
        saveStateRef();
        updateState( storeKey, value );
        setState({
            ...state,
            startTime: {
                data: state.startTime.data,
                message: "", validate: false
            },
            endTime: {
                data: state.endTime.data,
                message: "", validate: false
            },
            [storeKey]: value
        });
    }

    const getLocalTime = timeKey => {
        const time = new Date(state[timeKey].data);
        return formatLocalTime( time );
    }

    const handleStations = value => {
        if (value.filters.length === 0 && queryRef.current.acceleration) {
            delete queryRef.current.acceleration;
        } else {
            queryRef.current.acceleration = {
                station: { value: value.filters }
            };
        }
        stateRef.current.stations = value.filters
        saveQueryRef();
        saveStateRef();
        updateState('stations', value);
    }

    const inputNanValue = ( key, value ) => {
        isErrors.current = value.validate
        if (queryRef.current.event[value.parent]) {
            if (state[value.reverse].data === "") {
                delete queryRef.current.event[value.parent];
            } else {
                queryRef.current.event[
                    value.parent
                ][value.type] = Qlimits[key];
            }
            saveQueryRef();
        }
        stateRef.current[key] = value.data;
        saveStateRef();
        updateState(key, value);
    }

    const validateTmMagnitude = () => {
        let tmMagnitude = {
            validate: false, out: {
                magnitude: {
                    min: state.min_mag_tm.limits.min,
                    max: state.max_mag_tm.limits.max
                }
            }
        }
        if (state.min_mag_tm.data !== "") {
            tmMagnitude.out.magnitude.min = state.min_mag_tm.data
            tmMagnitude.validate = true;
        } if (state.max_mag_tm.data !== "") {
            tmMagnitude.out.magnitude.max = state.max_mag_tm.data
            tmMagnitude.validate = true;
        }
        return tmMagnitude;
    }

    const handleTmFilter = (key, value) => {
        if (value && queryRef.current[tmQueries[key]]){
            Object.keys(tmQueries).forEach(id => {
                delete queryRef.current[tmQueries[id]];
            });
        }
        if (!value && queryRef.current[tmQueries[key]]) {
            delete queryRef.current[tmQueries[key]];
        } else {
            const { validate, out } = validateTmMagnitude();
            queryRef.current[tmQueries[key]] = validate
                ? out : {};
        }
        stateRef.current.tm = { ...state.tm, [key]: value };
        saveQueryRef();
        saveStateRef();
        updateState('tm', stateRef.current.tm);
    }
    const createTmFilter = key => {
        queryRef.current[tmQueries[key]] = {
            magnitude: {
                min: Qlimits.min_magnitude_tm,
                max: Qlimits.max_magnitude_tm
            }
        }
    }
    const addTmFilter = (key, value, type) => {
        !queryRef.current[tmQueries[key]].magnitude
            && createTmFilter(key);
        queryRef.current[tmQueries[key]].magnitude[type] = value;
    }

    const deleteTmFilters = key => {
        Object.keys(tmQueries).forEach(id => {
            if (state.tm[id]) {
                queryRef.current[tmQueries[id]] = {}
            }
        });
        const newState = {
            ...state[key], data: "", validate: false, message: ""
        }
        saveQueryRef();
        updateState(key, newState);
    }

    const handleTmMagnitude = (key, value, type, validate, message) => {
        const newState = {
            ...state[key], data: value, validate, message
        };
        const filterValue = newState.data !== ""
            ? newState.data : newState.limits;
        state.tm.swift && addTmFilter('swift', filterValue, type);
        state.tm.phasew && addTmFilter('phasew', filterValue, type);
        state.tm.scmtv && addTmFilter('scmtv', filterValue, type);
        state.tm.isola && addTmFilter('isola', filterValue, type);
        stateRef.current[key] = newState.data;
        saveQueryRef();
        saveStateRef();
        updateState(key, newState);
    }

    const handleInputs = ( key, value ) => {
        isErrors.current = false;
        if ( !queryRef.current.event[ value.parent ] ) {
            queryRef.current.event[value.parent] = value.limits;
        } 
        if ( value.data === "" ){
            if ( state[value.reverse].data === "" ) {
                delete queryRef.current.event[ value.parent ];
            } else {
                queryRef.current.event[
                    value.parent
                ][ value.type ] = Qlimits[key];
            }
        } else {
            queryRef.current.event[value.parent] = {
                ...queryRef.current.event[value.parent],
                [ value.type ]: value.data
            };
        }
        stateRef.current[ key ] = value.data;
        saveQueryRef();
        saveStateRef();
        updateState( key, value );
    }

    const deleteLevelFilter = key => {
        const newState = { ...state[key], data: "" };
        saveStateRef.current[key] = newState.data;
        delete queryRef.current.shake_map;
        saveQueryRef();
        saveStateRef();
        updateState(key, newState);
    }

    const handleLevelIntensity = (key, value, type) => {
        if (!queryRef.current.shake_map) {
            queryRef.current.shake_map = {
                measure: value.limits
            }
        }
        queryRef.current.shake_map.measure[type] = value.data;
        stateRef.current[key] = value.data;
        saveQueryRef();
        saveStateRef();
        updateState(key, value);
    }

    const handleRadio = (value, newState) => {
        queryRef.current.event.location.distance = value;
        stateRef.current.map_options.circle.radio = value;
        saveQueryRef();
        saveStateRef();
        updateState('map_options', newState)
    }

    const updateState = (key, value) => {
        if (queryErrors.current[key]) {
            delete queryErrors.current[key];
            isErrors.current = false;
        }
        setState({ ...state, [key]: value });
    }
    const updateWithErrors = ( key, value, errors ) => {
        queryErrors.current[ key ] = [ ...errors ];
        isErrors.current = true;
        setState({ ...state, [ key ]: value });
    }

    const updateWithErrorsDouble = ( key1, key2, value1, value2, errors1, errors2 ) => {
        queryErrors.current[key1] = [...errors1];
        queryErrors.current[key2] = [...errors2];
        isErrors.current = true;
        setState({ ...state, [key1]: value1, [key2]: value2 });
    }

    const makePost = async ( form, filtersTm ) => {
        const userQuery = await QRequest(form);
        if ( userQuery === false ) { return false };
        saveToStorage( 'userQuery', JSON.stringify({
            "Departamento": state.location.departments.actual_id !== "department"
                    ? state.location.departments.selected._source.DPTO_CNMBR
                    : "",
            "Municipio": state.location.munis.actual_id !== "select"
                    ? state.location.munis.selected._source.MPIO_CNMBR
                    : "",
            "Fecha Inicial": getLocalTime('startTime'),
            "Fecha Final": getLocalTime('endTime'),
            "Magnitud Máxima": state.max_magnitude.data,
            "Magnitud Mínima": state.min_magnitude.data,
            "Profundidad Mínima": state.min_depth.data,
            "Profundidad Máxima": state.max_depth.data,
            "GAP Mínimo": state.min_gap.data,
            "GAP Máximo": state.max_gap.data,
            "RMS Mínimo": state.min_rms.data,
            "RMS Máximo": state.max_rms.data,
            "Error Latitud Mínimo": state.min_latitude_error.data,
            "Error Latitud Máximo": state.max_latitude_error.data,
            "Error Longitud Máximo": state.min_longitude_error.data,
            "Error Longitud Mínimo": state.max_longitude_error.data,
            "Error Profundidad Mínimo": state.min_depth_error.data,
            "Error Profundidad Máximo": state.max_depth_error.data,
            "Error Magnitud Mínimo": state.min_mag_error.data,
            "Error Magnitud Máximo": state.max_mag_error.data,
            "Tensor Momento": filtersTm,
            "Magnitud Mínima Tensor Momento": state.min_mag_tm.data,
            "Magnitud Máxima Tensor Momento": state.max_mag_tm.data,
            "Estaciones": state.stations.filters,
            "Nivel Intensidad Mínimo": state.min_level_intensity.data,
            "Nivel Intensidad Máximo": state.max_level_intensity.data,
            "Latitud": state.map_options.area === "circle"
                ? state.map_options.circle.lat.data.toFixed(2)
                : "",
            "Longitud": state.map_options.area === "circle"
                ? state.map_options.circle.lng.data.toFixed(2)
                : "",
            "Radio": state.map_options.area === "circle"
                ? state.map_options.circle.radio / 10000 : "",
            "Latitud Mínima": state.map_options.area === "rectangle"
                ? state.map_options.rectangle.min_lat.data.toFixed(2)
                : "",
            "Longitud Mínima": state.map_options.area === "rectangle"
                ? state.map_options.rectangle.min_lng.data.toFixed(2)
                : "",
            "Latitud Máxima": state.map_options.area === "rectangle"
                ? state.map_options.rectangle.max_lat.data.toFixed(2)
                : "",
            "Longitud Máxima": state.map_options.area === "rectangle"
                ? state.map_options.rectangle.max_lng.data.toFixed(2)
                : "",
        }));
        return {
            message: "success",
            type: "FeatureCollection",
            features: userQuery
        }
    }

    const clearFormulary = initState => {
        queryRef.current = restartQuery();
        isErrors.current = false;
        queryErrors.current = {};
        setState({ ...initState });
    }

    const preparePost = () => {
        if ( state.map_options.area === 'circle' ) {
            queryRef.current.event.location
                .distance = `${state.map_options.circle.radio}km`;
            saveToStorage('query', queryRef.current);
        }
        if (queryRef.current.event.location !== undefined) {
            const distance = queryRef.current.event.location.distance;
            if ( distance ) {
                const valueDistance = distance.replace( 'km', '' );
                queryRef.current.event.location.distance = `${ valueDistance }m`;
            }
        }

        let filtersTm = [
            { validate: state.tm.swift, text: 'SWIFT' },
            { validate: state.tm.scmtv, text: 'SCMTV' },
            { validate: state.tm.isola, text: 'ISOLA' },
            { validate: state.tm.phasew, text: 'FASE W' }
        ].filter( item => item.validate );

        if ( state.tm.available && !filtersTm[0] ) { // Controls empty TM
            queryRef.current = {
                moment_tensor_isola_solution: {},
                moment_tensor_phasew: {},
                moment_tensor_scmtv: {},
                moment_tensor_swift: {},
                ...queryRef.current
            }
            filtersTm = [
                { validate: state.tm.swift, text: 'SWIFT' },
                { validate: state.tm.scmtv, text: 'SCMTV' },
                { validate: state.tm.isola, text: 'ISOLA' },
                { validate: state.tm.phasew, text: 'FASE W' }
            ];
        }

        if ( state.mmi && !queryRef.current.shake_map ){ // Controls empty MMI
            queryRef.current.shake_map = {
               measure: { 
                  min: Qlimits.min_level_intensity, 
                  max: Qlimits.max_level_intensity
               }
            }
         }

        saveQueryRef();
        const form = new FormData();
        form.append("data", JSON.stringify({
            pager: { from: 1, size: 100 },
            source: [3],
            properties: ["*"],
            filter: queryRef.current,
            sort: { event: sortRef.current }
        }));
        return makePost( form, filtersTm );
    }

    useEffect(() => {
        queryErrors.current = {};
        isErrors.current = false;
        queryRef.current = initialQuery();
        stateRef.current = QState;
        sortRef.current = verifyStorage('querySort');
        setState(initialState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        ...state, updateState, updateWithErrors,
        deleteTmFilters, preparePost, handleArea, handlePlace,
        handleTime, handleInputs, getLocalTime,
        handleTmMagnitude, handleStations, handleRadio,
        inputNanValue, handleTmFilter, handleBooleans,
        handleLevelIntensity, deleteLevelFilter,
        saveToStorage, queryRef: queryRef.current,
        queryErrors: queryErrors.current, updateWithErrorsDouble,
        isErrors: isErrors.current, clearFormulary, handleSm, handleCdiBooleans
    }
}