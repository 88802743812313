const handleIconStyles = ( icon, open = true ) => {
    const containerIcon = document.querySelector(`#${icon.id}`)
    !open 
    ? containerIcon.classList.add( icon.classes )
    : containerIcon.classList.remove( icon.classes );
}

export const openPopup = ( id, icon = null ) => {
    icon && handleIconStyles( icon, false );
    document.querySelector( `#${id}` )
    .classList.remove( "Services_hidden" );
}

export const closePopup = ( id, icon = null ) => {
    icon && handleIconStyles( icon, true );
    document.querySelector( `#${id}` )
    .classList.add("Services_hidden");
}