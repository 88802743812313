import React from 'react';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { getQuery } from 'Components/Atoms/A_Query/QActive';
import { QStations } from './QStations';
import { QLevelIntensity } from './QLevelIntensity';
import { QTmInputs } from './QTmInputs';
import { UseSwitch } from 'hooks/UseSwitch';
import { UseTooltip } from 'hooks/UseTooltip';

const tmLabels = {
    swift:  {
        labels: "Swift",
        tooltips: `(Source parameter determination based on Waveform Inversion of Fourier Transformed seismograms): Propuesto por Nakano et. al. (2008), calcula la función temporal de la fuente y el centroide inviertiendo los sismogramas en el dominio de la frecuencia asumiendo una fuente puntual y un mecanismo de doble-cupla.`
    },
    scmtv:  {
        labels: "Scmtv",
        tooltips: `SCMTV (Seiscomp moment tensor inversion): Este módulo se encuentra implementado dentro el paquete SeisComP3, el algoritmo calcula la parte deviatorica del tensor momento asumiendo una fuente puntual. Este algoritmo invierte toda la forma de onda (ondas de cuerpo, de superficie y la fase W). La selección del tipo de fase se hace con base a la magnitud y la distancia epicentral del evento sísmico. La metodología de inversión se basa en la propuesta de Minson & Dreger (2008).`
    },
    isola:  {
        labels: "Isola",
        tooltips: `ISOLA: Desarrollado por Sokos y Zahradnik (2008,2013), basado en el método de deconvolución iterativa de Kikuchi & Kanamori (1991) para eventos locales y regionales.`
    },
    phasew: {
        labels:  "Fase W",
        tooltips: `Fase W: Este algoritmo propuesto por Kanamori & Rivera (2008), invierte la fase W, la cual es una señal de largo periodo que corresponde a la superposición de los primeros armónicos de los modos normales de la Tierra (100 s - 1000 s). Este algoritmo asume una fuente puntual y esta diseñado para caracterizar la fuente sísmica de manera rápida lo que permite hacer pronostico en la alerta por Tsunami.`
    },
};

export const QBooleans = () => {

    const {
        tm,
        sm,
        mmi,
        handleTmFilter,
        handleBooleans,
        handleSm,
    } = getQuery();
    
    const { available, ...tmInputs } = tm;

    const tmAvailable = () => tm.swift || tm.scmtv || tm.isola || tm.phasew;

    const handleTmFalse = ( userQuery, state, stateRef ) => {
        const { tm, min_mag_tm, max_mag_tm, ...refState } = stateRef;
        const {
            moment_tensor_swift,
            moment_tensor_scmtv,
            moment_tensor_phasew,
            moment_tensor_isola_solution,
            ...queryRest
        } = userQuery;
        state.tm = {
            available: false,
            swift: false,
            scmtv: false,
            isola: false,
            phasew: false
        };
        state.min_mag_tm.data = '';
        state.max_mag_tm.data = '';
        return { newState: state, queryRest, refState }
    }

    const handleSmFalse = ( userQuery, state, stateRef ) => {
        const { acceleration, ...queryRest } = userQuery;
        const  { sm, stations, ...refState } = stateRef;
        state.sm = false;
        state.stations.filters = '';
        return { newState: state, queryRest, refState };
    }

    const handleMmiFalse = ( userQuery, state, stateRef ) => {
        const { shake_map, ...queryRest } = userQuery;
        const {
            mmi, min_level_intensity,
            max_level_intensity, ...refState
        } = stateRef;
        state.mmi = false;
        state.min_level_intensity.data = '';
        state.max_level_intensity.data = '';
        return { newState: state, queryRest, refState };
    }

    return (
        <>
            <div className = "Qselections">
                <div className = "Qselector">
                    <div className = "Qselector_input">
                        <label>Tensor momento Sísmico</label>
                        <UseSwitch
                            name = "tm"
                            checked = { available }
                            onChange = {
                                ( e, value ) => handleBooleans(
                                    'tm',
                                    !value ? value : {
                                        ...tm, available: true
                                    },
                                    handleTmFalse
                                )
                            }
                        />
                    </div>
                </div>
                {
                    available && (
                        <FormGroup>
                            {
                                Object.keys( tmInputs ).map( key => {
                                    const { labels, tooltips } = tmLabels[ key ];
                                    return (
                                        <div className = "TmContainer" key = { `tm${ key }` }>
                                            <FormControlLabel
                                                control = {
                                                    <UseSwitch
                                                        checked = { tmInputs[ key ] }
                                                        name = { key }
                                                        onChange = {
                                                            () => handleTmFilter (
                                                                key, !tmInputs[ key ]
                                                            )
                                                        }
                                                    />
                                                } 
                                                label = { labels }
                                            />
                                            <UseTooltip
                                                title = { tooltips }
                                                placement = "right"
                                                classes = "spaceTm_tooltip"
                                            />
                                        </div>
                                    )
                                })
                            }
                        </FormGroup>
                    )
                }
            </div>
            { tmAvailable() && <QTmInputs /> }
            <div className = "Qselections">
                <div className = "Qselector">
                    <div className = "Qselector_input">
                        <label>Aceleraciones</label>
                        <UseSwitch
                            checked = { sm }
                            onChange = {
                                () => handleSm ( !sm, handleSmFalse )
                            }
                            name = "sm"
                        />
                    </div>
                </div>
            </div>
            { sm && <QStations /> }

            <div className = "Qselections">
                <div className = "Qselector">
                    <div className = "Qselector_input">
                        <label>Intensidad Instrumental</label>
                        <UseSwitch
                            checked = { mmi }
                            onChange = {
                                () => handleBooleans (
                                    'mmi', !mmi, handleMmiFalse
                                )
                            }
                            name = "mmi"
                        />
                    </div>
                </div>
            </div>
            { mmi && <QLevelIntensity /> }

            {/* <div className = "Qselections">
               <div className = "Qselector">
                  <div className = "Qselector_input">
                     <label>
                        Intensidad Percibida&nbsp;
                        <UseTooltip 
                           placement="bottom-start"
                           classes="inlineTooltip" size="small" 
                           title={ 
                              <> 
                                 Sismos reportados como sentidos mediante el aplicativo web de “Sismo sentido”:&nbsp;
                                 <a 
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#44BAFC" }} 
                                    href="https://sismosentido.sgc.gov.co/">
                                       https://sismosentido.sgc.gov.co/
                                 </a>.
                              </>
                           }
                        />
                     </label> 
                     <UseSwitch
                           color = "primary"
                           checked = { cdi }
                           onChange = { () => handleCdiBooleans ( 'cdi', !cdi ) }
                           name = "cdi"
                     />
                  </div>
               </div>
               {
                  cdi && (
                     <div className = "Qselector">
                        <div className = "Qselector_input">
                           <label>
                              Infografía&nbsp;
                              <UseTooltip 
                                 placement="bottom-start"
                                 classes="inlineTooltip" size="small" 
                                 title="Sismos que cuentan con un reporte gráfico simplificado del evento."
                              />   
                           </label>
                           <UseSwitch
                                 color = "primary"
                                 checked = { cdi_infographic }
                                 onChange = { () => handleCdiBooleans ( 'cdi_infographic', !cdi_infographic ) }
                                 name = "cdi"
                           />
                        </div>
                     </div>
                  )
               }
            </div> */}
        </>
    )
}