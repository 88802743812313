import Routes from "./Routes";
import { Provider } from "react-redux";
import "Styles/Sae.css";
import store from "redux/store";
import { analyticsPageTracker } from 'hooks/UseAnalyticsTracker';

analyticsPageTracker();

function SAE() {
   return (
      <Provider store={store}>
         <Routes />
      </Provider>
   )
}
export default SAE;
