import { getQuery } from 'Components/Atoms/A_Query/QActive';
import { QValidates } from './QinputsValidates';
import { UseTooltip } from 'hooks/UseTooltip';
import { UseNumberField, validateFloat } from 'hooks/UseTextField';
import { FormControl } from '@mui/material';

const formStyle = {
   margin: 1,
   minWidth: 120,
}


export const QInputs = ({ tooltips }) => {

    const {
        inputNanValue, updateWithErrors, handleInputs, ...query
    } = getQuery();

    const inputs = {
        min_magnitude: query.min_magnitude,
        max_magnitude: query.max_magnitude,
        min_depth: query.min_depth,
        max_depth: query.max_depth,
        min_rms: query.min_rms,
        max_rms: query.max_rms,
        min_gap: query.min_gap,
        max_gap: query.max_gap,
        min_mag_error: query.min_mag_error,
        max_mag_error: query.max_mag_error,
        min_depth_error: query.min_depth_error,
        max_depth_error: query.max_depth_error,
        min_latitude_error: query.min_latitude_error,
        max_latitude_error: query.max_latitude_error,
        min_longitude_error: query.min_longitude_error,
        max_longitude_error: query.max_longitude_error,
    };

    const manageInput = ( newValue, key ) => {

        newValue = validateFloat( newValue ) === true
            ? parseFloat( newValue ) : newValue;
        const actualInput = inputs[ key ];
        const maxValue = inputs[ actualInput.reverse ];
        const { validate, errors } = QValidates[ key ](
            newValue,
            maxValue.data,
            actualInput.label,
            maxValue.label,
            actualInput.limits
        );
        validate === true ? handleInputs( key, {
            ...actualInput, data: newValue,
            validate: false, message: ""
        }) : updateWithErrors( key, {
            ...actualInput, validate: true, data: newValue,
            message: `${ errors[0] }`
        }, errors );
    }

    const handleValue = ( validation, key ) => {
        validation.error === true
            ? inputNanValue(
                key, {
                    ...inputs[ key ],
                    data: "",
                    validate: validation.error,
                    message: validation.helperText
                }
            ) : manageInput( validation.value, key );
    }

    return (
        <div className = "Qselections">
            {
                Object.keys( inputs ).map(( key, index ) => {
                    return (
                        <div
                            key = { key }
                            id = { key }
                            className = {
                                index % 2 === 0
                                    ? "Qselector selector1"
                                    : "Qselector"
                            }
                        >
                            <div className = "Qselector_input">
                                <UseTooltip title = { tooltips[ key ] }/>
                                <FormControl style={ formStyle } >
                                    <UseNumberField
                                        init = { inputs[ key ].data }
                                        variant = "outlined"
                                        trigger = { handleValue }
                                        label = { inputs[ key ].label }
                                        name = { key }
                                        id = { `${ key }_input` }
                                        err = { inputs[ key ].validate }
                                        helpText = { inputs[ key ].message }
                                        aditionalConf = {{
                                            step: "0.1"
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}