import React from 'react';
import ReactApexChart from 'react-apexcharts';

export const CdiChart = ({ seriesName, getData, cdi, onlyPoints = false }) => {
    const { options, accumulated } = getData( cdi );
    return (
        <div id = "chart" style = {{ width: '100%'}} >
            <ReactApexChart
                options = { options }
                series = {onlyPoints ?
                    accumulated:
                    [ { name: seriesName, data: accumulated } ]
                }
            />
        </div>
    )
}
