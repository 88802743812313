import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import EQoriginTabs from "./EQoriginTabs";

import Loader from "Components/Pages/Loader";
import { EQOriginBodyTable } from "Components/Molecules/M_EQDetails/EQOriginBodyTable";
import ExpansionPanel from "Components/UI/ExpansionPanel";
import BasicTable from "Components/UI/BasicTable";


const columns = [ "", "Catálogo", "Magnitud", "Tiempo", "Profundidad", "Localización", "Estado", "Fuente" ];

function EQOrigen () {

   const event = useSelector( state => state.detailEvent );
   const totalOrigins = useSelector( state => state.totalOrigins );

  let display = false;
  const [ state, setState ] = useState({
    originTabs: null,
    validateTrace: true
  });

  useEffect(() => {
    document.getElementById('OriginInvisibleData')
    .style.display = display ? 'unset' : 'none';
  }, [ display ]);

  const show_data = ( data ) => {
    display = true;
    return data
  }

  // conditional
  if ( event.length > 0 ) {
    // if start
    const origin = event[0].properties.products.origin
      .filter( element => element.prefered === true );

   const Title = () => <> Ver todos los orígenes disponibles ( { totalOrigins } en total ) </>

    return (
      <div className = "EQorigen">

         <ExpansionPanel 
            title={<Title />}
         >
            <BasicTable size="medium" columns={ columns } className="OriginTable">
               <EQOriginBodyTable
                  origin = { event[0].properties.products.origin }
                  setState = { setState }
               />
            </BasicTable>
            
            <br />
            <div className="origincontributors">
               <p>
                  <span
                  className="material-icons preferedorigin"
                  style={{ fontSize: "large" }}
                  >
                  done
                  </span>
                  <span className="Emphasis-Gray SubText">
                  Corresponde al origen preferido para la solución del sismo.
                  </span>
               </p>
               <p className="Emphasis-Gray SubText">
                  <span
                  id = "OriginInvisibleData"
                  style = {{ display: 'none' }}
                  >
                  Datos aportados por: &nbsp;
                  </span>
                  {
                  event[0].properties.products.origin
                     .map(({ sourceDescription } , index ) => {
                        return (
                        sourceDescription ? (
                           <span 
                              key = {`contributors${ index }`}
                              id = {`contributors${ index }`}
                           >
                              {
                              `${ show_data( sourceDescription ) } |`
                              }
                              <br />
                           </span>
                        ) : (
                           <span
                              key = { `contributors${ index }`}
                              id = { `contributors${ index }`}
                           />
                        )
                        )
                     })
                  }
               </p>
            </div>
         </ExpansionPanel>


         <div className = "container-origin-tabs">
            {
            state.originTabs !== null ? (
               state.originTabs
            ) : (
               <EQoriginTabs
                  validateTrace = { state.validateTrace }
                  origin = { origin }
               />
            )
            }
         </div>
      
      </div>
    )
    // if end
  }
  return <Loader/>
}

export default EQOrigen;