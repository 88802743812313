
export const EventsSummary = ({ title, description }) => (
    <div className = "summary_content">
        <div>
            <h6 className = "summary_h6">
                { title }
            </h6>
            <p className = "summary_p">
                { description }
            </p>
        </div>
    </div>
);