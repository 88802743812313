import { TileLayer, LayersControl } from "react-leaflet";
import { useSelector } from 'react-redux';
import { EarthquakeMarker } from "Components/Molecules/M_Reusable/EarthquakeMarker";
import { colorsMap } from "Components/Atoms/A_Reusable/ActualDate";
import EpicenterMarker from "Components/Molecules/M_EQDetails/EpicenterMarker";
import { UseLeafletMap } from "hooks/UseLeafletMap";

const { BaseLayer } = LayersControl;

const EQshMap = ({ map_options, handleSelectMarker, handleZoom }) => {
  
   const sh = useSelector( state => state.smEvent );
   const event = useSelector( state => state.detailEvent[0] );

    const actual_date = new Date();
    const markers = sh.data.features;

    return(
      <>
        <span className = "SHtextHistory">
          Hemos encontrado { markers.length } eventos sísmicos ocurridos en los dos últimos años
        </span>
        <UseLeafletMap
          id = "EQShMap"
          position = {
            map_options.position[0] 
            ? map_options.position 
            : [ 
                event.geometry.coordinates[0],
                event.geometry.coordinates[1]
              ] 
          }
          zoom = { map_options.zoom }
          scrollWheelZoom = { true }
          mapOptions = {{ maxZoom: 13 }}
          bodyMap = {(
            <>
              <EpicenterMarker
                event = { event }
                shMode = "shMode"
                onSelect = { () => "" }
              />
              {
                markers.map( element => {
                  return(
                      <EarthquakeMarker
                        key = { element.id }
                        id = { element.id}
                        className = "EQMarkersTest"
                        defaultColor = {
                          colorsMap( element.properties, actual_date )
                        }
                        earthquake = { element }
                        isSelected = { false }
                        onSelect = {
                          () => handleSelectMarker( element )
                        }
                      />
                  )
                })
              }
              <LayersControl position = "topright">
                  <BaseLayer name = "Escala de grises">
                    <TileLayer
                      url = "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.jpg"
                    />
                  </BaseLayer>
                  <BaseLayer name = "Topográfico">
                    <TileLayer                  
                      url = "https://server.arcgisonline.com/arcgis/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}"
                    />
                  </BaseLayer>
                  <BaseLayer checked name = "Calles">
                    <TileLayer                  
                      url = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                    />
                  </BaseLayer>
                  <BaseLayer name = "Satelite">
                    <TileLayer                  
                      url = "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    />
                  </BaseLayer>              
                </LayersControl>
            </>
          )}
          isPrint
          isOverlays
        />
      </>
    )
}

export default EQshMap;
