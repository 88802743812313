import { Radio } from "@mui/material"
import { GREEN } from "utils/styles"

function SgcRadio({ value }) {
   return (
      <Radio 
         value={ value }
         sx={{
            color: GREEN,
            '&.Mui-checked': {
               color: GREEN,
             },
         }}
      />
   )
}
export default SgcRadio