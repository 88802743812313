import React from "react";
import velocidad from "assets/icons/EQViewer/velocidad_negro.svg";
import Tooltip from "@material-ui/core/Tooltip";

const BuildSm = ({ sm }) => (
	sm === true ? (
		<div>
			<Tooltip title = "Posee cálculo de aceleración">
				<img 
					className = "dates-icons"
					src = { velocidad }
					alt = "dates-icons1"
				/>
			</Tooltip>
		</div>
	) : (
		<div className = "dates-icons" style = {{ display: "none" }}>
			<img 
				className = "dates-icons"
				src = { velocidad }
				alt = "dates-icons2"
			/>
		</div>
	)
);

export default BuildSm;