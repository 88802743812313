import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles( theme => ({
  root: {
    '& > *': { marginTop: theme.spacing(2) },
    minWidth: 200
  },
}));

export const UseStack = ({ count, page, action, size }) => {
    const classes = useStyles();
    return (
      <div className = { classes.root }>
          <Pagination
              count = { count }
              page = { page }
              size = { size || 'medium' }
              onChange = { ( e, value ) => action( value ) }
          />
      </div>
    )
}
