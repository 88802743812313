let content = null;
let show_menu = false;
let count = 0;
let off_image = '';
let on_image = '';

// init the content controller
export const initControls = id => content = document.getElementById( id )

const _addSelected = tag => document
  .getElementById( tag )?.classList.add( 'selected' );

const _removeSelected = tag => {
  const element = document.getElementById( tag );
  element && element.classList.remove( 'selected' );
}

const _toogleSelected = tag => document
  .getElementById( tag ).classList.toggle( 'selected' );

const validateDelete = modes => modes.length === 1
  ? modes[0] !== 'mode-settings' : modes.length >= 1;  

  // unselect all toggles of the banner
const _unselectIcons = () =>{
  let iconList = document.getElementById('icon-list').childNodes
  for (let i = 0; i < iconList.length; i++){
    if (iconList[i].id !== 'list' && iconList[i].id !== 'map' && iconList[i].id !== 'settings' ){
      iconList[i].classList.remove( 'selected' )
    }
  }
}

const _onlyOne = view_mode => {
  const modes = content.className.split(" ");
  let selected = false;
  const temp = modes.filter( e => {
    if ( e === view_mode ) { selected = true }
    return e !== 'content' && e !== view_mode;
  })
  return !selected ? true : validateDelete ( temp );
}


export const resize_map = () => {
  if( window.location.pathname === "/catalogo-mapa" ){
    if ( window.innerWidth < 1100 ){
      _addSelected( "map" )
      _removeSelected( "list" )
      content.classList.remove( "mode-list", "mode-map" );
      content.classList.add( "mode-map" );
    }
    else {
      _addSelected( "map" );
      _addSelected( "list" );
      content.classList.remove( "mode-list", "mode-map" );
      content.classList.add( "mode-list", "mode-map" );
    }
  }
  else{
    if ( window.innerWidth < 1100 ){
      try{
         _addSelected( "list" )
         _removeSelected( "map" )
         _removeSelected( "settings" )
         content.classList.remove (
           "mode-list", "mode-map", "mode-settings"
         );
         content.classList.add( "mode-list" );
      } catch{}
    }
    else {
      _addSelected( "list" );
      _addSelected( "map" );
      _removeSelected( "settings" );
      content.classList.remove (
        "mode-list", "mode-map", "mode-settings"
      );
      content.classList.add( "mode-list", "mode-map" );
    }

  }
  
}

// const handleMobileCatalog = view => {
//   if ( _onlyOne( `mode-${ view }` ) ) {
//     content.classList.toggle( `mode-${ view }` );
//     _toogleSelected( view );
//   }
//   switch( view ) {
//     case "list":
//       content.classList.remove( "mode-map" );
//       _removeSelected( "map" );
//       break;
//     case "map":
//       content.classList.remove( "mode-list" );
//       _removeSelected( "list" );
//       break;
//     default:
//       content.classList.remove( "mode-map", "mode-list" );
//       _removeSelected( "list" );
//       _removeSelected( "map" );
//   }
// }

const handleMobile = view => {
  if ( _onlyOne( `mode-${ view }` ) ) {
    content.classList.toggle( `mode-${ view }` );
    _toogleSelected(view);
  }
  switch( view ) {
    case "list":
      content.classList.remove( "mode-map", "mode-settings" );
      _removeSelected( "map" );
      _removeSelected( "settings" );
      break;
    case "map":
      content.classList.remove( "mode-list", "mode-settings" );
      _removeSelected( "list" );
      _removeSelected( "settings" );
      break;
    default:
      content.classList.remove( "mode-map", "mode-list" );
      _removeSelected( "list" );
      _removeSelected( "map" );
  }
}

const handleDesktop = view => {
  if ( _onlyOne(`mode-${ view }`) ) {
    content.classList.toggle( `mode-${ view }` );
    _toogleSelected( view )
  }
}

// controls the banner items except map and list
export const displayControls = view => {

  let selected = 
    document.getElementById(view).classList.contains( 'selected' )

  if (selected === false){

    _unselectIcons();

    if ( !content.className.includes( `mode-catalog`) ){

      content.className = 'content'; // remove all classes
      content.style.display = 'block';
      content.style.overflowY = 'scroll';
      _removeSelected( "list" );
      _removeSelected( "map" );
      content.classList.toggle( `mode-catalog` );

    }

    _toogleSelected( view )
  }

}

// controls map, list and settings
export const mapControls = ( view, viewMode = '' ) => {

  content.style.display = 'grid';
  content.style.overflow = 'hidden';
  // content.classList.remove("mode-catalog");
  _unselectIcons();
  window.screen.width > 1200
    ? handleDesktop( view ) : handleMobile( view );
}
/**
 * services menu Management 
 */
export const close_menu = ( id, style, icon_id ) => {
  if ( count === 1 ) { count = 0 }
  else {
    off_state( id, style, icon_id );
    show_menu = false;
  }
}

const on_state = ( id, style, icon_id ) => {
  document.getElementById(id).classList.remove( style );
  let image_icon = document.getElementById( icon_id );
  image_icon.classList.add( "Services_icon_on" );
  image_icon.src = on_image;
}
const off_state = ( id, style, icon_id ) => {
  document.getElementById( id ).classList.add( style );
  let image_icon = document.getElementById( icon_id );
  image_icon.classList.remove( "Services_icon_on" );
  image_icon.src = off_image;
}
export const display_menu = (
    event, id, style, icon_id, img_off, img_on
) => {
  event.preventDefault();
  off_image = img_off;
  on_image = img_on;
  !show_menu 
    ? on_state( id, style, icon_id )
    : off_state( id, style, icon_id );
  show_menu = !show_menu;
  count++;
}

export const displayServicesMenu = (
  parent, style, child, childStyle, quit
) => {
  if ( quit === true ) {
    parent.classList.remove( style );
    child.classList.add( childStyle );
  }
  else {
    if ( parent.classList.contains( style ) ) {
      parent.classList.remove( style );
      child.classList.add( childStyle );
    }
    else {
      parent.classList.add( style );
      child.classList.remove( childStyle );
    }
  }
}

export const ControlsMap = minorSize => {
  const { modes, list_selected, map_selected } = window.screen
    .width < minorSize ? {
      modes: "content mode-list",
      list_selected: "modes-view-item selected",
      map_selected: "modes-view-item"
    } : {
      modes: "content mode-list mode-map",
      list_selected: "modes-view-item selected",
      map_selected: "modes-view-item selected"
    }
  return { modes, list_selected, map_selected }
}