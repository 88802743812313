export const detailEventExample = [
   {
       "geometry": {
           "type": "Point",
           "coordinates": [
               5.520866394,
               -72.29714966,
               10
           ]
       },
       "type": "Feature",
       "properties": {
           "status": "automatic",
           "rms": 1.2,
           "maxPGA": 3.253247,
           "felt": 348,
           "products": {
               "origin": [
                   {
                       "status": "automatic",
                       "source": "SGC",
                       "sourceDescription": "Servicio Geológico Colombiano",
                       "properties": {
                           "longitudeError": 3.718,
                           "eventType": "earthquake",
                           "latitudeError": 2.318,
                           "depthError": 0,
                           "magnitudeSource": "SGC",
                           "standardError": 1.2,
                           "title": "Paya - Boyacá, Colombia",
                           "originSource": "SGC",
                           "utctime": "2024-04-02 23:16:53",
                           "magnitudeType": "M_Pac",
                           "latitude": 5.520866394,
                           "numPhasesUsed": 32,
                           "localtime": "2024-04-02 18:16:53",
                           "reviewStatus": "rejected",
                           "numStationsUsed": 32,
                           "azimuthalGap": 147,
                           "magnitudeAzimuthalGap": null,
                           "magnitudeNumStationsUsed": 5,
                           "horizontalError": null,
                           "longitude": -72.29714966,
                           "depth": 10,
                           "magnitude": 4.7,
                           "magnitudeError": null
                       },
                       "phases": [
                           {
                               "status": "Automatico",
                               "azimuth": 295.4539185,
                               "residual": -2.517942429,
                               "phase": "P",
                               "distance": 0.8659236431,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "RUS",
                               "time": "2024-04-02 23:17:07",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 172.9747925,
                               "residual": 0.1589241028,
                               "phase": "P",
                               "distance": 1.331844807,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "PTGC",
                               "time": "2024-04-02 23:17:17",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 238.2059174,
                               "residual": -0.4636440277,
                               "phase": "P",
                               "distance": 1.688704133,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "CHI",
                               "time": "2024-04-02 23:17:22",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 274.3363342,
                               "residual": -0.01209449768,
                               "phase": "P",
                               "distance": 1.771623015,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "SPBC",
                               "time": "2024-04-02 23:17:23",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 224.7037964,
                               "residual": -0.4664382935,
                               "phase": "P",
                               "distance": 1.980114341,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "VIL",
                               "time": "2024-04-02 23:17:26",
                               "net": "CM",
                               "channel": "EHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 347.6229858,
                               "residual": 1.100637436,
                               "phase": "P",
                               "distance": 1.862060547,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "PAM",
                               "time": "2024-04-02 23:17:26",
                               "net": "CM",
                               "channel": "EHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 240.6182251,
                               "residual": 0.4614925385,
                               "phase": "P",
                               "distance": 2.032672644,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "CVER",
                               "time": "2024-04-02 23:17:28",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 241.3841553,
                               "residual": -0.9671096802,
                               "phase": "P",
                               "distance": 3.351788282,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "ORTC",
                               "time": "2024-04-02 23:17:44",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 281.9342957,
                               "residual": 0.1773300171,
                               "phase": "P",
                               "distance": 3.284041166,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "HEL",
                               "time": "2024-04-02 23:17:45",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 275.4906616,
                               "residual": 1.208011627,
                               "phase": "P",
                               "distance": 3.712605953,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "CBOC",
                               "time": "2024-04-02 23:17:52",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 26.0347805,
                               "residual": 1.026123047,
                               "phase": "P",
                               "distance": 3.7458992,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "SDV",
                               "time": "2024-04-02 23:17:52",
                               "net": "IU",
                               "channel": "BHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 304.943634,
                               "residual": -0.2146644592,
                               "phase": "P",
                               "distance": 3.912011862,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "URE",
                               "time": "2024-04-02 23:17:53",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 291.2585449,
                               "residual": 1.081977844,
                               "phase": "P",
                               "distance": 4.167524338,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "DBB",
                               "time": "2024-04-02 23:17:58",
                               "net": "CM",
                               "channel": "EHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 223.8419189,
                               "residual": -1.640598297,
                               "phase": "P",
                               "distance": 4.613279819,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "GARC",
                               "time": "2024-04-02 23:18:01",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 233.8561401,
                               "residual": -0.7756767273,
                               "phase": "P",
                               "distance": 4.52506876,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "MARO",
                               "time": "2024-04-02 23:18:01",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 336.1201172,
                               "residual": 2.262928009,
                               "phase": "P",
                               "distance": 4.747141838,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "ARGC",
                               "time": "2024-04-02 23:18:06",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 242.3243561,
                               "residual": -1.052585602,
                               "phase": "P",
                               "distance": 4.935199261,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "JAMC",
                               "time": "2024-04-02 23:18:06",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 232.1771393,
                               "residual": -0.3467521667,
                               "phase": "P",
                               "distance": 5.1886549,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "CONO",
                               "time": "2024-04-02 23:18:10",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 327.0471191,
                               "residual": 1.129276276,
                               "phase": "P",
                               "distance": 5.225119591,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "SJC",
                               "time": "2024-04-02 23:18:12",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 354.0357361,
                               "residual": -0.6151390076,
                               "phase": "P",
                               "distance": 5.529452324,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "CRJC",
                               "time": "2024-04-02 23:18:14",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 231.9519196,
                               "residual": -0.6131248474,
                               "phase": "P",
                               "distance": 5.473499775,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "SOTO",
                               "time": "2024-04-02 23:18:14",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 229.7672882,
                               "residual": 0.4539146423,
                               "phase": "P",
                               "distance": 6.098524094,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "CRU",
                               "time": "2024-04-02 23:18:23",
                               "net": "CM",
                               "channel": "EHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 2.763875723,
                               "residual": -1.175029755,
                               "phase": "P",
                               "distance": 6.188436985,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "URI",
                               "time": "2024-04-02 23:18:23",
                               "net": "CM",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 300.1025696,
                               "residual": -2.699214935,
                               "phase": "P",
                               "distance": 6.870178699,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "TOTI",
                               "time": "2024-04-02 23:18:31",
                               "net": "RP",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 230.4985809,
                               "residual": 0.988407135,
                               "phase": "P",
                               "distance": 7.170689583,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "CUM",
                               "time": "2024-04-02 23:18:39",
                               "net": "CM",
                               "channel": "EHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 228.9443207,
                               "residual": 0.2959327698,
                               "phase": "P",
                               "distance": 7.288079739,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "TULM",
                               "time": "2024-04-02 23:18:40",
                               "net": "EC",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 229.6335449,
                               "residual": 2.157840729,
                               "phase": "P",
                               "distance": 8.019589424,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "CUSE",
                               "time": "2024-04-02 23:18:51",
                               "net": "EC",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 286.4144287,
                               "residual": 0.2658882141,
                               "phase": "P",
                               "distance": 8.24115181,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "AZU",
                               "time": "2024-04-02 23:18:53",
                               "net": "RP",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 290.8651733,
                               "residual": 1.712459564,
                               "phase": "P",
                               "distance": 8.502586365,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "PNME",
                               "time": "2024-04-02 23:18:58",
                               "net": "RP",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 221.2114868,
                               "residual": 1.04327774,
                               "phase": "P",
                               "distance": 10.58884525,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "COHC",
                               "time": "2024-04-02 23:19:25",
                               "net": "EC",
                               "channel": "HHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 4.092706203,
                               "residual": 0.1035499573,
                               "phase": "P",
                               "distance": 13.49713326,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "SDDR",
                               "time": "2024-04-02 23:20:04",
                               "net": "CU",
                               "channel": "BHZ",
                               "published": true
                           },
                           {
                               "status": "Automatico",
                               "azimuth": 24.97943115,
                               "residual": -2.068294525,
                               "phase": "P",
                               "distance": 13.94634247,
                               "author": "pick_reg@seismo1.sgc.gov.co",
                               "station": "SJG",
                               "time": "2024-04-02 23:20:08",
                               "net": "IU",
                               "channel": "BHZ",
                               "published": true
                           }
                       ],
                       "traces": "https://archive.sgc.gov.co/events/SGC2024gorcrq/traces.png",
                       "type": "origin",
                       "id": "Origin202404030016020854970139",
                       "updateTime": "2024-04-22 15:53:41",
                       "prefered": true
                   }
               ],
               "originHistory": null,
               "mmi": {
                   "tabs": {
                       "pgv": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mmi/pgv.jpg",
                       "intensity": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mmi/intensity.jpg",
                       "pga": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mmi/pga.jpg"
                   },
                   "download": {
                       "gis": [
                           {
                               "link": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mmi/raster.zip",
                               "name": "Raster"
                           },
                           {
                               "link": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mmi/shakemap.kmz",
                               "name": "KMZ"
                           },
                           {
                               "link": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mmi/shape.zip",
                               "name": "Shape"
                           }
                       ],
                       "grid": [
                           {
                               "link": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mmi/grid.xml",
                               "name": "Grid"
                           }
                       ],
                       "complementary": [
                           {
                               "link": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mmi/grid.xml",
                               "name": "Curvas de Atenuación"
                           },
                           {
                               "link": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mmi/info.json",
                               "name": "Información General"
                           },
                           {
                               "link": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mmi/stationlist.json",
                               "name": "Lista de Estaciones"
                           },
                           {
                               "link": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mmi/uncertainty.xml",
                               "name": "Incertidumbre"
                           }
                       ]
                   }
               },
               "sm": "https://archive.sgc.gov.co/events/SGC2024gorcrq/sm.json",
               "staticmap": "https://archive.sgc.gov.co/events/SGC2024gorcrq/map.gif",
               "downloads": [
                   {
                       "link": "https://archive.sgc.gov.co/events/SGC2024gorcrq/SGC2024gorcrq.kml",
                       "name": "KML"
                   },
                   {
                       "link": "https://archive.sgc.gov.co/events/SGC2024gorcrq/SGC2024gorcrq.xml",
                       "name": "QuakeML"
                   },
                   {
                       "link": "http://sismo.sgc.gov.co:8080/",
                       "name": "Descarga Forma de Onda"
                   }
               ],
               "momenttensor": [
                   {
                       "methodname": "SCMTV",
                       "locationcentroid": {
                           "unity": "N.m",
                           "M0": 11510031070000000,
                           "mag": 4.7,
                           "time": "2024-04-02 23:16:53",
                           "latitude": 5.520866,
                           "magType": "Mw",
                           "depth": 10,
                           "longitude": -72.29715
                       },
                       "img": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mt/scmtv/beachball.png",
                       "momenttensor": [
                           {
                               "name": "Mrr",
                               "value": -1081074248000000
                           },
                           {
                               "name": "Mtt",
                               "value": 10443164320000000
                           },
                           {
                               "name": "Mpp",
                               "value": -9362090070000000
                           },
                           {
                               "name": "Mrt",
                               "value": -2813312396000000
                           },
                           {
                               "name": "Mrp",
                               "value": -1759086756000000
                           },
                           {
                               "name": "Mtp",
                               "value": -4746908034000000
                           }
                       ],
                       "nodalplanes": {
                           "NP1": {
                               "Strike": 327.6272487,
                               "Rake": -2.285161901,
                               "Dip": 72.90499162
                           },
                           "NP2": {
                               "Strike": 58.29931377,
                               "Rake": -162.8921802,
                               "Dip": 87.81584975
                           }
                       },
                       "principalaxes": {
                           "P": {
                               "Plg": 13.58029606,
                               "Azm": 284.2515751,
                               "Val": -11006210380000000
                           },
                           "T": {
                               "Plg": 10.42278151,
                               "Azm": 191.7048493,
                               "Val": 11955106040000000
                           },
                           "N": {
                               "Plg": 72.75745444,
                               "Azm": 65.3579122,
                               "Val": -948895662200000
                           }
                       }
                   },
                   {
                       "methodname": "SWIFT",
                       "locationcentroid": {
                           "unity": "N.m",
                           "M0": "1.5500e+16",
                           "mag": "4.7",
                           "time": "2024-04-02 23:16:53",
                           "latitude": "5.5",
                           "magType": "Mw",
                           "depth": "10",
                           "longitude": "-72.3"
                       },
                       "img": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mt/swift/beachball.png",
                       "momenttensor": [
                           {
                               "name": "Mxx",
                               "value": "1.4827e+16"
                           },
                           {
                               "name": "Myy",
                               "value": "-1.4902e+16"
                           },
                           {
                               "name": "Mzz",
                               "value": "7.5284e+13"
                           },
                           {
                               "name": "Mxy",
                               "value": "4.2238e+15"
                           },
                           {
                               "name": "Myz",
                               "value": "-2.2242e+14"
                           },
                           {
                               "name": "Mzx",
                               "value": "1.1853e+15"
                           }
                       ],
                       "nodalplanes": {
                           "NP1": {
                               "Strike": "53",
                               "Rake": "176",
                               "Dip": "88"
                           },
                           "NP2": {
                               "Strike": "143",
                               "Rake": "2",
                               "Dip": "86"
                           }
                       },
                       "tsunami": {
                           "fault_length": null,
                           "fault_dip": null,
                           "fault_slip": null,
                           "fault_width": null,
                           "max_tsunami_lon": null,
                           "fault_strike": null,
                           "fault_depth": null,
                           "fault_lon": null,
                           "max_tsunami_lat": null,
                           "fault_rake": null,
                           "max_tsunami_heigth": null,
                           "fault_lat": null
                       },
                       "principalaxes": {
                           "P": {
                               "Plg": "4",
                               "Azm": "8",
                               "Val": null
                           },
                           "T": {
                               "Plg": "1",
                               "Azm": "98",
                               "Val": null
                           },
                           "N": {
                               "Plg": "90",
                               "Azm": "-156",
                               "Val": null
                           }
                       }
                   }
               ],
               "cdi": "https://s3.amazonaws.com/sgc.sites.gov.co/events/SGC2024gorcrq/cdi.json",
               "sh": null
           },
           "feltReport": "Casanare (239), Bogotá  D. C. (30), Meta (24), Boyacá (21), Santander (19), Cundinamarca (11), Arauca (3), Norte De Santander (1)",
           "type": "earthquake",
           "magType": "M_Pac",
           "agency": "SGC",
           "cdi": 5,
           "utcTime": "2024-04-02 23:16:53",
           "gap": 147,
           "closerTowns": "Nunchía (Casanare) a 16 km, Paya (Boyacá) a 18 km, Pisva (Boyacá) a 31 km",
           "beachBall": "https://archive.sgc.gov.co/events/SGC2024gorcrq/mt/swift/beachball_small.png",
           "place": "Paya - Boyacá, Colombia",
           "mag": 4.7,
           "mmi": 4,
           "nst": 32,
           "types": [
               "staticmap",
               "overview",
               "map",
               "origin",
               "cdi",
               "mmi",
               "sm",
               "momenttensor",
               "downloads"
           ],
           "localTime": "2024-04-02 18:16:53"
       },
       "id": "SGC2024gorcrq"
   }
]