import React, { useEffect, useState } from 'react';
import { reqQuery } from 'api/ReqResApi';
import { GeoJSON, Circle, useMap } from 'react-leaflet';
import { actualQuery } from './QRequest';

function TensorArea ({ filter }) {

    const [ shape, setShape ] = useState({
        distance: 0, type: "", coordinates: []
    });

    const map = useMap();

    const requestCQL = async () => {
        const formData = new FormData();
        formData.append (
            'data', JSON.stringify({
                pager: { from: 1, size: 1000 },
                source: [3],
                properties: ["*"],
                filter: actualQuery()
            })
        );
        try {
            const { data } = await reqQuery
                .post( 'api/transform/query/cql_wms', formData );
            if ( data ) {
                const { draw, bound } = data;
                setShape({
                    distance: draw.distance ? parseInt( draw.distance ) : 0,
                    type: draw.type.charAt(0).toUpperCase() + draw.type.slice(1),
                    coordinates: draw.coordinates
                })
                bound[0] && map.fitBounds( bound );
            }
        } catch {
         map.setZoom(4);
        }
    }

    const createJSON = () => ({
        name: 'GeoJsonLayer',
        type: "FeatureCollection",
        features: [{
            type: "Feature",
            geometry: {
                coordinates: shape.coordinates,
                type: shape.type
            },
            properties: null
        }]
    })

    useEffect(() => {
      setShape({
         distance: 0, type: "", coordinates: []
      });
      requestCQL();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filter]);

    return shape.coordinates && shape.coordinates[0] ? (
        shape.type === "Polygon" ? (
            <GeoJSON
                data = { createJSON() }
                pathOptions={{ fill: false }}
                weight = { 4 }
                color = "red"
            />
        ) : (
            <Circle
                center = { shape.coordinates }
                radius = { shape.distance }
                fill={ false }
                color = "red"
            />
        )
    ) : <></>
}

export default TensorArea;
