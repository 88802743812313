import EQMmiDownloadCard from "./EQMmiDownloadCard";
import { TabViews } from "Components/UI/TabViews";

const EQmmiTabs = ({ mmi }) => {
  
  return (
      <TabViews 
         tabs={[
            { label: "Intensidad Instrumental" },
            { label: "Aceleración" },
            { label: "Velocidad" },
            { label: "Descargas" },
         ]}
         views={[
            // Intensidad Instrumental
            <div className="EQintensity">
               <div className="container-img">
               <img src={mmi.tabs.intensity} alt="EQintensity"></img>
               </div>
            </div>,
            // Aceleración
            <div className="EQpga">
               <div className="container-img">
               <img width="50%" src={mmi.tabs.pga} alt="EQpga"></img>
               </div>
            </div>,
            // Velocidad
            <div className="EQpgv">
               <div className="container-img">
               <img width="50%" src={mmi.tabs.pgv} alt="EQpgv"></img>
               </div>
            </div>,
            // Descargas
            <div className="EQDownloads">
               <div className="EQDownloads-main">
                  <EQMmiDownloadCard 
                     title = "Información Complementaria"
                     id = "complementary"
                     data = { mmi.download.complementary }
                  />
                  <EQMmiDownloadCard 
                     title = "Grillas"
                     id = "grid"
                     data = { mmi.download.grid }
                  />
                  <EQMmiDownloadCard 
                     title = "Archivos GIS"
                     id = "gis"
                     data = { mmi.download.gis }
                  />
               </div>
            </div>
         ]}
      />
  );
};
export default EQmmiTabs;