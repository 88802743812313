import { createContext, useReducer } from 'react';
import { seismicityReducer } from './SeismicityReducer';
import { defaultTimeRange } from 'utils/seismicityUtils';

export const seismicityInitState = {
   currentEvents: [],
   requestTimeRange: defaultTimeRange,
   selectedEvent: null,
   eventDate: null,
   initSimulation: false,
   stopSimulation: false,
   resetSimulation: false,
}

export const SeismicityContext = createContext({});

export const SeismicityProvider = ({ children }) => {
   
   const [ state, dispatch ] = useReducer(
      seismicityReducer, seismicityInitState
   );

   const setCurrentEvents = value => {
      dispatch({ type: 'SET_CURRENT_EVENTS', payload: value })
   }

   const setSelectedEvent = value => {
      dispatch({ type: 'SET_SELECTED_EVENT', payload: value })
   }

   const setRequestTimeRange = value => {
      dispatch({ type: 'SET_REQUEST_TIMERANGE', payload: value })
   }

   const setInitSimulation = () => {
      dispatch({ type: 'SET_INIT' })
   }

   const setStopSimulation = value => {
      dispatch({ type: 'SET_STOP', payload: value })
   }

   const setResetSimulation = () => {
      dispatch({ type: 'SET_RESET' })
   }

   return (
      <SeismicityContext.Provider
         value={{ ...state, 
            setStopSimulation, setCurrentEvents, setRequestTimeRange, 
            setInitSimulation, setSelectedEvent, setResetSimulation 
         }}
      >
         { children }
      </SeismicityContext.Provider>
   )
}