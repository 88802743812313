import React from 'react';
import triangle1 from 'assets/icons/Reusable/Viewer_services/setting_services/triangle1.svg';
import triangle2 from 'assets/icons/Reusable/Viewer_services/setting_services/triangle2.svg';
import triangle3 from 'assets/icons/Reusable/Viewer_services/setting_services/triangle3.svg';
import triangle4 from 'assets/icons/Reusable/Viewer_services/setting_services/triangle4.svg';
import triangle5 from 'assets/icons/Reusable/Viewer_services/setting_services/triangle5.svg';
// import triangle6 from 'assets/icons/Reusable/Viewer_services/setting_services/triangle6.svg';

const select_icon = ( icon, label ) => {
    switch ( icon ) {
        case 1:
            return <img src={ triangle1 } alt={ label }/>
        case 2:
            return <img src={ triangle3 } alt={ label }/>
        case 3:
            return <img src={ triangle2 } alt={ label }/>
        case 4:
            return <span></span>
        case 5:
            return <span></span>
        case 6:
            return <span></span>
        case 7:
            return <img src={ triangle4 } alt={ label }/>
        case 8:
            return <img src={ triangle5 } alt={ label }/>
        default:
            return <span></span>
    }
}

const build_settings_label = ( label, icon, text_value ) => {

    return (
        icon ? (
            <div className="Settings_label">
                { select_icon( icon, label ) }
                <span>
                    {
                        text_value ? (
                            text_value
                        ) : (
                            <span>
                                { label }
                            </span>
                        )
                    }       
                </span>
            </div>
        ) : (
            <span>
                { label }
            </span>
        )
    )

}
export default build_settings_label;