import { useState } from "react";
import { connect } from "react-redux";
import MapLegend from 'Components/Molecules/M_Reusable/MapLegend';
import { TileLayer, LayersControl } from 'react-leaflet';
import { Popover } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "Components/Pages/Loader";
import VolcanoIconScale from 'Components/Molecules/M_Reusable/VolcanoIconScale';
import DepthPointsScale from 'Components/Molecules/M_Reusable/DepthPointsScale';
import EarthquakePointsScale from 'Components/Molecules/M_Reusable/EarthquakePointsScale';
import { UseLeafletMap } from "hooks/UseLeafletMap";
import { EarthquakeDinamicMarker } from "Components/Molecules/M_Reusable/EarthquakeDinamicMarker";

const { BaseLayer } = LayersControl;

const useStyles = new makeStyles((theme) => ({
  button: {
    backgroundColor: "#fafafa",
    zIndex: 1000,
    marginTop: "80px",
    fontSize: "100%",
  },
  popup: {
    zIndex: 1000,
    backgroundColor: "fafafa",
    marginBottom: "20px",
  },
}));

const EQDinamicMap = ({ event }) => {

  const styles = useStyles();
  const [ anchorLegend, setAnchorLegend ] = useState( null );
  const openLegend = Boolean( anchorLegend );
  const legendId = openLegend ? "legend" : undefined;

  const handleCloseLegend = () => setAnchorLegend( null );

  const handleOnClickLegend = ({ currentTarget }) => {
    setAnchorLegend( currentTarget );
  };

  return event ? (
    <div className="EQDinamicMap">
      <UseLeafletMap
         id = "dinamic_map"
         position = {
            [ 
               event.geometry.coordinates[0] + 0.5,
               event.geometry.coordinates[1] 
            ]
         }
         mapOptions = {{ maxZoom: 13 }}
         zoom = {8}
         bodyMap = {(
            <>
               <EarthquakeDinamicMarker
               earthquake = { event }
               onSelect = { () => '' }
               moreRecent = "false"
               />
               <div id = "mapLegendContainer">
               <Button
                  aria-describedby = { legendId }
                  size = "small"
                  onClick = { handleOnClickLegend }
                  className = { styles.button }
                  variant = "contained"
               >
                  Leyenda
               </Button>
               <Popover
                  id={legendId}
                  className={styles.popup}
                  open={openLegend}
                  onClose={handleCloseLegend}
                  anchorEl={anchorLegend}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  transformOrigin={{ vertical: "bottom", horizontal: "center" }}
               >
                  <MapLegend 
                     DepthPointsScale={<DepthPointsScale/>}
                     EarthquakePointsScale={<EarthquakePointsScale/>}
                     VolcanoIconScale={<VolcanoIconScale/>}
                  />
               </Popover>
               </div>
   
               <LayersControl position="topright">
               <BaseLayer checked name="Escala de grises">
                  <TileLayer
                     
                     url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.jpg"
                  />
               </BaseLayer>
               <BaseLayer name="Topográfico">
                  <TileLayer
                     url="https://server.arcgisonline.com/arcgis/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}"
                  />
               </BaseLayer>
               <BaseLayer checked name="Calles">
                  <TileLayer               
                     url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                  />
               </BaseLayer>
               <BaseLayer name="Satelite">
                  <TileLayer                
                     url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  />
               </BaseLayer>
               </LayersControl>
            </>
         )}
         isFullScreen
         zoomControl
      />     
    </div>
  ) : <Loader />

}

const mapStateToProps = (state) => ({
  event: state.detailEvent[0],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EQDinamicMap);