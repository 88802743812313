import { UseLeafletMap } from "hooks/UseLeafletMap";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { SeismicityContext } from "context/SeismicityContext/SeismicityContext";
import SeismHeader from "Components/Molecules/M_SeismicitySummary/SeismHeader";
import LoaderModal from "Components/Atoms/A_Reusable/LoaderModal";
import SeismicityFooter from "Components/Molecules/M_SeismicitySummary/SeismFooter";
import { useSeismicityRequest } from "hooks/useSeismicityRequest";
import BuildSeismMarkers from "Components/Molecules/M_SeismicitySummary/BuildSeismMarkers";
import SgcMapTileLayer from "Components/Atoms/A_Reusable/SgcMapTileLayer";
import { filterRelevantEvents, setEventsDelay } from "../../../utils/seismicityUtils";
import SeismicityMiniMap from "./SeismicityMiniMap";

function SeismicityViewer({ start }) {

   const { getEvents } = useSeismicityRequest();
   const { requestTimeRange, setCurrentEvents, setInitSimulation } = useContext( SeismicityContext );
   const mobile = window.innerWidth <= 900

   const [ eventDate, setEventDate ] = useState( null );
   const [ isLoading, setLoading ] = useState( false );
   const [ isError, setError ] = useState( false );

   useEffect(() => {
      start && setInitSimulation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ start ])

   useEffect(() => {
      async function loadEvents (){
         setLoading(true);
         const data = await getEvents( requestTimeRange.startDate, requestTimeRange.endDate );
         setLoading(false);
         if ( data ) {
            filterRelevantEvents( data );
            setEventsDelay( data );
            setCurrentEvents( data );
         } else {
            setError( true );
         }
      }
      setCurrentEvents([]);
      isError && setError( false );
      loadEvents();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ requestTimeRange ])

   return (
      <>
         <LoaderModal isLoading={ isLoading } />

         <SeismHeader 
            hideCounter={ isLoading || isError }
            eventDate={ eventDate } 
         />
         {
            !mobile &&
               <SeismicityMiniMap
                  title="San Andrés y Providencia"
                  setEventDate={ setEventDate }
               /> 
         }

         <UseLeafletMap
            id = "Seismicity"
            position = {[4.158709, -74.708452]}
            zoom = { 6 }
            scrollWheelZoom = { true }
            dragging = { true }
            mapOptions = {{
               minZoom : 5, maxZoom : 15,
            }}
            scaleControl={ false }
            bodyMap = {(
               <>                  
                  <SgcMapTileLayer
                     attribution='&copy; <a href="https://server.arcgisonline.com/">ArcGIS</a>'
                  />

                  <BuildSeismMarkers 
                     setEventDate={ setEventDate }
                  />
               </>
            )}
         />

         <SeismicityFooter 
            isError={ isError }
         />

      </>
   )
}
export default SeismicityViewer