import React, { useState, useEffect, useRef, Fragment } from 'react';
import { CardsNews } from './CardsNews';
import { Carousel } from 'react-responsive-carousel';
import { DivNews } from './DivNews';
import defaultImg from "../../../assets/icons/Reusable/img_01.png";

export const VolcanoNews = ({ actualVolcano }) => {

    const [ images, setImages ] = useState([]);
    const [ item, setItem ] = useState(0);
    const volcanoOrder = useRef([]);
    const itemRef = useRef(0)

    const buildVolcanos = event => {
        const slider = event.images ? event.images.map(( image, idx ) => (
            <Fragment key = { `image_${ event.id }_${ idx }` }>
                <div className = "carousel-news">
                    <div className = "carousel-news">
                        <img src = { image } alt = "" />
                    </div>
                </div>
                <div className="VDivSection">
                    <DivNews
                        event = { event }
                        volcanoName = { actualVolcano.properties.VolcanoName }
                    />
                </div>
            </Fragment>
        )) : [
            <Fragment key = "image0">
                <div className = "carousel-news">
                    <div className = "carousel-news">
                        <img src = { defaultImg } alt = "defaultImg" />
                    </div>
                </div>
                <div className="VDivSection">
                    <DivNews
                        event = { event }
                        volcanoName = { actualVolcano.properties.VolcanoName }
                    />
                </div>
            </Fragment>
        ]; setImages( slider );
    }

    const handleNews = target => {
        itemRef.current = target;
        buildVolcanos( volcanoOrder.current[ target ] );
        setItem(0);
    }

    const organizeNews = () => {
        return actualVolcano.properties.news.sort(( a, b ) => (
            new Date( b.date ).getTime() - new Date( a.date ).getTime()
        ));
    }

    useEffect(() => {
        volcanoOrder.current = actualVolcano.properties.news[1]
            ? organizeNews() : actualVolcano.properties.news;
        buildVolcanos( volcanoOrder.current[ itemRef.current ] );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ actualVolcano ]);

    return (
        <div className = "carousel-cards-news" id = "carousel-cards-news">
            {
                images[0] && (
                    <>
                        <div className = "carousel-news-container">
                            <div id = "volcanos_carousel">
                                <Carousel
                                    showThumbs = { false }
                                    selectedItem = { item }
                                    onChange = { idx => setItem( idx ) }
                                >
                                    { images }
                                </Carousel>
                            </div>
                        </div>
                        <div className = "cards-news-container">
                            <CardsNews
                                news = { volcanoOrder.current }
                                volcanoName = { actualVolcano.properties.VolcanoName }
                                handleNews = { handleNews }
                                itemRef = { itemRef.current }
                            />
                        </div>
                    </>
                )
            }
        </div>
    )
}
// http://localhost:3001/detallevolcan/53
// 1386 has the slider div in the detail volcano
/**
 * slider superior: 1098x400 con menu lateral; sin menu 1386x400
 * slider inferior: 713x400 con menu lateral; 900x400 sin menu lateral
 * imgs pequeñas del slider: 70x80 con menu lateral; 90x80 sin menu lateral
 */