import React, { memo } from "react"
import { Stop, Replay } from "@mui/icons-material";
import { useContext } from "react";
import { SeismicityContext } from "context/SeismicityContext/SeismicityContext";
import SeismSettings from "../../Molecules/M_SeismicitySummary/SeismicitySettings/SeismSettings";
import SeismLegend from "Components/Molecules/M_SeismicitySummary/SeismLegend";

function SeismicityOptions() {

   const { stopSimulation, setStopSimulation, setResetSimulation } = useContext( SeismicityContext );

   const handleStop = () => {
      !stopSimulation && setStopSimulation( true );
   }

   const handleReset = () => {
      setResetSimulation();
      stopSimulation && setStopSimulation( false );
   }


   return (
      <div className="SettingsLegendContainer">
         
         <div className="SettingsItem">
            <SeismSettings title="Configuración" />
         </div>
         <div className="SettingsItem" onClick={ handleStop }>
            <div className="SettingsIcon" title="Finalizar">
               <Stop />
            </div>
         </div>
         <div className="SettingsItem" onClick={ handleReset }>
            <div className="SettingsIcon" title="Reiniciar">
               <Replay />
            </div>
         </div>
         <div className="SettingsItem">
            <SeismLegend title="Leyenda" />
         </div>
      </div>
   )
}
export default memo( SeismicityOptions )