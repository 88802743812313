import { Qlimits } from "./QueryMaxMin";

export const storageJSON = key => {
    return JSON.parse( sessionStorage.getItem( key ));
}

export const initialLatCircle = 5.591333231711958;
export const initialLngCircle = -74.47132870007248;

export const initialMinLatRectangle = 2.489033599935781;
export const initialMinLngRectangle = -75.32973422328875;
export const initialMaxLatRectangle = 7.391107188915558;
export const initialMaxLngRectangle = -70.84731234828877

export const QinitialLocation = {
    departments: {
        selected: {
            sort: ["COLOMBIA"],
            _id: "c0l0mb14",
            _index: "departamento",
            _score: null,
            _source: {
                DPTO_CCDGO: "1234567890",
                DPTO_CNMBR: "SELECCIONAR TODO"
            },
            _type: "_doc"
        },
        actual_id: 'department'
    },
    munis: {
        selected: {
            sort: ["SELECCIONAR TODO"],
            _id: "70d0",
            _index: "municipio",
            _source: {
                MPIO_CNMBR: "SELECCIONAR TODO",
                MPIO_CCNCT: "9876543210"
            },
            _score: null,
        },
        actual_id: 'select'
    }
};

export let QState = JSON.parse( sessionStorage.getItem('QState')) || {};

let validateInit = false;

export const validateQState = () => {
    QState = JSON.parse( sessionStorage.getItem('QState')) || {};
    validateInit = true;
}

export const saveInit = () => { validateInit = false }

export const initStartDefault = new Date( 1644, 0, 16 );
export const initEndDefault = new Date( );

export const formatDateInit = value => {
    const date = new Date( value );
    return new Date( date.getTime() + 18000000 );
}

export const startTimeInit = () => {
    if ( QState.startTime ) {
        const date = new Date( Date.parse( QState.startTime ));
        return new Date( date.getTime() + 18000000 )
    }
    return initStartDefault;
}

export const endTimeInit = () => {
    if ( QState.endTime ) {
        const date = new Date( QState.endTime );
        return new Date( date.getTime() + 18000000 )
    }
    return initEndDefault;
}

export const restartState = () => { QState = {} }

export const initState = () => {
    !validateInit && validateQState();
    return {
        tm: QState.tm || {
            available: false,
            swift: false,
            scmtv: false,
            isola: false,
            phasew: false
        },
        sm: QState.sm || false,
        mmi: QState.mmi || false,
        cdi: QState.cdi || false,
        cdi_infographic : QState.cdi_infographic || false,
        loading: false,
        location: QState.location || QinitialLocation,
        min_magnitude: {
            data: QState.min_magnitude || "",
            label: "Magnitud Mínima",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_magnitude" ],
                max: Qlimits[ "max_magnitude" ]
            }, parent: "magnitude", type: "min", reverse: "max_magnitude"
        },
        max_magnitude: {
            data: QState.max_magnitude || "",
            label: "Magnitud Máxima",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_magnitude" ],
                max: Qlimits[ "max_magnitude" ]
            }, parent: "magnitude", type: "max", reverse: "min_magnitude"
        },
        min_depth: {
            data: QState.min_depth || "",
            label: "Profundidad Mínima",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_depth"],
                max: Qlimits[ "max_depth"]
            }, parent: "depth", type: "min", reverse: "max_depth"
        },
        max_depth: {
            data: QState.max_depth || "",
            label: "Profundidad Máxima",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_depth" ],
                max: Qlimits[ "max_depth" ]
            }, parent: "depth", type: "max", reverse: "min_depth"
        },
        min_mag_error: {
            data: QState.min_mag_error || "",
            label: "Error Magnitud Mínima",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_mag_error" ],
                max: Qlimits[ "max_mag_error" ]
            }, parent: "magnitude_uncertainty", type: "min",
            reverse: "max_mag_error"
        },
        max_mag_error: {
            data: QState.max_mag_error || "",
            label: "Error Magnitud Máxima",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_mag_error" ],
                max: Qlimits[ "max_mag_error" ]
            }, parent: "magnitude_uncertainty", type: "max",
            reverse: "min_mag_error"
        },
        min_depth_error: {
            data: QState.min_depth_error || "",
            label: "Error Profundidad Mínimo",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_depth_error" ],
                max: Qlimits[ "max_depth_error" ]
            }, parent: "depth_uncertainty", type: "min",
            reverse: "max_depth_error"
        },
        max_depth_error: {
            data: QState.max_depth_error || "",
            label: "Error Profundidad Máximo",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_depth_error" ],
                max: Qlimits[ "max_depth_error" ]
            }, parent: "depth_uncertainty", type: "max",
            reverse: "min_depth_error"
        },
        min_latitude_error: {
            data: QState.min_latitude_error || "",
            label: "Error Latitud Mínimo",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_latitude_error" ],
                max: Qlimits[ "max_latitude_error" ]
            }, parent: "latitude_uncertainty", type: "min",
            reverse: "max_latitude_error"
        },
        max_latitude_error: {
            data: QState.max_latitude_error || "",
            label: "Error Latitud Máximo",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_latitude_error" ],
                max: Qlimits[ "max_latitude_error" ]
            }, parent: "latitude_uncertainty", type: "max",
            reverse: "min_latitude_error"
        },
        min_longitude_error: {
            data: QState.min_longitude_error || "",
            label: "Error Longitud Mínimo",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_longitude_error" ],
                max: Qlimits[ "max_longitude_error" ]
            }, parent: "longitude_uncertainty", type: "min",
            reverse: "max_longitude_error"
        },
        max_longitude_error: {
            data: QState.max_longitude_error || "",
            label: "Error Longitud Máximo",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_longitude_error" ],
                max: Qlimits[ "max_longitude_error" ]
            }, parent: "longitude_uncertainty", type: "max",
            reverse: "min_longitude_error"
        },
        min_rms:{
            data: QState.min_rms || "",
            label: "RMS Mínimo",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_rms" ],
                max: Qlimits[ "max_rms" ]
            }, parent: "standard_error", type: "min", reverse: "max_rms"
        },
        max_rms:{
            data: QState.max_rms || "",
            label: "RMS Máximo",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_rms" ],
                max: Qlimits[ "max_rms" ]
            }, parent: "standard_error", type: "max", reverse: "min_rms"
        },
        min_gap:{
            data: QState.min_gap || "",
            label: "GAP Mínimo",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_gap" ],
                max: Qlimits[ "max_gap" ]
            }, parent: "gap", type: "min", reverse: "max_gap"
        },
        max_gap:{
            data: QState.max_gap || "",
            label: "GAP Máximo",
            validate: false,
            message: "",
            limits: {
                min: Qlimits[ "min_gap" ],
                max: Qlimits[ "max_gap" ]
            }, parent: "gap", type: "max", reverse: "min_gap"
        },
        min_mag_tm: {
            data: QState.min_mag_tm || "",
            validate: false,
            label: "Magnitud Mínima",
            message: "",
            reverse: "max_mag_tm",
            limits: {
                min: Qlimits[ "min_magnitude_tm" ],
                max: Qlimits[ "max_magnitude_tm" ]
            }
        },
        max_mag_tm: {
            data: QState.max_mag_tm || "",
            validate: false,
            label: "Magnitud Máxima",
            message: "",
            reverse: "min_mag_tm",
            limits: {
                min: Qlimits[ "min_magnitude_tm" ],
                max: Qlimits[ "max_magnitude_tm" ]
            }
        },
        min_level_intensity: {
            data: QState.min_level_intensity || "",
            validate: false,
            message: "",
            reverse: "max_level_intensity",
            limits: {
                min: Qlimits[ "min_level_intensity" ],
                max: Qlimits[ "max_level_intensity" ]
            }, parent: "shake_map", type: "min",
            label: "Nivel de intensidad mínimo"
        },
        max_level_intensity: {
            data: QState.max_level_intensity || "",
            validate: false,
            message: "",
            reverse: "min_level_intensity",
            limits: {
                min: Qlimits[ "min_level_intensity" ],
                max: Qlimits[ "max_level_intensity" ]
            }, parent: "shake_map", type: "max",
            label: "Nivel de intensidad máximo"
        },
        startTime: {
            data: startTimeInit(),
            validate: false, message: ""
        },
        endTime: {
            data: endTimeInit(),
            validate: false, message: ""
        },
        stations: {
            filters: QState.stations || "",
            label: "Filtro de estaciones por aceleración"
        },
        map_options: QState.map_options || {
            area: null,
            circle: {
                lat: {
                    label: "Latitud Mínima",
                    validate: false,
                    data: initialLatCircle,
                    message: "",
                    limits: {
                        min: Qlimits[ "min_lat" ],
                        max: Qlimits[ "max_lat" ]
                    }
                },
                lng: {
                    label: "Longitud Mínima",
                    validate: false,
                    message: "",
                    data: initialLngCircle,
                    limits: {
                        min: Qlimits[ "min_lat" ],
                        max: Qlimits[ "max_lat" ]
                    }
                },
                radio: 100000
            },
            rectangle: {
                min_lat: {
                    label: "Latitud Mínima",
                    validate: false,
                    message: "",
                    data: initialMinLatRectangle,
                    reverse: "max_lat",
                    limits: {
                        min: Qlimits[ "min_lat" ],
                        max: Qlimits[ "max_lat" ]
                    }
                },
                min_lng: {
                    label: "Longitud Mínima",
                    validate: false,
                    message: "",
                    data: initialMinLngRectangle,
                    reverse: "max_lng",
                    limits: {
                        min: Qlimits[ "min_lng" ],
                        max: Qlimits[ "max_lng" ]
                    }
                },
                max_lat: {
                    label: "Latitud Máxima",
                    validate: false,
                    message: "",
                    data: initialMaxLatRectangle,
                    reverse: "min_lat",
                    limits: {
                        min: Qlimits[ "min_lat" ],
                        max: Qlimits[ "max_lat" ]
                    }
                },
                max_lng: {
                    label: "Longitud Máxima",
                    validate: false,
                    message: "",
                    data: initialMaxLngRectangle,
                    reverse: "min_lng",
                    limits: {
                        min: Qlimits[ "min_lng" ],
                        max: Qlimits[ "max_lng" ]
                    }
                }
            }
        }
    }
}