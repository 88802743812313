import { useState, useRef, useEffect } from 'react';
import { Circle, GeoJSON } from 'react-leaflet';
import { CustomWMSLayerEvent } from '../../Molecules/M_EQCatalogoMapa/CustomWMSLayerEvent'
// import Epicenter from "../../../assets/icons/EQViewer/Icons/info_event.svg";
import { useSelector } from "react-redux";
import { reqQuery } from 'api/ReqResApi';
import { actualQuery } from 'Components/Atoms/A_Query/QRequest';
import { geoserver, geoFilter } from 'utils/urls';
import { Viewer } from 'Components/Templates/T_Viewer/Viewer';
import {
	wmsGeoInitState, wmsInitStateFilter, wmsMapInitState,
	wmsStyles, optionsWMS, createGeoJson
} from './WmsInitData';
import UseMapLegend from 'hooks/UseMapLegend';
import { SelectedCatalogMarker } from 'Components/Molecules/M_Reusable/SelectedCatalogMarker';
import ErrorRequest from "Components/Atoms/A_Reusable/ErrorRequest";
import { EarthquakeMarker } from 'Components/Molecules/M_Reusable/EarthquakeMarker';
import SgcMapTileLayer from 'Components/Atoms/A_Reusable/SgcMapTileLayer';

const { show_info_mss } = ErrorRequest;
const formData = new FormData();

const MapWMS = (
	{
		entity, selectedEvent,
		updateMapBounds, activeEvents, setSelectItem
	}
) => {

   const network_filters = useSelector( state => state.network_filters );

	const styles = wmsStyles();
	const markerRef = useRef(null);
	const geoJsonRef = useRef(null);
	const zoomRef = useRef( 6.1 );
	const [ filterEvent, setFilter ] = useState(wmsInitStateFilter());
	const mapRef = useRef();

	const [ geographyEntity, setGeograph ] = useState(wmsGeoInitState());

	const map = wmsMapInitState();

	const customMap = useRef();

	const updateDrawFilter = (draw, filterDraw) => {

		switch (draw.type.toLowerCase()) {

			case "polygon":
				filterDraw.polygon.feature = createGeoJson(draw);
				filterDraw.polygon.status = true;
				return filterDraw;

			case "circle":
				filterDraw.circle.position = [
					draw.coordinates[0], draw.coordinates[1]
				];
				const radius = parseFloat(draw.distance);
				filterDraw.circle.radius = draw.measure
					.toLowerCase() === "kilometers" ||
					draw.measure.toLowerCase() === "km"
					? radius * 1000
					: radius;
				filterDraw.circle.status = true;
				return filterDraw;

			case "wms":
				filterDraw.wmsEntity.url = `${geoFilter}?`
				filterDraw.wmsEntity.cql = draw.cql
				filterDraw.wmsEntity.layers = draw.layers
				filterDraw.wmsEntity.status = true;
				return filterDraw;

			default: return filterDraw;

		}
	}

	const activateGeographyEntity = ({ type, layers }) => type
		.toLowerCase() === "wms" && setGeograph({
			url: geographyEntity.url, status: true, layers
		});

	const updateFilterEvents = ({ cql, bound, draw }) => {
		const newDraw = draw;
		if (draw && draw.type) {
			filterEvent.draw = {
				...filterEvent.draw,
				polygon: {
					ref: {}, feature: {}, color: 'cyan', status: false
				},
				circle: {
					ref: {}, position: {}, radius: {}, color: 'cyan', status: false
				}
			}
			draw = updateDrawFilter(newDraw, filterEvent.draw);
			activateGeographyEntity(newDraw);
		}
		setFilter({ cql, bound, draw: draw || wmsInitStateFilter().draw });
	}

	const createCQLFilterWMS = async () => {

		formData.append(
			'data', JSON.stringify({
				pager: { from: 1, size: 1000 },
				source: [3],
				properties: ["*"],
				filter: actualQuery()
			})
		);
		try {
			const { data } = await reqQuery
				.post('api/transform/query/cql_wms', formData);
			data && updateFilterEvents(data);
		} catch { }
	}

	const loadEntity = () => {
		if (entity.toLowerCase() === "municipio") {
			setGeograph({
				url: geographyEntity.url,
				layers: "sgc:municipio", status: true
			});
		} else if (entity.toLowerCase() === "departamento") {
			setGeograph({
				url: geographyEntity.url,
				layers: "sgc:departamento", status: true
			});
		}
	}

	// const showPopUpWMS = infoFeature => {
	// 	setFilter({ ...filterEvent, bound: [] });
	// 	const activeIndex = activeEvents.findIndex(e => e.id === infoFeature.id);
	// 	if ( activeIndex < 0 ) {
	// 		setSelectItem(infoFeature);
	// 	} else {
	// 		setSelectItem( activeEvents[ activeIndex ] );
	// 	}
	// 	markerRef.current.leafletElement.openPopup();
	// }

   const showPopup = eventId => {
      const activeIndex = activeEvents.findIndex(e => e.id === eventId);
      setSelectItem( activeEvents[ activeIndex ] );
   }

	if (filterEvent.cql && filterEvent !== "") {
		optionsWMS["CQL_FILTER"] = filterEvent.cql;
	}

	const updateBounds = mapBounds => {
		const newBounds = mapBounds.getBounds();
		const boundsFormat = {
			north: newBounds.getNorth(),
			south: newBounds.getSouth(),
			east: newBounds.getEast(),
			west: newBounds.getWest(),
		};
		boundsFormat.north !== boundsFormat.south &&
			boundsFormat.east !== boundsFormat.west
			&& updateMapBounds(boundsFormat);
	}

	const getMapReferences = map => mapRef.current = map;

	const updateZoom = ({ target }) => {
		if ( target._zoom < 13 && target._zoom > 4 ) {
			zoomRef.current = target._zoom;
		} else if ( target._zoom >= 14 ) {
			show_info_mss (
				'Ha alcanzado el nivel máximo para ampliar el zoom', 
				`Por favor reduzca el zoom de la imagen del mapa`
			);
		} else {
			show_info_mss (
				'Ha alcanzado el nivel máximo para reducir el zoom',
				`Por favor amplíe el zoom de la imagen del mapa`
			);
		}
		// target._zoom > 14 ? setZoom( 5.7 ) : setZoom( target._zoom );
	}

	useEffect(() => {
		selectedEvent && markerRef.current
			&& markerRef.current.leafletElement.openPopup();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ selectedEvent ]);

	useEffect(() => {
		const meta = document.createElement('meta');
		meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
		meta.setAttribute('name', 'viewport');
		document.head.appendChild(meta);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	useEffect(() => {
		// when the requestProgress is false its means that the request end
		// and it has data
		entity && loadEntity();
		createCQLFilterWMS();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ activeEvents ]);

   useEffect(()=>{ // update bounds
      if ( mapRef.current){
         if (filterEvent.bound && filterEvent.bound[0] ){
            mapRef.current.fitBounds( filterEvent.bound );
         }
      } 
   }, [ filterEvent.bound ])

   
	const optionsEntity = {
      ...optionsWMS,
		CQL_FILTER: filterEvent.draw.wmsEntity.cql,
		styles: "select",
		opacity: 0.8
	}

	return (
		<Viewer
			styles = { styles }
			id = "leafletMapId"
			getMapReferences = { getMapReferences }
			onzoomend = { updateZoom }
			initPosition = { map.position }
			initialZoom = { zoomRef.current }
			onClick = { () => '' }
			mapOptions = {{
				minZoom : 4, maxZoom : 13
			}}
			netWorksMarkers = { network_filters }
			onMoveEnd = { updateBounds }
         zoomSnap = { false }
         Tiles={ <SgcMapTileLayer />}
         BuildMarkers={(
         <>
            {
               selectedEvent && (
               	<SelectedCatalogMarker
               		earthquake={selectedEvent}
               		hiddenCdi={true}
               		onSelect={value => value}
               		isCatalog
               	/>
               )
            }
            {
               activeEvents.length > 0 && activeEvents.map( event => {
                  return <EarthquakeMarker 
                           key={ event.id }
                           earthquake={ event }
                           isSelected={ false }
                           onSelect={ () => showPopup( event.id ) }
                     />
               }) 
            }
					{/* Global mapa mundi */}
					{/* <WMSTileLayer
						layers="TOPO-OSM-WMS"
						srs="EPSG:4326"
						format="image/png"
						version='1.1.0'
						opacity={1}
						transparent
						url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.jpg"
					/> */}
					{/* consulta por area polygon */}
					{
						filterEvent.draw.polygon.status === true && (
							<GeoJSON
								data={filterEvent.draw.polygon.feature}
								ref={geoJsonRef}
                        pathOptions={{
                           fill: false, 
                        }}
								weight={4}
								color={filterEvent.draw.polygon.color}
							/>
						)
					}
					{/* consulta por area circle */}
					{
						filterEvent.draw.circle.status === true && (
							<Circle
								center={filterEvent.draw.circle.position}
								radius={filterEvent.draw.circle.radius}
                        fill={false}
								color={filterEvent.draw.circle.color}
								weight={4}
							/>
						)
					}
					{/* capa departamento | muni */}
					{
						filterEvent.draw.wmsEntity.status === true && (
							// WMSTileLayer
							<CustomWMSLayerEvent
								options={optionsEntity}
								url={geoserver}
								mapRef={customMap}
								layersRef={[filterEvent.draw.wmsEntity.layers]}
							/>
						)
					}
               {/* { Uncomment when Geoserver event points layer works correctly } */}

					{/* <CustomWMSLayerEvent
						coordinates={selectedEvent ? selectedEvent.geometry.coordinates : []}
						showPopUp={showPopUpWMS}
						options={optionsWMS}
						url={geoserver}
						bound={filterEvent.bound}
						mapRef={customMap}
						layersRef={["sgc:event"]}
						showLayers={filterEvent.draw.wmsEntity.status}
						showShapes={
							filterEvent.draw.polygon.status || filterEvent.draw.circle.status
						}
					/> */}
				</>
			)}
			FooterMap={(
				<UseMapLegend align="end" />
			)}
		/>
	)
}

export default MapWMS;