import React from "react";
import { Switch, FormControlLabel, FormGroup } from "@material-ui/core";
import { styled, alpha } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { GREEN } from "utils/styles";

const StyledSwitch = styled( Switch )(({ theme }) => ({
   '& .MuiSwitch-switchBase.Mui-checked': {
      color: GREEN,
      '&:hover': {
         backgroundColor: alpha(GREEN, theme.palette.action.hoverOpacity),
      },
   },
   '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: GREEN,
   },
   '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 15,
      height: 15,
      borderRadius: 7,
      transition: theme.transitions.create(['width'], {
         duration: 200,
      }),
      },
}));

const useStyles = makeStyles((theme) => ({
   root: {
       width: "100%",
       padding: "0px 0px 0px 0px",
       borderBottom: "0.5px solid #fff"
   },
   label: {
       fontSize: "100%",
       color: "#333",
       textAlign: "left",
       marginRight: "auto"
   },
   labelContainer: {
       marginLeft: "0px",
       marginRight: "0px",
       padding: "0px 20px",
       width: "100%",
       borderTop: "0.5px solid #fff",
       borderBottom: "0.5px solid #fff"
   }
}));


function SwitchesGroup({ items, labelValue, onChange }) {

   const styles = useStyles();

   return (
      items && (
            <FormGroup>
               {
                  items.map(( item, index ) => {
                        return (
                           <FormControlLabel
                              key={ index }
                              classes = {{
                                 root: styles.labelContainer,
                                 label: styles.label
                             }}
                              control={
                                    <StyledSwitch
                                       checked = { item.selected }
                                       size="small"
                                       name = { item[ labelValue ] }
                                       onChange = {
                                          () => onChange( item )
                                       }
                                    />
                              }
                              label={ item[ labelValue ] }
                              labelPlacement="start"
                           />
                        )
                  })
               }
            </FormGroup>
      )
   )
}
export default SwitchesGroup