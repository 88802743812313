import { useEffect, useState, useContext } from 'react';
import BuildMagnitude from '../M_Reusable/BuildMagnitude';
import BuildMmi from '../M_Reusable/BuildMmi';
import BuildPlace from '../M_Reusable/BuildPlace';
import BuildSm from '../M_Reusable/BuildSm';
import BuildDepth from "Components/Molecules/M_Reusable/BuildDepth";
import BuildDepthText from "Components/Molecules/M_Reusable/BuildDepthText";
import BuildLocalization from '../M_Reusable/BuildLocalization';
import { onlyTensorIcon } from 'Components/Organisms/O_Query/TableFields';
import TensorIcon from "assets/icons/Query/pelotadeplaya_vacia-01.svg";
import { CircularProgress } from '@material-ui/core';
import { analyticsEventTracker, EVENT_CATEGORIES } from 'hooks/UseAnalyticsTracker';
import { QueryContext } from 'context/QueryContext';
import { Link } from 'react-router-dom';

const color = id => id % 2 === 0 ? "item" : "item + item2";

// gets the event year
const _getEventYear = event => {
    if ( event.properties.localTime ) {
        const date = new Date( event.properties.localTime.replace( " ", "T" ) );
        return date.getFullYear();
    }
    return 0;
}

// sets the url for event details
export const eventDetailsUrl = ( eventId, isCatalog ) => {
    const path = isCatalog ? 'detalleevento' : 'detallesismo';
    return `/${ path }/${ eventId }/resumen`;
} 

// validate the event date to show up the "Más información" button
export const validateLocal = event => {
    const YEAR_RANGE = [ 1644, 2017 ];
    const eventYear = _getEventYear( event );
    return eventYear >= 2018 || (eventYear >= YEAR_RANGE[0] && eventYear <= YEAR_RANGE[1]);

}

export const QEvents = ({ event, index, active, show }) => {
    
    const { moreInfoAssets } = useContext( QueryContext );
    const { loading, hasData } = moreInfoAssets;
    const [ imgEvent, setImg ] = useState(
        <img src = { TensorIcon } alt = { event.properties.beachBall } />
    )

    const buildTensorIcon = async () => event.properties.beachBall !== "" && setImg(
        await onlyTensorIcon( event )
    );

    useEffect(() => {
        buildTensorIcon();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div
            className = "accordion"
            id = { event.id } style = {{ zIndex: 50 }}
        >
            <div
                id = { `item${ event.id }` }
                onClick = { () => show( event.id, index ) }
                className = { `${ color( index ) } ${ active( event, index ) }` }
            >
                <div className = "item-container">
                    <div className = "magnitude">
                        <div className = "magnitude-background">
                            <BuildMagnitude
                                mag = { event.properties.mag }
                                felt = { event.properties.felt }
                            />
                        </div>
                    </div>
                    <div className = "main">
                        <div className = "place">
                            <BuildPlace
                                mag = { event.properties.mag }
                                felt = { event.properties.felt }
                                place = { event.properties.place }
                            />
                        </div>
                        <div className = "dates">
                            <div className = "container-date">
                                <p className = "date-text">
                                    { event.properties.localTime }
                                </p>
                            </div>
                            <div className = "container-icons">
                                <div className = "dates-mmi">
                                    <BuildMmi
                                        mmi = { event.properties.mmi }
                                    />
                                </div>
                                {/* <div className = "dates-cdi">
                                    <BuildCdi
                                        cdi = { event.properties.cdi }
                                    />
                                </div> */}
                                <div className = "dates-sm">
                                    <BuildSm
                                        sm = { event.properties.sm }
                                    />
                                </div>
                                {
                                    event.properties.beachBall !== null
                                        && event.properties.beachBall !== '' && (
                                            <div className = "dates-tm">
                                                { imgEvent }
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    <div className = "depth">
                        <div className = "depth-background">
                            <BuildDepth
                                eventList = { true }
                                status = { event.properties.status }
                                depth = { event.geometry.coordinates[2] }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                id = { `item-content${ event.id }` }
                className = { `item-content ${ active( index ) }` }
            >
                <div className = "info-content">
                    <p className = "info-text">
                        <span>Tiempo de origen: </span>
                        { event.properties.localTime } <span> Hora Local </span>(
                        { event.properties.utcTime } <span>UTC</span>)
                    </p>
                    <p className = "info-text">
                        <span>Estado: </span>
                        {
                            event.properties.status.toLowerCase() === "manual"
                                ? "Manual" : "Automático"
                        }
                    </p>
                    <div className = "info-text">
                        <BuildDepthText
                            eventList = { true }
                            status = { event.properties.status }
                            depth = { event.geometry.coordinates[2] }
                        />
                    </div>
                    <BuildLocalization
                        eventList = { true }
                        status = { event.properties.status }
                        latitude = { event.geometry.coordinates[0] }
                        longitude = { event.geometry.coordinates[1] }
                    />
                    {
                        event.properties.maxPGA !== null && 
                            event.properties.maxPGA !== '' && (
                                <p className = "info-text">
                                  <span>PGA Máximo: </span>
                                  { parseFloat( event.properties.maxPGA ).toFixed(6) } cm/s^2
                                </p>
                            )
                    }
                    {
                        event.properties.closerTowns !== null &&
                        event.properties.closerTowns !== "" && (
                            <p className = "info-text">
                              <span>Municipios Cercanos: </span>
                              { event.properties.closerTowns }
                            </p>
                        )
                    }
                    <p className = "info-text">
                        <span>Agencia: </span>
                        { event.properties.agency }
                    </p>
                </div>
                <div className = "buttons">
                    { loading && <CircularProgress size = { 22 } /> }
                    {
                        hasData &&
                            <button className = "buttons-text">
                                <Link
                                    to = { eventDetailsUrl( event.id, true ) }
                                    rel = "noopener noreferrer"
                                    target = '_blank'
                                    onClick = { e =>
                                        analyticsEventTracker({
                                            category: EVENT_CATEGORIES.eventsList,
                                            action: `Más Información from Lista de Eventos`,
                                        }) 
                                    }
                                >
                                    Más Información
                                </Link>
                            </button>
                    }
                </div>
            </div>
        </div>
    )
}
