import axios from "axios";
import { CLOUD_URL, queryUrl, tensorServer, geoserver } from 'utils/urls';

export const createTensorBall = ( year, id, type ) => {
    type = type === 'isola_solution' ? 'isola' : type;
    return `${ tensorServer }${ year }/${ id }/mt/${ type }/beachball_small.png`;
}

export const reqTensor = axios.create({ baseUrl: tensorServer })
export const reqQuery = axios.create({ baseURL: queryUrl });
export const reqGeoserver = axios.create({ baseURL: geoserver });

export const reqTempQuery = axios.create({
    //url pruebas
    //baseURL: "http://172.25.1.135/"
    // url producciòn
    baseURL: "http://172.25.1.58"
});

export const reqStations = axios.create({
    baseURL: CLOUD_URL
})
