import { Typography, Paper } from "@mui/material"
import { GREEN } from "utils/styles"

function SeismicityReport({ formattedDateRange, totalEvents, responsive }) {
   return (
      <Paper elevation={4} style={{ padding: "10px 30px", color: "white", backgroundColor: GREEN }}>
         <Typography
            variant="h5" 
            style={{ 
               fontSize: responsive ? ".8rem" : ".9rem", 
               letterSpacing: "0.08rem", 
               fontWeight: "bold" 
            }}
         >
            Resumen de sismicidad del <br />
            { formattedDateRange }
         </Typography>
         <hr className="Divider DividerWhite" />
         <Typography 
            variant="h5" 
            style={{ 
               fontSize: "1.1rem", 
               letterSpacing: "0.1rem", 
               fontWeight: "bold" 
            }}
         >
            { totalEvents } Sismos
         </Typography>
      </Paper>
   )
}
export default SeismicityReport