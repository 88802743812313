import { endTimeInit, startTimeInit } from "./QInitState";

const query = () => JSON.parse( sessionStorage.getItem( 'query' ))

const initQuery = () => {
    const { event, ...rest } = query();
    return {
        ...rest,
        event: event || {
            local_date: {
                min: new Date( startTimeInit().getTime() - 18000000 ),
                max: new Date( endTimeInit().getTime() - 18000000 )
            }
        }
    }
}

export const initialQuery = () => (
    query() ? initQuery() : {
        event: {
            local_date: {
                min: new Date( startTimeInit().getTime() - 18000000 ),
                max: new Date( endTimeInit().getTime() - 18000000 )
            }
        }
    }
)

export const restartQuery = () => ({
    event: {
        location: {}, local_date: {
            min: new Date( startTimeInit().getTime() - 18000000 ),
            max: new Date( endTimeInit().getTime() - 18000000 )
        }
    }
});