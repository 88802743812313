import circle from 'assets/icons/Home/slider/4_inte-perc.svg'
import BuildMmi from "../M_Reusable/BuildMmi";
import BuildCdi from "../M_Reusable/BuildCdi";
import Moment from "react-moment";
import MoreInfoButton from 'Components/Atoms/A_Home/MoreInfoButton';
import { useEffect } from 'react';
import { useRef } from 'react';

const buildDepth = ( depth, status, round = 0 ) => {

   if (status !== null) status = status.toLowerCase();
   depth = Number.parseFloat(depth).toFixed( round );
   
   if (status === "automatic" && depth < 70) {
      return "Superficial";
   } else if (status === "manual" && depth < 30) {
      return "Superficial";
   } 
   return depth;
}

function SlideEqEvent({ event }) {

   const oneIntensity = event.properties.cdi > 0 ^ event.properties.mmi > 0;
   const locateDivRef = useRef();
   const gridRef = useRef();
   const depth = buildDepth(
      event.geometry.coordinates[2],
      event.properties.status
   );


   useEffect( () => {
      if ( locateDivRef.current ) {
         if ( event.properties.cdi > 0 && event.properties.mmi > 0 ){
            let child = gridRef.current.children[2]
            gridRef.current.insertBefore(locateDivRef.current, child);
         } else {
            locateDivRef.current.classList.remove("col-3");
         }
      }
   })

   return (
      <div className="SlideEventContainer"> 
         <div className="SlideEvent">
            <div className="SlideEventTitle">
               <span className="HomeTitle">
                  Eventos Geológicos Destacados
               </span>
            </div>
            <div className="SlideEventContent">
               <div className="EventInfo">

                  <span className="EventEmphasisText Title">
                     Sismos - <Moment fromNow>{ event.properties.localTime }</Moment>
                  </span>

                  <span className="Title EventTitle">
                     { event?.properties?.place }
                  </span>

                  <div className="EventDescription">
                     <div className="Title EventSubTitle">
                        Tiempo de origen:
                     </div>
                     <span>{ event.properties.localTime } Hora local -&nbsp;</span>
                     <span>{ event.properties.utcTime } UTC</span>
                  </div>

                  {
                     event.properties.closerTowns && event.properties.closerTowns !== 'None' && (
                        <div className="EventDescription">
                           <div className="Title EventSubTitle">
                              Municipios Cercanos:
                           </div>
                           <span>
                              { event.properties.closerTowns }
                           </span>
                        </div>
                     )
                  }

               </div>
               <div className="EventData">
                  <div className={`ResponsiveGrid EventsGrid ${ oneIntensity ? "FourItems" : "FullItems" }` } ref={ gridRef }>
                     {
                     event.properties.mag && (
                        <div className="GridItem">
                           <div className="GridItemTitle">Magnitud</div>
                           <div className="GridItemContent">
                              <img src={circle} alt="mag" />
                              <div className="ItemText">
                                 <span 
                                    className="Big Title" 
                                 >
                                    { event.properties.mag.toFixed(1) }
                                 </span>
                                 <span className="Small Title">M</span>
                              </div>
                           </div>
                        </div>
                     )
                  }
                  {
                     event.geometry.coordinates && (
                        <div className="GridItem">
                           <div className="GridItemTitle">
                              <span><p> Profundidad </p></span>
                           </div>
                           <div className="GridItemContent">
                              <img src={circle} alt="depth" />
                              <div className="ItemText">
                                 {
                                    depth !== "Superficial" ? ( 
                                       <>
                                          <span className="Big Title">
                                             { depth }
                                          </span>
                                          <span className="Small Title">km</span>
                                       </>
                                    ) : (
                                       <span className="Title">
                                          Superficial
                                       </span>
                                    )
                                 }
                              </div>
                           </div>
                        </div>
                     )
                  }
                  
                     {
                     event.properties.cdi > 0 && (
                        <div className="GridItem col-3">
                           <div className="GridItemTitle" title="Intensidad Máxima Percibida">
                              <span>
                                 Intensidad <p>máxima percibida</p>
                              </span> 
                           </div>
                           <div className="GridItemContent">
                              <img className="hidden" src={ circle } alt="cdi" />
                              <div className="ItemText Img">
                                 <BuildCdi cdi={ event.properties.cdi } />
                              </div>

                           </div>
                        </div>
                     )
                  }
                  { event.properties.mmi > 0 &&
                      (
                        <div className="GridItem col-3">
                           <div className="GridItemTitle">
                              <span>
                                 Intensidad <br /> <p>instrumental</p>
                              </span>
                           </div>
                           <div className="GridItemContent">
                              <img className="hidden" src={ circle } alt="mmi" />
                              <div className="ItemText Img">
                                 <BuildMmi mmi={event.properties.mmi} />
                              </div> 
                           </div>
                        </div>
                     )
                  }
                  {
                     event.geometry.coordinates && (
                        <div className="GridItem" ref={ locateDivRef }>
                           <div className="GridItemTitle">
                              <span><p> Localización </p></span>
                           </div>
                           <div className="GridItemContent">
                              <img src={circle} alt="locate" />
                              <div className="ItemText">
                                 <span className="Title">{ event.geometry.coordinates[0].toFixed(2) }&#176;</span>
                                 <span className="Title">{ event.geometry.coordinates[1].toFixed(2) }&#176;</span>
                              </div>
                           </div>
                        </div>
                     )
                  }
                  </div>
                     
               </div>
            </div>

            <div className="EventAction">
               <MoreInfoButton 
                  href={`/detallesismo/${ event.id }/resumen`}
                  target="_blank"
               />
            </div>

         </div>
      </div>
   )
}
export default SlideEqEvent