import { Modal, Backdrop, Fade } from '@material-ui/core';

export const UseModal = ({ open, handleClose, component, styles }) => (
    <Modal
        className = { styles }
        aria-labelledby = "transition-modal-title"
        aria-describedby = "transition-modal-description"
        open = { open }
        onClose = { handleClose }
        closeAfterTransition
        BackdropComponent = { Backdrop }
        BackdropProps = {{ timeout: 500 }}
        disableEnforceFocus
    >
        <Fade in = { open }>
            <div>
                { component }
            </div>
        </Fade>
    </Modal>
)