import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { loadDetailEvent, show_menu } from "redux/actionsCreators";
import EQContentManager from "Components/Organisms/O_EQDetails/EQContentManager";
import NotFound from "Components/Pages/NotFound";
import Loader from "Components/Pages/Loader";
import BlockUi from "@availity/block-ui";
import EQDetailHeader from "Components/Templates/T_EQDetail/EQDetailHeader";
import ModalImg from "Components/Molecules/M_Reusable/ModalImg";
import { reqResApi } from "api/reqRes";
import { reqResBackUp } from "api/reqRes";
import EQSummary from "Components/Molecules/M_Reusable/EQSummary";
import { EQSummaryTemplate } from "Components/Molecules/M_Reusable/EQSummaryTemplate";
import { useParams } from "react-router-dom";
import { getEventYear } from "hooks/UseDetailsRequest";
import { detailEventExample } from "../../data/detail";
import EQMenu from "Components/Organisms/O_EQDetails/EQMenu";
import EQViewerFooter from "Components/Templates/T_EQViewer/EQViewerFooter";

const EQDetail = ({ menuIcon, loadDetailEvent, eventDetail, showMenu }) => {

	const [state, setState] = useState({
		active: menuIcon,
		blocking: true,
		notFound: false
	});
	const [ optionsMenu, setMenu ] = useState([]);
	const optionsRef = useRef([]);
	const params = useParams();

	const eventYear = getEventYear(params.id);

	const urlGetApi = `events/${params.id}/detail.json`;
	const urlGetBackUp = `${eventYear}/${params.id}/detail_local.json`;

	// First request to AWS: https://archive.sgc.gov.co/
	const primaryRequest = async () => {
		await reqResApi.get(urlGetApi)
			.then(async ({ data }) => {
				if (data) {
					loadDetailEvent(data);
					setState({
						...state,
						blocking: false
					})
				}
				else {
					await secondaryRequest();
				}

			}).catch(async () => {
				await secondaryRequest();
			})
	}

	// Second request to BackUp: https://xylon.sgc.gov.co/eqevents/events/
	const secondaryRequest = async () => {
		await reqResBackUp.get(urlGetBackUp)
			.then(async ({ data }) => {
				if (data) {
					loadDetailEvent(data);
					setState({
						...state,
						blocking: false,
					})
				}
				else {
					await finalRequest();
				}

			}).catch(async () => {
				await finalRequest();
			});
	}

	// Final request with /detail.json if the event is not found
	const finalRequest = async () => {
		await reqResBackUp.get(`${eventYear}/${params.id}/detail.json`)
			.then(({ data }) => {
				if (data) {
					loadDetailEvent(data);
					setState({
						...state,
						blocking: false,
					})
				}
				else {
					setState({
						...state,
						notFound: true
					});
				}

			}).catch(() => {
				setState({
					...state,
					notFound: true
				})
			});

	}

	const loadData = () => {

		document.title = "SGC - Sismo Generalidades";

		window.screen.width < 1237 && setState({
			...state,
			active: false
		});

		primaryRequest();
      loadDetailEvent(detailEventExample);

	}

	const handleNotEvent = menuOption => setMenu(
		optionsRef.current.filter(item => item !== menuOption)
	);

	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if ( eventDetail[0] ) {
			optionsRef.current = eventDetail[0].properties.types;
			setMenu( optionsRef.current );
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ eventDetail ]);

	const noDataComponent = () => {
		if ( params.product === 'noData' ) {
			return <NotFound notFoundById />
		}
		return state.notFound ? <NotFound notFoundById /> : <Loader />
	}

	return eventDetail[0] && params.product !== "noData" ? (
		<>
			{/* <LoaderEvents /> */}
			<BlockUi className="SgcContent" tag="div" blocking={state.blocking} >
            <ModalImg />
            <div className="EQcontainer">
               <header className="EQheader" id="div">
                  {/* <LegalHeader /> */}
                  <EQDetailHeader />
               </header>

               <main className={`EQmain ${showMenu}`}>

                  <div className={`EQmenu`}>
                     <ul className="products-list">
                        <div className="menuheader">
                           <h2>Sismos en Colombia</h2>
                        </div>
                        <div className="EQMenu-list-container TitleText">
                           <div className="EQMenu_list_content" id="EQMenu_list_content_detail">
                              <EQMenu
                                 test={true}
                                 types={optionsMenu}
                                 active={params.product}
                                 id={params.id}
                              />
                           </div>
                        </div>

                     </ul>
                  </div>
                  
                  <div className="EQproduct">
                     <EQSummary
                        id={
                           {
                              header: 'ID del Sismo',
                              body: eventDetail[0].id
                           }
                        }
                        title={eventDetail[0].properties.place != null
                           ? eventDetail[0].properties.place
                           : eventDetail[0].properties.products.origin[0].properties.title
                        }
                        body={
                           <EQSummaryTemplate event={eventDetail[0]} />
                        }
                     />
                     <EQContentManager
                        product={params.product}
                        handleNotEvent={handleNotEvent}
                     />
                  </div>

               </main>

               <footer className="footer">
					   <EQViewerFooter />
				   </footer>
            </div>
			</BlockUi>
		</>
	) : noDataComponent()
}

const mapStateToProps = state => ({
	eventDetail: state.detailEvent,
	showMenu: state.showMenu,
	menuIcon: state.menuIcon,
});

const mapDispatchToProps = {
	loadDetailEvent,
	show_menu
};

export default connect(mapStateToProps, mapDispatchToProps)(EQDetail);