import React from 'react'
import { FormControl, TextField, MenuItem } from '@material-ui/core';
import { getQuery } from 'Components/Atoms/A_Query/QActive';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { QValidates } from './QinputsValidates';

import BuildMmi from '../M_Reusable/BuildMmi';

//Opciones de Nivel de Intensidad

const intensityValues = [
    { roman: "No sentido", decimal: "2" },
    { roman: "Débil", decimal: "3" },
    { roman: "Ligero", decimal: "4" },
    { roman: "Moderado", decimal: "5" },
    { roman: "Fuerte", decimal: "6" },
    { roman: "Muy Fuerte", decimal: "7" },
    { roman: "Severo", decimal: "8" },
    { roman: "Violento", decimal: "9" },
    { roman: "Extremo", decimal: "10" },
]

const buildRomans = () => (
    intensityValues.map( number => (
        <MenuItem
            key = { number.decimal }
            id = { number.decimal }
            value = { number.decimal }
        >
            <div className='flexItemCatalog'>
            <BuildMmi
                mmi = { number.decimal }
                selectOption
            />
            { number.roman }
            </div>

        </MenuItem>
    ))
);

export const QLevelIntensity = () => {
    const classes = useStyles();
    const {
        min_level_intensity, max_level_intensity,
        updateWithErrors, handleLevelIntensity, deleteLevelFilter
    } = getQuery();

    const handleMinLevelIntensity = ( value ) => {
        const { validate, errors } = QValidates[
            "min_level_intensity"
        ](
            value,
            max_level_intensity.data,
            min_level_intensity.label,
            max_level_intensity.label
        );
        const newState = {
            ...min_level_intensity, data: value,
            validate: !validate, message: errors[0] || ""
        };
        validate ? handleLevelIntensity(
            'min_level_intensity', newState, "min"
        ) : updateWithErrors(
            'min_level_intensity', newState, errors
        );
    }

    const handleMaxLevelIntensity = ( value ) => {
        const { validate, errors } = QValidates[
            "max_level_intensity"
        ](
            value,
            min_level_intensity.data,
            max_level_intensity.label,
            min_level_intensity.label,
        );
        const newState = {
            ...max_level_intensity, data: value,
            validate: !validate, message: errors[0] || ""
        }
        validate ? handleLevelIntensity(
            'max_level_intensity', newState, "max"
        ) : updateWithErrors(
            'max_level_intensity', newState, errors
        );
    }

    const minNanIntensity = () => {
        handleLevelIntensity(
            'min_level_intensity', {
                ...min_level_intensity, data: "",
                message: "", validate: false
            }, "min"
        )
    }

    const maxNanIntensity = () => {
        handleLevelIntensity(
            'max_level_intensity', {
                ...max_level_intensity, data: "",
                message: "", validate: false
            }, "max"
        )
    }
    return (
        <div className = "Qselections">
            <div
                className = "Qselector selector1"
                id = "Qdepartment"
            >
                <div className = "Qselector_input">
                    <FormControl
                        className = { classes.formControl }
                        variant = "outlined"
                    >
                        <TextField
                            error = { min_level_intensity.validate }
                            helperText = { min_level_intensity.message }
                            id = "min_level_intensity"
                            label = { min_level_intensity.label }
                            variant = "outlined" select
                            value = { min_level_intensity.data }
                            onChange = {
                                ({ target }) => {
                                    const value = parseInt( target.value );
                                    if ( isNaN( value ) ) {
                                        max_level_intensity.data === ""
                                        ? deleteLevelFilter( 'min_level_intensity' )
                                        : minNanIntensity();
                                    } else { handleMinLevelIntensity( value ) }
                                }
                            }
                        >
                            { buildRomans() }
                        </TextField>
                    </FormControl>
                </div>
            </div>
            <div
                className = "Qselector"
                id = "Qdepartment"
            >
                <div className = "Qselector_input">
                    <FormControl
                        className = { classes.formControl }
                        variant = "outlined"
                    >
                        <TextField
                            error = { max_level_intensity.validate }
                            helperText = { max_level_intensity.message }
                            id = "max_level_intensity"
                            label = { max_level_intensity.label }
                            variant = "outlined" select
                            value = { max_level_intensity.data }
                            onChange = {
                                ({ target }) => {
                                    const value = parseInt( target.value );
                                    if ( isNaN( value ) ) {
                                        min_level_intensity.data === ""
                                        ? deleteLevelFilter( 'max_level_intensity' )
                                        : maxNanIntensity();
                                    } else { handleMaxLevelIntensity( value ) }
                                }
                            }
                        >
                            { buildRomans() }
                        </TextField>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}
