import { Mui5StyledTableSortLabel, StyledTableCell, StyledTableRow } from "./SeismEnhancedTable";

const columnHeaders = [
   {
      id: "relevant",
      label: '#',
      sort: true,
   },
   {
      id: "place",
      label: 'Origen',
      sort: false,
   },
   {
      id: "time",
      label: 'Fecha y Hora',
      sort: true,
   },
   {
      id: "depth",
      label: 'Prof (km)',
      sort: false,
   },
   {
      id: "magnitude",
      label: 'Mag',
      sort: true,
   },
];

function EnhancedTableHead({ onClick, order, orderBy, onRequestSort }) {

   const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
   };

   return (
      <StyledTableRow>
         {
            columnHeaders.map((headCell => (
               <StyledTableCell
                  key={ headCell.id }
                  sortDirection={ orderBy === headCell.id ? order : false }
                  about={ headCell.sort ? "sort" : "regular" }
               >
                  {
                     headCell.sort ? (
                        <Mui5StyledTableSortLabel
                           direction={ orderBy === headCell.id ? order : 'asc' }
                           onClick={ createSortHandler(headCell.id) }
                     >
                        { headCell.label }
                     </Mui5StyledTableSortLabel>
                     ) : <>{ headCell.label }</>
                  }

               </StyledTableCell>
            )))
         }
      </StyledTableRow>
   )
}
export default EnhancedTableHead