import React from "react";
import { Tooltip } from "@mui/material";
import Inv from "assets/icons/EQViewer/LevelOfVolcanicActivity/Inv.svg"
import { useSelector } from "react-redux";

function BuildLevelVolcano({ level, description, size=40 }) {

   const volcanoLevels = useSelector( state => state.volcanoLevels );
   const volcanoSelectedLevel = volcanoLevels.at( -level );

   return (
      volcanoSelectedLevel ? (
         <Tooltip disableInteractive title = { description || volcanoSelectedLevel.short_description } >
            <div className = "level_volcano">
               <img
                  src = { volcanoSelectedLevel.icon }
                  style = {{ width: size, display: "block" }}
                  alt = "volcanoLevel"
               />
            </div>
         </Tooltip>
      ) : (
         <Tooltip title = "No Info" >
            <div className = "level_volcano">
               <img
                  src = { Inv }
                  style = {{ width: size}}
                  alt = "Inv"
               />
            </div>
         </Tooltip>
      )
   )
}
export default BuildLevelVolcano;