import { useRef } from "react";
import { useMap } from "react-leaflet";
import * as WMS from "leaflet.wms";
import L from 'leaflet';
import { REPORT_URL } from "utils/urls";

// Format Text date from UTC (yyyy-mm-ddThh:mm:ssZ) to yyyy-mm-dd hh:mm
const formatFeaturesInfoDate = date =>{
    var convertedDate = date.substring(0, 10);
    var convertedTime = date.substring(11, 16);

    return convertedDate + " " + convertedTime;
}

export const CustomWMSLayerEvent = (
    {
        showPopUp, layersRef, mapRef,
        options, url, bound, coordinates, showLayers,
        showShapes
    }
) => {

    mapRef.current = useMap();
    const source = WMS.source ( url, options );
    const locationRef = useRef( coordinates || [] );

    layersRef.forEach( name => {
        mapRef.current.eachLayer( layer => {
            if ( layer._name ) {
                if ( showLayers === false || showShapes === true ) {
                    layer._name === 'sgc:departamento' && mapRef.current.removeLayer( layer )
                    layer._name === 'sgc:municipio' && mapRef.current.removeLayer( layer )
                }
            }
            layer._name === name
                && mapRef.current.removeLayer( layer );
        });
        const actualLayer = source.getLayer( name );
        if ( showLayers === false && name === "sgc:event" ) {
            mapRef.current.addLayer( actualLayer );
            source.getLayer( name ).bringToFront();
        } else {
            mapRef.current.addLayer( actualLayer );
            source.getLayer( name ).bringToFront();
        }
    });
    // coordinates && mapRef.current.panTo(
    //     new L.latLng ( locationRef.current )
    // );
    bound && bound[0] && mapRef.current.fitBounds( bound );

    source.showFeatureInfo = ( latlng, info ) => {
        try {
            if ( showPopUp ) {
                const featuresColection = JSON.parse(info);
                if ( featuresColection.features[0] ) {
                    const featureProperties = featuresColection.features[0]["properties"];
                    // console.log("FEATURE PROPERTIES", featureProperties)
                    // this part change the position or the bound on the map
                    locationRef.current = [
                        featureProperties.origin_latitude,
                        featureProperties.origin_longitude
                    ];
                    mapRef.current.panTo (
                        new L.latLng ( locationRef.current )
                    );
                    showPopUp({
                        geometry: {
                            coordinates: [
                                featureProperties.origin_latitude,
                                featureProperties.origin_longitude,
                                parseFloat( featureProperties.origin_depth ).toFixed(2)
                            ],
                            type: 'Point'
                        },
                        id: featureProperties.public_id,
                        properties: {
                            place: featureProperties.description,
                            localTime: formatFeaturesInfoDate(featureProperties.cal_local_time),
                            utcTime: formatFeaturesInfoDate(featureProperties.origin_time),
                            status: featureProperties.origin_evaluation_mode.toString().toLowerCase(),
                            closerTowns: featureProperties.closer_towns,
                            mag: parseFloat( featureProperties.magnitude ).toFixed(2),
                            mmi: featureProperties[ "shake_map.value" ], // intensidad instrumental
                            agency: featureProperties.origin_agency_id,
                            cdi: featureProperties[ "cal_shake_map" ], // intensidad percibida
                            maxPGA: featureProperties.cal_acceleration,
                            beachBall: featureProperties.cal_solution_moment_tensor,
                            urlMoreInfo:  `/detallesismo/${featureProperties.public_id}/resumen`,
                            urlReport: `${ REPORT_URL }/EvaluacionIntensidadesServlet?idSismo=${ featureProperties.public_id }&metodo=irASismoEspecifico`
                        }
                    });
                }
            }
        } catch ( err ) { console.log( err.message ) }
    }
    return null;
}