import { makeStyles } from "@material-ui/core";
import { geoserver } from 'utils/urls';

export const wmsStyles = new makeStyles( theme => ({
    base: { position: "relative", width: "100%", height: "100%" },
    legendLabel: { fontSize: "80%" },
    button: {
      backgroundColor: "#fafafa", zIndex: 1000, fontSize: "100%"
    },
    popup: {
      zIndex: 1000, backgroundColor: "fafafa", marginBottom: "20px",
    },
    legendBox: {
      zIndex: 1000, position: "absolute", bottom:"30px",
    },
    legendContainer: {
      overflowY: 'auto', overflowX: 'hidden',
    },
    minimapContainer: {
      position: "absolute",
      top: "0",
      right: "0",
      paddingTop: "10px",
      paddingRight: "10px",
      zIndex: "1000",
    }
}));

export const wmsInitStateFilter = () => ({
    cql : "",
    bound : [],
    draw : {
        wmsEntity:{
            ref:{}, url:"", cql:"", layers:"", status: false
        },
        polygon: {
            ref:{}, feature: {}, color: 'cyan', status: false
        },
        circle: {
            ref:{}, position:{}, radius:{}, color: 'cyan', status: false
        }
    }
})

export const wmsGeoInitState = () => ({
    layers:"sgc:departamento", url: geoserver, status: false
})

export const wmsMapInitState = () => ({
    currentLocation: { lat: 4, lng: -74 },
    map:{},
    position: [ 5.6, -72.9 ]
})

export const createGeoJson = ({ type, ...rest }) => {
    type = type.charAt(0).toUpperCase() + type.slice(1);
    return {
        name: "GeoJsonLayer",
        type: "FeatureCollection",
        features: [{
            type: "Feature",
            geometry: { ...rest, type },
            properties: null
        }]
    }
}

export const optionsWMS = {
    format: "image/png",
    srs: "EPSG:4326",
    transparent: "true",          
    info_format: "application/json",
    version: '1.3.0'   
}