import React, { useRef } from 'react';
import { getQuery } from 'Components/Atoms/A_Query/QActive';
import { QValidates } from './QinputsValidates';
import { UseNumberField, validateFloat } from 'hooks/UseNumberField';
import { UseRange } from 'hooks/UseRange';
import { FormControl } from '@mui/material';

const handleValue = value => {
    if ( value !== "" ) {
        const valueString = value.toString().split('.');
        return valueString[1] && valueString[1].length > 2
        ? parseFloat( value ).toFixed(2) : parseFloat( value );
    }
    return value;
}
const selectableArea = {
    circle: [ "lat", "lng" ],
    rectangle: [ "min_lat", "min_lng", "max_lat", "max_lng" ]
};
export const QLatLng = () => {

    const shapeRef = useRef();
    const {
        map_options, handleArea, handleRadio, updateWithErrors
    } = getQuery();

    const { area, circle, rectangle } = map_options;

    const handleStorage = ( key, value, errors = null ) => {

        if ( errors !== null ) {
            updateWithErrors( 'map_options', {
                ...map_options,
                [ key ]: value
            }, errors );
        } else {
            handleArea( area === 'circle' ? {
                type: "geo_distance",
                geometry : {
                    lat: value.lat.data,
                    lon: value.lng.data
                },
                distance: `${ circle.radio }km`
            } : {
                type: "geo_bounding_box",
                geometry: { 
                    top_left: {
                        lat: value.max_lat.data,
                        lon: value.max_lng.data
                    },
                    bottom_right: {
                        lat: value.min_lat.data,
                        lon: value.min_lng.data
                    }
                }
            }, {
                ...map_options, [ key ]: value
            });
        }
    }

    const changeRadio = value => {
        let newRadius = parseInt( value );
        newRadius = isNaN( newRadius ) ? 0 : newRadius;
        const newState = {
            ...map_options, circle: {
                ...circle,
                radio: newRadius
            }
        }
        handleRadio( newRadius, newState );
    }


    const validateInputs = ( value, shape, key ) => {
        value = validateFloat( value ) === true
            ? parseFloat( value ) : value;
        const { reverse, label, limits } = shape[ key ];
        const { validate, errors } = reverse ? QValidates[
            key
        ](
            value, shape[ reverse ].data, label,
            shape[ reverse ].label, limits
        ) : QValidates[ key ]( value, label, limits );
        const newValue = {
            ...shape, [ key ]: {
                ...shape[ key ], data: value,
                validate: !validate, message: errors[0] || ""
            }, [ reverse ] : {
               ...shape[ reverse ], message: errors[0] ? shape[ reverse ].message : ""
            }
        };
        validate
            ? handleStorage ( area, newValue )
            : handleStorage( area, newValue, errors );
    }

    const handleValueShape = ( validation, key ) => { 

        validation.error === false ? validateInputs(
            validation.value, shapeRef.current, key
        ) : handleStorage(
            area, {
                ...shapeRef.current, [ key ]: {
                    ...shapeRef.current[ key ],
                    validate: validation.error,
                    message: validation.helperText,
                    data: '',
                }
            }, [ validation.helperText ]
        )      
    }

    const buildLatLng = ( shape, keys ) => {
        shapeRef.current = shape;
        return keys.map(( key, index ) => (
            <div
                className = {
                    index % 2 === 0  ? "Qselector" : "Qselector selector1"
                }
                key = {`${ shape }_${ key }`}
            >
                <div className = "Qselector_input">
                    <FormControl sx={{ margin: 1 }}>
                        <UseNumberField
                            init = { handleValue( shape[ key ].data ) }
                            placeholder = "Ingrese"
                            id = { `standard-number${ key }` }
                            name = { key }
                            label = { shape[ key ].label }
                            variant = "outlined"
                            helpText = { shape[ key ].message }
                            trigger = { handleValueShape }
                            err = { shape[ key ].validate }
                        />
                    </FormControl>
                </div>
            </div>
        ));
    }
    return area === "circle" ? (
        <>
            { buildLatLng( circle, selectableArea[ "circle" ] ) }
            <div className="Qselector">
                <div id = "QrangeCircle">
                    <UseRange
                        title = "Radio"
                        value = { circle.radio }
                        handleRange = { changeRadio }
                        min = { 0 }
                        max = { 900000 }
                    />
                </div>
            </div>
        </>
    ) : buildLatLng( rectangle, selectableArea[ "rectangle" ] );

}