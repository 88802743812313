import React from 'react'
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const SGCTooltip = withStyles( theme => ({
    tooltip: { backgroundColor: '#646464', color: '#ffffff' }
}))( Tooltip );

export const UseTooltip = ({ title, placement, children, classes, size="medium" }) => (
    <div className = { classes || "defaultToolTip" }>
        <SGCTooltip 
            interactive
            enterTouchDelay={50}
            placement = { placement || 'bottom-end' }
            title = { <>{ title }</> }
        >
            { children || <InfoIcon fontSize={ size } color = 'primary' /> }
        </SGCTooltip>
    </div>
)
