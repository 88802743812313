import { validateFloat } from "hooks/UseTextField";

const maxMinValue = ( min, max ) => {
    return max > min;
}
const limitsValidate = ( value, { max, min } ) => {
    return value >= min && value <= max;
}
const onlyLimits = ( value, label, limits ) => {
    return limitsValidate( value, limits ) ? {
        validate: true, errors:[]
    } : {
        validate: false, errors: [
            `${ label } debe estar entre ${ limits.min }
            y ${ limits.max }`
        ]
    }
}
const max_values_validate = (
    updateValue, min_value, label, labelMin, limits = null
) => {

    updateValue = validateFloat( updateValue ) === true
        ? parseFloat( updateValue ) : updateValue;
    let errors = [];
    const minMaxValidation = limits
        ? limitsValidate( updateValue, limits )
        : true;
    // if a validation is false its means a error
    !minMaxValidation && errors.push(
        `${ label } debe estar entre ${ limits.min }
        y ${ limits.max }`
    );

    if ( min_value === "" ) {
        return {
            validate: minMaxValidation, errors
        }
    }

    const compareValidation = maxMinValue( min_value, updateValue );
    !compareValidation && errors.push(
        `${ label } debe ser mayor que ${ labelMin }`
    );
    return {
        validate: compareValidation && minMaxValidation,
        errors
    }
}

const min_values_validate = (
    updateValue, max_value, label, labelMax, limits = null
) => {

    updateValue = validateFloat( updateValue ) === true
        ? parseFloat( updateValue ) : updateValue;
    let errors = [];
    const minMaxValidation = limits
        ? limitsValidate( updateValue, limits )
        : true;

    !minMaxValidation && errors.push(
        `${ label } debe estar entre ${ limits.min }
        y ${ limits.max }`
    );
    if ( max_value === "" ) {
        return {
            validate: minMaxValidation, errors
        }
    }
    const compareValidation = maxMinValue( updateValue, max_value );
    !compareValidation && errors.push(
        `${ label } debe ser menor que ${ labelMax }`
    );
    
    return {
        validate: compareValidation && minMaxValidation,
        errors
    }
}

export const QValidates = {
    min_magnitude: min_values_validate,
    max_magnitude: max_values_validate,
    min_depth: min_values_validate,
    max_depth: max_values_validate,
    min_mag_error: min_values_validate,
    max_mag_error: max_values_validate,
    min_depth_error: min_values_validate,
    max_depth_error: max_values_validate,
    min_latitude_error: min_values_validate,
    max_latitude_error: max_values_validate,
    min_longitude_error: min_values_validate,
    max_longitude_error: max_values_validate,
    min_rms: min_values_validate,
    max_rms: max_values_validate,
    min_gap: min_values_validate,
    max_gap: max_values_validate,
    min_mag_tm: min_values_validate,
    max_mag_tm: max_values_validate,
    min_level_intensity: min_values_validate,
    max_level_intensity: max_values_validate,
    lat: onlyLimits,
    lng: onlyLimits,
    min_lat: min_values_validate,
    max_lat: max_values_validate,
    min_lng: min_values_validate,
    max_lng: max_values_validate,
}