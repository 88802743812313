import resume from "assets/icons/EQDetail/EQMenu/resumen_verde.svg";
import map from "assets/icons/EQDetail/EQMenu/mapa_dinamico_verde.svg";
import origin from "assets/icons/EQDetail/EQMenu/origen_verde.svg";
import perceived_intensity from "assets/icons/EQDetail/EQMenu/intensidad_percibida_verde.svg";
import instrumental_instensity from "assets/icons/EQDetail/EQMenu/intensidad_instrumental_verde.svg";
import accelerations from "assets/icons/EQDetail/EQMenu/aceleraciones_verde.svg";
import focal_mechanism from "assets/icons/EQDetail/EQMenu/mecanismo_focal_verde.svg";
import tensor_moment from "assets/icons/EQDetail/EQMenu/momento_tensor_verde.svg";
import historical_seismicity from "assets/icons/EQDetail/EQMenu/sismicidad_historica_verde.svg";
import downloads from "assets/icons/EQDetail/EQMenu/descargas_verde.svg";

// Recibe un arreglo de propiedades
// Devuelve un arreglo con la lista de productos
const assing = (cards) => {
  let items = [];
  for (let product of cards) {
    // dependiendo del tipo de producto se le asigna un product o nombre y un icon
    switch (product) {
      case "origin":
        items.push({
          product: "Origen",
          icon: origin,
          key: "origen",
          link: "origen",
        });
        break;
      case "staticmap":
        items.push({
          product: "Mapa Dinámico",
          key: product,
          icon: map,
          link: "mapa",
        });
        break;
      case "cdi":
        items.push({
          product: "Intensidad Percibida",
          key: product,
          icon: perceived_intensity,
          link: "cdi",
        });
        break;
      case "mmi":
        items.push({
          product: "Intensidad Instrumental",
          key: product,
          icon: instrumental_instensity,
          link: "mmi",
        });
        break;
      case "sm":
        items.push({
          product: "Aceleraciones",
          key: product,
          icon: accelerations,
          link: "sm",
        });
        break;
      case "sh":
        items.push({
          product: "Sismicidad Histórica",
          key: product,
          icon: historical_seismicity,
          link: "sh",
        });
        break;
      case "focalmechanism":
        items.push({
          product: "Mecanismo Focal",
          key: product,
          icon: focal_mechanism,
          link: "fm",
        });
        break;
      case "momenttensor":
        items.push({
          product: "Tensor Momento Sísmico",
          key: product,
          icon: tensor_moment,
          link: "tm",
        });
        break;
      case "downloads":
        items.push({
          product: "Descargas",
          key: product,
          icon: downloads,
          link: "descargas",
        });
        break;
      case "overview":
        items.push({
          product: "Resumen",
          key: product,
          icon: resume,
          link: "resumen",
        });
        break;
      default:
        break;
    }
  }
  return items;
};
const translate = (param, value) => {
  let output = [];
  switch (param) {
    case "close_towns":
      output = [{ header: "Municipios Cercanos:" }, { key: param, value }];
      break;
    case "felt":
      output = [{ header: "Sentido:" }, { key: param, value }];
      break;
    case "gap":
      output = [{ header: "Gap:" }, { key: param, value }];
      break;
    case "rms":
      output = [{ header: "RMS:" }, { key: param, value }];
      break;
    case "mmi":
      output = [{ header: "Intensidad Instrumental:" }, { key: param, value }];
      break;
    case "place":
      output = [{ header: "Ubicación:" }, { key: param, value }];
      break;
    case "agency":
      output = [{ header: "Agencia:" }, { key: param, value }];
      break;
    case "cdi":
      output = [{ header: "Intensidad Percibida:" }, { key: param, value }];
      break;
    case "mag":
      output = [{ header: "Magnitud" }, { key: param, value }];
      break;
    case "status":
      output = [{ header: "Estatus:" }, { key: param, value }];
      break;
    case "fases":
      let phases_count = 0;
      for (let phase of value) {
        phases_count += phase["phases"].length;
      }
      output = [{ header: "Fases:" }, { key: param, value: phases_count }];
      break;
    case "updated":
      output = [{ header: "Tiempo de origen:" }, { key: param, value }];
      break;
    default:
      break;
  }
  return output;
};

const origins_table = (data_table, headers) => {
  let output = {
    headers,
    body: [],
  };
  // se recorre todo el arreglo
  data_table.forEach((element) => {
    let props = element["properties"];

    output["body"].push([
      { key: "source", value: element["source"], prefered: element.prefered },
      { key: "magnitude", value: props["magnitude"] },
      { key: "eventTime", value: props["eventtime"] },
      { key: "depth", value: props["depth"] },
      { key: "Estatus", value: element["status"] },
      { key: "Ubicación", value: `${props["latitude"] - props["longitude"]}` },
      { key: "Source", value: props["originSource"] },
    ]);
  });

  return output;
};

const convert = { assing, translate, origins_table };

export default convert;