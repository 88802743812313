import { Carousel } from "react-responsive-carousel";
import { buildArrowNext, buildArrowPrev } from "Components/Atoms/A_Home/HmCarouselArrows";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

function HomeMainCarousel() {

   const carouselEvents = useSelector( state => state.homeEvent );

   return (
      <div className="HomeCarouselContainer" role="presentation">
         {
            carouselEvents ? carouselEvents[0] && (
               <Carousel
                  className="HomeCarousel"
                  showStatus={ false }
                  renderArrowPrev = { buildArrowPrev }
                  renderArrowNext = { buildArrowNext }
                  infiniteLoop = { true }
                  showThumbs = { false }
                  autoPlay = { true }
                  interval={ 8000 }
                  height = {`100%`}
                  width = {`100%`}
                  swipeable={false}
                  >
                     {
                        carouselEvents.map(( element, index ) => {
                           return(
                                 <a 
                                    href = { element.href }
                                    target = "_blank"
                                    rel = "noopener noreferrer"
                                    key = {`Carousel${index}`}
                                    id = {`Carousel${index}`}
                                    aria-label="Enlace a Aviso SGC"
                                    title={ element.alt }
                                 >
                                    <div className="CarouselImg">
                                       <img className="HomeImg" src = { element.src } alt ={ element.alt }/>
                                    </div>
                                 </a>
                           )
                        })
                     }
               </Carousel>
            ) : 
            <Box height={350} />
         }         
      </div>
   )
}
export default HomeMainCarousel