
import { memo, useEffect, useRef, useState } from "react";
import { Marker } from "react-leaflet";
import L from 'leaflet';
import { pixelCalculator } from "Components/Atoms/A_Reusable/CalculatorPixelRadiusForEQ";
import { calculateColor } from "Components/Atoms/A_Reusable/CalculatorDepthColors";

function SeismSimpleMarker({ lat, lng, depth, mag, markerDelay, reset, stop, onShowEvent, time }) {

   const [ show, setShow ] = useState( false );
   const markerTimeoutRef = useRef();

   const iconPixel = pixelCalculator( Math.floor( mag ) )*2.5;
   const iconColor = calculateColor( depth );
   
   const eventIcon =  L.divIcon({
      iconSize: [ iconPixel, iconPixel ],
      className: 'animated-marker blinking-small'
   });
   
   // To show the marker timeout animation
   useEffect( () => {
      show && setShow( false ); // to apply reset

      markerTimeoutRef.current = window.setTimeout( () => {
         setShow( true );
         onShowEvent( time );
      }, markerDelay )

      return () => window.clearTimeout( markerTimeoutRef.current );
      // eslint-disable-next-line
   }, [ markerDelay, reset ] );

   // To stop the marker timeout animation
   useEffect( () => {
      if ( stop ){ 
         window.clearTimeout( markerTimeoutRef.current );
         !show && setShow( true );
      }
      // eslint-disable-next-line
   }, [ stop ] );

   return (
      show && (
         <Marker 
            position={[ lat, lng ]}
            icon={ eventIcon }
            eventHandlers={{
               add: function(e){
                  var myIcon =  e.target._icon;
                  myIcon.style.backgroundColor = iconColor;
               },
            }}
         />
      )
   )  
}
export default memo( SeismSimpleMarker );