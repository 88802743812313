import React from 'react'
import {
    Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Button, makeStyles
} from "@material-ui/core";
import { GREEN } from 'utils/styles';

const useStyles = makeStyles(theme => ({
   title: {
     color: GREEN,
     textAlign: "center",
     "& h2.MuiTypography-root":{
      fontWeight: "bolder"
     }
   },
   content: {
      textAlign: "justify",
   }
 }));

export const UseDialog = (
    { open, onClose, title, message, btn, titleCenter=false }
) => {

   const classes = useStyles();
   return (
    <Dialog
        open = { open }
        style={{ zIndex: 1301 }}
        onClose = { onClose }
        aria-labelledby = "alert-dialog-title"
        aria-describedby = "alert-dialog-description"
    >
        {
            title && (
                <DialogTitle id = "alert-dialog-title" className={ titleCenter && classes.title }>
                    { title }
                </DialogTitle>
            )
        }
        <DialogContent className={ classes.content }>
            <DialogContentText id = "alert-dialog-description">
                { message }
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
                onClick = { btn.action }
                color = "primary"
               //  autoFocus
            >
                { btn.text || "Recargar Página" }
            </Button>
        </DialogActions>
    </Dialog>
   )
}
