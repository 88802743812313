import { useMediaQuery } from "@mui/material"
import BuildLevelVolcano from "../M_Reusable/BuildLevelVolcano"
import Moment from "react-moment";

function SlideWeekBulletins({ bulletins, date }) {
   
   const responsive = useMediaQuery('(max-width: 800px)'); 

   const buildBulletin = ( array ) => {

      return array.map((bulletin, index) => (
            <a 
               href={ `/detallevolcan/${bulletin.volcanoId}/` } 
               key={ `v${index}` } 
               className="TableListLink"
               target="_blank"
               rel="noopener noreferrer"
            >
               <li className="TableListItem">
                  <BuildLevelVolcano
                     level={ bulletin.activityLevel }
                     size={ responsive ? "4.5vw" : 30 }
                  />
                  <span title={ bulletin.VolcanoName }>
                     { bulletin.VolcanoName }
                  </span>
               </li>
            </a>
         )
      )
   }

   return (
      
      <div className="SlideEventContainer"> 

         <div className="SlideEvent">
            <div className="SlideEventTitle">
               <span className="HomeTitle">
                  Eventos Geológicos Destacados
               </span>
            </div>
            <div className="SlideEventSubTitle">
               <div className="EventSuperTitle Title">
                  Boletines semanales <span style={{ display:"inline-block" }}>de actividad volcánica</span> 
               </div>
               <span className="EventEmphasisText Title" style={{ textAlign: "center" }}>
                  <Moment fromNow>{ date }</Moment>
               </span>
            </div>

            <div className="SlideEventContent">

               <div className="EventTableContainer">
                  {
                     Object.values( bulletins ).map( (itemsArray, i) => {
                        return itemsArray.length > 0 && (
                           <div key={ `OVS`+ i } className="EventTableItem">
                              <div className="EventTableContent">
                                 <ul className="TableList">
                                    { buildBulletin( itemsArray ) }
                                 </ul>
                              </div>
                           </div> 
                        )
                     } )
                  }                                                        
               </div>
            </div>

         </div>
      </div>
   )
}
export default SlideWeekBulletins