import { Box } from "@mui/material"
import HomeNews from "Components/Molecules/M_Home/HomeNews"
import HomeServices from "Components/Molecules/M_Home/HomeServices"
import HomeTopic from "Components/Molecules/M_Home/HomeTopic"
import { HomeService } from "api/Services/ReqHome"
import React, { useEffect, useState } from "react"

const sectionComponents = [
   {
      component: <HomeServices />,
      class: "HMservices",
   },
   {
      component: <HomeTopic />,
      class: "HmTopic",
   },
   {
      component: <HomeNews />,
      class: "HmTopic",
   },
]

const joinArrays = ( array1, array2 ) => {
   return array1.map( (e, i) => {
      return { ...e, ...array2[i] }
   })
}

function HomeSections() {

   const [ sections, setSections ] = useState([]);

   useEffect(()=> {
      HomeService.getSections().then( response => {
         setSections( joinArrays( response.data, sectionComponents ));
      }).catch( e => null );
   }, [])

   return (

         sections[0] ? sections.map( (section, i) => (
            <section key={ `section` + i } className={ section.class } aria-label={ section.title }>
               { React.cloneElement( section.component, { 
                  title: section.title, 
                  description: section.description,
               } ) }
            </section>
            ) ) : <Box height={350}/>
         ) 
         
}
export default HomeSections