import React from 'react';

const MapLegend = ({ AgePointsScale, EarthquakePointsScale, DepthPointsScale, VolcanoIconScale, StarEpicenter }) => {
    return(
        <>
            { StarEpicenter }
            { AgePointsScale }
            { DepthPointsScale }
            { EarthquakePointsScale }
            { VolcanoIconScale }
        </>
    )
}

export default MapLegend;
