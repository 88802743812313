import React, { useRef, useEffect } from 'react';
import L from 'leaflet';
import TensorIcon from "assets/icons/Query/pelotadeplaya_vacia-01.svg";
import { tmApprobed } from "Components/Atoms/A_Query/QueryControl";
import { createTensorBall } from "api/ReqResApi";
import { TensorCluster } from './TensorCluster';
import { REPORT_URL } from 'utils/urls';

export const TensorMarker = ({ event, isSelected, onSelect }) => {

    const markerRef = useRef();

    const createIcon = async key => {
        const img = new Image();
        try {
            const imageUrl = createTensorBall(
                new Date (
                    event.properties.localTime.replace( ' ', 'T' )
                ).getFullYear(),
                event.id, tmApprobed[ key ].out
            );

            const fetchImage = await fetch( imageUrl );
            img.src = fetchImage.url;
            return L.icon({
                iconUrl: img.src,
                className: 'leafletDivIcon'
            });
        } catch {
            return L.icon({
                iconUrl: TensorIcon,
                className: 'leafletDivIcon'
            })
        }
    }

    const handleClick = coordinates => {
        onSelect(
            event, {
                id: event.id,
                coordinates, place: event.properties.place,
                lat: event.geometry.coordinates[0],
                lng: event.geometry.coordinates[1],
                depth: event.geometry.coordinates[2],
                localTime: event.properties.localTime,
                utcTime: event.properties.utcTime,
                status: event.properties.status,
                closerTowns: event.properties.closerTowns,
                magnitude: event.properties.mag,
                mmi: event.properties.mmi,
                cdi: event.properties.cdi,
                maxPGA: event.properties.maxPGA,
                agency: event.properties.agency,
                beachBall: event.properties.beachBall,
                urlMoreInfo: `/detalleevento/${ event.id }/resumen`,
                urlReport: `${ REPORT_URL }/EvaluacionIntensidadesServlet?idSismo=${ event.id }&metodo=irASismoEspecifico`,
            }
        )
    }

    useEffect(() => {
        markerRef.current && isSelected
            && markerRef.current.leafletElement.openPopup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <TensorCluster
            markers = {
                event.properties.tensorMoment.map( tensor => ({
                    lat: event.geometry.coordinates[0],
                    lng: event.geometry.coordinates[1],
                    icon: () => createIcon( tensor ),
                    // popup: popup.toString()
                }))
            }
            onClick = { handleClick }
        />
    );
}