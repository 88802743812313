import React, { useRef } from 'react';
import { useFilterStyles } from 'hooks/UseFiltersView';
import { FormControl, Select, MenuItem } from '@material-ui/core';

export const CatalogFilters = ({ sortData }) => {

    const classes = useFilterStyles();
    const filterRef = useRef(10);

    const updateFilter = ({ target }) => {
        filterRef.current = target.value;
        sortData( filterRef.current );
    }

    return (
        <div className = "filter-container">
            <div className = "filter-select">
                <FormControl className = { classes.formControl }>
                    <Select
                        value = { filterRef.current }
                        className = { classes.selectEmpty }
                        onChange = { updateFilter }
                        displayEmpty
                    >
                        <MenuItem  value = { 0 } disabled>
                            Filtro
                        </MenuItem>
                        <MenuItem  value = { 10 }>
                            Más reciente primero
                        </MenuItem>
                        <MenuItem  value = { 20 }>
                            Más antiguo primero
                        </MenuItem>
                        <MenuItem  value = { 30 }>
                            Mayor magnitud primero
                        </MenuItem>
                        <MenuItem  value = { 40 }>
                            Menor magnitud primero
                        </MenuItem>
                        {/* <MenuItem  value = { 50 }>
                            Intensidad percibida (Mayor a menor)
                        </MenuItem>
                        <MenuItem  value = { 60 }>
                            Intensidad instrumental (Mayor a menor)
                        </MenuItem> */}
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}