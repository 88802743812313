import { useState } from 'react';
import { CdiChart } from 'Components/Atoms/A_EQDetails/CdiChart';
import { CdiMuiTable } from 'Components/Atoms/A_EQDetails/CdiMuiTable';
import { EQCdiMap } from './EQCdiMap';
import { TabViews } from 'Components/UI/TabViews';

const CATEGORIES_QUANTITY = 7;

export const EQCdiTabs = ({ event, cdi }) => {

  const [ anchorLegend, setAnchorLegend ] = useState(null);
  const openLegend = Boolean(anchorLegend);
  const legendId = openLegend ? "legend" : undefined;

  
  const cdiTab1 = () => {

    let accumulated = [];
    const timeReports = cdi.timereports
      .map( item => {
        accumulated.push( item.accumulated )
        return Math.trunc( item.position )
      });
  
    return {
      options: {
        chart: {
          height: 350, type: 'line',
          zoom: {
            enabled: true, type: 'x', autoScaleYaxis: false,
            zoomedArea: {
              fill: { color: '#90CAF9', opacity: 0.4 },
              stroke: { color: '#0D47A1', opacity: 0.4, width: 1 }
            }
          }
        },
        dataLabels: { enabled: false },
        labels: timeReports,
        tooltip: {
          marker: { show: false }
        },
        stroke: { curve: 'straight' },
        title: {
          text: 'N° Reportes vs Tiempo',
        },
        grid: {
          row: { colors: [ '#f3f3f3', 'transparent' ], opacity: 0.5 }
        },
        xaxis: {
          categories: timeReports,
          labels: { rotate: 0 },
          title: { text: 'Tiempo(Minutos)' }
        },
        yaxis: {
          title: { text: 'N° Reportes' }
        }
      }, accumulated
    }
  
  }

  const cdiTab2 = () => {
    const [{ distance: maxDistance }] = cdi.distanceintensity
      .sort((item1, item2) => item2.distance - item1.distance)

    const offsetCategories = maxDistance / CATEGORIES_QUANTITY;
    const categoriesX = [];
    for (let accumDistance = 0; accumDistance < maxDistance; accumDistance += offsetCategories ) {
      categoriesX.push(accumDistance);
    }
    categoriesX.push(maxDistance);

    return {
      options: {
        chart: { height: 350, type: 'scatter' },
        tooltip: {
          marker: { show: false }
        },
        dataLabels: { enabled: false },
        xaxis: {
          type: 'category',
          title: {
            text: 'Distancia (Km)',
            offsetY: 15,
          },
          categories: categoriesX,
          tickPlacement: 'between',
          min: 0,
          max: maxDistance * 1.05,
          labels: {
            rotate: 0,
            show: true,
            showDuplicates: false,
            formatter: (value) => value | 0,
          },
          axisBorder: {
            show: true,
            color: '#000',
          },
        },
        yaxis: {
          title: { text: 'Intensidad' },
          min: 0,
          max: 10,
          axisBorder: {
            show: true,
            color: '#000',
          }
        },
        title: { text: 'Intensidad vs Distancia' },
        align: 'center',
        legend: {
          show: false,
        },
        markers: {
          colors: ['#2874fc'],
        },
      },
      accumulated: cdi.distanceintensity.map( item => ({
        name: `[${item.intensity}, ${item.distance}]`,
        data: [[ item.distance, item.intensity ]],
      }))
    }
  }

  const views = [
    <EQCdiMap
      handleClick = {
        ({ currentTarget }) => setAnchorLegend( currentTarget )
      }
      event = { event }
      legendId = { legendId }
      reports = { cdi.reports }
    />,
    <CdiMuiTable reports = { cdi.reports } />,
    <div className = "ans-vs-time">
      <div className = "ans-graph">
        <CdiChart
          seriesName = "Respuestas"
          getData = { cdiTab1 }
          cdi = { cdi }
        />
      </div>
      <div className = "intensity-info">
        <p>
          En el gráfico se observa la cantidad de formularios diligenciados 
          por los usuarios desde el momento en que ocurrió el sismo hasta algunas 
          horas después del mismo. El número de formularios diligenciados en minutos, 
          horas y días después de un evento refleja el interés de los ciudadanos 
          para proporcionar y recibir información sobre el sismo.
        </p>
      </div>
    </div>,
    <div className = "dist-vs-intensity">
      <div className = "dist-graph">
        <CdiChart seriesName = "Intensidad" getData = { cdiTab2 } onlyPoints />
      </div>
      <div className = "intensity-info">
        <p>
          Este gráfico representa la atenuación que hace referencia a la 
          intensidad evaluada para cada municipio con relación a la distancia 
          al hipocentro (punto en profundidad donde se origina el sismo). 
          Normalmente la intensidad disminuye al aumentar la distancia desde 
          el hipocentro y si la magnitud del sismo es alta, el sismo tenderá a 
          sentirse a una distancia mayor. Es muy importante estudiar la atenuación 
          de la intensidad porque permite predecir cuáles pueden ser los efectos 
          de un sismo en el territorio
        </p>
      </div>
    </div>,
    <div className="main-content-cdi">
      <div className = "cdi-tables">
         <div className="EQintensity">
            <div className="container-img">
              <img 
               src={ event.properties.products.cdi.replace( "cdi.json", cdi.infographic_file ) } 
               alt="EQCdiInfographic"
              />
            </div>
          </div>
         <div className = "infographic-info">
            <p>
               <b>Nota:</b> La infografía presenta la información capturada en horas posteriores al evento, 
               pero puede variar con la recepción de nuevos formularios y reevaluación de los parámetros 
               del sismo (localización, magnitud y profundidad).
            </p>
         </div>
      </div>
    </div>
  ]

  return (
      <TabViews 
         link={`https://sismosentido.sgc.gov.co/EvaluacionIntensidadesServlet?id_sismo=${ event.id }&metodo=verSalidas`}
         tabs={[
            { label: 'MAPA' },
            { label: 'RESPUESTAS' },
            { label: 'RESPUESTAS vs TIEMPO' },
            { label: 'INTENSIDAD vs DISTANCIA'},
            { label: 'INFOGRAFÍA', validation: cdi.infographic_file ? true : false },
         ]}
         views={ views }
      />
  )
}