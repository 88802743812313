import { Link, useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { show_menu, changeMenuIcon } from "redux/actionsCreators";

import aceleraciones from "assets/icons/EQDetail/EQMenu/aceleraciones_blanco.svg";
import descargas from "assets/icons/EQDetail/EQMenu/descargas_blanco.svg";
import intensidad_instrumental from "assets/icons/EQDetail/EQMenu/intensidad_instrumental_blanco.svg";
import intensidad_percibida from "assets/icons/EQDetail/EQMenu/intensidad_percibida_blanco.svg";
import mapa_dinamico from "assets/icons/EQDetail/EQMenu/mapa_dinamico_blanco.svg";
import mecanismo_focal from "assets/icons/EQDetail/EQMenu/mecanismo_focal_blanco.svg";
import momento_tensor from "assets/icons/EQDetail/EQMenu/momento_tensor_blanco.svg";
import origen from "assets/icons/EQDetail/EQMenu/origen_blanco.svg";
import resumen from "assets/icons/EQDetail/EQMenu/resumen_blanco.svg";
import sm_historica from "assets/icons/EQDetail/EQMenu/sismicidad_historica_blanco.svg";
import eq_historic from "assets/icons/EQDetail/EQMenu/Icono-HL_.svg";


/* 
Orden 	Key	               Descripción	             URL 	
1		    Sin key	           Resumen del evento        resumen
2		    Sin key	           Mapa Dinámico	           mapa
3		    origin	           Origen	                   origen
4		    cdi	               Intensidad Percibida      cdi	
5		    mmi	               Intensidad Instrumental	 mmi
6		    sm	               Aceleraciones	           sm
7		    nearbyseismos	     Sismicidad Histórica	     sh
8		    focalmechanism	   Mecanismo Focal	         mf
9		    momenttensor	     Tensor Momento 	         tm
10		  downloads	         Descargas	               descargas
 */

const EQMenu = ({ types, active, id, test }) => {

   const dispatch = useDispatch();
	const location = useLocation();
	const currentLocation = test ? "detalletest" : location.pathname.includes('detalleevento')
		? 'detalleevento' : 'detallesismo'

	const hideMenu = () => {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		if (window.innerWidth <= 1260) {
			dispatch( changeMenuIcon(false) );
			dispatch( show_menu() );
		}
	}

	const buildLinks = (product, active, id) => {
		switch (product) {
			case "overview":
				return (
					<li
						key="overview"
						onClick={hideMenu}
						className={active === "resumen" ? "active" : ""}
					>
						<div>
							<Link 
                        to={`/${currentLocation}/${id}/resumen`}
                        className="EQMenu-list-item"
                     >
								<img
									className="icon-list"
									src={resumen}
									alt="resumen"
								/>
								<hr />
								<p>Resumen del Evento</p>
							</Link>
						</div>
					</li>
				)
			case "map":
				return (
					<li
						key="map"
						onClick={hideMenu}
						className={active === "mapa" ? "active" : ""}
					>
						<div>
							<Link to={`/${currentLocation}/${id}/mapa`}
                        className="EQMenu-list-item">
								<img
									className="icon-list"
									src={mapa_dinamico}
									alt="resumen"
								/>
								<hr />
								<p>Mapa Dinámico</p>
							</Link>
						</div>
					</li>
				)
			case "origin":
				return (
					<li
						key="origin"
						onClick={hideMenu}
						className={active === "origen" ? "active" : ""}
					>
						<div>
							<Link to={`/${currentLocation}/${id}/origen`}
                        className="EQMenu-list-item"
                     >
								<img
									className="icon-list"
									src={origen}
									alt="resumen"
								/>
								<hr />
								<p>Origen</p>
							</Link>
						</div>
					</li>
				)
			case "cdi":
				return (
					<li
						key="cdi"
						onClick={hideMenu}
						className={active === "cdi" ? "active" : ""}
					>
						<div>
							<Link to={`/${currentLocation}/${id}/cdi`}
                        className="EQMenu-list-item"
                     >
								<img
									className="icon-list"
									src={intensidad_percibida}
									alt="resumen"
								/>
								<hr />
								<p>Intensidad Percibida</p>
							</Link>
						</div>
					</li>
				)
			case "mmi":
				return (
					<li
						key="mmi"
						onClick={hideMenu}
						className={active === "mmi" ? "active" : ""}
					>
						<div>
							<Link to={`/${currentLocation}/${id}/mmi`}
                        className="EQMenu-list-item"
                     >
								<img
									className="icon-list"
									src={intensidad_instrumental}
									alt="resumen"
								/>
								<hr />
								<p>Intensidad Instrumental</p>
							</Link>
						</div>
					</li>
				);
			case "sm":
				return (
					<li
						key="sm"
						onClick={hideMenu}
						className={active === "sm" ? "active" : ""}
					>
						<div>
							<Link to={`/${currentLocation}/${id}/sm`}
                        className="EQMenu-list-item"
                     >
								<img
									className="icon-list"
									src={aceleraciones}
									alt="resumen"
								/>
								<hr />
								<p>Aceleraciones</p>
							</Link>
						</div>
					</li>
				);
			case "sh":
				return (
					<li
						key="sh"
						onClick={hideMenu}
						className={active === "sh" ? "active" : ""}
					>
						<div>
							<Link to={`/${currentLocation}/${id}/sh`}
                        className="EQMenu-list-item"
                     >
								<img
									className="icon-list"
									src={sm_historica}
									alt="resumen"
								/>
								<hr />
								<p>Sismicidad Regional</p>
							</Link>
						</div>
					</li>
				);
			case "nearbyseismos":
				return (
					<li
						key="sh"
						onClick={hideMenu}
						className={active === "sh" ? "active" : ""}
					>
						<div>
							<Link to={`/${currentLocation}/${id}/sh`}
                        className="EQMenu-list-item"
                     >
								<img
									className="icon-list"
									src={sm_historica}
									alt="resumen"
								/>
								<hr />
								<p>Sismicidad Regional</p>
							</Link>
						</div>
					</li>
				);
			case "Nearbyseismos":
				return (
					<li
						key="sh"
						onClick={hideMenu}
						className={active === "sh" ? "active" : ""}
					>
						<div>
							<Link to={`/${currentLocation}/${id}/sh`}
                        className="EQMenu-list-item"
                     >
								<img
									className="icon-list"
									src={sm_historica}
									alt="resumen"
								/>
								<hr />
								<p>Sismicidad Histórica</p>
							</Link>
						</div>
					</li>
				);
			case "originHistory":
				return (
					<li
						key="originHistory"
						onClick={hideMenu}
						className={active === "originHistory" ? "active" : ""}
						style={{ display: "none" }}
					>
						<div>
							<Link to={`/${currentLocation}/${id}/originHistory`}
                        className="EQMenu-list-item"
                     >
								<img
									className="icon-list"
									src={eq_historic}
									alt="originHistory"
								/>
								<hr />
								<p>Historial de Localización</p>
							</Link>
						</div>
					</li>
				);
			case "focalmechanism":
				return (
					<li
						key="focalmechanism"
						onClick={hideMenu}
						className={active === "fm" ? "active" : ""}
					>
						<div>
							<Link to={`/${currentLocation}/${id}/fm`}
                        className="EQMenu-list-item"
                     >
								<img
									className="icon-list"
									src={mecanismo_focal}
									alt="resumen"
								/>
								<hr />
								<p>Mecanismo Focal</p>
							</Link>
						</div>
					</li>
				);
			case "momenttensor":
				return (
					<li
						key="momenttensor"
						onClick={hideMenu}
						className={active === "tm" ? "active" : ""}
					>
						<div>
							<Link to={`/${currentLocation}/${id}/tm`}
                        className="EQMenu-list-item"
                     >
								<img
									className="icon-list"
									src={momento_tensor}
									alt="resumen"
								/>
								<hr />
								<p>Tensor Momento Sísmico</p>
							</Link>
						</div>
					</li>
				);
			case "downloads":
				return (
					<li
						key="downloads"
						onClick={hideMenu}
						className={active === "descargas" ? "active" : ""}
					>
						<div>
							<Link to={`/${currentLocation}/${id}/descargas`}
                        className="EQMenu-list-item"
                     >
								<img
									className="icon-list"
									src={descargas}
									alt="resumen"
								/>
								<hr />
								<p>Descargas</p>
							</Link>
						</div>
					</li>
				);
			default:
				return "";
		}
	}
	return types.map(element => buildLinks(element, active, id));
}

export default EQMenu;
