import { useRef, useEffect } from 'react';
import DraggableMap from 'Components/Atoms/A_EQDetails/DraggableMap';
import { TileLayer } from "react-leaflet";
import { initialMaxLatRectangle, initialMaxLngRectangle, initialMinLatRectangle, initialMinLngRectangle, storageJSON } from 'Components/Atoms/A_Query/QInitState';
import { Viewer } from 'Components/Templates/T_Viewer/Viewer';
import DraggableRectangleLayer from '../O_Reusable/DraggableRectangleLayer';

const initialProps = {
    layerPosition: [], zoom: 4.3, position: [ 8.1544, -74.3078 ]
}

export const QRectangle = ({ rectangle, handleStorage, validateFullScreen }) => {

    const validateRectangle = () => (
        storageJSON( 'map_rectangle' ) || initialProps
    )

    const propsRef = useRef( validateRectangle() );

    const layerRef = useRef({
        min_lat: rectangle.min_lat || initialMinLatRectangle,
        min_lng: rectangle.min_lng || initialMinLngRectangle,
        max_lat: rectangle.max_lat || initialMaxLatRectangle,
        max_lng: rectangle.max_lng || initialMaxLngRectangle
    })

    const saveToStorage = () => {
        sessionStorage.setItem(
            'map_rectangle', JSON.stringify( propsRef.current )
        );
    }

    const handleSetRectangle = (props) => {

      const { min_lat, min_lng, max_lat, max_lng } = props;

      layerRef.current.min_lat.data = min_lat;
      layerRef.current.min_lng.data = min_lng;
      layerRef.current.max_lat.data = max_lat;
      layerRef.current.max_lng.data = max_lng;

      handleStorage({
         type: "geo_bounding_box",
         geometry: { 
             top_left: {
                 lat: max_lat,
                 lon: min_lng
             },
             bottom_right: {
                 lat: min_lat,
                 lon: max_lng
             }
         }
     }, 'rectangle', {
         min_lat: layerRef.current.min_lat,
         min_lng: layerRef.current.min_lng,
         max_lat: layerRef.current.max_lat,
         max_lng: layerRef.current.max_lng
     });
    }

    const updateZoom = zoom => {
        propsRef.current.zoom = zoom.target._zoom;
        saveToStorage();
    }

    const updatePositionMap = ( map, place ) => {
        propsRef.current.position = place;
        saveToStorage();
    }

    useEffect(() => {
        DraggableMap();
        return () => {
            // eslint-disable-next-line
            saveToStorage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className = "QMapContent">
            <div className = "QMapBody">
                <Viewer
                    id = "map"
                    initPosition = { propsRef.current.position }
                    initialZoom = { propsRef.current.zoom }
                    mapOptions = {{ maxZoom: 13, minZoom: 4 }}
                    Tiles = {(
                     <>
                        <TileLayer
                            url = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                            attribution = ""
                        />
                        <DraggableRectangleLayer
                           area={{
                              min_lat: rectangle.min_lat.data,
                              min_lng: rectangle.min_lng.data,
                              max_lat: rectangle.max_lat.data,
                              max_lng: rectangle.max_lng.data
                           }}
                           setArea={ handleSetRectangle }
                        />
                     </>
                    )}
                    onMoveEnd = { updatePositionMap }
                    onzoomend = { updateZoom }
                    isFullScreen = { validateFullScreen }
                />
            </div>
        </div>
    )
}