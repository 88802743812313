import React, { useRef } from 'react';
import BuidDepthText from 'Components/Molecules/M_Reusable/BuildDepthText';
import { createTensorBall } from 'api/ReqResApi';
import TensorIcon from "assets/icons/Query/pelotadeplaya_vacia-01.svg";
import BuildMmi from 'Components/Molecules/M_Reusable/BuildMmi';
import { validateLocal, eventDetailsUrl } from 'Components/Molecules/M_Query/QEvents';
import { Link } from 'react-router-dom';
import { analyticsEventTracker, EVENT_CATEGORIES } from 'hooks/UseAnalyticsTracker';
import moment from 'moment';

const normalOptions = { 
   filter: true, 
   sort: true,
};

export const onlyTensorIcon = async ({ properties, id }) => {
    if ( properties.beachBall.includes( 'http://' ) || properties.beachBall.includes( 'https://' ) ) {
        return <img src = { properties.beachBall } alt = { properties.beachBall } />
    }
    const img = new Image();
    try {
        const imageUrl = createTensorBall(
            new Date( properties.localTime.replace( ' ', 'T' ))
                .getFullYear(), id, properties.beachBall
        );
        const fetchImage = await fetch( imageUrl );
        img.src = fetchImage.url;
        return (
            <img src = { img.src } alt = { properties.beachBall }/>
        )
    } catch {
        return <img src = { TensorIcon } alt = { properties.beachBall } />
    }
}

/**
* @param { tensorType } string moment_tensor_swift | moment_tensor_scmtv | moment_tensor_phasew | moment_tensor_isola_solution
* @param { event } Object current event
*/
export const tensorIcon = ( tensorType, { properties, id }) => {
    if ( properties.tensorMoment.find( item => item === tensorType ) ) {
        const img = new Image();
        img.src = createTensorBall(
            new Date( properties.localTime.replace( ' ', 'T' ))
                .getFullYear(), id, properties.beachBall
        )
        return (
            <img
                className = "TensorMinHeightImg"
                src = { img.height !== 0 ? img.src : TensorIcon }
                alt = { tensorType }
            />
        );
    }
    return <></>
}

export const defineUtcTime = time => {
    let date = new Date( time.replace( ' ', 'T' ));
    date = date.setHours( date.getHours() + 5 );
    return moment ( date ).format( 'YYYY-MM-DD hh:mm' );
}

export const TableFields = query => {

    const validateFields = fields => fields
        .filter( field => query[ field ]).length === 2

    const buildTensor = label => query[ 'Tensor Momento' ] &&
        query[ 'Tensor Momento' ].find(({ text }) => text === label )

    const simpleValidator = () => true;

   const fieldsValidate = [
      {
         label: 'ID',
         validator: simpleValidator,
         options: {
            ...normalOptions,
            display: false,
         },
         name: 'id',
      },
      {
         label: 'Fecha UTC',
         validator: simpleValidator,
         options: normalOptions,
         name: 'utcTime'
      },
      {
         label: 'Fecha Local',
         validator: simpleValidator, 
         options: normalOptions,
         name: 'localTime'
      },
      {
         label: 'Latitud (grados)',
         validator: simpleValidator,
         options: normalOptions,
         name: 'lat'
      },
      {
         label: 'Longitud (grados)',
         validator: simpleValidator,
         options: normalOptions,
         name: 'lng'
      },
      {
         label: 'Profundidad (km)',
         validator: simpleValidator,
         options: {
            sort: true, filter: false,
            customBodyRender: ({ status, depth }, tableMeta) => {
               return (
                  <BuidDepthText
                     status={status}
                     depth={depth}
                     exact
                  />
               )
            }
         },
         name: 'depth'
      },
      {
         label: 'Magnitud',
         validator: simpleValidator,
         options: normalOptions,
         name: 'mag'
      },
      {
         label: 'Tipo Mag',
         validator: simpleValidator,
         options: normalOptions,
         name: 'magType'
      },
      {
         label: 'Fases',
         validator: simpleValidator,
         options: normalOptions,
         name: 'phases'
      },
      {
         label: 'RMS (seg)',
         validator: simpleValidator,
         options: normalOptions,
         name: 'rms'
      },
      {
         label: 'GAP (grados)',
         validator: simpleValidator,
         options: normalOptions,
         name: 'gap'
      },
      {
         label: 'Error Lat (km)',
         validator: simpleValidator,
         options: normalOptions,
         name: 'latitudeError'
      },
      {
         label: 'Error Long (km)',
         validator: simpleValidator,
         options: normalOptions,
         name: 'longitudeError'
      },
      {
         label: 'Error Prof (km)',
         validator: simpleValidator,
         options: normalOptions,
         name: 'depthError'
      },
      {
         label: 'Región',
         validator: simpleValidator,
         options: normalOptions,
         name: 'place'
      },
      {
         label: 'Estado',
         validator: simpleValidator,
         options: normalOptions,
         name: 'status'
      },
      {
         label: 'Nivel de Intensidad',
         validator: () => validateFields(
            ['Nivel Intensidad Mínimo', 'Nivel Intensidad Máximo']
         ),
         options: {
            ...normalOptions,
            customBodyRender: (value, tableMeta) => {
               return value ? (
                  <BuildMmi
                     mmi={ value }
                  />
               ) : <></>
            }
         },
         name: 'mmi',
      },
      {
         label: 'SCMTV',
         validator: () => buildTensor('SCMTV'),
         options: {
            ...normalOptions,
            customBodyRender: (value, tableMeta) => tensorIcon(
               'moment_tensor_scmtv', value
            )
         },
         name: 'scmtv'
      },
      {
         label: 'SWIFT',
         validator: () => buildTensor('SWIFT'),
         options: {
            ...normalOptions,
            customBodyRender: (value, tableMeta) => tensorIcon(
               'moment_tensor_swift', value
            )
         },
         name: 'swift'
      },
      {
         label: 'FASE W',
         validator: () => buildTensor('FASE W'),
         options: {
            ...normalOptions,
            customBodyRender: (value, tableMeta) => tensorIcon(
               'moment_tensor_phasew', value
            )
         },
         name: 'fasew'
      },
      {
         label: 'ISOLA',
         validator: () => buildTensor('ISOLA'),
         options: {
            ...normalOptions,
            customBodyRender: (value, tableMeta) => tensorIcon(
               'moment_tensor_isola_solution', value
            )
         },
         name: 'isola'
      },
      {
         label: 'Mas Información',
         validator: () => true, // change to hide
         options: {
            filter: false, sort: false,
            customBodyRender: (value, tableMeta) => (
               validateLocal(value) ? (
                  <Link
                     to={eventDetailsUrl(value.id, true)}
                     className="MoreInfoTableConsult"
                     target="_blank"
                     rel="noopener noreferrer"
                     onClick={e =>
                        analyticsEventTracker({
                           category: EVENT_CATEGORIES.eventsTable,
                           action: `Más Información from Tabla de Eventos`,
                        })
                     }
                  >
                     Más Información
                  </Link>
               ) : <></>
            )
         },
         name: 'information'
      },
   ];

    const updateFields = () => fieldsValidate
        .filter(({ validator }) => validator() );
    
    const fieldsRef = useRef( updateFields() );
    
    const updateRef = () => fieldsRef.current = updateFields();

    return { fieldsRef: fieldsRef.current, updateFields, updateRef }

}