import { useState, useEffect, useRef, useContext } from "react";
import { TableFooter, TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MUIDataTable from 'mui-datatables';
import { TableFields } from "./TableFields";
import { MUIOptionsESP } from "Components/Templates/MUITables";
import { Button } from "@material-ui/core";
import { UseModal } from 'hooks/UseModal';
import { ExcelModal } from "./QExcel";
import GetAppIcon from '@material-ui/icons/GetApp';
import { sizeRequest } from "hooks/useCatalogRequest";
import Loader from "Components/Pages/Loader";
import PrintIcon from '@material-ui/icons/Print';
// import SgcInfoIcon from "Components/Atoms/A_Reusable/SgcInfoIcon";
import { QueryContext } from "context/QueryContext";
// import { eventDetailsUrl } from "Components/Molecules/M_Query/QEvents";
// import { CircularProgress } from "@material-ui/core";
// import { analyticsEventTracker, EVENT_CATEGORIES } from "hooks/UseAnalyticsTracker";

/**
 * @param { eventsData } array of events
 * @param { userQuery }  Object with the current user query
 * @param { total } Int the number of events at the query
 * @param { eventsDownload } function for download the table data
 * @param { maxPages } Int the number of pages of 100 cell at the query
 * @param { actualPage } Int the current page at the request from param
 * @param { buildPage } function this function send the request for load the next page on the request
 * @param { setSearch } function filters the events by id
 * @param { setOrder } function apply the table order by a condition
 * @param { orderRef } Int the current order in the table
 */
export const TableConsult = (
    {
        eventsData, userQuery, total, searchByID,
        eventsDownload, maxPages, actualPage,
        setSearch, setOrder, orderRef, buildPage, tableHeight
    }
) => {

    const {
      //   selectedEvent, setSelectItem, 
        filterEvents,
        filterQuery, changeStateOrder
    } = useContext( QueryContext );

    const validateEventLength = eventsData.length === 1;
    const [ loading, setLoading ] = useState( false );
   //  const idSelectedRef = useRef( null );
    const [ labelPagination, setLabel ] = useState(
        validateEventLength ? 1 : actualPage
    );
    const modalRef = useRef();
    const labelTotalPageRef = useRef( Math.ceil( total / sizeRequest ));
    const pageRef = useRef( 0 );

    const searchCountRef = useRef(0);
    const showIdRef = useRef( false );
    const foundInApi = useRef( false );

    const { fieldsRef } = TableFields( userQuery );

    const closeModal = () => setLoading( false );
    
    const getModalRef = ref => modalRef.current = ref;

    const handleModal = value => modalRef.current( value );
    
    const startSearch = async target => {
        changeStateOrder( true );
        const { out, updateFilter } = await searchByID( target );
        changeStateOrder( false );
        if ( out ) {
         updateFilter( out );
         foundInApi.current = true;
        } else {
         filterQuery([]);
         foundInApi.current = false;
        }
    }

    const onViewColumns = ( changedColumn, action ) => { // to fix State Bug in the table
      if ( changedColumn === "id" ){
         if ( action === "add" ) showIdRef.current = true
         else { showIdRef.current = false }
      }
    }

    const searchInApi = filter => {
      fieldsRef[0].options.display = showIdRef.current; // to fix State Bug in the table
     
      if ( filter ) {
         if ( searchCountRef.current === 0 ){
            startSearch( filter ); // updates State
         } else {
            filterQuery([]);
         }
         searchCountRef.current = 0
      }
      filterQuery([]);

    }

    const customSearchTable = ( search, currentRow, columns ) => {
      
      const value = search?.toLowerCase();
      const found = currentRow[0].toLowerCase().includes( value );

      if ( found && !foundInApi.current ) searchCountRef.current += 1;
      return found;
    }


    const handlePage = pageToChange => {
        if ( pageToChange ) {
            setLoading( true );
            buildPage( pageToChange );
            // removeSelectedRow();
        }
    }

   //  const removeSelectedRow = () =>{
   //      idSelectedRef.current = null;
   //      const selectedRows = document.getElementById('MuiDataTableConsult').getElementsByClassName('selected');
   //      if (selectedRows.length > 0) selectedRows[0].classList.remove('selected');
   //  }

   //  const selectRow = ( dataIndex ) =>{
   //      removeSelectedRow();
   //      const rowToSelect = document.getElementById(`MUIDataTableBodyRow-${ dataIndex }`);
   //      rowToSelect.classList.add('selected');
   //  }

   //  const handleRowClick = ( rowData, rowMeta ) =>{
   //      selectRow( rowMeta.dataIndex );
   //      const eventId = rowData[0];
   //      idSelectedRef.current = eventId;
   //      setSelectItem( eventsData[ rowMeta.dataIndex ] );
   //  }
    
    const actualData = filterEvents[0] ? filterEvents :eventsData;

    useEffect(() => {
        setLoading( false );
        setLabel( validateEventLength ? 1 : actualPage );
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[ eventsData ]);

//     useEffect(() => {
//       fieldsRef[0].options.display = showIdRef.current;
//       // eslint-disable-next-line react-hooks/exhaustive-deps 
//   },[ showId ]);

    return (
        <div>
            <div className = "container-table-consult" id="MuiDataTableConsult">
                <ExcelModal getModalRef = { getModalRef }/>
                <MUIDataTable
                    title = "Lista de Sismos"
                    data = {
                        actualData.map( e => ({
                            id: e.id,
                            status: e.properties.status.toLowerCase() === 'manual' ? 'Manual' : 'Automático',
                            place: e.properties.place,
                            localTime: e.properties.localTime,
                            utcTime: e.properties.utcTime,
                            mag: e.properties.mag,
                            magType: e.properties.magType,
                            lat: e.geometry.coordinates[0].toFixed(3),
                            lng: e.geometry.coordinates[1].toFixed(3),
                            depth: {
                                status: e.properties.status,
                                depth: e.geometry.coordinates[2]
                            },
                            phases: e.properties.phases,
                            rms: e.properties.rms,
                            gap: e.properties.gap ? e.properties.gap.toFixed(1) : '',
                            magnitudeError: e.properties.magnitudeError
                                ? e.properties.magnitudeError.toFixed(1) : '',
                            depthError: e.properties.depthError
                                ? e.properties.depthError.toFixed(1) : '',
                            latitudeError: e.properties.latitudeError
                                ? e.properties.latitudeError.toFixed(1) : '',
                            longitudeError: e.properties.longitudeError
                                ? e.properties.longitudeError.toFixed(1) : '',
                            mmi: e.properties.mmi,
                            information: e,
                            scmtv: e, swift: e, fasew: e, isola: e
                        }))
                    }
                    columns = {
                        fieldsRef.map(({ name, label, options }) => ({
                            name, label, options: {
                                ...options, 
                            }
                        }))
                    }
                    options = {{
                        ...MUIOptionsESP,
                        textLabels: { 
                           ...MUIOptionsESP["textLabels"], 
                           body: {
                              noMatch: "Lo sentimos, no se encontraron registros coincidentes",
                              toolTip: "Ordenar",
                              columnHeaderTooltip: column => {
                                 switch( column.name ){
                                    case 'utcTime':
                                       return `La hora UTC (Coordinated Universal Time, por su sigla en inglés) 
                                          corresponde a la hora internacional a la cual se debe restar 5 horas 
                                          para obtener la hora local de Colombia. `
                                    case 'localTime':
                                       return 'Hora oficial para Colombia'
                                    default:
                                       return `Ordenar por ${ column.label }`
                                 }
                              },
                           }, 
                        },
                        count: total,
                        onChangePage: handlePage,
                        page: pageRef.current,
                        responsive: 'standard',
                        onSearchChange: searchInApi,
                        customSearch: customSearchTable,
                        onViewColumnsChange: onViewColumns,
                        searchPlaceholder: 'Consultar por ID',
                        download: 'false',
                        rowsPerPage: sizeRequest,
                        fixedHeader: true,
                        tableBodyHeight: tableHeight + "px",
                        print: false,
                        // onRowClick: handleRowClick,
                        // onColumnSortChange: removeSelectedRow,
                        customToolbar: () => (
                            <>  
                                <Tooltip title = "Imprimir" className="HideElement">
                                    <IconButton
                                        aria-label = "print"
                                        className = "form-control-button"
                                        onClick = { () => window.print() }
                                    >
                                        <PrintIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title = "Descargar">
                                    <IconButton
                                        aria-label = "download"
                                        className = "form-control-button"
                                        onClick = { () => handleModal( true ) }
                                    >
                                        <GetAppIcon>Download Data</GetAppIcon>
                                    </IconButton>
                                </Tooltip>
                                {/* More Information Validation */}
                                {/* { 
                                    idSelectedRef.current && selectedEvent.moreInfo === null ? (
                                        <Tooltip title="Loading">
                                            <IconButton className="form-control-button">
                                                <CircularProgress size={22} />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                    : idSelectedRef.current && selectedEvent.moreInfo ? (
                                        <a
                                            href = { eventDetailsUrl( selectedEvent.id, true ) }
                                            rel = "noopener noreferrer"
                                            target = '_blank'
                                            onClick = { e => 
                                                analyticsEventTracker({
                                                    category: EVENT_CATEGORIES.eventsTable,
                                                    action: 'Más Información from Tabla de Eventos'
                                                }) 
                                            }
                                        >
                                            <Tooltip title="Más información">
                                                <IconButton
                                                    aria-label="MoreInfo"
                                                    className="form-control-button"
                                                >
                                                    <SgcInfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </a>
                                    ) : (<></>)
                                } */}
                            </>
                        ),
                        customFooter: ( rowCount, page, rowsPerPage, changeRowsPerPage, changePage, textLabels ) => (
                            <TableFooter>
                                <TableRow>
                                    <TableCell className = "tableConsultCell">
                                        <div className = "tableConsultFooter">
                                            <span>
                                                { labelPagination } - { labelTotalPageRef.current }
                                            </span>
                                            <Button
                                                onClick = {
                                                    () => {
                                                        handlePage( actualPage - 1 )
                                                        setLabel( labelPagination - 1 );
                                                    }
                                                }
                                                disabled = { actualPage === 1 || validateEventLength }
                                            >
                                                <NavigateBeforeIcon/>
                                            </Button>
                                            <Button
                                                onClick = {
                                                    () => {
                                                        handlePage( actualPage + 1 );
                                                        setLabel( labelPagination + 1 );
                                                    }
                                                }
                                                disabled = {
                                                    ( actualPage && maxPages <= actualPage ) ||
                                                        validateEventLength
                                                }
                                            >
                                                <NavigateNextIcon />
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        )
                    }}
                />
            </div>
            <UseModal
                open = { loading }
                onClose = { closeModal }
                styles = "QTableModal_loading"
                component = { <Loader/> }
            />
        </div>
    )

}