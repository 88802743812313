import React, { useRef, useEffect } from "react";
import { CircleMarker, Popup } from "react-leaflet";
import { pixelCalculator } from "../../Atoms/A_Reusable/CalculatorPixelRadiusForEQ";
import { calculateColor } from "../../Atoms/A_Reusable/CalculatorDepthColors";
import { useLocation } from "react-router-dom";
import EarthquakePopup from "Components/Organisms/O_Reusable/EarthquakePopup";

export const selectionColor = "#49ece8";
export const moreRecentColor = "#7AD4E2"; // change color #7AD4E2 activate_marker

export const defineMarkerColor = ( defaultColor, isSelected, depth ) => {
  if ( defaultColor ) { return defaultColor }
  return isSelected ? moreRecentColor : calculateColor( depth );
}

export const EarthquakeMarker = (
  { earthquake, onSelect, defaultColor, isSelected, isCatalog=false }
) => {

   const markerRef = useRef();

  const location = useLocation();
  const directory = location.pathname.split( '/' );
  const lat = earthquake.geometry.coordinates[0];
  const lng = earthquake.geometry.coordinates[1];
  const depth = earthquake.geometry.coordinates[2];
  
  const validateDirectory = () => directory[2] === 'detallesismo'
    ? 'detallesismo' : 'detalleevento';

  const color = defineMarkerColor( defaultColor, isSelected, depth );

  useEffect(()=>{
   if ( markerRef.current && !isCatalog ){
      markerRef.current.setStyle(({ fillColor: color }));
      if ( isSelected ){
         markerRef.current.openPopup();
      }
   }
   }, [ isSelected, color, isCatalog ])
  
  return (
    <CircleMarker
      center = {[ lat, lng ]}
      fillColor = { color }
      color = "#fff"
      weight = { 2 }
      className = "EQMarkersTest"
      fillOpacity = { 1 }
      radius = { pixelCalculator( Math.floor( earthquake.properties.mag )) }
      pane = "overlayPane"
      eventHandlers={{ click: () => onSelect( earthquake ) }}
      ref={ markerRef }
    >
      {
         !isCatalog &&
         <Popup
            keepInView = { true }
            closeButton = { false }
            autoPan = { false }
         >
            <EarthquakePopup
               id = { earthquake.id }
               lat = { lat }
               lng = { lng }
               place = { earthquake.properties.place }
               localTime = { earthquake.properties.localTime }
               utcTime = { earthquake.properties.utcTime }
               status = { earthquake.properties.status }
               closerTowns = { earthquake.properties.closerTowns }
               magnitude = { earthquake.properties.mag }
               mmi = { earthquake.properties.mmi }
               depth = { earthquake.geometry.coordinates[2] }
               cdi = { earthquake.properties.cdi }
               maxPGA = { earthquake.properties.maxPGA }
               agency = { earthquake.properties.agency }
               beachBall = { earthquake.properties.beachBall }
               urlMoreInfo = { `/${ validateDirectory() }/${ earthquake.id }/resumen` }
            />
       </Popup>

      } 
    </CircleMarker>
  );
};