// Radius [pixels]
const pixelRadiusForMagnitudeEQ = {
  "0": 4,
  "1": 4,
  "2": 6,
  "3": 8.82,
  "4": 9.72,
  "5": 11.62,
  "6": 12.52,
  "7": 17.42,
  "8": 19.32,
};
Object.freeze(pixelRadiusForMagnitudeEQ);

const pixelCalculator = (magnitude) => {
  let pixelRadius = "0px";
  if (pixelRadiusForMagnitudeEQ.hasOwnProperty(magnitude)) {
    pixelRadius = pixelRadiusForMagnitudeEQ[`${magnitude}`];
  }
  return pixelRadius;
};

const magnitudes = () => {
  let scales = Object.keys(pixelRadiusForMagnitudeEQ);
  scales.splice(0, 1);
  return scales;
};

export { pixelCalculator, magnitudes };
