import { useEffect } from 'react'
import L from 'leaflet';
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { MarkerClusterGroup } from "leaflet.markercluster";
import { useMap } from 'react-leaflet';

let cluster = new MarkerClusterGroup();

export const TensorCluster = ({ markers, onClick }) => {
    const map = useMap();
    useEffect(() => {
        // cluster.clearLayers();
        markers.forEach( async ({ lat, lng, icon, popup }) => {
            // const marker = L.marker().on('')
            const markerIcon = await icon();
            const marker = L.marker(
                new L.latLng( lat, lng ), {
                    icon: markerIcon
                }
            );
            marker.on( 'click', () => onClick([ lat, lng ]) )
            marker.addTo( cluster );
            popup && marker.bindPopup( popup );
        });
        map.addLayer( cluster );
        return () => {
            map.removeLayer( cluster );
            cluster = new MarkerClusterGroup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return null;
}