import React from 'react';
import { Marker, Popup} from 'react-leaflet';
import L from 'leaflet';

const SmMarker = ({
    event, 
    name, 
    isSelected, 
    onSelect, 
    color, 
    popupMarker,
    isHeader 
}) => {
    
    const lng = event.longitude;
    const lat = event.latitude;
    const width = 44;
    const height = 35;

    var stationIcon = L.Icon.extend({
        options: {
            iconSize: new L.Point(width, height),
            className: 'leaflet-markercluster-icon',
        },
    
        createIcon: function () {
            var e = document.createElement('canvas');
            this._setIconStyles(e, 'icon');
            var s = this.options.iconSize;
            e.width = s.x;
            e.height = s.y;
            this.draw(e.getContext('2d'), s.x, s.y);
            return e;
        },
    
        createShadow: function () {
            return null;
        },
    
        draw: function (canvas, width, height) {
            canvas.strokeStyle = '#686868'; // by default is black
            canvas.lineWidth = 4;
            canvas.beginPath();
            canvas.moveTo(22, 4);
            canvas.lineTo(4, 31);
            canvas.lineTo(40, 31);
            canvas.closePath();
            canvas.stroke();

            canvas.fillStyle = color.intern;
            canvas.beginPath();
            canvas.moveTo(22, 4);
            canvas.lineTo(4, 31);
            canvas.lineTo(40, 31);
            canvas.closePath();
            canvas.fill();
    
            canvas.fillStyle = 'white';
            canvas.textAlign = 'center';
            canvas.textBaseline = 'middle';
            canvas.font = 'lighter 8px Nunito Sans';
            
            canvas.fillText(name, 22, 25, 40);
        }
    });

    var icon = new stationIcon();

    const handlePopupOpen = () => {
        onSelect(event);
    };

    const handlePopupClose = () => {
        onSelect(null);
    };

    const popup = React.createRef();

    return(
        <Marker 
            position={[lat, lng]} icon={icon} className={isSelected ? 'selectedEventMap' : 'unselectedEventMap'} 
            eventHandlers={{ click: handlePopupOpen, popupclose: handlePopupClose }}>
            <Popup closeButton={true} autoPan={true} ref={popup}>
                { popupMarker }
            </Popup>
        </Marker>
    )
};

export default SmMarker;