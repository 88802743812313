import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import Epicenter from "assets/icons/EQViewer/PerceivedIntensity/Epicenter.svg";
import EpicenterMarker from './EpicenterMarker';
import ReportMarker from './ReportMarker';
import { TileLayer } from 'react-leaflet';
import BuildCdi from '../M_Reusable/BuildCdi';
import { UseLeafletMap } from 'hooks/UseLeafletMap';

export const EQCdiMap = ({ event, legendId, reports, handleClick }) => (
    <div className = "main-content-cdi">
        <div className = "map-table-cdi">
            <div className = "map-cdi">
                <UseLeafletMap
                    id = "map-cdi"
                    position = {[
                        event.geometry.coordinates[0],
                        event.geometry.coordinates[1]
                    ]}
                    mapOptions = {{ maxZoom: 13 }}
                    zoom = { 6 }
                    isFullScreen
                    bodyMap = {(
                        <>
                            <div className = "mapLegendContainer">
                                <Button
                                    aria-describedby = { legendId }
                                    size = "small"
                                    onClick = { handleClick }
                                    className="LegendButton"
                                >
                                    <div className = "epicenter-icon-container">
                                        <img
                                            className = "epicenter-icon"
                                            src = { Epicenter }
                                            alt = "Epicenter"
                                        />
                                        <span>Epicentro</span>
                                    </div>
                                </Button>
                            </div>
                            <EpicenterMarker
                                event = { event }
                                onSelect = { () => '' }
                            />
                            {
                                reports.map(( report, index ) => (
                                    <ReportMarker
                                        id = { `Report${ index }` }
                                        key = { `Report${index}` }
                                        report = { report }
                                        onSelect = { () => "" }
                                    />
                                ))
                            }
                            <TileLayer url = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}" />
                        </>
                    )}
                />
            </div>
            <div className = "intensity-table">
                <table>
                    <thead>
                        <tr>
                            <td className = "ems">Intensidad (EMS-98)</td>
                            <td>Descripción</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p className = "intensity-icon">
                                    <BuildCdi cdi = {2} />
                                </p>
                            </td>
                            <td>
                                <p className = "intensity-description">
                                    Apenas sentido
                                    <Tooltip title = "Sentido por muy pocas personas en reposo">
                                        <span className = "material-icons">info</span>
                                    </Tooltip>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className = "intensity-icon">
                                    <BuildCdi cdi = {3} />
                                </p>
                            </td>
                            <td>
                                <p className = "intensity-description">
                                  Sentido levemente
                                    <Tooltip title = "Sentido por pocas personas. Balanceo de objetos colgantes">
                                        <span className = "material-icons">info</span>
                                    </Tooltip>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className = "intensity-icon">
                                    <BuildCdi cdi = {4} />
                                </p>
                            </td>
                            <td>
                                <p className = "intensity-description">
                                    Sentido ampliamente
                                    <Tooltip title = "Sentido por muchas personas en el interior de edificaciones y por pocas en el exterior. Las ventanas, puertas y platos vibran.">
                                        <span className = "material-icons">info</span>
                                    </Tooltip>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className = "intensity-icon">
                                    <BuildCdi cdi = {5} />
                                </p>
                            </td>
                            <td>
                                <p className = "intensity-description">
                                    Sentido fuertemente
                                    <Tooltip title = "Algunas personas se asustan y corren a la calle. Objetos pequeños se desplazan. Vaiven de puertas o vetanas. Leves grietas en casos aislados">
                                        <span className = "material-icons">info</span>
                                    </Tooltip>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className = "intensity-icon">
                                    <BuildCdi cdi = {6} />
                                </p>
                            </td>
                            <td>
                                <p className = "intensity-description">
                                    Daño leve
                                    <Tooltip title = "Algunas personas pierden el equilibrio. Algunos objetos caen. Muchas eficicaciones presentan daños leves.">
                                        <span className = "material-icons">info</span>
                                    </Tooltip>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className = "intensity-icon">
                                  <BuildCdi cdi = {7} />
                                </p>
                            </td>
                            <td>
                                <p className = "intensity-description">
                                    Daño moderado
                                        <Tooltip title= "Los muebles pesados se desplazan. Muchos edificios presentan grietas y caida de revestimiento de los muros">
                                            <span className = "material-icons">info</span>
                                        </Tooltip>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className = "intensity-icon">
                                  <BuildCdi cdi = {8} />
                                </p>
                            </td>
                            <td>
                                <p className = "intensity-description">
                                    Daño severo
                                    <Tooltip title = "Muchas personas tienen dificultar para mantenerse de pie, Caen objetos pesados. Las estructuras antiguas y débiles pueden colapsar.">
                                        <span className = "material-icons">info</span>
                                    </Tooltip>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className = "cdi-tables">
            <div className = "intensity-info">
                <p>
                    El mapa de intensidad sísmica en la escala EMS-98 (escala macrosísmica europea) 
                    hace referencia a la distribución y severidad con la que se sintió el sismo 
                    teniendo en cuenta los efectos reportados por la población a través del formulario 
                    para la evaluación de intensidades en diferentes municipios. 
                    La estrella de color rojo indica el epicentro instrumental del sismo 
                    y los círculos de colores se refieren a la intensidad asociada a cada municipio.
                </p>
          </div>
        </div>
        <div className = "intensity-legal SubText">
            <p><b>Aviso legal:</b> Los puntos de intensidad que se muestran en el mapa se determinan teniendo en cuenta 
                todos los formularios diligenciados por la comunidad. 
                Los datos recogidos son sometidos a un filtro automático de tipo estadístico, pero no se comprueban de forma individual. 
                Se considera que a partir de intensidad mayor o igual a 6 en la escala EMS-98 se necesita una verificación en el sitio por personal capacitado. 
                El SGC no es responsable del uso indebido de la información proporcionada en este sitio web.
            </p>
        </div>
    </div>
)
