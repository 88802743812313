import React, { Fragment } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import BuildAgeIcons from 'Components/Atoms/A_EQDetails/BuildAgeIcons'

const useStyles = makeStyles((theme) => ({
    grid: {
      paddingTop: "10px",
      paddingBottom: "10px",
      cursor: "default",
      backgroundColor: "#fafafa",
    },
    itemContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      paddingTop: "2px",
      paddingBottom: "2px",
    },
    depthTextContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    depthText: {
      marginLeft: "1px",
      marginRight: "1px",
      marginBottom: "0px",
      marginTop: "0px",
      fontSize: "70%",
    },
    title: {
      marginLeft: "10px",
      marginRight: "5px",
      marginBottom: "2px",
      marginTop: "5px",
      fontSize: "80%",
      fontWeight: "bold",
    },
    squareColor: {
      width: "15px",
      height: "15px",
    },
    color: {
      width: "15px",
      height: "15px",
      border: "1px solid #686869",
    },
  }));


const AgePointsScale = () => {
    const styles = useStyles();
    return(
        <Fragment>
            <p className={styles.title}>
                Antigüedad
            </p>
            <Grid className={styles.grid} container justifyContent="space-evenly">
              <BuildAgeIcons/>
            </Grid>
        </Fragment>
    )
}

export default AgePointsScale
