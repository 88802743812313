import React from 'react';
import { createStore } from "redux";
import { filterEvents } from "../Components/Atoms/A_Reusable/filterEvents";
import DefineIconsRules from "Components/Molecules/M_Reusable/DefineIconsRules";

import {
    LOAD_LIST_FIVE_DAYS_2,
    LOAD_LIST_FIVE_DAYS_ALL,
    LOAD_LIST_THIRTY_DAYS_IMPORTANT,
    LOAD_LIST_SIXTY_DAYS_4,
    LOAD_ACTIVE_EVENTS,
    LOAD_ACTIVE_EVENTS_DOWN,
    APPEND_ACTIVE_EVENTS_DOWN,
    LOAD_DETAILS_EVENT,
    LOAD_HOME_EVENT,
    LOAD_SM_EVENT,
    LOAD_QUERY_MAP,
    SET_DEPARTMENTS,
    SET_MUNICIPALITIES,
    SET_STATIONS,
    SET_QUERY,

    NETWORK_DATA,
    NETWORK_FILTERS,
    MAKE_FILTER_NETWORK,
    EVENT_STRING_FILTER_DATA,
    RESIZE_EVENT,
    MODAL_IMG,
    UPDATE_ACTIVE_EVENTS,
    SELECT_EVENTS_LIST,
    SELECT_EVENTS_LIST_TITLE,
    SELECT_EVENT,
    SET_MAP_LAYER,
    SET_FILTER_BOUNDS,
    SET_MAP_BOUNDS,
    SHOW_MENU,
    LOAD_CDI,
    LOAD_LIST_INTERNATIONAL_EVENTS,
    CHANGE_MENU_ICON,
    LOAD_VOLCANOS,
    LOAD_VOLCANOS_SORT,
    LOAD_VOLCANOS_FILTER,
    ORDER_BY,
    FILTER_BY,
    FILTER_EVENTS_BY,
    LOAD_ACELERATIONS,
    LOAD_VOLCANO_ACTUAL,
    SELECT_VOLCANO,
    VISIBLE_NOTIFICATIONS,
    SET_SHOWN_VOLCANOS, // made by Alejandro
    SET_SHOWN_EVENTS,
    SET_ACTIVE_FILTER,
    LOAD_NOTIFICATIONS,
    SET_BADGE,
    LOAD_NEWS_LIST,
    LOAD_NEW_ACTUAL,
    LOAD_ALL_NOTIFICATIONS,
    START_EVENTS,
    IS_VALID_CONSULTATION,
    // YEAR_EVENT_DETAIL
    LOAD_SEGMENTO_ACTUAL,
    LOAD_SEGMENTO_NUEVO,
    DISABLED_FORM_INPUT,
    LOAD_VOLCANO_LEVELS
} from "./actions";
import { TemplateStore } from 'Components/Atoms/A_Reusable/TemplateStore';

export const defaultLayer =
"https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}";

const {
    seismological_rules,
    accelerographic_rules,
    hybrid_rules,
    real_time_rule,
    download_rule,
    active_rule,
    desactive_rule,
    inactive_rule
} = DefineIconsRules;

function showMenuDisplay() {
    var show = "";
    if (window.screen.width < 1237) {
        show = "active";
    } else {
        show = "";
    }
    return show;
}

const add_filter = ( list_to_filter, filter_element, filter_rule, filter_value ) => {
    const filters_added = list_to_filter.filter((element) => {
        if (filter_rule(element)) {
            element['filter_number'] = filter_value;
            return filter_rule(element) === true
        }
        return false;
    });
    const { filters, filter_list } = filter_element;
    return filters_added.length > 0 ? {
        filters: [
            ...filters,
            {
                filter_value,
                start: filter_list.length === 0 ? 0 : filter_list.length - 1,
                end: filters_added.length - 1
            }
        ],
        filter_list: [...filter_list, ...filters_added]
    } : {
        filters,
        filter_list
    };
}

const remove_filter = (filter_element, filter_value) => {
    const { filters, filter_list } = filter_element;
    const filters_NoRemoved = filter_list.filter((element) => {
        return element.filter_number !== filter_value
    });
    return {
        filters: filters.filter(item => item.filter_value !== filter_value),
        filter_list: filters_NoRemoved
    }
}

const validate_available_list = (list, value) => {
    return list.filter((element) => {
        return element.name === value.name
    }).length === 0 ? [...list, value] : list;
}

const update_layer_map = (list, id) => {
    return list.map((element) => {
        return {
            ...element,
            selected: id === element.id ? true : false
        }
    })
}



const update_available_list = (list, code) => {
    let selected = {};
    return {
        items: list.map((element) => {
            if (code === element.id) {
                selected = element;
                return {
                    ...element,
                    selected: true
                }
            }
            return {
                ...element,
                selected: false
            }
        }),
        selected
    }
}
const initialStore = {
    magBounds: [],
    depthBounds: [],
    dateBounds: [],
    mapBounds: { north: 0, south: 0, east: 0, west: 0 },
    List_five_days_2: [], //●	5 Días, Magnitud de 2.0+
    List_five_days_all: [], //●	5 Días, Todas la magnitudes
    List_thirty_days_importan: [], //●	30 Días, Eventos sísmicos destacados
    List_sixty_days_4: [], //●	60 Días, Magnitud 4.0+
    List_international: [], // latest international earthquake
    available_list: [],

    mapLayers: [
        { id: 1, name: "Escala de grises", value: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.jpg", selected: true },
        { id: 2, name: "Topográfico", value: "https://server.arcgisonline.com/arcgis/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}", selected: false },
        { id: 3, name: "Calles", value: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}", selected: false },
        { id: 4, name: "Satélite", value: "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", selected: false }
    ],
    networksEq: [],

    detailEvent: [],
    homeEvent: [],
    smEvent: [],
    resizeEvent: [],
    modalImg: [],
    query_map: [],
    queryDepartments: [],
    queryMunicipalities: [],
    queryStations: [],
    queryData: [],

    network_data: [],
    network_filters: {},

    titleEventsList: "",
    codeSelectedEventsList: 0,

    selectedEventsList: [],
    activeEventsCounter: 0,
    activeEvents: [],
    activeEventsDown: [],
    eventsOneCounter: 0,

    volcanos: [],
    volcanoLevels: [],
    volcanosFilter: "",
    volcanoActual: [],
    volcanosSort: [],

    shownEvents: [],
    shownVolcanos: [],
    startEvents: false,


    selectedEvent: null,
    selectedVolcano: null,

    totalOrigins: 0,
    magFilterBounds: [0, 10],
    depthFilterBounds: [-50, 2000],

    eventSearchString: '',
    eventCodeFilter: 10,
    appliedMapLayer: defaultLayer,
    showMenu: showMenuDisplay(),
    cdiData: {},
    menuIcon: window.screen.width > 1238 ? true : false,

    orderBy: "VolcanoName",
    filterBy: "",
    filterEventsBy: "",
    aceleraciones: [],
    visibleNotifications: false,
    notifications: [],
    badge: 0,
    activeFilter: 1,
    newsList: [],
    newActual: {},
    allNotifications: [],
    yearEventDetail: "2050",
    isValid: false,
    segmentoActual: [],
    segmentoNuevo: [],
    disabledFormInput: JSON.parse(sessionStorage.getItem("disabledFormInput")) ? JSON.parse(sessionStorage.getItem("disabledFormInput")) : { location: false, area: false }
};

const getList = ( state, codeSelection ) => {
    switch ( codeSelection ) {
        case 0:
            return state.List_five_days_2;
        case 1:
            return state.List_five_days_all;
        case 2:
            return state.List_thirty_days_importan;
        case 3:
            return state.List_sixty_days_4;
        default:
            return [];
    }
};

const rootReducer = ( state = initialStore, action ) => {
    switch ( action.type ) {
        case DISABLED_FORM_INPUT:
            return {
                ...state,
                disabledFormInput: action.event,
            };
        case LOAD_NEWS_LIST:
            return {
                ...state,
                newsList: action.event,
            };
        case LOAD_SEGMENTO_ACTUAL:
            return {
                ...state,
                segmentoActual: action.event,
            };
        case LOAD_SEGMENTO_NUEVO:
            return {
                ...state,
                segmentoNuevo: action.event,
            };
        case LOAD_NEW_ACTUAL:
            return {
                ...state,
                newActual: action.event,
            };
        case LOAD_VOLCANOS:
            return {
                ...state,
                volcanos: action.events,
            };
         case LOAD_VOLCANO_LEVELS:
               return {
                   ...state,
                   volcanoLevels: action.event,
               };
        case LOAD_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.events,
            };
        case SET_BADGE:
            return {
                ...state,
                badge: action.events,
            };
        case SET_SHOWN_VOLCANOS:
            return {
                ...state,
                shownVolcanos: action.volcanos,
            };
        case SET_SHOWN_EVENTS:
            return {
                ...state,
                shownEvents: action.events,
                activeEvents: action.events,

            };
        case LOAD_VOLCANOS_SORT:
            return {
                ...state,
                volcanosSort: action.events,
            };
        case LOAD_VOLCANOS_FILTER:
            return {
                ...state,
                volcanosFilter: action.events,
            };
        case LOAD_ACTIVE_EVENTS:
            return {
                ...state,
                selectedEventsList: action.events,
                activeEvents: action.events,
                activeEventsCounter: action.events.length,
                eventsOneCounter: action.events.length,

            };
        case LOAD_ACTIVE_EVENTS_DOWN:
            return {
                ...state,
                activeEventsDown: action.events,

            };
        case APPEND_ACTIVE_EVENTS_DOWN:
            return { ...state };
        case LOAD_LIST_FIVE_DAYS_2:
            return {
                ...state,
                List_five_days_2: action.events,
                available_list: validate_available_list(
                    state.available_list, { id: 0, name: "5 Días, Magnitud de 2.0+", selected: false }
                ),

            };
        case LOAD_LIST_FIVE_DAYS_ALL:
            return {
                ...state,
                List_five_days_all: action.events,
                available_list: validate_available_list(
                    state.available_list, { id: 1, name: "5 Días, Todas las magnitudes", selected: false },
                ),

            };
        case LOAD_LIST_THIRTY_DAYS_IMPORTANT:
            return {
                ...state,
                List_thirty_days_importan: action.events,
                available_list: validate_available_list(
                    state.available_list, { id: 2, name: "30 Días, Eventos sísmicos destacados", selected: false },
                ),

            };
        case LOAD_LIST_SIXTY_DAYS_4:
            return {
                ...state,
                List_sixty_days_4: action.events,
                available_list: validate_available_list(
                    state.available_list, { id: 3, name: "60 Días, Magnitud 4.0+", selected: false }
                ),

            };
        case LOAD_LIST_INTERNATIONAL_EVENTS:
            return {
                ...state,
                List_international: action.events,

            };
        case LOAD_DETAILS_EVENT:
            return {
                ...state,
                detailEvent: action.event,
                totalOrigins: action.event[0].properties.products.origin.length,
            };
        case LOAD_HOME_EVENT:
            return {
                ...state,
                homeEvent: action.event,
            };
        case LOAD_SM_EVENT:
            return {
                ...state,
                smEvent: action.events,
            };
        case RESIZE_EVENT:
            return {
                ...state,
                resizeEvent: action.events,
            };
        case MODAL_IMG:
            return {
                ...state,
                modalImg: action.event,
            };
        case LOAD_QUERY_MAP:
            return {
                ...state,
                query_map: action.event,
            };
        case NETWORK_DATA:
            if (state.networksEq.length > 0) {
                return {
                    ...state,
                    network_data: state.network_data,
                    network_filters: state.network_filters,
                    networksEq: state.networksEq
                }
            }
            return {
                    ...state,
                    network_data: action.event,
                    network_filters: {
                        filters: [],
                        filter_list: []
                    },
                    networksEq: [
                        { id: 1, selected: false, name: 'Estaciones sismológicas', value: 1, function: seismological_rules, icon: 1 },
                        { id: 2, selected: false, name: 'Estaciones acelerográficas', value: 2, function: accelerographic_rules, icon: 2 },
                        {
                            id: 3,
                            selected: false,
                            name: `Estaciones híbridas`,
                            text_value: <TemplateStore />,
                            value: 3,
                            function: hybrid_rules,
                            icon: 3
                        },
                        { id: 4, selected: false, name: 'Estaciones inactivas', value: 7, function: desactive_rule, icon: 7 },
                        { id: 5, selected: false, name: 'Estaciones retiradas', value: 8, function: inactive_rule, icon: 8 },
                        { id: 6, selected: false, name: 'Estaciones activas', value: 6, function: active_rule, icon: 6 },
                        { id: 7, selected: false, name: 'Estaciones en tiempo real', value: 4, function: real_time_rule, icon: 4 },
                        { id: 8, selected: false, name: 'Estaciones por descarga', value: 5, function: download_rule, icon: 5 }
                    ]
                }
                
            case NETWORK_FILTERS:
                return {
                    network_filters: action.event
                }

            case MAKE_FILTER_NETWORK:
                const { network_filters, network_data, networksEq } = state;
                networksEq[action.event.index].selected = action.event.type;
                if (action.event.type === false) {
                    return {
                        ...state,
                        network_filters: network_filters.filters.length === 1 ? {
                            filters: [],
                            filter_list: []
                        } : remove_filter(
                            network_filters,
                            action.event.value
                        ),
                        networksEq: networksEq
                    };
                } else {
                    return {
                        ...state,
                        network_filters: add_filter(
                            network_data,
                            network_filters,
                            action.event.function,
                            action.event.value
                        ),
                        networksEq: networksEq
                    }
                }
            case SET_DEPARTMENTS:
                return {
                    ...state,
                    queryDepartments: action.event
                };
            case SET_MUNICIPALITIES:
                return {
                    ...state,
                    queryMunicipalities: action.event
                }
            case SET_STATIONS:
                return {
                    ...state,
                    queryStations: action.event
                };
            case SET_QUERY:
                return {
                    ...state,
                    queryData: action.event
                }
            case UPDATE_ACTIVE_EVENTS:
                return {
                    ...state,
                    activeEvents: action.events,

                };
            case SELECT_EVENTS_LIST_TITLE:
                return {
                    ...state,
                    titleEventsList: action.title,
                };
            case SELECT_EVENTS_LIST:
                const selectedList = getList(state, action.code);
                const filtering = filterEvents(
                    state.mapBounds,
                    state.magBounds,
                    state.depthBounds,
                    state.dateBounds,
                    state.eventSearchString,
                    state.eventCodeFilter,
                    selectedList
                );
                const newAvailabel = update_available_list(state.available_list, action.code);
                return {
                    ...state,
                    codeSelectedEventsList: action.code,
                    activeEventsCounter: filtering.length,
                    activeEvents: filtering,
                    selectedEventsList: selectedList,
                    eventsOneCounter: selectedList.length,
                    available_list: newAvailabel.items,
                    titleEventsList: newAvailabel.selected ? newAvailabel.selected.name : ''
                };
            case SELECT_EVENT:
                return {
                    ...state,
                    selectedEvent: action.event,
                };
            case SET_MAP_LAYER:
                return {
                    ...state,
                    appliedMapLayer: action.layer.value,
                    mapLayers: update_layer_map(state.mapLayers, action.layer.id)
                };
            case SET_FILTER_BOUNDS:
                const measureFiltering = filterEvents(
                    state.mapBounds,
                    action.magBounds,
                    action.depthBounds,
                    action.dateBounds,
                    state.eventSearchString,
                    state.eventCodeFilter,
                    state.selectedEventsList
                );
                return {
                    ...state,
                    magBounds: action.magBounds,
                    depthBounds: action.depthBounds,
                    dateBounds: action.dateBounds,
                    activeEvents: measureFiltering,
                    activeEventsCounter: measureFiltering.length,
                };
            case EVENT_STRING_FILTER_DATA:
                const eventsFilteredByProperties = filterEvents(
                    state.mapBounds,
                    state.magBounds,
                    state.depthBounds,
                    state.dateBounds,
                    action.string,
                    action.filterCode,
                    state.selectedEventsList
                );
                return {
                    ...state,
                    eventSearchString: action.string,
                    eventCodeFilter: action.filterCode,
                    activeEvents: eventsFilteredByProperties,
                    activeEventsCounter: eventsFilteredByProperties.length,
                }
            case SET_MAP_BOUNDS:
                const mapFiltering = filterEvents(
                    action.mapBounds,
                    state.magBounds,
                    state.depthBounds,
                    state.dateBounds,
                    state.eventSearchString,
                    state.eventCodeFilter,
                    state.selectedEventsList
                );
                return {
                    ...state,
                    mapBounds: action.mapBounds,
                    activeEvents: mapFiltering,
                    activeEventsCounter: mapFiltering.length,
                };
            case SHOW_MENU:
                return {
                    ...state,
                    showMenu: state.showMenu === "active" ? "" : "active",
                };
            case LOAD_CDI:
                return {
                    ...state,
                    cdiData: action.cdiData,
                };
            case CHANGE_MENU_ICON:
                return {
                    ...state,
                    menuIcon: action.active,
                };
            case ORDER_BY:
                return {
                    ...state,
                    orderBy: action.order,
                };
            case FILTER_BY:
                return {
                    ...state,
                    filterBy: action.filter,
                };
            case FILTER_EVENTS_BY:
                return {
                    ...state,
                    filterEventsBy: action.filter,
                };
            default:
                return state;
            case LOAD_ACELERATIONS:
                return {
                    ...state,
                    aceleraciones: action.array,
                };
            case LOAD_VOLCANO_ACTUAL:
                return {
                    ...state,
                    volcanoActual: action.array,
                };
            case SELECT_VOLCANO:
                return {
                    ...state,
                    selectedVolcano: action.volcano,
                };
            case VISIBLE_NOTIFICATIONS:
                return {
                    ...state,
                    visibleNotifications: action.event,
                };
            case SET_ACTIVE_FILTER:
                return {
                    ...state,
                    activeFilter: action.events,
                };
            case LOAD_ALL_NOTIFICATIONS:
                return {
                    ...state,
                    allNotifications: action.event,
                };
            case START_EVENTS:
                return {
                    ...state,
                    startEvents: action.events,
                };
            case IS_VALID_CONSULTATION:
                return {
                    ...state,
                    isValid: action.event,
                };
        }
    };

export default createStore(rootReducer);