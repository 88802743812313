import { Fragment } from 'react';

const BuildAgeIcons = () => {
    /**
	 *  day: 	'#48C9B0',
        week: 	'#52BE80', 
        month: 	'#F4D03F',
        year: 	'#D35400',
        more: 	'#99A3A4'
	 */
    return(
        <Fragment>
            <div className="ContainerAge">
                <div className="Cont_icons">
                    <div className="CicleAge" style={{background: '#48C9B0'}}></div>
                    <span>Día</span>
                </div>
                <div className="Cont_icons">
                    <div className="CicleAge" style={{background: '#52BE80'}}></div>
                    <span>Semana</span>
                </div>
                <div className="Cont_icons">
                    <div className="CicleAge" style={{background: '#F4D03F'}}></div>
                    <span>Mes</span>
                </div>
                <div className="Cont_icons">
                    <div className="CicleAge" style={{background: '#D35400'}}></div>
                    <span>Año</span>
                </div>
                <div className="Cont_icons">
                    <div className="CicleAge" style={{background: '#99A3A4'}}></div>
                    <span>{`> Año`}</span>
                </div>
            </div>
        </Fragment>
    )
}

export default BuildAgeIcons