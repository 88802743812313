import React, { useState, useEffect, useRef } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import {
  loadList_five_days_2,
  loadList_five_days_all,
  loadList_thirty_days_importan,
  loadList_sixty_days_4,
  loadListActiveEvents,
  selectEventsList,
  loadListInternationalEvents,
  loadVolcanos,
  loadNotifications,
  setStartEvents
} from "redux/actionsCreators";
import ErrorRequest from "Components/Atoms/A_Reusable/ErrorRequest";
import { reqResApi } from "api/reqRes";
import { useVolcanoLevelsRequest } from "hooks/useVolcanoLevels";
import { HomeService } from "api/Services/ReqHome";


const Alert = props => (
  <MuiAlert elevation={6} variant="filled" {...props} />
);

const { show_excuses_reload_page } = ErrorRequest;

const LoaderEvents = (
  { 
    loadVolcanos, loadNotifications,
    loadListInternationalEvents, selectEventsList,
    codeSelectedList, loadList_five_days_all, loadList_five_days_2,
    loadList_thirty_days_importan, loadList_sixty_days_4, home=false
  }
) => {

  const intervalRef = useRef();
  const codeRef = useRef( codeSelectedList );
  const [ open, setOpen ] = useState( false );
  const { getVolcanoLevels } = useVolcanoLevelsRequest();

  const handleClose = reason => {
    reason !== "clickaway" && setOpen( false );
  }

  const getVolcanos = async () => {
    try {
      const { data } = await reqResApi
        .get( 'feed/v1.0/volcano/volcanos.json' );
      data && loadVolcanos( data.features );
    } catch {
      show_excuses_reload_page (
        'Ofrecemos disculpas', 
        `Puede que el tiempo de inactividad en pantalla nos haya afectado, por favor, recargue la página web` 
      );
    }
  }

  const getNotifications = async () => {
    try {
      const { data } = await HomeService.getEvents();
      data && loadNotifications( data.notifications );
    } catch {
      show_excuses_reload_page (
         'Ofrecemos disculpas', 
         `Puede que el tiempo de inactividad en pantalla nos haya afectado, por favor, recargue la página web` 
       );
    }
  }

  const sixty_days_4 = async () => {
    try {
      const { data } = await reqResApi
        .get( 'feed/v1.0/summary/sixty_days_4.json' );
      if ( data ) {
        loadList_sixty_days_4( data.features );
        selectEventsList( codeRef.current );
      }
    } catch {}
  }

  const thirty_days_important = async () => {
    try {
      const { data } = await reqResApi
        .get( 'feed/v1.0/summary/thirty_days_importan.json' );
      if ( data ) {
        loadList_thirty_days_importan( data.features );
        selectEventsList( codeRef.current );
      }
    } catch {}
  }

  const loadfive_days_all = async () => {
    try {
      const { data } = await reqResApi
        .get( 'feed/v1.0/summary/five_days_all.json' );
      if ( data ) {
        loadList_five_days_all( data.features );
        selectEventsList( codeRef.current );
      }
    } catch {}
  }

  const loadfive_days_2 = async () => {
    try {
      const { data } = await reqResApi
        .get( 'feed/v1.0/summary/five_days_2.json' );
      if ( data ) {
        loadList_five_days_2( data.features );
        selectEventsList( codeRef.current );
      }
    } catch {
      show_excuses_reload_page (
        'Ofrecemos disculpas', 
        `Puede que el tiempo de inactividad en pantalla nos haya afectado, por favor, recargue la página web`
      );
    }
  }

  const loadInternational = async () => {
    try {
      const { data } = await reqResApi
        .get( 'feed/v1.0/summary/international.json' );
      data && loadListInternationalEvents( data.features );
    } catch {}
  }

  const loadAditionalData = () => {
   getVolcanos();
   getVolcanoLevels();
  }

  const loadAllIntervalList = () => {
    loadInternational();
    loadfive_days_2();
    loadfive_days_all();
    thirty_days_important();
    sixty_days_4();
    getNotifications();
    loadAditionalData();
  }

  const loadHomeData = () => {
   getNotifications();
   loadAditionalData();
  }

  const intervalTrigger = home ? loadHomeData : loadAllIntervalList;

  useEffect(() => {
    codeRef.current = codeSelectedList;
  },[ codeSelectedList ])

  useEffect(() => {
   intervalTrigger();
   loadAditionalData();
    intervalRef.current = setInterval(() => {
      intervalTrigger();
      setOpen( true );
    }, 60000 );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Snackbar
      open = { open }
      autoHideDuration = { 6000 }
      onClose = { handleClose }
    >
      <Alert onClose = { handleClose } severity = "success">
        Eventos geológicos actualizados
      </Alert>
    </Snackbar>
  );
}

const mapStateToProps = state => ({
  codeSelectedList: state.codeSelectedEventsList,
  notifications: state.notifications,
  startEvents: state.startEvents,
  activeEventsCounter: state.activeEventsCounter
});

const mapDispatchToProps = {
  loadList_five_days_2,
  loadList_five_days_all,
  loadList_thirty_days_importan,
  loadList_sixty_days_4,
  loadListActiveEvents,
  selectEventsList,
  loadListInternationalEvents,
  loadVolcanos,
  loadNotifications,
  setStartEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoaderEvents);
