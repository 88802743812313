import React from "react";
import safari from 'assets/icons/Reusable/safari.svg'
import chrome from 'assets/icons/Reusable/chrome.svg'
import firefox from 'assets/icons/Reusable/firefox.svg'
import Incompatible from 'assets/icons/Reusable/Incompatible.png'

//Pagina no encontrada, en caso de que el usuario ingrese una ruta incorrecta
const ErrorBrowser = () => (
  <div className="page-404">
    <div className="menu-404">
      <div className="title-menu-404">
        <p>Navegador Incompatible</p>
      </div>
      <div className="content-menu-404">
        <div className="lines-404">
          <hr></hr>
          <hr></hr>
          <hr></hr>
        </div>
        <div className="question-404">
          <p>Prueba con estos navegadores:</p>
        </div>
        <div className="options-menu-404">
          <div className="home-option-404">
            <a href="/"><img alt="browser" src={chrome}></img><p>Versión: 10.1.1+</p></a>
          </div>
          <div className="sismos-option-404">
            <a href="/"><img alt="browser" src={firefox}></img><p>Versión: 10.2.3+</p></a>
          </div>
          <div className="volcanos-option-404">
            <a href="/"><img alt="browser" src={safari}></img><p>Versión: 10.18+</p></a>
          </div>
        </div>
      </div>

    </div>
    <div className="not-404">
      <div className="bg-404">
        <img alt="browser" src={Incompatible}></img>
      </div>
      <div className="info-404">
        <p>TU NAVEGADOR NO SOPORTA LA APLICACIÓN :(</p>
      </div>
    </div>
    <div className="menu-404-tablet">
      <div className="question-404-tablet">
        <p>¿A dónde quieres ir?</p>
      </div>
      <div className="options-menu-404">
        <div className="home-option-404-tablet">
          <a href="/"><img alt="browser" src={chrome}></img><p>Home</p></a>
          
        </div>
        <div className="sismos-option-404-tablet">
          <a href="/"><img alt="browser" src={firefox}></img><p>Sismos</p></a>
        </div>
        <div className="volcanos-option-404-tablet">
          <a href="/"><img alt="browser" src={safari}></img><p>Volcanes</p></a>
        </div>
      </div>
    </div>
  </div>
);

export default ErrorBrowser;
