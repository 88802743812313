import React, { useEffect } from 'react'
import { UseAccessibility } from 'hooks/UseAccesibility'

export const AccessibilityTemplate = ({ trigger }) => {
    
    const {
        switchContrast, increaseFont, clearFont, switchSmall, contrast
    } = UseAccessibility();

    useEffect(() => {
        trigger && trigger( contrast );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ contrast ]);

    return (
        <div className = "styles_body">
            <span onClick = { switchContrast }>
                Contraste
            </span>
            <span onClick = { increaseFont }>
                Aumentar letra
            </span>
            <span onClick = { clearFont }>
                Letra normal
            </span>
            <span onClick = { switchSmall }>
                Disminuir letra
            </span>
        </div>
    )
}
