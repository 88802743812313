import { useSelector } from "react-redux";
import Loader from "Components/Pages/Loader";
import EQsmTabs from "./EQsmTabs";

import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider } from "react-lightgallery";

const EQSm = ({ handleNotEvent }) => {
   
   const event = useSelector( state => state.detailEvent );

   return (
    event.length > 0 ? (
      <div className="EQSm">
        <div className="EQSectionContainer GrayBordered">
            <LightgalleryProvider>
               <EQsmTabs 
                  handleNotEvent = { handleNotEvent } 
               />
            </LightgalleryProvider>
        </div>
      </div>
    ) : <Loader />
  );
}

export default EQSm;