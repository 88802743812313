import { useState, useEffect } from 'react'
import { FormControl, InputLabel, Input, InputAdornment, Select, MenuItem, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { removeAccents } from 'Components/Atoms/A_Reusable/filterEvents';

import { useDispatch } from 'react-redux';
import { setActiveFilter, setEventStringFilterData, setStartEvents } from 'redux/actionsCreators';

export const useFilterStyles = makeStyles( theme => ({ 
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "100%",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    formControl: {
      margin: ".2rem",
      minWidth: "97%",
      fontSize: "5pt"
    },
    selectEmpty: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "9pt"
    },
    menuItem:{
        fontSize: "5pt"
    } 
}));

export const UseFiltersView = ({ allEvents }) => {
    
   const dispatch = useDispatch();

    const classes = useFilterStyles();
    const [ filter, setFilter ] = useState(10);
    const [ searchedString, setSearchedString ] = useState("");

    const filterByName = event => {
        return removeAccents(
            event.properties.place.toLowerCase()
        ).includes( searchedString.toLocaleLowerCase() );
    }

    const filterByCdi = event => {
        return event.properties.cdi !== 0 && event.properties.cdi !== null
    };

    const filterByMmi = event => {
        return event.properties.mmi !== 0 && event.properties.mmi !== null
    };

    const filterBySm = event => {
        return event.properties.sm === true
    };

    const getNewShownEvents = () => {
        dispatch( setEventStringFilterData( searchedString, filter ) );
        let newShownEvents = allEvents
            ? allEvents.filter( filterByName ) : [];
        switch ( filter ) {
            case 10:
                return newShownEvents.sort(( a, b ) =>
                ("" + b.properties.localTime).localeCompare(
                    a.properties.localTime
                )
            );
            case 20:
                return newShownEvents.sort(( a, b ) =>
                ("" + a.properties.localTime).localeCompare(
                    b.properties.localTime
                )
            );
            case 30:
                return newShownEvents.sort(( a, b ) =>
                    ("" + b.properties.mag).localeCompare(
                        a.properties.mag
                    )
                );
            case 40:
                return newShownEvents.sort(( a, b ) =>
                    ("" + a.properties.mag).localeCompare(
                        b.properties.mag
                    )
                );
            case 50:
                newShownEvents = newShownEvents.filter( filterByCdi );
                return newShownEvents.sort((a, b) =>
                    ("" + b.properties.cdi).localeCompare(
                        a.properties.cdi
                    )
                );
            case 60:
                newShownEvents = newShownEvents.filter( filterByMmi );
                return newShownEvents.sort((a, b) =>
                    ("" + a.properties.mmi).localeCompare(
                        b.properties.mmi
                    )
                );
            case 70:
                return newShownEvents.filter( filterBySm );
            default:
                return newShownEvents;
        }
    }

    const activeFilter = ({ target }) => {
        setSearchedString( removeAccents( target.value ) );
        dispatch( setStartEvents( true ) );
    }

    useEffect(() => {
        getNewShownEvents();
        dispatch( setActiveFilter( filter ) );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ filter, searchedString ]);

    return (
        <div className = "filter-container">
            <div className = "filter-placeholder">
                <FormControl className = { classes.formControl }>
                    <InputLabel>
                        Buscar departamento o municipio
                    </InputLabel>
                    <Input
                        id = "textFieldSearchEvents"
                        value = { searchedString }
                        onChange = { activeFilter }
                        endAdornment = {
                            <InputAdornment position = "end">
                                <SearchIcon />
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>
            <div className = "filter-select">
                <FormControl className = { classes.formControl }>
                    <Select
                        value = { filter }
                        onChange = { ({ target }) => setFilter( target.value ) }
                        displayEmpty
                        className = { classes.selectEmpty }
                    >
                        <MenuItem  value = "" disabled>
                            Filtro
                        </MenuItem>
                        <MenuItem  value = { 10 }>Más reciente primero</MenuItem>
                        <MenuItem  value = { 20 }>Más antiguo primero</MenuItem>
                        <MenuItem  value = { 30 }>Mayor magnitud primero</MenuItem>
                        <MenuItem  value = { 40 }>Menor magnitud primero</MenuItem>
                        <MenuItem  value = { 50 }>Intensidad percibida (Mayor a menor)</MenuItem>
                        <MenuItem  value = { 60 }>Intensidad instrumental (Mayor a menor)</MenuItem>
                        <MenuItem  value = { 70 }>Posee cálculo de aceleración</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}
