import { ArrowForwardIos } from "@mui/icons-material";
import { ArrowBackIosNew } from "@mui/icons-material";

export const buildArrowPrev = ( onClickHandler, hasPrev, label ) =>
   hasPrev && (
      <div className="CarouselSwip">
         <div 
            className = "CarouselBtnBack" 
            onClick = { onClickHandler } 
            title = "Anterior" 
         >
            <ArrowBackIosNew />
         </div>
      </div>
   )

export const buildArrowNext = ( onClickHandler, hasNext, label ) =>
   hasNext && (
      <div className="CarouselSwip Right">
         <div 
            className = "CarouselBtnNext" 
            onClick = { onClickHandler } 
            title = "Siguiente"
         >
            <ArrowForwardIos />
         </div>
      </div>
   )