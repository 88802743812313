import React, { Fragment } from "react";

import {
  pixelCalculator,
  magnitudes,
} from "../../Atoms/A_Reusable/CalculatorPixelRadiusForEQ";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingTop: "10px",
    paddingBottom: "10px",
    cursor: "default",
    backgroundColor: "#fafafa",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    padding: "2px 5px 2px 5px",
    width: "100%",
    height: "100%",
  },
  magnitude: {
    marginLeft: "1px",
    marginRight: "1px",
    marginBottom: "0px",
    marginTop: "0px",
    fontSize: "70%",
  },
  title: {
    marginLeft: "10px",
    marginRight: "5px",
    marginBottom: "2px",
    marginTop: "5px",
    fontSize: "80%",
    fontWeight: "bold",
  },
}));

const OFFSET = 10;

const EarthquakePointsScale = () => {
  const styles = useStyles();
  const earthquakesMaginutudes = magnitudes();

  const buildItem = (magnitude, index) => {
    const radius = pixelCalculator(magnitude);
    const size = `${radius + OFFSET}px`;

    return (
      <Grid item key={index}>
        <div className={styles.itemContainer}>
          <div styles={{ height: size, width: size }}>
            <div
              className="earthquakePoint"
              style={{
                height: size,
                width: size,
                backgroundColor: "#7ad4e2",
                marginLeft: "1px",
                marginRight: "1px",
              }}
            ></div>
          </div>
          <p className={styles.magnitude}>{magnitude}</p>
        </div>
      </Grid>
    );
  };

  return (
    <Fragment>
      <p className={styles.title}>Magnitudes</p>
      <Grid className={styles.grid} container justifyContent="space-evenly">
        {earthquakesMaginutudes.map((magnitude, index) =>
          buildItem(magnitude, index)
        )}
      </Grid>
    </Fragment>
  );
};

export default EarthquakePointsScale;
