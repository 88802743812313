import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { getQuery } from 'Components/Atoms/A_Query/QActive';
import { reqStations } from 'api/ReqResApi';
import { QLoad } from 'Components/Atoms/A_Query/QLoad';

export const QStations = () => {

    const { stations, handleStations } = getQuery();
    const { filters, label } = stations;
    const [ station, setStation ] = useState([]);
    const getRequest = async () => {
        try {
            const { data } = await reqStations.get(
                "feed/v1.0/stations/stations.json"
            );
            data && setStation( data );
        } catch {}
    }

    const loadFilters = () => {
        return filters[0] ? filters.map( filter => {
            return { stationID: filter }
        }) : [];
    }
    const handleChange = ( event, value ) => {
        handleStations({
            label,
            filters: value.map( e => e.stationID )
        });
    }

    useEffect(() => {
        !station[0] && getRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        station[0] ? (
            <div className = "Qselector Qstations">
                <div className = "Qselector_input">
                    <div >
                        <Autocomplete
                            multiple
                            id = "tags-outlined"
                            options = { station }
                            getOptionLabel = {
                                option => option.stationID
                            }
                            filterSelectedOptions
                            noOptionsText={'No hay opciones'} 
                            onChange = { handleChange }
                            renderInput = { params => (
                                <TextField
                                    { ...params }
                                    variant = "outlined"
                                    label = { label }
                                />
                            )}
                            defaultValue = { loadFilters }
                        />
                    </div>
                </div>
            </div>
        ) : <QLoad />
    );
}