import React from 'react'
import listA from "assets/icons/EQViewer/Icons/Active/listA.svg";
import mapIconA from "assets/icons/Reusable/colombia_mapa_1.svg";
import QueryIcon from "assets/icons/EQViewer/Icons/query.svg";
import TableIcon from "assets/icons/EQViewer/Icons/table.svg";
import { Link } from 'react-router-dom';
import logo_sgc from "assets/icons/Reusable/logoWhite.svg";
import accessibility from 'assets/icons/EQViewer/Icons/accesibilidad_verde.svg';
import { AccessibilityTemplate } from 'Components/Molecules/M_Reusable/AccessibilityTemplate';
import { SGCPopup } from 'Components/Atoms/A_EQDetails/SGCPopup';

const setIconSelected = ( iconName, id ) =>{
    if (iconName === id) return "modes-view-item selected"
    return "modes-view-item"
}

export const QNavar = ({ children, handleModal, handleCloseModal, selected }) => (
    <div className = "CatalogModalContainer">
        <div className = "container-header">
            <div className = "viewer-header">
                <div className = "sgc-logo" >
                    <Link to = "/" className = "logo">
                        <img src = { logo_sgc } alt = "SGC" />
                    </Link>
                </div>
                <div className = "earthquakes-modes-view">
                    <ul className = "modes-view-container no-style" id = "icon-list">
                        <li
                            className = { setIconSelected( selected, 'catalog' ) }
                            id = "catalog"
                            data-id = "catalog"
                            title = "Tabla de resultados"
                            onClick = { handleModal }
                        >
                            <img
                                src = { TableIcon }
                                className = "button-header"
                                alt = "tableIcon"
                            />
                        </li>
                        <li
                            className = { setIconSelected( selected, 'query' ) }
                            id = "query"
                            data-id = "query"
                            title = "Consulta"
                            onClick = { handleModal }
                        >
                            <img
                                src = { QueryIcon }
                                className = "button-header"
                                alt = "tableIcon"
                            />
                        </li>
                        <li
                            className = "modes-view-item"
                            data-id = "accessibility"
                            title = "Accesibilidad"
                        >
                            <span
                                id = "accessibility_icon_popup"
                                className = "icon_display"
                            >
                                <img src = { accessibility } alt = "accesibilidad" />
                            </span>
                            <SGCPopup
                                Component = { <AccessibilityTemplate/> }
                                id = "accesibility_services_popup"
                                styles = {{ top: "100%" }} 
                                trigger = {{
                                    id: 'accessibility_icon_popup',
                                    classes: 'Services_icon_on'
                                }}
                            />
                        </li>
                        <li
                            className = "modes-view-item"
                            onClick = { handleCloseModal }
                            id = "list"
                            title = "Ver lista"
                        >
                            <img
                                className = "button-header"
                                src = { listA }
                                alt = "button-header"
                            />
                        </li>
                        <li
                            className = {`modes-view-item map_icon_access`}
                            onClick = { handleCloseModal }
                            id = "map"
                            data-id = "map"
                            title = "Ver mapa"
                        >
                            <img
                                className = "button-header"
                                src = { mapIconA }
                                alt = "button-map"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className = "queryModalContent">
            { children }
        </div>
    </div>
)
