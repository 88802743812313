import React from 'react'
import BuildLevel from "Components/Molecules/M_Reusable/BuildLevelVolcano";

export const VolcanoBasicInfo = ({ properties }) => (
   <div className="DetailsInfoGrid Volcano">

      <div className="InfoColumn">
         <div className="InfoRow">
            <div className="InfoTitle">Nombre:</div>
            <div className="InfoText">{ properties.VolcanoName }</div>
         </div>
         <div className="InfoRow">
            <div className="InfoTitle">Estado de Alerta:</div>
            <div className="InfoText Icon"> 
               <BuildLevel
                  level = { properties.activityLevel }
                  size={ 38 }
               />
            </div>
         </div>
         <div className="InfoRow">
            <div className="InfoTitle">Tipo de Volcán:</div>
            <div className="InfoText">{ properties.composition }</div>
         </div>
      </div>

      <div className="InfoColumn">
         <div className="InfoRow">
            <div className="InfoTitle">Elevación:</div>
            <div className="InfoText">{ properties.msnm }</div>
         </div>
         <div className="InfoRow">
            <div className="InfoTitle">Latitud:</div>
            <div className="InfoText">{ properties.latitude }&#176;</div>
         </div>
         <div className="InfoRow">
            <div className="InfoTitle">Longitud:</div>
            <div className="InfoText">{ properties.longitude }&#176;</div>
         </div>
      </div>

   </div>
);

