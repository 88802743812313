function HomeChat() {
   return (
      <a 
         className = "HomeChat"
         href = "https://www2.sgc.gov.co/chat"
      >
         Atención virtual
      </a>
   )
}
export default HomeChat