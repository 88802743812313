import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    root: (props) =>({
        color: props.fontColor,
        backgroundColor: props.bgColor
    })
})

export default function CustomAlert ({ children, color, fontColor, closable }) {

    const [open, setOpen] = useState(true);
    const styleProps = {
        bgColor: color,
        fontColor: fontColor ? fontColor : 'white',
    }

    const classes = useStyles( styleProps );

    return(
        open && 
        <Alert 
            className={ color ? classes.root : '' }
            icon={ false }
            onClose={ closable ? () => setOpen(false) : null }
        >
            { children }
        </Alert>
    )

}