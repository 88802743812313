import ReactGa from 'react-ga4';
import { ANALYTICS_KEY } from 'utils/analytics';

export const EVENT_CATEGORIES = {
    sm: 'Aceleraciones',
    tm: 'Tensor Momento Sísmico',
    cdi: 'Intensidad Percibida',
    mmi: 'Intensidad Instrumental',
    downloads: 'Descargas',
    query: 'Catálogo de Sismos',
    eventsTable: 'Tabla de eventos',
    eventsList: 'Lista de eventos',
    eventsMap: 'Mapa de eventos',
    eartquakes: "Visor de sismos",
    vulcans: "Visor de Volcanes",
    home: "Home Page",
}
    
export const analyticsPageTracker = () =>{
        ReactGa.initialize( ANALYTICS_KEY );
    }

export const analyticsEventTracker = ( { category, action, label = null }) =>{
        label 
        ? ReactGa.event({ category, action, label })
        : ReactGa.event({ category, action })
    }
