import React from 'react';
import { connect } from 'react-redux';
import EQDefault from './EQDefault';
import DownloadI from "assets/icons/EQDetail/EQMenu/descargas.svg";
import PreviewI from "assets/icons/EQDetail/EQMenu/preview.svg"
import { analyticsEventTracker, EVENT_CATEGORIES } from 'hooks/UseAnalyticsTracker';
import { Grid, Box } from '@material-ui/core';
import download from 'downloadjs';

const EQDownloads = ({ downloads }) => {
   
   const downloadFileFromRoute = ( link, name ) =>{
      download( link );
      analyticsEventTracker({
         category: EVENT_CATEGORIES.downloads,
         action: `${ name }` 
      })
   }

   return downloads ? (
       <div className = "EQUploads GrayBordered">
        {
            downloads.map(({ link, name }) => {
               return name === "QuakeML" ? (
                     <div className = "EQCard" key = { name } >
                        <div className = "EQCardImg">
                           <Grid container>
                              <Grid item xs={6}>
                                 <a href={ link } title="Previsualizar">
                                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                       <img
                                          src = { PreviewI }
                                          alt = "Preview"
                                          />
                                    </Box>
                                 </a>
                              </Grid>
                              <Grid item xs={6}>
                                 <div className="Link" title="Descargar" onClick={ e => downloadFileFromRoute( link, name ) }>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                       <img
                                          className="FullWidth"
                                          src = { DownloadI }
                                          alt = "Download"
                                       />
                                    </Box>
                                 </div>
                              </Grid>
                           </Grid>
                        </div>
                        <div className='EQCardContent'>
                           { name }
                        </div>
                     </div>
               )
               :(
                  <a
                        href = { link }
                        key = { name }
                        id = { name }
                        title="Descargar"
                        onClick = { e =>
                           analyticsEventTracker({
                              category: EVENT_CATEGORIES.downloads,
                              action: `${ name }` 
                           }) 
                        }
                  >
                        <div className = "EQCard" >
                           <div className = "EQCardImg">
                              <img 
                                 src = { DownloadI }
                                 alt = "Download"
                              />
                           </div>
                           <div className='EQCardContent'>
                              { name }
                           </div>
                        </div>
                  </a>
               )
            })
         }
      </div>
   ) : <EQDefault />
};

const mapStateToProps = state => ({
    downloads: state.detailEvent[0].properties.products.downloads,
    totalOrigins: state.totalOrigins,
});
export default connect(mapStateToProps)(EQDownloads)

/**
 * maxWidth: '170px',
   minWidth:'170px',
   maxHeight:'90%',
   boxShadow:'5px 5px 5px 0px #cccccc;',
   borderRadius:'10px'

 */
/**
 * change the visual of the component display flex screen > 1300px
 * otherwise a grid 2 columns
 */