import TableValidate from 'Components/Atoms/A_Reusable/ValidateTable';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import BuildLocalization from "Components/Molecules/M_Reusable/BuildLocalization";
import BuildDepth from "Components/Molecules/M_Reusable/BuildDepth";
import BuildMmi from "Components/Molecules/M_Reusable/BuildMmi";
import BuildCdi from "Components/Molecules/M_Reusable/BuildCdi";
import { validateValue } from 'utils/earthquakes';
import { GREEN } from 'utils/styles';

const { render_table, render_exist } = TableValidate;

const HtmlTooltip = withStyles( theme => ({
    tooltip: {
      backgroundColor: '#545454',
      color: '#ffffff'
    },
  }))( Tooltip );

export const EQOverTable = ({ event, handleHref }) => {

   const agency = validateValue( event.properties.agency ) ? event.properties.agency : "";

    return (
        <table className = "overviewtable">
            <tbody>
                {
                    event.properties.localTime !== null &&
                    event.properties.localTime !== undefined &&
                    event.properties.utcTime !== undefined &&
                    event.properties.utcTime !== null &&
                    (
                        <tr>
                            <th>Tiempo de origen:</th>
                            <td>
                                <span>
                                    { event.properties.localTime } Hora local<br />
                                    ({ event.properties.utcTime } UTC )
                                </span>
                            </td>
                        </tr>
                    )
                }
                {
                    render_table(
                        event.properties.status,
                        event.geometry.coordinates[0],
                        event.geometry.coordinates[1]
                    ) && (
                        <tr>
                            <th>Localización:</th>
                            <td>
                                <BuildLocalization
                                    eventList = {false}
                                    status = { event.properties.status }
                                    latitude = { event.geometry.coordinates[0] }
                                    longitude = { event.geometry.coordinates[1] }
                                />
                            </td>
                        </tr>
                    )
                }
                {
                    event.properties.status &&
                    event.geometry.coordinates[2] !== undefined &&
                    event.geometry.coordinates[2] !== null && (
                        <tr>
                            <th>Profundidad:</th>
                            <td>
                                <BuildDepth
                                    status = { event.properties.status }
                                    depth = { event.geometry.coordinates[2] }
                                    root = "detail"
                                />
                            </td>
                        </tr>
                    )
                }
                {
                    event.properties.mag !== undefined &&
                    event.properties.mag !== null && (
                        <tr>
                            <th>Magnitud:</th>
                            <td>{ event.properties.mag }</td>
                        </tr>
                    )
                }
                {
                    agency.includes("SGC") &&              
                    event.properties.nst !== undefined &&
                    event.properties.nst !== null && (
                        <tr>
                            <th>Estaciones:</th>
                            <td>{ event.properties.nst }</td>
                        </tr>
                    )
                }
                {
                    event.properties.status !== undefined &&
                    event.properties.status !== null && (
                        <tr>
                        <th>Estatus:</th>
                        {
                            event.properties.status === 'manual' ? (
                            <td style = {{ color:GREEN }}>
                                <strong>Manual</strong>
                            </td>
                            ) : (
                            <td style = {{ color:'#D64139' }}>
                                <strong>Automático</strong>
                            </td>
                            )
                        }
                        </tr>
                    )
                }
                {
                  agency.includes("SGC") &&
                  validateValue( event.properties.rms ) && (
                        <tr>
                            <th>RMS:</th>
                            <td>{ event.properties.rms } s</td>
                        </tr>
                    )
                }
                {
                     agency.includes("SGC") &&
                     validateValue( event.properties.gap ) && (
                        <tr>
                            <th>Gap:</th>
                            <td>{ event.properties.gap }°</td>
                        </tr>
                    )
                }
                {
                    agency !== "" && (
                        <tr>
                            <th>Agencia:</th>
                            <td>{ agency }</td>
                        </tr>
                    )
                }
                {
                    event.properties.feltReport !== undefined &&
                    event.properties.feltReport !== null && (
                        <HtmlTooltip 
                            title = "Más Información" placement="bottom"
                        >
                        <tr 
                            className = "EQoverHref"
                            onClick = { () => handleHref(`/detallesismo/${event.id}/cdi`) }
                        >
                            <th>Sentido:</th>
                            <td>
                                { event.properties.feltReport }
                            </td>
                        </tr>
                        </HtmlTooltip>
                    )
                }
                {
                    render_exist( event.properties.felt ) && (
                        <HtmlTooltip title="Más Información" placement="bottom">
                            <tr
                                className = "EQoverHref"
                                onClick = { () => handleHref(`/detallesismo/${event.id}/cdi`) }
                            >
                                <th>Total Reportes:</th>
                                <td>
                                    { event.properties.felt }
                                </td>
                            </tr>
                        </HtmlTooltip>
                    )
                }
                {
                    event.properties.closerTowns !== undefined &&
                    event.properties.closerTowns !== null &&
                    event.properties.closerTowns !== "" && (
                        <tr>
                            <th>Municipios cercanos:</th>
                            <td>{ event.properties.closerTowns }</td>
                        </tr>
                    )
                }
                {
                    render_exist( event.properties.mmi ) && (
                        <HtmlTooltip
                            title = "Más Información" placement = "bottom"
                        >
                            <tr
                                className = "EQoverHref"
                                onClick = { () => handleHref(`/detallesismo/${event.id}/mmi`) }
                            >
                                <th>Intensidad Instrumental:</th>
                                <td className = "short_icon">
                                    <BuildMmi mmi = { event.properties.mmi }/>
                                </td>
                            </tr>
                        </HtmlTooltip>
                    )
                }
                {
                    event.properties.cdi !== undefined && event.properties.cdi !== null && event.properties.cdi > 0 
                    && (
                        <HtmlTooltip title = "Más Información" placement = "bottom">
                            <tr
                                className = "EQoverHref"
                                onClick = { () => handleHref( `/detallesismo/${ event.id }/cdi` ) }
                            >
                                <th>Intensidad Máxima Percibida:</th>
                                <td className = "short_icon">
                                    <BuildCdi cdi = { event.properties.cdi } />
                                </td>
                            </tr>
                        </HtmlTooltip>
                    )
                }
                {
                    event.properties.maxPGA !== null && event.properties.maxPGA !== undefined && (
                        <HtmlTooltip 
                            title = "Más Información"
                            placement = "bottom"
                        >
                        <tr
                            className = "EQoverHref" 
                            onClick = { () => handleHref(`/detallesismo/${event.id}/sm`)}
                        >
                            <th>PGA Máxima:</th>
                            <td>
                                { event.properties.maxPGA } cm/seg^2
                            </td>
                        </tr>
                        </HtmlTooltip>
                    )
                }
                {
                    event.properties.beachBall !== null && event.properties.beachBall !== undefined &&
                        event.properties.beachBall !== "" && (
                        <HtmlTooltip
                            title = "Más Información"
                            placement = "bottom"
                        >
                        <tr 
                            className = "EQoverHref"
                            onClick = { () => handleHref(`/detallesismo/${event.id}/tm`) }
                        >
                            <th>Tensor Momento Sísmico:</th>
                            <td>
                                <img 
                                    src = { event.properties.beachBall }
                                    alt = "tensor_momento"
                                />
                            </td>
                        </tr>
                        </HtmlTooltip>
                    )
                }
            </tbody>
        </table>
    )
}