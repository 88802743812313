import React, { useState, useEffect } from "react";

import { Slider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GREEN } from "utils/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px 10px 0px 10px"
  },
  label: {
    color: "#888",
    fontSize: "75%",
    marginBottom: "40px",
  },
  sliderPrimaryColor: {
    color: GREEN,
  },
  sliderMarkerLabel: {
    color: GREEN,
    fontSize: "70%",
    fontWeight: "bold"
  },
  markBound: {
    fontSize: "65%",
    color: "#666"
  }
}));

const SliderFilter = ({
  minLimit, maxLimit, step, defaultValue, onChange,
}) => {

  const [ value, setValue ] = useState( defaultValue );
  const styles = useStyles();
  const marks = [
    { value: minLimit, label: `${ minLimit }` },
    { value: maxLimit, label: `${ maxLimit }` },
  ];

  const handleChange = ( event, newValue ) => {
    setValue( newValue );
    onChange( newValue );
  };

  const getValueText = value => `${ value }`;

  useEffect(() => {
    defaultValue && defaultValue[0] && defaultValue[1] &&
      setValue( defaultValue );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ defaultValue ])

  return (
    <div className={styles.root}>
      <Slider
        classes={{
          colorPrimary: styles.sliderPrimaryColor,
          valueLabel: styles.sliderMarkerLabel,
          markLabel: styles.markBound
        }}
        defaultValue={defaultValue}
        value={value}
        step={step}
        max={maxLimit}
        min={minLimit}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={getValueText}
        marks={marks}
      />
    </div>
  );
};

export default SliderFilter;
