import React from "react";
import { analyticsEventTracker, EVENT_CATEGORIES } from "hooks/UseAnalyticsTracker";

const EQMmiDownloadCard = ({ title, id, data }) => {
    return (
        <div className="DownloadMmi">
                <div className="tittle-option">
                  <h2>{ title }</h2>
                </div>
                <div className="DownloadMmi_content">
                  <div className="container-content">
                    { data.map((element, index) => {
                      return (
                        <div className="container-option"
                          key={ id + index}
                          id={ id + index }
                        >
                          <a 
                            href={ element.link } 
                            target="_blank" 
                            rel="noopener noreferrer"
                            onClick = { e =>
                              analyticsEventTracker({
                                  category: EVENT_CATEGORIES.mmi,
                                  action: `Descargas: ${ element.name }`,
                                  label: `${ title }: ${ element.name }`
                              }) 
                            }
                          >
                            <div className="btn_href">
                              <span>{ element.name }</span>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </div>
            </div>
        </div>
    )
}

export default EQMmiDownloadCard;