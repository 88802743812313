import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	show_menu,
	changeMenuIcon,
	loadVolcanosFilter
} from "redux/actionsCreators";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import VListItem from 'Components/Molecules/M_VViewer/VListItem';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: theme.spacing(16),
			height: theme.spacing(16),
		},
	},
}));

/**
 * Filters Menu
 */

const filterByLatN = volcano => (
	volcano.properties.latitude >= 3.4 && volcano.properties.latitude <= 15
)

const filterByLatC = volcano => (
	volcano.properties.latitude >= 1.4 && volcano.properties.latitude < 3.4
)

const filterByLatS = volcano => (
	volcano.properties.latitude >= -2 && volcano.properties.latitude < 1.4
)

const filterLevel1 = volcano => volcano.properties.activityLevel === 1

const filterLevel2 = volcano => volcano.properties.activityLevel === 2

const filterLevel3 = volcano => volcano.properties.activityLevel === 3

const filterLevel4 = volcano => volcano.properties.activityLevel === 4


const EQMenuVolcanos = (
	{ items, order, filter, active, id, changeMenuIcon, show_menu }
) => {

	const hideMenu = () => {
		if (window.innerWidth <= 1260) {
			changeMenuIcon(false);
			show_menu();
		}
	}

	const MenuVolcanosItem = ({ product, volcano, index }) => {

      return (
            <div 
               key={`product${id}_${index}`}
               onClick={hideMenu}
            >
               <Link to={`/detallevolcan/${volcano.id}`}>
                  <VListItem 
                     content={ false }
                     id={ volcano.id }
                     index={ index }
                     volcano={ volcano?.properties }
                     activeClass={ active === `${product}` ? "Active" : "" }
                  />
               </Link>
            </div>
      )
   }

	const removeAccents = str => (
		str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
	)

	const filterByName = obj => (
		removeAccents(
			obj.properties.VolcanoName.toLowerCase()
		).includes(filter.toLowerCase())
	)

	const arrByName = items.filter(filterByName);

	useEffect(() => {
		loadVolcanosFilter(arrByName);
	}, [arrByName]);

	const level1 = arrByName.filter(filterLevel1).sort((a, b) => {
		return ('' + a.properties.VolcanoName).localeCompare(b.properties.VolcanoName);
	});

	const level2 = arrByName.filter(filterLevel2).sort((a, b) => {
		return ('' + a.properties.VolcanoName).localeCompare(b.properties.VolcanoName);
	});

	const level3 = arrByName.filter(filterLevel3).sort((a, b) => {
		return ('' + a.properties.VolcanoName).localeCompare(b.properties.VolcanoName);
	});

	const level4 = arrByName.filter(filterLevel4).sort((a, b) => {
		return ('' + a.properties.VolcanoName).localeCompare(b.properties.VolcanoName);
	});

	const selectFilter = () => {
		switch (order) {
			case 10:
				return arrByName.sort((a, b) => {
					return ('' + a.properties.VolcanoName).localeCompare(b.properties.VolcanoName);
				});
			case 20:
				return [...level1, ...level2, ...level3, ...level4];
			case 30:
				return arrByName.filter(filterByLatN).sort((a, b) => {
					return ('' + a.properties.VolcanoName).localeCompare(b.properties.VolcanoName);
				});
			case 40:
				return arrByName.filter(filterByLatC).sort((a, b) => {
					return ('' + a.properties.VolcanoName).localeCompare(b.properties.VolcanoName);
				});
			case 50:
				return arrByName.filter(filterByLatS).sort((a, b) => {
					return ('' + a.properties.VolcanoName).localeCompare(b.properties.VolcanoName);
				});
			default:
				return arrByName;
		}
	}

	const links = selectFilter().map((element, index) => {
		return <MenuVolcanosItem
         key={ index }
         index={ index }
         product={ element.id }
         volcano={ element }
      />
	});

	return links[0] ? links : (
		<div className={useStyles.root}>
			<Paper
				elevation={3}
				style={{ margin: "1rem", padding: "1rem", display: "block" }}
			>
				<h3>No hemos encontrado resultados.</h3>
				<br />
				<h5
					style={{ fontWeight: "normal" }}
				>
					Por favor, comprueba los parámetros de búsqueda.
				</h5>
			</Paper>
		</div>
	)

};

const mapStateToProps = ({ filterBy }) => ({
	filter: filterBy
});

const mapDispatchToProps = {
	show_menu,
	changeMenuIcon,
	loadVolcanosFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(EQMenuVolcanos);
