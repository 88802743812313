import React, { useRef, useState } from 'react'
import { UseLeafletMap } from 'hooks/UseLeafletMap';
import SmMarker from 'Components/Molecules/M_Reusable/SmMarker';
import DefineIconsRules from 'Components/Molecules/M_Reusable/DefineIconsRules';
import SettingsPopup from 'Components/Molecules/M_Reusable/SettingsPopup';

const { build_network_colors } = DefineIconsRules;
export const Viewer = (
    { 
        id, styles, initPosition, initialZoom, netWorksMarkers,
        selectedEvent, HeaderMap, mapOptions, Tiles, isPrint,
        BuildMarkers, FooterMap, getMapReferences, isFullScreen,
        onzoomend, onMoveEnd, onClick, updateZoom, onzoomstart, zoomSnap
    }
) => {

    const mapRef = useRef();
    const [ zoom, setZoom ] = useState( initialZoom );

    const onZoomStart = ({ target }) => {
        onzoomstart && onzoomstart( target._zoom );
        setZoom( target._zoom )
    }

    /**
     * @param { ref } Map references
     * this references give the map properties to
     * the father component who needs make a validation
     */
    const getMap = ref => {
        mapRef.current = ref;
        getMapReferences && getMapReferences( ref );
    };

    return (
        <div className = { styles ? styles.base : 'defaultViewer' }>
            { HeaderMap && HeaderMap }
            <UseLeafletMap
                id = { id }
                position = { initPosition }
                zoom = { updateZoom ? initialZoom : zoom }
                onzoomstart = { updateZoom || onZoomStart }
                onzoomend = { onzoomend }
                onClick = { onClick }
                mapOptions = { mapOptions }
                onMoveEnd = { onMoveEnd }
                scrollWheelZoom = { true }
                getMap = { getMap }
                zoomSnap={ zoomSnap }
                bodyMap = {(
                    <>
                        { Tiles && Tiles }
                        { selectedEvent && selectedEvent() }
                        { BuildMarkers && BuildMarkers }
                        {
                            netWorksMarkers && netWorksMarkers.filter_list
                                && netWorksMarkers.filter_list[0]
                                && netWorksMarkers.filter_list.map(( element, index ) => (
                                    <SmMarker
                                        key = { `${ element.id } - ${ index }` }
                                        event = { element }
                                        name = { element.stationID }
                                        isSelected = { false }
                                        onSelect = { () => "" }
                                        color = { build_network_colors( element ) }
                                        popupMarker = { <SettingsPopup event = { element }/> }
                                        isHeader = { false }
                                    />
                                ))
                        }
                    </>
                )}
                dragging = { true }
                isPrint = { isPrint || false }
                zoomControl
                isFullScreen = { isFullScreen || false }
            />
            { FooterMap && FooterMap }
        </div>
    )
}
