import { useState } from 'react';
import { InputBase } from '@mui/material';
import searchIcon from '../../../assets/icons/Home/indice.svg';
import HmSearchList from 'Components/Atoms/A_Home/HmSearchList';
import { useRef } from 'react';
import { IconButton } from '@mui/material';

const HomeSearchBar = () => {

	const [ text, setText ] = useState('');

   const searchPoint = useRef(1);

   const searchHome = ( params ) => {
		if ( params !== '' ) {
			window.location.href =
			searchPoint.current === 0
				? `https://siig.sgc.gov.co/Paginas/todo.aspx?k=${ params }` 
				: `https://www2.sgc.gov.co/bus/Paginas/results.aspx?k=${ params }`;
		} 
   }

   const changeOption = value =>{ searchPoint.current = value; }

	return (
		<div className = "HomeSearchContainer">

			<div className = "HomeSearchBar" role="searchbox">

				<form className = "HomeInput">

					<HmSearchList 
                  onChange={ changeOption }
                  searchPointRef={ searchPoint }
               />

					<div className = "HomeInputBar">
                  <div className="SearchInput">
                     <InputBase
                        className="SearchInputText"
                        role="textbox"
                        id = "search_input"
                        value = { text }
                        onChange={
                           ({ target }) => setText( target.value )
                        }
                        onKeyDown={
                           event => {
                              if ( event.key === 'Enter' ) {
                                 event.preventDefault();
                                 searchHome( text );
                              }
                           }
                        }
                        placeholder = "Buscar en este sitio"
                        inputProps = {{ 'aria-label': "Buscar en este sitio" }}
                     />
                  </div>
                  <IconButton 
                     className="SearchButton" 
                     onClick = { () => searchHome( text ) } 
                     size="small"
                  >
                     <img src = { searchIcon } alt = "search icon" />
                  </IconButton>
					   
					</div>
				</form>

			</div>
		</div>
	)
}

export default HomeSearchBar;