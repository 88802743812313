function SgcButton({ className="SgcButton", variant="Oxigen", href, onClick=null, label }) {
   return (
      <button className={ `${className} ${variant}` }>
         <a
            href={ href }
            rel="noopener noreferrer"
            target = '_blank'
            onClick = { onClick }
         >
            { label ?? "Más Información" }
         </a>
      </button>
   )
}
export default SgcButton;