import { useState, useRef } from 'react';

export const UseAccessibility = (
    font_styles = {
        min_font: 'min_font',
        small: 'small_font',
        max: 'max_font'
    }
) => {

    const htmlContainer = useRef (
        document.querySelector(`#html_container`)
    );

    const initContrast = () => {
        const contrast = window.localStorage.getItem( 'contrast' );
        if ( contrast && contrast === 'true' ) {
            htmlContainer.current.classList.add('High_contrast');
            return { active: true, text: "Reducir contraste" }
        }
        return { active: false, text: "Aumentar contraste" }
    }

    const initFont = () => {
        const font = window.localStorage.getItem( 'font' );
        if ( font && font !== "0" ) {
            const fontData = parseInt( font );
            switch( fontData ) {
                case 1:
                    htmlContainer.current.classList
                        .add( font_styles.max );
                    return {
                        font: { active: true, text: "Disminuir fuente" },
                        fontData
                    }
                case -1:
                    htmlContainer.current.classList
                        .add( font_styles.small );
                    return {
                        font: { active: false, text: "Aumentar fuente" },
                        fontData
                    }
                case -2:
                    htmlContainer.current.classList
                        .add( font_styles.min_font );
                    return {
                        font: { active: false, text: "Aumentar fuente" },
                        fontData
                    }
                default: return;
            }
        }
        return {
            font: { active: false, text: 'Aumentar fuente' },
            fontData: 0
        }
    }
    
    const { font, fontData } = initFont();
    const fontRef = useRef( fontData );
    const [ appearance, setAppearance ] = useState({
        contrast: initContrast(), font
    });

    const printPage = () => window.print();
    
    const clearFont = ( trigger = false ) => {
        Object.keys( font_styles ).forEach( classes => {
            htmlContainer.current.classList
            .remove( font_styles[ classes ] );
        });
        if( trigger ) {
            window.localStorage.setItem( "font", 0 );
            setAppearance ({
                ...appearance,
                font: {
                    active: false,
                    text: "Aumentar fuente",
                    class: ""
                }
            });
        }
    }

    const switchFont = () => {
        !appearance.font.active
        ? increaseFont()
        : clearFont( true );
    }

    const switchContrast = () => {

        window.localStorage.setItem(
            'contrast', `${ !appearance.contrast.active }` 
        );
        if ( !appearance.contrast.active ) {
            htmlContainer.current.classList.add('High_contrast');
            setAppearance({
                ...appearance,
                contrast: {
                    text: 'Reducir contraste',
                    active: true
                }
            });
        } else {
            htmlContainer.current.classList.remove('High_contrast');
            setAppearance({
                ...appearance,
                contrast: {
                    text: 'Aumentar contraste',
                    active: false
                }
            })
        }

    }


    const increaseFont = () => {
        clearFont();
        htmlContainer.current.classList.add( font_styles.max );
        window.localStorage.setItem( "font", 1 );
        setAppearance({
            contrast: appearance.contrast,
            font: {
                text: "Reducir fuente",
                active: true
            }
        });
    }

    const smallFont = () => {
        htmlContainer.current.classList.add( 'small_font' );
        fontRef.current = -1;
        window.localStorage.setItem( "font", -1 );
        setAppearance({
            ...appearance,
            font: {
                text: "Aumentar fuente",
                active: true
            }
        });
    }

    const minSmallFont = () => {
        htmlContainer.current.classList.add( 'min_font' );
        fontRef.current = -2;
        window.localStorage.setItem( "font", -2 );
        setAppearance({
            ...appearance,
            font: {
                text: "Aumentar fuente",
                active: true
            }
        });
    }

    const switchSmall = () => {

        clearFont();

        if ( fontRef.current > -1 ) {
            smallFont();
        } else if ( fontRef.current === -1 ) {
            minSmallFont();
        } else {
            fontRef.current = 0;
        }
        
        window.localStorage.setItem( "font", fontRef.current );
    }

    return { 
        ...appearance, printPage,
        clearFont, increaseFont, switchSmall,
        switchFont, switchContrast
    }
}