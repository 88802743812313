import React, { useEffect, useRef, useState } from 'react'
import { CircleMarker, Popup } from 'react-leaflet';
import TensorIcon from "assets/icons/Query/pelotadeplaya_vacia-01.svg";
import { createTensorBall } from 'api/ReqResApi';
import EarthquakePopup from '../O_Reusable/EarthquakePopup';

export const CTensorPopup = ({ event }) => {

    const [ tensorImg, setImg ] = useState(
        <img src = { TensorIcon } alt = { "properties.beachBall" } />
    )
    const markerRef = useRef();

    const buildTensor = async () => {
        const img = new Image();
        try {
            const imageUrl = createTensorBall(
                new Date( event.localTime.replace( ' ', 'T' ))
                    .getFullYear(), event.id, event.beachBall
            );
            const fetchImage = await fetch( imageUrl );
            img.src = fetchImage.url;
            return (
                <img src = { img.src } alt = "properties.beachBall"/>
            )
        } catch {
            return <img src = { TensorIcon } alt = "properties.beachBall" />
        }
    }

    const buildTensorIcon = async () => setImg( await buildTensor( event ));
    
    useEffect(() => {
        markerRef.current && markerRef.current.openPopup();
        buildTensorIcon();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ event ]);

    return (
        <CircleMarker
            ref = { markerRef }
            center = { event.coordinates }
            radius = {0}
        >
            <Popup
                keepInView = { true }
                closeButton = { false }
                autoPan = { false }
            >
                <EarthquakePopup
                    id = { event.id }
                    lat = { event.lat }
                    lng = { event.lng }
                    place = { event.place }
                    localTime = { event.localTime }
                    utcTime = { event.utcTime }
                    status = { event.status }
                    closerTowns = { event.closerTowns }
                    magnitude = { event.magnitude }
                    buildBeachBall = { tensorImg }
                    mmi = { event.mmi }
                    depth = { event.depth }
                    cdi = { event.cdi }
                    maxPGA = { event.maxPGA }
                    agency = { event.agency }
                    beachBall = { event.beachBall }
                    urlMoreInfo = { event.urlMoreInfo }
                />
            </Popup>
        </CircleMarker>
    )
}