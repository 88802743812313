import OriginPhase from "Components/Atoms/A_EQDetails/OriginPhase";

import MUIDataTable from 'mui-datatables';
import { MUIOptionsESP } from "Components/Templates/MUITables";


export default function PhasesOrigin(props) {
  const { phases } = props;

  return (
      <div className="SgcMUIDataTable">
         <MUIDataTable 
            columns={[
               { name: 'net', label: 'Red' },
               { name: 'station', label: 'Estación' },
               { name: 'time', label: 'Tiempo UTC', 
                  options: { 
                     filter: false, 
                     setCellHeaderProps: () => ({ style: { minWidth: "130px", } })
                  } 
               },
               { name: 'phase', label: 'Fase' },
               { name: 'residual', label: 'Residual', options: { filter: false, } },
               { name: 'distance', label: 'Distancia(°)', options: { filter: false, } },
               { name: 'azimuth', label: 'Azimuth(°)', options: { filter: false, } },
               { name: 'status', label: 'Estatus',
                  options: { 
                     sort: false, 
                     customBodyRender: ( value ) => <OriginPhase status={value} />  
                  },
               },
            ]}
            data={
               phases?.map(( item, i ) => ({
                  net: item.net,
                  station: item.station,
                  time: item.time,
                  phase: item.phase,
                  residual: item.residual,
                  distance: item.distance,
                  azimuth: item.azimuth,
                  status: item.status
               }))
            }
            options={ MUIOptionsESP }
         />
      </div>
   );
}
