import { LeafControls } from "Components/Atoms/A_Reusable/LeafControls";
import { useEffect, useRef } from "react";
import { Circle, Popup, useMap } from "react-leaflet";

export const fixedNumbers = number => {
   if ( typeof number === "number" )
      return number.toFixed( 2 );
   return number;
}

function DraggableCircleLayer({ area, setArea, popup=true }) {

   const radiusRef = useRef( area.radius );
   const layerRef = useRef( null );
   const circleRef = useRef();

   const map = useMap();

   const { 
      createCircle, setRadius, setCirclePosition,
   } = LeafControls();

   const addToMap = element => element.addTo ( map );
   const openPopup = () => circleRef.current?.openPopup(); 
   const closePopup = () => circleRef.current?.closePopup();

   const onDragEndCircle = ( event, layer ) => {
      setArea({ 
         radius: radiusRef.current,
         lat: parseFloat( layer.getLatLng().lat ),
         lng: parseFloat( layer.getLatLng().lng ),
      });
      openPopup();
   }

   const createLayer = () => {
      layerRef.current = createCircle (
         [ area.lat, area.lng ], 
         {
            radius: radiusRef.current, transform: true,
            draggable: true, color: 'red'
         },
         [
            { key: 'dragend', action: onDragEndCircle },
            { key: 'dragstart', action: closePopup },
            { key: 'click', action: openPopup }
         ]
     );
     addToMap( layerRef.current );
     openPopup();
   }

   useEffect( () => {
      setCirclePosition( [ area.lat, area.lng ] )
   // eslint-disable-next-line react-hooks/exhaustive-deps      
   }, [ area.lat, area.lng ] );


   useEffect( () => {
      radiusRef.current = area.radius;
      setRadius( area.radius );
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ area.radius ] );

   useEffect( () =>{
      createLayer();
      return () => map.removeLayer( layerRef.current );
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   if ( !popup ) return null;

   return (
      <Circle 
         radius={ area.radius } 
         fillColor="#ff000000" color="#A9A9A923" // reference circle to popup 
         center={[ area.lat, area.lng ]} 
         ref={ circleRef }
      >
         <Popup autoClose={false} closeOnClick={ false } keepInView={ true }  >
            <div id="QPopup" className="MapPopup">
               <b>Coordenadas</b>
               <br/>
               Latitud: { fixedNumbers( area.lat ) }° Longitud: { fixedNumbers( area.lng ) }°
               <br/>
               Radio: { area.radius }km
            </div>
         </Popup>
      </Circle>
   )
}
export default DraggableCircleLayer