import { useEffect, useRef, useState } from "react";

export default function AnimateOnScroll({ children }) {

   const divRef = useRef(null);
   const eventRef = useRef();
   const [ onScreen, setOnScreen ] = useState( false );

   useEffect(()=>{

      const animateIn = () => {
         if ( divRef.current ){
            let windowHeight = window.innerHeight;
            let revealTop = divRef.current.getBoundingClientRect().top;
            const revealPoint = 100;

            if (revealTop < windowHeight - revealPoint){
               setOnScreen( true );
            } 
         }
      }

      eventRef.current = window.addEventListener("scroll", animateIn )
		return () => {
			eventRef.current && window.removeEventListener(
				'scroll', eventRef.current
			);
		}
   }, [])
   
   return (
      <div
         ref={divRef}
         style={{
            opacity: onScreen ? 1 : 0,
            translate: onScreen ? "none" : "0 3rem",
            transition: "600ms ease-in-out",
         }}
      >
         {children}
      </div>
   );
};