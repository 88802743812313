import { Box } from "@mui/material"
import { UseLeafletMap } from "hooks/UseLeafletMap"
import SgcMapTileLayer from "./SgcMapTileLayer";
import { useSelector } from "react-redux";
import { EarthquakeMarker } from "Components/Molecules/M_Reusable/EarthquakeMarker";

const insularBounds = {
   sanAndres: [[12.630140, -81.616105], [12.448063, -81.8180712]],
   // sanAndres: [[4.7, -82.2], [4.3, -82.7]],
   providencia: [[13.457434, -81.270929], [13.261888, -81.500355]]
}

function InsularMiniMap({ events, viewer = false }) {

   const allEvents = useSelector( state => state.selectedEventsList )
   const zoom = 10;

   const buildEvents = () => {
      return allEvents?.map( e => { 
         if ( e.geometry.coordinates[0] >= 12.2 && e.geometry.coordinates[0] <= -81.1){
            return (
               <EarthquakeMarker 
                  key = { e.id }
                  earthquake = { e }
               />
            )
         }
         return null;
      } )
   }

   return (
      <Box display="flex">
         <UseLeafletMap
            id = "MiniMapLeft"
            position = {[ 12.542708, -81.716594 ]}
            zoom = { zoom }
            scaleControl={ false }
            inertia={ false }
            mapOptions = {{ maxZoom: zoom+3, minZoom: zoom, maxBounds: insularBounds.sanAndres }}
            scrollWheelZoom
            dragging
            bodyMap = {(
               <>
               <SgcMapTileLayer />
               { viewer ? buildEvents() : events }
            </>
            )}
         />
         <UseLeafletMap
            id = "MiniMapRight"
            position = {[ 13.356, -81.372 ]}
            zoom = { zoom }
            scaleControl={ false }
            mapOptions = {{ maxZoom: zoom+3, minZoom: zoom, maxBounds: insularBounds.providencia }}
            scrollWheelZoom
            dragging
            bodyMap = {(
               <>
               <SgcMapTileLayer />
               { viewer ? buildEvents() : events }
            </>
            )}
         />
      </Box>
   )
}
export default InsularMiniMap