import ReactApexChart from 'react-apexcharts'

const EQHmChart = ( { info, index } ) => {
    
    return(
        <div className={`HmChart${index}`}>
            <ReactApexChart
                options={info.chart_options}
                series={info.data}
                type={info.type}
                height={info.height}
            />
        </div>
    )
}

export default EQHmChart;