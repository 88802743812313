import L from 'leaflet';

export const LeafLetGrid = () => {
    return L.FeatureGroup.extend({
        include: L.Mixin.Events,
        options: {
            cellSize: 64,
            delayFactor: 2.5,
            style: {
                stroke: true,
                color: '#000000',
                dashArray: null,
                lineCap: null,
                lineJoin: null,
                weight: 1,
                opacity: 0.2,
                fill: true,
                fillColor: 'transparent', //same as color by default
                fillOpacity: 0.5,
                clickable: true
            }
        },
        initialize: function( options ){
            L.Util.setOptions( this, options );
            L.FeatureGroup.prototype.initialize.call( this, [], options );
        },
        onAdd: function( map ){
            L.FeatureGroup.prototype.onAdd.call(this, map);
            this._map = map;
            this._cells = [];
            this._setupGrid(map.getBounds());

            map.on( "move", this._moveHandler, this );
            map.on( "zoomend", this._zoomHandler, this );
            map.on( "resize", this._resizeHandler, this );
        },
        onRemove: function(map){
            L.FeatureGroup.prototype.onRemove.call( this, map );
            map.off( "move", this._moveHandler, this );
            map.off( "zoomend", this._zoomHandler, this );
            map.off( "resize", this._resizeHandler, this );
        },
        _clearLayer: function(e) {
            this._cells = [];
        },
        _moveHandler: function(e){
            this._renderCells( e.target.getBounds() );
        },
        _zoomHandler: function(e){
            this.clearLayers();
            this._renderCells( e.target.getBounds() );
        },
        _renderCells: function( bounds ) {
            let cells = this._cellsInBounds( bounds );
            this.fire("newcells", cells);
            for ( let i = cells.length - 1; i >= 0; i-- ) {
                let cell = cells[i];
                if( this._loadedCells.indexOf( cell.id ) === -1 ){
                    ( function( cell, i ) {
                        const { lat, lng } = cell.bounds._northEast;
                        const rectangle = L.rectangle( 
                            cell.bounds,
                            {
                                ...this.options.style,
                                dashArray: '4 1 2 3',
                                dashOffset: '40'
                            }
                        );
                        rectangle.bindTooltip(() => {
                            return `[${ lat.toFixed(0) },${ lng.toFixed(0) }]`
                        }, { permanent: true, direction: 'top', className: 'SmLatlngText' })
                        setTimeout (
                            this.addLayer.bind(
                                this,
                                rectangle
                            ),
                            this.options.delayFactor*i
                        );
                    }.bind(this))(cell, i);
                    this._loadedCells.push(cell.id);
                }
            }
        },
        _resizeHandler: function(e) {
            this._setupSize();
        },
        _setupSize: function(){
            this._rows = Math.ceil(this._map.getSize().x / this._cellSize);
            this._cols = Math.ceil(this._map.getSize().y / this._cellSize);
        },
        _setupGrid: function(bounds){
            this._origin = this._map.project(bounds.getNorthWest());
            this._cellSize = this.options.cellSize;
            this._setupSize();
            this._loadedCells = [];
            this.clearLayers();
            this._renderCells(bounds);
        },
        _cellPoint:function(row, col){
            const x = this._origin.x + (row*this._cellSize);
            const y = this._origin.y + (col*this._cellSize);
            return new L.Point(x, y);
        },
        _cellExtent: function(row, col){
            const swPoint = this._cellPoint(row, col);
            const nePoint = this._cellPoint(row-1, col-1);
            const sw = this._map.unproject(swPoint);
            const ne = this._map.unproject(nePoint);
            return new L.LatLngBounds(ne, sw);
        },
        _cellsInBounds: function(bounds){
            let offset = this._map.project( bounds.getNorthWest() );
            let center = bounds.getCenter();
            let offsetX = this._origin.x - offset.x;
            let offsetY = this._origin.y - offset.y;
            let offsetRows = Math.round( offsetX / this._cellSize );
            let offsetCols = Math.round( offsetY / this._cellSize );
            let cells = [];
            for ( let i = 0; i <= this._rows; i++ ) {
            for ( let j = 0; j <= this._cols; j++ ) {
                let row = i - offsetRows;
                let col = j - offsetCols;
                let cellBounds = this._cellExtent(row, col);
                let cellId = row+":"+col;
                cells.push({
                    id: cellId,
                    bounds: cellBounds,
                    distance:cellBounds.getCenter().distanceTo(center)
                });
            }
            }
            cells.sort(function (a, b) {
                return a.distance - b.distance;
            });
            return cells;
        }
    });
}
