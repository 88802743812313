import React from 'react';

const BuidDepthText = ({ status, depth, eventList, exact=false }) => {
  status = status.toLowerCase();
  if ( eventList ) {
    
    if ( status === 'automatic' && depth < 70 ) {
      return (
        <p>
          <span>Profundidad: </span>
          Superficial
        </p>
      );
    }
    else if ( status === 'manual' && depth < 30 ) {
      return (
        <p>
          <span>Profundidad: </span>
          Superficial
        </p>
      )
    }
    return (
      <p>
        <span>Profundidad: </span>
        { depth } km
      </p>
    )
  }
  else if ( exact ){
      return <span>{ depth } km</span>
  } 
  else {
    
    if ( status === 'automatic' && depth < 70 ) {
      return (
        <p>
          <span>Profundidad: </span>
          Superficial
        </p>
      );
    }
    else if ( status === 'manual' && depth < 30 ) {
      return (
        <p>
          <span>Profundidad: </span>
          Superficial
        </p>
      );
    }
    return (
      <p>
        <span>Profundidad: </span>
        { depth } km
      </p>
    );

  }

}
export default BuidDepthText;