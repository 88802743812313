import React from "react";
import { CircleMarker, Popup } from "react-leaflet";
import { pixelCalculator } from "../../Atoms/A_Reusable/CalculatorPixelRadiusForEQ";
import { calculateColor } from "../../Atoms/A_Reusable/CalculatorDepthColors";
import PopupEarthquakeMarker from "Components/Atoms/A_EQViewer/PopupEarthquakeMarker";
import { useLocation } from "react-router-dom";
import { REPORT_URL } from "utils/urls";

export const selectionColor = "#49ece8";
export const moreRecentColor = "#7AD4E2"; // change color #7AD4E2 activate_marker

export const defineMarkerColor = ( defaultColor, isSelected, depth ) => {
  if ( defaultColor ) { return defaultColor }
  return isSelected ? selectionColor : calculateColor( depth );
}

export const EarthquakeDinamicMarker = (
  { earthquake, onSelect, moreRecent, defaultColor }
) => {

  const location = useLocation();
  const directory = location.pathname.split( '/' );
  const lat = earthquake.geometry.coordinates[0];
  const lng = earthquake.geometry.coordinates[1];
  const depth = earthquake.geometry.coordinates[2];
  
  const validateDirectory = () => directory[1] === 'detallesismo'
    ? 'detallesismo' : 'detalleevento';

  const color = defineMarkerColor( defaultColor, false, depth );
  
  return (
    <CircleMarker
      center = {[ lat, lng ]}
      fillColor = { moreRecent ? moreRecentColor : color }
      color = "#fff"
      weight = { 2 }
      className = "EQMarkersTest"
      fillOpacity = { 1 }
      radius = { pixelCalculator( Math.floor( earthquake.properties.mag )) }
      eventHandlers={{ click: () => onSelect( earthquake ) }}
      pane = "overlayPane"
    >
      <Popup
        keepInView = { true }
        closeButton = { false }
        autoPan = { true }
      >
        <PopupEarthquakeMarker
          id = { earthquake.id }
          lat = { lat }
          lng = { lng }
          place = { earthquake.properties.place }
          localTime = { earthquake.properties.localTime }
          utcTime = { earthquake.properties.utcTime }
          status = { earthquake.properties.status }
          closerTowns = { earthquake.properties.closerTowns }
          magnitude = { earthquake.properties.mag }
          mmi = { earthquake.properties.mmi }
          depth = { earthquake.geometry.coordinates[2] }
          cdi = { earthquake.properties.cdi }
          maxPGA = { earthquake.properties.maxPGA }
          agency = { earthquake.properties.agency }
          beachBall = { earthquake.properties.beachBall }
          validatorDates = { () => true }
          urlMoreInfo = { `/${ validateDirectory() }/${ earthquake.id }/resumen` }
          urlReport = { `${ REPORT_URL }/EvaluacionIntensidadesServlet?idSismo=${earthquake.id}&metodo=irASismoEspecifico` }
        />
      </Popup>
    </CircleMarker>
  );
};