import { UseModal } from "hooks/UseModal";
import React from "react";
import { LinearProgress, Box, styled } from "@material-ui/core";

const StyledLinear = styled (props => <LinearProgress {...props} />)(
	({ theme }) => ({
		color: '#069169',
		colorPrimary: '#069169'
	})
)

const LoadingOrder = () => (
	<div className="LoadingOrder">
		<Box sx={{ width: '100%' }}>
			<StyledLinear />
			<StyledLinear />
		</Box>
	</div>
)

function LoaderModal({ isLoading }) {
   return (
      <UseModal
         open={ isLoading }
         handleClose={() => ''}
         component={ <LoadingOrder /> }
      />
   )
}
export default LoaderModal;