import React, { useState, useRef, useEffect } from 'react'
import { UseLeafletMap } from 'hooks/UseLeafletMap'
import { saveToStorage } from 'Components/Atoms/A_Query/QueryControl';
import { TensorMarker } from 'Components/Molecules/M_Reusable/TensorMarker';
import { CTensorPopup } from './CTensorPopup';
import TensorArea from 'Components/Atoms/A_Query/TensorArea';
import UseMapLegend from 'hooks/UseMapLegend';
import { REPORT_URL } from 'utils/urls';
import { actualQuery } from 'Components/Atoms/A_Query/QRequest';
import SgcMapTileLayer from 'Components/Atoms/A_Reusable/SgcMapTileLayer';

const validateStorage = ( key, value ) => {
    const temp = JSON.parse( sessionStorage.getItem( key ));
    return temp ? temp : value
}

export const CTensorMap = (
    {  selectedEvent, setSelectItem, activeEvents }
) => {
    const [ popupSelected, setSelected ] = useState();
    const [ popup, setPopup ] = useState( false );
    const [ currentQuery, setCurrentQuery ] = useState(null);
    
    const [ zoom, setZoom ] = useState(
        validateStorage( 'TensorZoom', 5.7 )
    )
    const positionRef = useRef(
        validateStorage( 'TensorPosition', [ 5.6, -72.9 ] )
    );
    
    const validateEvent = id => selectedEvent && id === selectedEvent.id;

    const formatEvent = event =>(
        {
            id: event.id,
            coordinates: [event.geometry.coordinates[0], event.geometry.coordinates[1]],
            place: event.properties.place,
            lat: event.geometry.coordinates[0],
            lng: event.geometry.coordinates[1],
            depth: event.geometry.coordinates[2],
            localTime: event.properties.localTime,
            utcTime: event.properties.utcTime,
            status: event.properties.status,
            closerTowns: event.properties.closerTowns,
            magnitude: event.properties.mag,
            mmi: event.properties.mmi,
            cdi: event.properties.cdi,
            maxPGA: event.properties.maxPGA,
            agency: event.properties.agency,
            beachBall: event.properties.beachBall,
            urlMoreInfo: `/detalleevento/${ event.id }/resumen`,
            urlReport: `${ REPORT_URL }/EvaluacionIntensidadesServlet?idSismo=${ event.id }&metodo=irASismoEspecifico`,
        }
    )

    const handlePopup = ( event, popup ) => {
        if ( event ) {
            positionRef.current = [
                event.geometry.coordinates[0], event.geometry.coordinates[1]
            ]
            saveToStorage(
                'TensorPosition', JSON.stringify( positionRef.current )
            );
            setSelectItem( event );
        }
        setPopup( popup );
        setSelected(true);
    }

    const onZoomChange = ({ target }) => {
        saveToStorage( 'TensorZoom', target._zoom );
        setZoom( target._zoom )
    }

    useEffect(() => {
        setSelected(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ selectedEvent ]);

    useEffect(()=>{ // to update the query state of the components
      const currentQuery = actualQuery();
      setCurrentQuery( currentQuery );
    }, [ activeEvents ])

    return (
        <>
            <UseLeafletMap
                id = "leafletMapId"
                position = { positionRef.current }
                zoom = { zoom }
                mapOptions = {{ maxZoom: 13, minZoom: 4 }}
                zoomSnap={ false }
                bodyMap = {(
                    <>
                        <TensorArea filter = { currentQuery } />
                        {
                            activeEvents.map(( event, index ) => (
                                <TensorMarker
                                    key = { `${ event.id }_${ index }` }
                                    event = { event }
                                    isSelected = { validateEvent( event.id ) }
                                    onSelect = { handlePopup }
                                />
                            ))
                        }
                        <SgcMapTileLayer />
                        {
                            popupSelected ? (
                                <CTensorPopup event = { popup } />
                            ) : 
                            selectedEvent ? (
                                <CTensorPopup event = { formatEvent(selectedEvent) } />
                            ) : <></>
                        }
                    </>
                )}
                onzoomend = { onZoomChange }
                zoomControl
                dragging = { true }
                scrollWheelZoom = { true }
            />
            <UseMapLegend align="end" />
        </>
    )
}
