import React from 'react';
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import BuildIcons from 'Components/Atoms/A_Reusable/BuildIcons';
import Tooltip from "@material-ui/core/Tooltip";

/**
 * 
 * @param {data} columns 
 * @returns 
 * * Tiempo de Origen
 * Magnitud,
 * profundidad
 * Latitud
 * longitud
 * región
 * Detalles
 */

export const EQShTable = ({ data }) => {

    const { BuildMmiIcons, BuildCdiIcons } = BuildIcons;
    const columns = [
        {
            name: "originTime",
            label: "Tiempo de Origen",
            options: {
                filter: true,
                sort: true,
                viewColumns:true
            }
        },
        {
            name: "mag",
            label: "Magnitud",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "depth",
            label: "Profundidad(km)",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "lat",
            label: "Latitud(°)",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "lng",
            label: "Longitud(°)",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "region",
            label: "Región",
            options: {
                filter: true,
                sort: true
            } 
        },
        {
            name: "mmi",
            label: "Intensidad Instrumental",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "cdi",
            label: "Intensidad Percibida",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "details",
            label: "Detalles",
            options: {
                filter: false,
                sort: false
            }
        },
    ];
    
    const info = data.map( element => {
        return {
            originTime: element.properties.localTime,
            mag: element.properties.mag,
            depth: element.geometry.coordinates[2],
            lat: parseFloat( element.geometry.coordinates[0]).toFixed(2 ),
            lng: parseFloat( element.geometry.coordinates[1]).toFixed(2 ),
            region: element.properties.place,
            mmi: element.properties.mmi,
            cdi: element.properties.cdi,
            details: <Link to = { `/detallesismo/${ element.id }/resumen` }>Ver</Link>
        }
    })
    // 'dropdown' | 'checkbox' | 'multiselect' | 'textField' | 'custom'
    const options = {
        filter: true,
        selectableRows: 'none',
        responsive: "standard",
        fixedSelectColumn: true,
        fixedHeader: true,
        customRowRender: ( data, index ) => {
            const size = data.length
            data[ size - 2 ] = BuildCdiIcons( data[ size - 2 ] )
            data[ size - 3 ] = BuildMmiIcons( data[ size - 3 ] )
            return (
                <tr key = { `row${ index }` } id = { `row${ index }` }>
                    {
                        data.map(( element, key ) => {
                            return(
                                element && element.data ? (
                                    <td
                                        className = "shCell"
                                        key = { `MUItd${ key }` }
                                        id = { `MUItd${ key }` }
                                    >
                                        <Tooltip
                                            title = { element.alt || '' }
                                        >
                                            <img
                                                src = { element.img }
                                                alt = { element.alt }
                                            />
                                        </Tooltip>
                                    </td>
                                ) : (
                                    <td
                                        className = "shCell"
                                        key = { `MUItd${ key }` }
                                        id = { `MUItd${ key }` }
                                    >
                                        { element }
                                    </td>
                                )
                            )
                        })
                    }
                </tr>
            )
        },
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros coincidentes",
                toolTip: "Ordenar",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Sig. Pag.",
                previous: "Ant. Pag",
                rowsPerPage: "Filas por pag.:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "REINICIAR",
            },
            viewColumns: {
                title: "Mostar Columnas",
                titleAria: "Mostar/Ocultar Columnas",
            },
            selectedRows: {
                text: "Fila(s) seleccionadas",
                delete: "Eliminar",
                deleteAria: "Eliminar Filas Seleccionadas",
            },
        },
        filterType: 'multiselect',
        print: false,
        download: true
    };
    return(
        <div className = "EQShTable">
            <MUIDataTable
                title = ""
                data = { info }
                columns = { columns }
                options = { options }
            />
        </div>
    )
}
