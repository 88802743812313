import React from 'react';
import {connect} from 'react-redux';
import EQmfContent from 'Components/Molecules/M_EQDetails/EQmfContent';


const EQmf = ({ event }) => (
    <div className = "EQmf">
        <div className = "mf-container-main">
            <EQmfContent
                focalmechanism = { event.properties.products.focalmechanism }  
            />
        </div>
    </div>
)

const mapStateToProps = (state) => ({
    event: state.detailEvent[0]
});
export default connect( mapStateToProps )( EQmf );