let stateSlider = {};
let container = null;
let imgs = null;
let modalImg = null;
let prevIcon = null;

/**
 * Slider funtions 
 * @param {string} imgs_class class id for the slider element
 */
const initSlider = imgs_class => {
    imgs = document.getElementsByClassName(imgs_class)
    stateSlider = {
        count: 1,
        backSlider: []
    }
}
const nextSlider = () => {

    let width = imgs[0].clientWidth;
    let move_position = '';
    if ( stateSlider.count === imgs.length-1 ) {
        move_position = 'translate(0px)';
        stateSlider.backSlider = [];
        stateSlider.count = 1;
    }
    else {
        stateSlider.backSlider.push(imgs[0].style.transform);
        move_position = `translate(-${stateSlider.count*width}px)`;
        stateSlider.count++;
    }
    for (let img of imgs){
        img.style.transform = move_position;
    }

}
const prevSlider = () =>{
    if ( stateSlider.backSlider.length > 0 ){
        stateSlider.count--;
        for ( let img of imgs ) {
            img.style.transform = stateSlider.backSlider[stateSlider.backSlider.length - 1];
        }
        stateSlider.backSlider.pop()
    }
}
/**
 * modal functions
 */


const restartModal = () => {
    stateSlider = {
        count: 1,
        backSlider: []
    }
    modalImg.style.visibility = 'visible';
    container.style.transform = 'translate(0px)';
    prevIcon.className = 'PrevInvisibleModal';
}
const initModal = ( container_id, imgs_class, modal_class, prevIcon_class ) => {
    container = document.getElementById(container_id)
    imgs = document.getElementsByClassName(imgs_class)
    modalImg = document.getElementById(modal_class);
    prevIcon = document.getElementById(prevIcon_class);
    restartModal()
}

const handleNext = () => {
    if ( imgs[0] ) {
        const width = imgs[0].clientWidth;
        if ( stateSlider.count === imgs.length ){
            stateSlider.count = 1;
            container.style.transform = 'translate(0px)';
            prevIcon.className = 'PrevInvisibleModal'
            stateSlider.backSlider = [];
        }
        else {
            stateSlider.backSlider.push(container.style.transform);
            container.style.transform = `translate(-${width*stateSlider.count}px)`;
            stateSlider.count++;
            prevIcon.className = 'PrevVisibleModal';
        }
        container.style.transition = "transform .7s"
    }
}

const handlePrev = () => {
    if ( stateSlider.backSlider.length === 0 && prevIcon ){
        prevIcon.className = "PrevInvisibleModal";
    }
    else {
        container.style.transform = stateSlider.backSlider[stateSlider.backSlider.length-1];
        stateSlider.backSlider.pop()
        prevIcon.className = stateSlider.backSlider.length === 0 ? 'PrevInvisibleModal' : 'PrevVisibleModal';
    }
}
const closeModal = () => {
    restartModal()
    modalImg.style.visibility = 'hidden';
}

const InitModal = { initModal, handlePrev, handleNext, closeModal, initSlider, nextSlider, prevSlider }
export default InitModal;