import MUIDataTable from "mui-datatables";

export const EQHmTable = ( { info } ) => {
    
    const columns = [
        {
            name:'lastModified',
            label:'Última modificación',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name:'localTime',
            label:'Hora Local',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name:'status',
            label:'Estado',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name:'latitude',
            label:'Latitud(°)',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name:'longitude',
            label:'Longitud(°)',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name:'depth',
            label:'Profundidad(km)',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name:'magnitude',
            label: 'Magnitud',
            options: {
                filter: true,
                sort:true
            }
        },
        {
            name:'magnitudeType',
            label:'Tipo de Magnitud',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name:'numStationsUsed',
            label:'Num. Estaciones',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name:'numPhasesUsed',
            label:'Num. Fases',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name:'azimuthalGap',
            label:'Gap(º)',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name:'locateMethod',
            label:'Método de Localización',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name:'localPublishTime',
            label:'Tiempo de Publicación ',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'gds',
            options: {
                display:'false',
                filter: false,
                viewColumns: false,
                sort: false
            }
        }
    ]
    const data = info.map((element)=>{ 
        return {
            lastModified: element.lastModified,
            localTime: element.localTime,
            status: element.status,
            latitude: element.latitude,
            longitude: element.longitude,
            depth: element.depth,
            magnitude: element.magnitude,
            magnitudeType: element.magnitudeType,
            numStationsUsed: element.numStationsUsed,
            numPhasesUsed: element.numPhasesUsed,
            azimuthalGap: element.azimuthalGap,
            locateMethod: element.locateMethod,
            localPublishTime: element.localPublishTime,
            gds: element.gds
        }
    })
    data.reverse()
    const options = {
        filter: true,
        selectableRows: 'none',
        responsive: "standard",
        fixedSelectColumn: true,
        fixedHeader: true,
        customRowRender: ( data, index ) => {
            return (
                <tr
                    key = { `key${ index }` }
                    className = {
                        data[ data.length - 1 ] === true
                        ? 'HmGds' : 'HmTableRow'
                    }
                >
                    { data.pop() }
                    {
                        data.map(( element, key ) => {
                            return(
                                <td
                                    key = { `MUItd${ key }` }
                                    id = { `MUItd${ key }`}
                                >
                                    { element }
                                </td>
                            )
                        })
                    }
                </tr>
            )
        },
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros coincidentes",
                toolTip: "Ordenar",
                columnHeaderTooltip: column => `Ordenar por ${ column.label }`
            },
            pagination: {
                next: "Sig. Pag.",
                previous: "Ant. Pag",
                rowsPerPage: "Filas por pag.:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "REINICIAR",
            },
            viewColumns: {
                title: "Mostar Columnas",
                titleAria: "Mostar/Ocultar Columnas",
            },
            selectedRows: {
                text: "Fila(s) seleccionadas",
                delete: "Eliminar",
                deleteAria: "Eliminar Filas Seleccionadas",
            },
        },
        // responsive: 'vertical',
        sortOrder: {
          name: 'lastModified',
          direction: 'desc'
        },
        filterType: 'multiselect',
        print: false,
        download: false
    };
    return (
        <div className = "HistoricTable">
            <MUIDataTable
                title = ""
                columns = { columns }
                data = { data }
                options = { options }
            />
            <div className = "EQHisFtTable">
                <div/>
                <span>localización publicada en redes sociales</span>
            </div>
        </div>
    )
}
/**
 * change size in the table have to take the length of the array od columns
 */
