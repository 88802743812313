import { useRef } from 'react';
import L from 'leaflet';

export const LeafControls = () => {

    const vector_layer = useRef( null );

    const createCircle = ( coordinates, options, controls ) => {
        vector_layer.current = L.circle( coordinates, options );
        controls?.forEach( element => {
            vector_layer.current.on (
                element.key,
                event => element.action( event, vector_layer.current )
            )
        });
        return vector_layer.current;
    }

    const display_popup = ( layer, content, latlng ) => {
        layer.bindPopup( content );
        layer.openPopup( latlng );
    }
    
    const scalingRectangle = controls => {
        vector_layer.current.transform.enable (
            { rotation: false, scaling: true }
        );
        controls.forEach( element => {
            vector_layer.current.on(
                element.key,
                event => element.action( event, vector_layer.current )
            )
        });
    }

    const createRectangle = ( bounds, options ) => {
        const southWest = L.latLng( bounds[0], bounds[1] )
        const northEast = L.latLng( bounds[2], bounds[3] );
        const rectangleBounds = L.latLngBounds( southWest, northEast );
        vector_layer.current = L.rectangle(
            rectangleBounds,
            options 
        );
        return vector_layer.current;
    }

    const setRadius = radius => vector_layer.current &&
        vector_layer.current.setRadius( radius );
        
   const setCirclePosition = ( position ) => vector_layer.current &&
   vector_layer.current.setLatLng( position );
  
   const setRectanglePosition = ( southWest, northEast ) => {
      if (vector_layer.current) {
         vector_layer.current.setBounds( L.latLngBounds( southWest, northEast ) );
         vector_layer.current.transform.updateRect();
      }
   }
  
    return {
        createCircle,createRectangle,
        display_popup, setRadius,
        scalingRectangle, setCirclePosition, setRectanglePosition
      }
  }
  
