import React, { Fragment } from "react";
import { Popover } from "@material-ui/core";
import MapLegend from "Components/Molecules/M_Reusable/MapLegend";
import AgePointsScale from 'Components/Molecules/M_Reusable/AgePointsScale';
import EarthquakePointsScale from 'Components/Molecules/M_Reusable/EarthquakePointsScale';
import Epicenter from "assets/icons/EQViewer/PerceivedIntensity/Epicenter.svg";

const EQShPop = ( { popup, handleCloseLegend, handleLegend, anchorLegend, legend } ) => {

    return(
        <Fragment>
            <div className="ShLegendContainer">
                <button onClick={handleLegend} className="ShLegend">
                    {legend}
                </button>
            </div>
            <Popover
                className="shLegend"
                open={popup}
                onClose={handleCloseLegend}
                anchorEl={anchorLegend}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                <MapLegend 
                    AgePointsScale={<AgePointsScale/>}
                    EarthquakePointsScale={<EarthquakePointsScale/>}
                    StarEpicenter={<div className="StarEpicenter"><span>Epicentro</span><img src={Epicenter} alt="epicentro"></img></div>}
                />
            </Popover>
        </Fragment>
    )
}

export default EQShPop;