import React, { useEffect, useRef } from 'react';
import { openPopup, closePopup } from './SGCPopupActions';

export const SGCPopup = ({ Component, id, styles = null, trigger }) => {

    const containerRef = useRef( null );
    const triggerRef = useRef( false );
    const eventRef = useRef( true );

    const handleOpen = () => {
        !eventRef.current
            ? openPopup( id, trigger ) : closePopup( id, trigger )
    }

    const handleClickOutsite = event => {
        if ( triggerRef.current.contains( event.target ) ) {
            eventRef.current = !eventRef.current;
        }
        else if( !containerRef.current.contains( event.target ) ) {
            closePopup ( id,trigger );
            eventRef.current = true;
        }
    }

    const initialState = () => {
        window.addEventListener( 'mousedown', handleClickOutsite );
        triggerRef.current = document.querySelector(`#${ trigger.id }`);
        triggerRef.current?.addEventListener( 'click', handleOpen );
    }
    
    useEffect(() => {
        initialState();
        return () => {
            window.removeEventListener( 'mousedown', handleClickOutsite );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div
            id = { id } ref = { containerRef }
            className = "Services_hidden" style = { styles }
        >
            { Component }
        </div>
    )
}
