import React, { useRef } from 'react';
import 'date-fns';
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { getQuery } from 'Components/Atoms/A_Query/QActive';
import { initStartDefault } from 'Components/Atoms/A_Query/QInitState';
import { initEndDefault } from 'Components/Atoms/A_Query/QInitState';
import { UseTooltip } from 'hooks/UseTooltip';

export const QTime = ({ tooltips }) => {

    const startMessage = useRef([
        "La fecha inicial debe ser mayor a 01/16/1644",
        "La fecha inicial debe ser menor a la final",
        "La fecha debe estar en formato MM/DD/AAAA HH:MM:SS",
    ]);
    const endMessage = useRef([
        "La fecha final debe ser menor a la fecha actual",
        "La fecha final debe ser mayor a la inicial",
        "La fecha debe estar en formato MM/DD/AAAA HH:MM:SS",
    ]);

    const {
        startTime, endTime, handleTime, updateWithErrors,
        updateWithErrorsDouble
    } = getQuery();

    const maxMinValidation = ( max, min, triggerError ) => {
        // if the condition is false the error event is trigger
        const validate = max > min;
        !validate && triggerError();
        return validate;
    }

    const startTimeUpdate = value => updateWithErrors( 'startTime', value, value.message );

    const endTimeUpdate = value => updateWithErrors( 'endTime', value, value.message );

    const doubleUpdate = ( start, end ) => {
        updateWithErrorsDouble(
            'startTime', 'endTime',
            start, end,
            start.message, end.message
        );
    }

    const updateToStorage = ( key, value ) => {
        handleTime ( key === "startTime" ? "min": "max", key, value );
    }

    const validateStartTime = ( endDate, updateDate ) => {
        endDate = new Date( endDate ).getTime();
        updateDate = new Date( updateDate ).getTime();
        const minDate = initStartDefault.getTime() - 0.1;

        return maxMinValidation (
            updateDate, minDate, () => startTimeUpdate ({
                data: startTime.data, validate: true,
                message: startMessage.current[0]
            })
        ) && maxMinValidation (
            endDate, updateDate, () => doubleUpdate (
                {
                    data: new Date(updateDate), validate: true,
                    message: startMessage.current[1]
                }, {
                    data: endTime.data, validate: true,
                    message: endMessage.current[1]
                }
            )
        );
    }

    const validateEndTime = ( startDate, updateDate ) => {
        updateDate = new Date( updateDate ).getTime();
        startDate = new Date( startDate ).getTime();
        const maxDate = initEndDefault.getTime() + 0.1;
        
        return maxMinValidation (
            maxDate, updateDate, () => endTimeUpdate ({
                data: endTime.data, validate: true,
                message: endMessage.current[0]
            })
        ) && maxMinValidation (
            updateDate, startDate, () => doubleUpdate (
                {
                    data: startTime.data, validate: true,
                    message: startMessage.current[1]
                }, {
                    data: new Date( updateDate ), validate: true,
                    message: endMessage.current[1]
                }
            )
        );
    }

    const dateIsNotValid = ( start ) => {
        if ( start ){
            startTimeUpdate ({
                data: startTime.data, validate: true,
                message: startMessage.current[2]
            });
        } else {
            endTimeUpdate ({
                data: endTime.data, validate: true,
                message: endMessage.current[2]
            });
        }
    }
    
    return (
         <div className = "Qselections">
            <MuiPickersUtilsProvider
               utils = { DateFnsUtils }
               locale = { esLocale }
            >
               <div className = "Qselector selector1" id = "Qstarttime">
                  <div className = "Qselector_input QTime">
                     <UseTooltip
                           title = { tooltips.initialDate }
                           classes = "spaceBottom_tooltip"
                     />
                           <KeyboardDateTimePicker
                              openTo = "year"
                              views = {[ "year", "month", "date" ]}
                              error = { startTime.validate }
                              helperText = { startTime.message }
                              autoOk
                              id = "starttime_input"
                              variant = "inline"
                              inputVariant = "outlined"
                              format = "MM/dd/yyyy HH:mm:ss"
                              placeholder = "MM/DD/YYYY HH:MM:SSS"
                              value = { startTime.data }
                              onChange = {
                                 date => {
                                       if ( isNaN( new Date( date ) )) {
                                          dateIsNotValid(true)
                                       } else {
                                          validateStartTime (
                                             endTime.data, date
                                          ) && updateToStorage (
                                             'startTime', {
                                                   data: date,
                                                   validate: false,
                                                   message: ""
                                             }
                                          );
                                       }
                                 }
                              }
                              minDate = { initStartDefault }
                              maxDate = { initEndDefault }
                              label = "Fecha Inicial"
                           />
                  </div>
               </div>
               <div className = "Qselector" id = "Qendtime">
                  <div className = "Qselector_input QTime">
                     <UseTooltip
                           title = { tooltips.endDate }
                           classes = "spaceBottom_tooltip"
                     />
                           <KeyboardDateTimePicker
                              openTo = "year"
                              views = {[ "year", "month", "date" ]}
                              error = { endTime.validate }
                              helperText = { endTime.message }
                              autoOk
                              id = "endtime_input"
                              variant = "inline"
                              inputVariant = "outlined"
                              format = "MM/dd/yyyy HH:mm:ss"
                              value = { endTime.data }
                              onChange = {
                                 date => {
                                       if ( isNaN( new Date( date ) )) {
                                          dateIsNotValid(false);
                                       } else {
                                          const validation = validateEndTime (
                                             startTime.data,
                                             date
                                          );
                                          validation && updateToStorage (
                                             'endTime', {
                                                   data: date,
                                                   validate: false,
                                                   message: ""
                                             }
                                          );
                                       }
                                 }
                              }
                              minDate = { initStartDefault }
                              maxDate = { initEndDefault }
                              label = "Fecha Final"
                           />
                  </div>
               </div>
            </MuiPickersUtilsProvider>
         </div>

    )
}