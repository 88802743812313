import React from 'react';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core';
import { GREEN } from 'utils/styles';

const SgcRange = withStyles({
    root: {
        color: GREEN,
        height: 5,
        marginTop: '40px',
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#ffffff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
          boxShadow: 'inherit',
        },
      },
      active: {},
      valueLabel: {
        left: 'calc(-50% + 4px)',
        fontSize: '9px',
      },
      track: {
        height: 6,
        borderRadius: 4,
      },
      rail: {
        height: 6,
        borderRadius: 4,
      },
      markLabel: {
        fontSize: '10px',
        marginTop: 5,
      },
})( Slider );

export const UseRange = ({ title, value, handleRange, min, max, formatter }) => {

    const defaultFormatterValue = (value) => (`${Math.round(value / 1000)}km`);

    const formatterValue = formatter || defaultFormatterValue;

    return (
        <div style={{ paddingRight: 8, paddingLeft: 8 }}>
            <Typography
                id = "non-linear-slider"
                gutterBottom
            >
                { title }
            </Typography>
            <SgcRange
                value = { value }
                min = { min }
                max = { max }
                onChange = {
                    ( _, newValue ) => { handleRange( newValue ) }
                }
                valueLabelDisplay = "on"
                valueLabelFormat = { formatterValue(value) }
                aria-labelledby = "non-linear-slider"
                marks={[
                  {value: min, label: formatterValue(min)},
                  {value: max, label: formatterValue(max)},
                ]}
            />
        </div>
    )
}
