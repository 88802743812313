import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Tooltip } from '@material-ui/core';
import { useVolcanoLevels } from 'hooks/useVolcanoLevels';

const useStyles = makeStyles((theme) => ({
    grid: {
        paddingTop: "10px",
        paddingBottom: "10px",
        cursor: "default",
        backgroundColor: "#fafafa"
    },
    itemContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        paddingTop: "2px",
        paddingBottom: "2px"
    },
    level: {
        marginLeft: "1px",
        marginRight: "1px",
        marginBottom: "0px",
        marginTop: "0px",
        fontSize: "70%"
    },
    title: {
        marginLeft: "10px",
        marginRight: "5px",
        marginBottom: "2px",
        marginTop: "5px",
        fontSize: "80%",
        fontWeight: "bold"
    }
}));

const VolcanoIconScale = () => {

    const styles = useStyles();
    const { volcanoLevels } = useVolcanoLevels();

    return (
        <Fragment>
            <p className={styles.title}>Estados de Alerta volcánica</p>
            <Grid className={styles.grid} container justifyContent="center">
                {
                    volcanoLevels?.slice().reverse().map( level =>(
                        <Grid item key={level.index_number} xs={3} md={2} lg={2}>
                           <Tooltip title={level.short_description}>
                              <Container>
                                 <div className={styles.itemContainer}>
                                       <img src={level.icon} alt='icon' className="legendVolcanoIcon" />
                                       <p className={styles.level}>{level.index_text}</p>
                                 </div>
                              </Container>
                           </Tooltip>
                        </Grid>
                    ))
                }
            </Grid>
        </Fragment>
    );

};

export default VolcanoIconScale;