import React, { useEffect, useRef } from 'react';
import { defineMarkerColor, moreRecentColor } from './EarthquakeMarker';
import { CircleMarker, Popup } from 'react-leaflet';
import { pixelCalculator } from 'Components/Atoms/A_Reusable/CalculatorPixelRadiusForEQ';
import { onlyTensorIcon } from 'Components/Organisms/O_Query/TableFields';
import TensorIcon from "assets/icons/Query/pelotadeplaya_vacia-01.svg";
import { eventDetailsUrl } from 'Components/Molecules/M_Query/QEvents';
import { useState } from 'react';
import EarthquakePopup from 'Components/Organisms/O_Reusable/EarthquakePopup';

const build_class = color => {
	switch (color) {
		case "#FF0000":
			return "markerRed"
		case "#EBEB00":
			return "markerYellow"
		case "#00FF00":
			return "markerGreen"
		case "#0000FF":
			return "markerBlue"
		case "#C800F6":
			return "markerMagenta"
		default:
			return;
	}
}

export const SelectedCatalogMarker = ({ earthquake, onSelect, hiddenCdi = false, isCatalog = undefined }) => {

	const markerRef = useRef();
	const [ tensorImg, setImg ] = useState(
		<img src = { TensorIcon } alt = "properties.beachBall" />
	)
	const lat = earthquake.geometry.coordinates[0];
	const lng = earthquake.geometry.coordinates[1];
	const depth = earthquake.geometry.coordinates[2];
	const {
		place, localTime, utcTime,
		status, closerTowns, mmi,
		cdi, maxPGA, beachBall,
		mag, agency
	} = earthquake.properties;
	const color = defineMarkerColor( null, true, depth );

	const buildTensorIcon = async () => {
		if (earthquake.properties.beachBall !== "" &&
			earthquake.properties.beachBall !== null) {
			setImg(await onlyTensorIcon(earthquake));
		}
	}

	useEffect(() => {
		earthquake && buildTensorIcon();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ earthquake ]);

	useEffect(() => {
		return () => onSelect( null )
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		markerRef.current && markerRef.current.openPopup();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [earthquake.id, markerRef.current]);

	return (
		<CircleMarker
			center={[lat, lng]}
			fillColor={moreRecentColor}
			color="#fff"
			weight={2}
			className = { `EQMarkersTest ${ build_class( color ) } recent_marker`}
			fillOpacity={1}
			radius = { pixelCalculator( Math.floor( mag )) }
			pane="markerPane"
			ref={markerRef}
		>
			<Popup
				keepInView={true}
				closeButton={false}
				autoPan={false}
			>
				<EarthquakePopup
					id={earthquake.id}
					lat={lat}
					lng={lng}
					place={place}
					localTime={localTime}
					utcTime={utcTime}
					status={status}
					closerTowns={closerTowns}
					magnitude={mag}
					mmi={mmi}
					depth={depth}
					cdi={cdi}
					hiddenCdi={hiddenCdi}
					maxPGA={maxPGA}
					agency={agency}
					beachBall={beachBall}
					buildBeachBall={tensorImg}
					urlMoreInfo={eventDetailsUrl(earthquake.id, isCatalog)}
				/>
			</Popup>
		</CircleMarker>
	)
};
