export const MUIOptionsESP = {
    filter: true,
    selectableRows: "none",
    responsive: "standard",
    fixedSelectColumn: true,
    fixedHeader: true,
    filterType: "multiselect",
    textLabels: {
        body: {
            noMatch: "Lo sentimos, no se encontraron registros coincidentes",
            toolTip: "Ordenar",
            columnHeaderTooltip: column => `Ordenar por ${ column.label }`,
        },
        pagination: {
            next: "Sig. Pag.",
            previous: "Ant. Pag",
            rowsPerPage: "Filas por pag.:",
            displayRows: "de",
        },
        toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Columnas",
            filterTable: "Filtrar Tabla",
        },
        filter: {
            all: "Todos",
            title: "FILTROS",
            reset: "REINICIAR",
        },
        viewColumns: {
            title: "Mostrar Columnas",
            titleAria: "Mostrar/Ocultar Columnas",
        },
        selectedRows: {
            text: "Fila(s) seleccionadas",
            delete: "Eliminar",
            deleteAria: "Eliminar Filas Seleccionadas",
        }
    }
}