import React from 'react';

const BuildMagnitude = ({ mag, felt }) => {

  if ( felt > 0 && mag < 4 ) { // case 1
    return (
      <div>
        <h2 className = "indicators-text">
          <b>{ mag }</b>
        </h2>
        <h3 className = "measures-text">M</h3>
      </div>     
    )
  } else if ( mag >= 5 ) { // case 2
    return (
      <div>
        <h2 className = "indicators-text" style = {{ color: "#cc0000" }}>
          <b>{ mag }</b>
        </h2>
        <h3 className="measures-text">M</h3>
      </div>
    )
  }
  else if ( mag >= 4 ) { // case 3
    return (
      <div>
        <h2 className = "indicators-text">
          <b>{ mag }</b>
        </h2>
        <h3 className = "measures-text">M</h3>
      </div>
    )
  }
  return ( // default case
    <div>
      <h2 className = "indicators-text">{ mag }</h2>
      <h3 className = "measures-text">M</h3>
    </div>
  )
}

export default BuildMagnitude;