import HomeIcon from 'assets/icons/NotFound/HomeIcon.svg'
import SismoIcon from 'assets/icons/NotFound/SismoIcon.svg'
import VolcanoIcon from 'assets/icons/NotFound/VolcanoIcon.svg'
import Desktop404 from 'assets/icons/NotFound/Desktop404.png'
import { Link } from 'react-router-dom';
import Loader from "./Loader";
import HomeFooter from "Components/Templates/T_Home/HomeFooter";
import LegalHeader from "Components/Atoms/A_Home/LegalHeader";

//Pagina no encontrada, en caso de que el usuario ingrese una ruta incorrecta
const redirect = location => {
  document.location.href = `https://www2.sgc.gov.co${ location && location.pathname ? location.pathname : '/notFound' }`;
  return <Loader />
}
const NotFound = ({ notFoundById, location }) => (
  notFoundById && notFoundById === true ? (
    <>
      <LegalHeader />
      <div className="page-404">
        <div className="menu-404">
          <div className="title-menu-404">
            <p>Página no encontrada</p>
          </div>
          <div className="content-menu-404">
            <div className="lines-404">
              <hr></hr>
              <hr></hr>
              <hr></hr>
            </div>
            <div className="question-404">
              <p>¿A dónde  quieres ir?</p>
            </div>
            <div className="options-menu-404">
              <div className="home-option-404">
                <Link to = "/">
                  <img src = { HomeIcon } alt = ""/>
                  <p>Home</p>
                </Link>
                
              </div>
              <div className="sismos-option-404">
                <Link to = "/sismos">
                  <img src = { SismoIcon } alt = ""/>
                  <p>Sismos</p>
                </Link>
              </div>
              <div className="volcanos-option-404">
                <Link to = "/volcanes">
                  <img src = { VolcanoIcon } alt = ""/>
                  <p>Volcanes</p>
                </Link>
              </div>
            </div>
          </div>

        </div>
        <div className="not-404">
          <div className="bg-404">
            <img src = { Desktop404 } alt = ""/>
          </div>
          <div className="info-404">
            <p>¡LA PÁGINA QUE BUSCAS NO EXISTE!</p>
          </div>
        </div>
        <div className="menu-404-tablet" style={{ padding: "15px 0" }}>
          <div className="question-404-tablet">
            <p>¿A donde quieres ir?</p>
          </div>
          <div className="options-menu-404">
            <div className="home-option-404-tablet">
              <Link to = "/">
                <img src = { HomeIcon } alt = ""/>
                <p>Home</p>
              </Link>
              
            </div>
            <div className="sismos-option-404-tablet">
              <Link to = "/sismos">
                <img src = { SismoIcon } alt = ""/>
                <p>Sismos</p>
              </Link>
            </div>
            <div className="volcanos-option-404-tablet">
              <Link to = "/volcanes">
                <img src = { VolcanoIcon } alt = ""/>
                <p>Volcanes</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className = "HmFooter">
        <HomeFooter />
      </div>
    </>
  ) : redirect( location )
);

export default NotFound;