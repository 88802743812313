import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import { GREEN } from 'utils/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        padding: "0px 0px 0px 0px",
        borderBottom: "0.5px solid #fff"
    },
    base: {
        padding: "0px 0px 0px 0px",
        width: "100%"
    },
    label: {
        fontSize: "72%",
        color: "#333",
        textAlign: "left",
        marginRight: "auto"
    },
    labelContainer: {
        marginLeft: "0px",
        marginRight: "0px",
        paddingLeft: "20px",
        paddingRight: "10px",
        width: "100%",
        borderTop: "0.5px solid #fff",
        borderBottom: "0.5px solid #fff"
    }
}));

const CustomSwitch = withStyles({
    switchBase: {
        color: "#ececec",
        '&$checked': {
            color: GREEN,
        },
        '&$checked + $track': {
            backgroundColor: GREEN,
        }
    },
    checked: {},
    track: {},
})(Switch);


const SwitchesList = ({
    items,
    labelValue, 
    label_switch, 
    initializerComponent,
    onChange,
    required
}) => {
    if ( required && initializerComponent ) {
        items = initializerComponent( items );
    }
    const styles = useStyles();
    return (
        <div className = { styles.root }>
            {
                items && (
                    <FormGroup className = { styles.base }>
                        {
                            items.map(( item, index ) => {
                                return (
                                    <FormControlLabel
                                        key = { index }
                                        classes = {{
                                            root: styles.labelContainer,
                                            label: styles.label
                                        }}
                                        control = {
                                            <CustomSwitch
                                                checked = { item.selected }
                                                name = { item[ labelValue ] }
                                                onChange = {
                                                    () => onChange( item )
                                                }
                                            />
                                        }
                                        label = { label_switch( item[ labelValue ], item.icon, item.text_value ) }
                                        labelPlacement = "start"
                                    />
                                )
                            })
                        }
                    </FormGroup>
                )
            }
        </div>
    )
};

export default SwitchesList;
