import React from 'react';
import MUIDataTable from 'mui-datatables';
// import BuildLevelVolcano from '../M_Reusable/BuildLevelVolcano';
import { MUIOptionsESP } from 'Components/Templates/MUITables';

export const VolcanoTable = ({ properties }) => {

  const bulletinsData = properties.bulletins.map( bulletin => {
    return {
      name: bulletin.name,
      type: bulletin.type,
      date: bulletin.update,
      level: properties.activityLevel,
      file: bulletin.file
    }
  }).reverse()

  return (
   <div className="SgcMUIDataTable">
      <MUIDataTable
        data = { bulletinsData }
        columns = {[
          {
            name: "name",
            label: "Nombre",
            options: {
              filter: false,
              sort: true,
              customBodyRender: ( value, tableMeta ) => {
                return (
                  <a href = { bulletinsData[ tableMeta.rowIndex ].file } download>
                    { value }
                  </a>
                )
              }
            }
          },
          {
            name: "type",
            label: "Tipo de Boletín",
            options: {
              filter: true,
              sort: true,
            }
          },
          {
            name: "date",
            label: "Fecha de Publicación",
            options: {
              filter: true,
              sort: true
            }
          },
         //  {
         //    name: "level",
         //    label: "Estado de Alerta",
         //    options: {
         //      filter: true,
         //      sort: true,
         //      customBodyRender: () => {
         //        return (
         //            <BuildLevelVolcano
         //              level = { properties.activityLevel }
         //            />
         //        )
         //      },
         //      setCellHeaderProps: () => ({ style: { minWidth: "120px", } })
         //    }
         //  }
        ]}
        options = { MUIOptionsESP }
      />
   </div>
  );
  
}