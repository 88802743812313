import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { GREEN } from 'utils/styles';

const SGCSwitch = withStyles({
    switchBase: {
      color: GREEN,
      '&$checked': {
        color: GREEN,
      },
      '&$checked + $track': {
        backgroundColor: GREEN,
      },
    },
    checked: {},
    track: {},
  })( Switch );

export const UseSwitch = (
    { checked, onChange, inputProps, name = 'sgc' }
) => (
    <SGCSwitch
        checked = { checked }
        onChange = { onChange }
        name = { name }
        inputProps = {
          inputProps || { 'arial-label': 'secondary checkbox' }
        }
    />  
)