import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import PerceivedTwo from "assets/icons/EQViewer/PerceivedIntensity/PerceivedTwo.svg";
import PerceivedThree from "assets/icons/EQViewer/PerceivedIntensity/PerceivedThree.svg";
import PerceivedFour from "assets/icons/EQViewer/PerceivedIntensity/PerceivedFour.svg";
import PerceivedFive from "assets/icons/EQViewer/PerceivedIntensity/PerceivedFive.svg";
import PerceivedSix from "assets/icons/EQViewer/PerceivedIntensity/PerceivedSix.svg";
import PerceivedSeven from "assets/icons/EQViewer/PerceivedIntensity/PerceivedSeven.svg";
import PerceivedSevenMore from "assets/icons/EQViewer/PerceivedIntensity/PerceivedSevenMore.svg";

const BuildCdi = ({ cdi }) => {

  const highCdi = () => (
    cdi > 7 ? (
      <Tooltip title="Intensidad Percibida – Daño Severo">
        <img
          alt = ""
          className = "dates-icons"
          src = { PerceivedSevenMore }/>
      </Tooltip>
    ) : (
      <Tooltip title="">
        <img
          alt = ""
          style = {{ display: "none" }}
          className = "dates-icons" />
      </Tooltip>
    )
  );

  const cases = {
    case1: (
      <Tooltip title = "">
        <img
          alt = ""
          style = {{ display: "none" }}
          className = "dates-icons"
        />
      </Tooltip>
    ),
    case2: (
      <Tooltip title = "Intensidad Percibida – Apenas Sentido">
        <img
          alt=""
          className = "dates-icons"
          src = { PerceivedTwo }/>
      </Tooltip>
    ),
    case3: (
      <Tooltip title = "Intensidad Percibida – Sentido Levemente">
        <img
          alt = ""
          className = "dates-icons"
          src = { PerceivedThree }/>
      </Tooltip>
    ),
    case4: (
      <Tooltip title = "Intensidad Percibida – Sentido Ampliamente">
        <img
          alt = ""
          className = "dates-icons"
          src = { PerceivedFour }/>
      </Tooltip>
    ),
    case5: (
      <Tooltip title = "Intensidad Percibida – Sentido Fuertemente">
        <img
          alt = ""
          className = "dates-icons"
          src = { PerceivedFive }/>
      </Tooltip>
    ),
    case6: (
      <Tooltip title = "Intensidad Percibida – Daño Leve">
        <img
          alt = ""
          className = "dates-icons"
          src = { PerceivedSix }/>
      </Tooltip>
    ),
    case7: (
      <Tooltip title="Intensidad Percibida – Daño Moderado">
        <img
          alt = ""
          className = "dates-icons"
          src = { PerceivedSeven }/>
      </Tooltip>
    )
  };
  return cases[ `case${ Math.round(cdi) }` ] || highCdi();
}

export default BuildCdi;