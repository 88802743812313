import EQDinamicMap from "Components/Organisms/O_EQDetails/EQDinamicMap";
import EQOverview from "Components/Organisms/O_EQDetails/EQOverview";
import EQOrigin from "Components/Organisms/O_EQDetails/EQOrigin";
import EQmmi from "Components/Organisms/O_EQDetails/EQMmi";
import EQcdi from "Components/Organisms/O_EQDetails/EQCdi";
import EQtm from "Components/Organisms/O_EQDetails/EQtm";
import EQmf from 'Components/Organisms/O_EQDetails/EQmf';
import EQDefault from 'Components/Organisms/O_EQDetails/EQDefault';
import EQNearbyseismos from 'Components/Organisms/O_EQDetails/EQNearbyseismos';
import EQoriginHistory from 'Components/Organisms/O_EQDetails/EQoriginHistory';
import EQDownloads from "./EQDownloads";
import EQsm from './EQsm'
import NotFound from "Components/Pages/NotFound";
import { useEffect } from "react";

const EQContenManager = ({ product, handleNotEvent }) => {

  const renderSelector = () => {
    switch ( product ) {
      case "resumen":
        return <EQOverview handleNotEvent = { handleNotEvent } />
      case "mapa":
        return <EQDinamicMap handleNotEvent = { handleNotEvent } />
      case "origen":
        return <EQOrigin handleNotEvent = { handleNotEvent } />
      case "cdi":
        return <EQcdi handleNotEvent = { handleNotEvent } />
      case "mmi":
        return <EQmmi handleNotEvent = { handleNotEvent } />
      case "sm":
        return <EQsm handleNotEvent = { handleNotEvent } />
      case "tm":
        return <EQtm handleNotEvent = { handleNotEvent } />
      case "fm":
        return <EQmf handleNotEvent = { handleNotEvent } />
      case "sh":
        return <EQNearbyseismos handleNotEvent = { handleNotEvent } />
      case "originHistory":
        return <EQoriginHistory handleNotEvent = { handleNotEvent } />
      case "descargas":
        return <EQDownloads handleNotEvent = { handleNotEvent } />
      case "noData":
        return <NotFound notFoundById />
      default:
        return <EQDefault />
      }
    }

    useEffect( () => {
		document.querySelector("main")?.scrollTo( 0, 0 );
    }, [ product ] )

  return (
    <div className = "EQContentManager">
      { renderSelector() }
    </div>
  )
}

export default EQContenManager;
