import React, { useState, useRef, useContext } from "react";
import { SGCPopup } from 'Components/Atoms/A_EQDetails/SGCPopup';
import { AccessibilityTemplate } from 'Components/Molecules/M_Reusable/AccessibilityTemplate';
import { Link } from "react-router-dom";
import logo_sgc from "assets/icons/Reusable/logoWhite.svg";
import accessibility from 'assets/icons/EQViewer/Icons/accesibilidad_verde.svg';
import listA from "assets/icons/EQViewer/Icons/Active/listA.svg";
import mapIconA from "assets/icons/Reusable/colombia_mapa_1.svg";
import QueryIcon from "assets/icons/EQViewer/Icons/query.svg";
import TableIcon from "assets/icons/EQViewer/Icons/table.svg";
import { UseModal } from "hooks/UseModal";
import { TableConsultation } from "Components/Pages/TableConsultation";
import { Query } from "Components/Pages/Query";
import { mapControls } from "Components/Atoms/A_Reusable/ControlsMap";
import { QNavar } from "Components/Templates/T_Query/QNavar";
import settingsIconA from "assets/icons/EQViewer/Icons/Active/settingsA.svg";
import { QueryContext } from "context/QueryContext";

export const CatalogNav = ({
    list_selected, map_selected, queryView, updateUserQuery,
    searchByID, buildPage, userQueryRef
}) => {

    const { openSettingsPanel, settingsIsOpen } = useContext( QueryContext );
    const initModal = { modalContent: <></>, modalOpen: false, iconSelected: '' }
    const [{ modalContent, modalOpen, iconSelected }, setOpen ] = useState({
        ...initModal
    });
    const modalIDRef = useRef('');

    const handleCloseModal = () => {
        modalIDRef.current = '';
        setOpen({
            modalContent: <></>, modalOpen: false, iconSelected: ''
        });
        openSettingsPanel( !settingsIsOpen )
    }

    const modalOptions = {
        catalog: {
            modalOpen: true, iconSelected: "catalog", 
            modalContent: (
                <TableConsultation 
                    updateUserQuery = { updateUserQuery }
                    searchByID = { searchByID }
                    buildPage = { buildPage }
                    userQueryRef = { userQueryRef }
                />
            )
        },
        query: {
            modalOpen: true, iconSelected: "query",
            modalContent: (
                <div className = "ModalQueryForm">
                    <Query
                        updateUserQuery = { updateUserQuery }
                        handleCloseModal = { handleCloseModal }
                        className = "QueryContainerModal"
                        legal
                    />
                </div>
            )
        }
    }

    const handleModal = event => {
        if ( modalIDRef.current === event.currentTarget.id ) {
            modalIDRef.current = "";
            setOpen({ ...initModal });
        } else {
            modalIDRef.current = event.currentTarget.id;
            setOpen({ ...modalOptions[ event.currentTarget.id ] })
        }
    }

    const btnClass = queryView
        ? 'button-header disabled' : 'button-header';
    
    const handleListMap = event => {
        mapControls ( event.currentTarget.id, 'catalog' );
    };

    return (
        <header className = "container-header">
            <UseModal
                styles = "modalPresentation"
                open = { modalOpen }
                handleClose = { handleCloseModal }
                component = {
                    <QNavar
                        selected = { iconSelected }
                        handleModal = { handleModal }
                        handleCloseModal = { handleCloseModal }
                    >
                        { modalContent }
                    </QNavar>
                }
            />
            <div className = "viewer-header">
                <div className = "sgc-logo" >
                    <Link to = "/" className = "logo">
                        <img src = { logo_sgc } alt = "SGC" />
                    </Link>
                </div>
                <div className = "earthquakes-modes-view">
                    <ul className = "modes-view-container no-style" id = "icon-list">
                        <li
                            className = "modes-view-item"
                            id = "catalog"
                            data-id = "catalog"
                            title = "Tabla de resultados"
                            onClick = { handleModal }
                        >
                            <img
                                src = { TableIcon }
                                className = { btnClass }
                                alt = "tableIcon"
                                hidden = { queryView }
                            />
                        </li>
                        <li
                            className = "modes-view-item"
                            id = "query"
                            data-id = "query"
                            title = "Consulta"
                            onClick = { handleModal }
                        >
                            <img
                                src = { QueryIcon }
                                className = { btnClass }
                                alt = "tableIcon"
                                hidden = { queryView } 
                            />
                        </li>
                        <li
                            className = "modes-view-item"
                            data-id = "accessibility"
                            title = "Accesibilidad"
                        >
                            <span
                                id = "accessibility_icon"
                                className = "icon_display"
                            >
                                <img src = { accessibility } alt = "accesibilidad" />
                            </span>
                            <SGCPopup
                                Component = { <AccessibilityTemplate/> }
                                id = "accesibility_services"
                                styles = {{ top: "100%" }} 
                                trigger = {{
                                    id: 'accessibility_icon',
                                    classes: 'Services_icon_on'
                                }}
                            />
                        </li>
                        <li
                            className = { list_selected }
                            onClick = { handleListMap }
                            id = "list"
                            title = "Ver lista"
                        >
                            <img
                                className = { btnClass }
                                src = { listA }
                                alt = "button-header"
                                hidden = { queryView }
                            />
                        </li>
                        <li
                            className={`${ map_selected } map_icon_access`}
                            onClick = { handleListMap }
                            id = "map"
                            data-id = "map"
                            title = "Ver mapa"
                        >
                            <img
                                className = { btnClass }
                                src = { mapIconA }
                                alt = "button-map"
                                hidden = { queryView }
                            />
                        </li>
                        {
                            !queryView && (
                                <li
                                    className = "modes-view-item"
                                    onClick = { handleListMap }
                                    id = "settings"
                                    data-id = "settings"
                                    title='Ajustes'
                                    style = {{ display: 'block' }}
                                >
                                    <img
                                        className = "button-header"
                                        src = { settingsIconA }
                                        alt = "button-settings"
                                    />
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </header>
    )
}