import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import SpanishLocale from "date-fns/locale/es";

const useStyles = makeStyles((theme) => ({
  base: {
    padding: "0px",
    width: "100%",
  },
  item: {
    width: "100%",
    padding: "4px 0px 4px 0px",
  },
  datePicker: {
    margin: "0px",
  },
}));

export let FORMAT_ERROR_MESSAGE = "Formato de fecha inválido";
export let MIN_DATE_ERROR_MESSAGE = "La fecha no debe ser menor a la permitida";
export let MAX_DATE_ERROR_MESSAGE = "La fecha no debe ser mayor a la permitida";
export let FORMAT = "yyyy-MM-dd HH:mm:ss";

const DateFilter = ({ minDate, maxDate, startDate, endDate, onChange }) => {
  const styles = useStyles();

  const handleStartDateChange = (date) => {
    onChange([date, endDate || maxDate]);
  };

  const handleEndDateChange = (date) => {
    onChange([startDate || minDate, date]); 
    
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={SpanishLocale}>
      <Grid container className={styles.base}>
        <Grid item className={styles.item} xs={12}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            className={styles.datePicker}
            invalidDateMessage={FORMAT_ERROR_MESSAGE}
            margin="normal"
            format={FORMAT}
            id="startDate"
            label="Fecha Inicial"
            maxDateMessage={MAX_DATE_ERROR_MESSAGE}
            minDateMessage={MIN_DATE_ERROR_MESSAGE}
            minDate={minDate}
            value={startDate || minDate }
            maxDate={endDate || maxDate }
            onChange={handleStartDateChange}
            required
          />
        </Grid>
        <Grid item className={styles.item} xs={12}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            className={styles.datePicker}
            invalidDateMessage={FORMAT_ERROR_MESSAGE}
            margin="normal"
            format={FORMAT}
            id="endDate"
            label="Fecha Final"
            maxDateMessage={MAX_DATE_ERROR_MESSAGE}
            minDateMessage={MIN_DATE_ERROR_MESSAGE}
            minDate={startDate || minDate}
            value={endDate || maxDate}
            maxDate={maxDate}
            onChange={handleEndDateChange}
            required
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider >
  );
};

export default DateFilter;
