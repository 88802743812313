import { Box, Typography, Paper } from "@mui/material";
import { useState } from "react";
import SeismEnhancedTable from "./SeismEnhancedTable";
import { GREEN } from "utils/styles";

function SeismicityFeaturedTable({ rows }) {

   const [ showTable, setShowTable ] = useState(true);

   const handleHideTable = () => {
      if (rows.length > 0) setShowTable( prev => !prev );
   }
   
   return (
      <Paper style={{ backgroundColor: GREEN }} >
         <Box 
            sx={{ cursor: "pointer" }} 
            textAlign="center" color="white"
            paddingTop={1} paddingBottom={1}
            onClick={ handleHideTable }
         >
            <Typography
               variant="h5"
               style={{
                  fontSize: "15px",
                  letterSpacing: "0.08rem",
                  fontWeight: "bold"
               }}
            >
               { rows.length > 0 ? "Sismos destacados" : "No hay sismos destacados" }
            </Typography>
         </Box>

         <Box position="relative" top={-4} sx={{ borderBottomLeftRadius: 5 }}>
            <SeismEnhancedTable 
               rows={ rows }
               showTable={ showTable }
            />
         </Box>
      </Paper>
   )
}
export default SeismicityFeaturedTable