import swal from 'sweetalert';

const show_excuses_reload_page = ( 
   title= 'Ofrecemos disculpas', 
   text=`Puede que el tiempo de inactividad en pantalla nos haya afectado, por favor, recargue la página web`
   ) => {
    swal(
      {
        title,
        text,
        closeOnClickOutside: false,
        buttons: {
          catch: {
            text: 'Recargar pagina',
            value: 'reload'
          }
      }
    }).then((value) => {
      value === 'reload' && window.location.reload();
    })
}

const show_info_mss = ( title, text ) => {
    swal(
      {
        title,
        text,
        closeOnClickOutside: false,
        buttons: {
          catch: {
            text: 'Confirmar',
            value: 'reload'
          }
      }
    })
}

const ErrorRequest = {
   show_excuses_reload_page, show_info_mss
}
export default ErrorRequest;