import React, { useEffect } from 'react'
import { useCatalogRequest } from "hooks/useCatalogRequest";
import { CatalogContainerMap } from 'Components/Organisms/O_EQCatalogoMapa/CatalogContainerMap';
import { UseDialog } from 'hooks/UseDialog';
import { useNavigate } from 'react-router-dom';

function QueryMapContainer() {

   const navigate = useNavigate();

    const {
        modal, loadTotalData, modalContent, userQueryRef, totalPageRef,
        updateUserQuery, updateModal, buildPage, setModal,
        searchByID, sortData, filterData
    } = useCatalogRequest();

    const handleErrorModal = ({ message, type }) => {
        updateUserQuery();
        if ( type === 0 ) {
            updateModal({
                closeModal: () => navigate( '/catalogo' ),
                btn: 'Cerrar',
                message
            });
        } else if ( type === -1 ) {
            updateModal({
                closeModal: () => navigate( '/catalogo' ),
                btn: 'Cerrar',
                message
            });
        }
        setModal( true );
    }

    const makeValidations = async () => {
        const validation = await loadTotalData();
        !validation.state && handleErrorModal( validation );
    }
    
    useEffect(() => {
        userQueryRef.current ? makeValidations() : navigate( '/catalogo' );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ userQueryRef.current ]);
    
    return userQueryRef.current ? (
        <>
            <UseDialog
                open = { modal }
                onClose = { modalContent.closeModal }
                title = "Lo Sentimos"
                message = { modalContent.message }
                btn = {{
                    text: modalContent.btn,
                    action: modalContent.closeModal
                }}
            />
            <CatalogContainerMap
                buildPage = { buildPage }
                userQueryRef = { userQueryRef.current }
                totalPageRef = { totalPageRef.current }
                updateUserQuery = { updateUserQuery }
                searchByID = { searchByID }
                sortData = { sortData }
                filterData = { filterData }
            />
        </>
    ) : <></>;  
}

export default QueryMapContainer;
