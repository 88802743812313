import MoreInfoButton from "Components/Atoms/A_Home/MoreInfoButton"
import AnimateOnScroll from "Components/Atoms/A_Reusable/AnimateIn"
import { useEffect, useState } from "react";
import { HomeService } from "api/Services/ReqHome";
import HmSectionHeader from "Components/Atoms/A_Home/HmSectionHeader";
import { Box } from "@mui/material";

function HomeNews({ title, description }) {

   const [ news, setNews ] = useState( null );

   const loadNews = async () =>{
      await HomeService.getNews()
      .then( res => {
         res.data && setNews( res.data )
      })
      .catch( e => null );
   }
   useEffect(()=>{
      loadNews();
   }, []);

   if ( !news || !news[0] ) return <Box height={200} />

   return (
      news && news[0] && (
         <AnimateOnScroll>
            <div className="HomeNewsContainer">

               <HmSectionHeader
                  title={ title || "Noticias Recientes" }
                  description={ description }
                  ariaLevel={ 5 }
               />

               <div className="HomeNewsContent">

                  <div className="MainNew">
                     <div className="MainImgContainer">
                        <a 
                           href={ news[0].link }
                           target="_blank" 
                           rel="noopener noreferrer"
                        >
                           <img 
                              src={ news[0].src } 
                              alt="MainNew" 
                              className="MainImg" 
                              title={ news[0].title }
                           />
                        </a>
                     </div>
                     <div className="MainNewInfoBg">
                        <div className="MainNewInfo" role="article">
                           <p className="Title">
                              { news[0].publish_date }
                           </p>
                           <p>Noticias</p>
                           <p className="mt-1 mb-1 Title NewTitle">
                              <a 
                                 href={ news[0].link }
                                 target="_blank"
                                 rel="noopener noreferrer"
                                 title={ news[0].title }
                              >
                                 { news[0].title }
                              </a>
                           </p>
                           <p className="NewsText">
                              {  news[0].comments }
                           </p>
                           <p className="mt-1 NewsText">Servicio Geológico Colombiano</p>
                        </div>
                     </div>
                  </div>

                  <div className="NewsGrid">
                        {
                           news?.slice(1).map( (item, i) => (
                              <div className="NewsItem" key={i}>
                                 <a 
                                    href={ item.link } 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="NewsItemLink"
                                    title={ item.title }
                                 >
                                    <img 
                                       className="HomeNewImg"
                                       src={ item.src } 
                                       alt={`New-${i}`} 
                                    />

                                    <p className="SubInfoText">{ item.publish_date }</p>
                                    <p className="Title InfoText">Noticias</p>
                                    <p className="TitleText">
                                          { item.title }
                                    </p>
                                       </a> 
                              </div>
                           ) )
                        }

                  </div>
                  <div className="SectionAction">
                     <MoreInfoButton
                        href="https://www2.sgc.gov.co/Noticias/Paginas/Historico-de-noticias.aspx"
                        title="Todas las noticias"
                        variant="filled"
                        color="green"
                     />
                  </div>
               </div>
               <div className="ShadowBoxGray"></div>
            </div>
         </AnimateOnScroll>
      )
   )
}
export default HomeNews