import React from "react";
import Loader from "Components/Pages/Loader";
import { EQContentDetail } from "./EQContentDetail";
import ErrorRequest from "Components/Atoms/A_Reusable/ErrorRequest";

const { show_excuses_reload_page } = ErrorRequest;

export const EQContentManagerVolcano = ({ list, id }) => {

	const actualVolcano = list
		.find( element => element.id === parseInt( id ));

	const show_not_Found = () => {
		show_excuses_reload_page(
			'Ofrecemos disculpas',
			`El evento ingresado no se encuentra disponible`
		);
		return <Loader />
	}

	return (
		<>
			{
				actualVolcano
					? 	<EQContentDetail actualVolcano = { actualVolcano }/>
					:	 show_not_Found()
			}
		</>
	)
}