import { reqResApi, reqResBackUp } from "api/reqRes";

export const getEventYear = ( id ) => { // gets year from id 
    var year = id.slice(3, 7);
    if ( /SGC\d{5}/.test(id) ) year = id.slice(4, 8);
    return year;
  }

export const makeDetailsRequest = async eventId => {

    const eventYear = getEventYear( eventId ); // gets year from id 

    // https://xylon.sgc.gov.co/eqevents/events/eventYear/id
    const urlGetBackUp = `${ eventYear }/${ eventId }/detail_local.json`

    // https://archive.sgc.gov.co/events/id/
    const urlGetApi = `events/${ eventId }/detail.json`;

    // First request to AWS: https://archive.sgc.gov.co/
    const primaryRequest = async () => {
        try {
            const { data } = await reqResApi.get( urlGetApi );
            return data ? true : await secondaryRequest();
        } catch {
            return await secondaryRequest();
        }
    }

    const secondaryRequest = async () => {
        try {
            const { data } = await reqResBackUp.get( urlGetBackUp );
            return data ? true : finalRequest();
        } catch {
            return finalRequest();
        }
    }

    // Final request if the event is not found with /detail.json
    const finalRequest = async () => {
        try {
            const { data } = await reqResBackUp.get( `${ eventYear }/${ eventId }/detail.json` );
            return data ? true : false;
        } catch {
            return false;
        }
    }

    return await primaryRequest();
    
}


