const rulesSm = ( ranges, event ) => {
    const getMaxPGA = ( e, n, z ) => Math.max.apply( null, [ e, n, z ] );
    const range1 = ranges[0];
    const range2 = ranges[1];
    const range3 = ranges[2];
    const range4 = ranges[3];
    const range5 = ranges[4];
    const range6 = ranges[5];
    const range7 = ranges[6];
    const range8 = ranges[7];
    const range9 = ranges[8];

    if (getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) >= range1[0] && getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) <= range1[1]) {
        return { intern:"#6C9023", extern:"black" };
    } else if (getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) >= range2[0] && getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) <= range2[1]) {
        return { intern:"#A5A31E", extern:"black" };
    } else if (getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) >= range3[0] && getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) <= range3[1]) {
        return { intern:"#BA8016", extern:"black" };
    } else if (getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) >= range4[0] && getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) <= range4[1]) {
        return { intern:"#CF480C", extern:"black" };
    } else if (getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) >= range5[0] && getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) <= range5[1]) {
        return { intern:"#F50D00", extern:"black" };
    } else if (getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) >= range6[0] && getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) <= range6[1]) {
        return { intern:"#6C9023", extern:"black" };
    } else if (getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) >= range7[0] && getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) <= range7[1]) {
        return { intern:"#A5A31E", extern:"black" };
    } else if (getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) >= range8[0] && getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) <= range8[1]) {
        return { intern:"#BA8016", extern:"black" };
    } else if (getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) >= range9[0] && getMaxPGA(event.pgaE, event.pgaN, event.pgaZ) <= range9[1]) {
        return { intern:"#F50D00", extern:"black" };
    }
}


const seismological_rules = item => {
    const { hybrid, state, subnetwork } = item;
    let out = hybrid === 0 && hybrid === 2;
    out = state === 2 && subnetwork === 'RSNC'
    return out;
}

const accelerographic_rules = item => {
    const { hybrid, state, subnetwork } = item;
    let out = hybrid === 0 || hybrid === 2;
    out = state === 2 && subnetwork === 'RNAC';
    return out;
}

const hybrid_rules = item => {
    const { hybrid } = item;
    return hybrid === 1;
}

const real_time_rule = item => {
    const { acquisitionType } = item;
    return acquisitionType === 1;
}

const download_rule = item => {
    const { acquisitionType } = item;
    return acquisitionType === 2;
}

const active_rule = item => {
    const { state } = item;
    return state === 2;
}

const desactive_rule = item => {
    const { state } = item;
    return state === 1;
}

const inactive_rule = item => {
    const { state } = item;
    return state === 4;
}

const build_network_colors = item => {
    const { hybrid, state, subnetwork } = item;
    if ( hybrid === 0 && state === 2 && subnetwork === 'RSNC' ) {
        return {
            intern: '#6C9023',
            extern: 'black'
        };
    }
    else if ( hybrid === 1 && state === 2 ) {
        return {
            intern: '#5D6D0C',
            external: '#6C9023'
        }
    }
    else if ( hybrid === 0 && state === 2 && subnetwork === 'RNAC' ) {
        return {
            intern: '#BA8016',
            extern: 'black'
        }
    }
    else if ( state === 1 ) {
        return {
            intern: '#99A3A4',
            extern: 'black'
        }
    }
    else if ( state === 4 ) {
        return {
            intern: '#0E0F0F',
            extern: 'black'
        }
    }
    else if ( state === 3 ) {
        return {
            intern: '#E5D81A',
            extern: 'black'
        }
    }
    return {
        intern: '#667f00',
        extern: 'black'
    }
}

const DefineIconsRules = { 
   rulesSm,
   seismological_rules,
   accelerographic_rules,
   hybrid_rules,
   real_time_rule,
   download_rule,
   active_rule,
   desactive_rule,
   inactive_rule,
   build_network_colors
};
export default DefineIconsRules;