import { BrowserRouter, Outlet, Route, Routes as Switch } from "react-router-dom"
// import EQViewer from "./Pages/EQViewer";
// import VViewer from "./Pages/VViewer";
import NotFound from "./Pages/NotFound";
import EQDetail from "./Pages/EQDeatail";
import EQDetailVolcano from "./Pages/EQDeatailVolcano";
import Home from "./Pages/Home";
import ErrorBrowser from "./Pages/ErrorBrowser";
import QueryContainer from "./Pages/QueryContainer";
import QueryMapContainer from "./Pages/QueryMapContainer";
import SeismicitySummary from "./Pages/SeismicitySummary";

import { QueryProvider } from "context/QueryContext";
import { Suspense, lazy } from "react";
import Loader from "./Pages/Loader";
import EQDeatailCopy from "./Pages/EQDeatailTest";

const Sismos = lazy( () => import("./Pages/EQViewer") );
const Volcanes = lazy( () => import("./Pages/VViewer") );
// const SismosDetails = lazy( () => import("./Pages/EQDeatail") );
// const VolcanoDetails = lazy( () => import("./Pages/EQDeatailVolcano") );
const HistoricoSemanal = lazy( () => import("./Pages/SeismicitySummary") );
// const Catalog = lazy( () => import("./Pages/QueryContainer") );
// const CatalogMap = lazy( () => import("./Pages/QueryMapContainer") );
// const Product3D = lazy( () => import("./Pages/Product3D") );

const SuspenseLoad = () => <Suspense fallback={ <Loader /> }>{ <Outlet /> }</Suspense>

function Routes() {
   return (
      <QueryProvider>
         <BrowserRouter>
            <Switch>
               <Route path="/">
                  <Route index element={ <Home /> }/>
                  <Route path="/detallesismo/:id/:product" element={ <EQDetail />} />
                  <Route path="/detallesismo/:id" element={ <EQDetail />} />
                  <Route path="/detalletest/:id/:product" element={ <EQDeatailCopy />} />
                  <Route path="/detalletest/:id" element={ <EQDeatailCopy />} />
                  <Route path="/detalleevento/:id/:product" element={ <EQDetail />} />
                  <Route path="/detalleevento/:id/" element={ <EQDetail /> } />
                  <Route path="/detallevolcan/:id/" element={ <EQDetailVolcano /> } />
                  <Route path="/catalogo" element={ <QueryContainer /> } />
                  <Route path="/catalogo-mapa" element={ <QueryMapContainer /> } />
                  <Route path="/historico-semanal" element={ <SeismicitySummary /> } />
                  <Route path="/errorbrowser" element={ <ErrorBrowser />} />
                  <Route element={ <SuspenseLoad/> }>
                     <Route path="/historico-semanal" element={ <HistoricoSemanal /> } />
                     <Route path="/sismos" element={ <Sismos /> }/>
                     <Route path="/volcanes" element={<Volcanes />} />
                  </Route>
               </Route>
               <Route path="*" element={<NotFound />} />
            </Switch>
         </BrowserRouter>
      </QueryProvider>
   )
}

export default Routes;