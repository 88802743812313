import { LeafControls } from "Components/Atoms/A_Reusable/LeafControls";
import { useEffect, useRef } from "react";
import { Popup, Rectangle, useMap } from "react-leaflet"
import { fixedNumbers } from "./DraggableCircleLayer";

function DraggableRectangleLayer({ area, setArea, popup=true }) {

   const map = useMap();
   const layerRef = useRef( null );
   const rectangleRef = useRef();

   const { 
      createRectangle, scalingRectangle, setRectanglePosition
   } = LeafControls();

   const addToMap = element => element.addTo ( map );
   const openPopup = () => rectangleRef.current?.openPopup(); 
   const closePopup = () => rectangleRef.current?.closePopup();

   const onTransformedRectangle = ( event, layer ) => {
      const { _northEast, _southWest } = layer.getBounds();

      setArea({ // from SO to NE (left-bottom to right-top)
         min_lat: parseFloat( _southWest.lat ),
         min_lng: parseFloat( _southWest.lng ),
         max_lat: parseFloat( _northEast.lat ),
         max_lng: parseFloat( _northEast.lng ),
      });
      openPopup();
   }

   const createLayer = () => {
      layerRef.current = createRectangle (
         [ 
            area.min_lat, area.min_lng,
            area.max_lat, area.max_lng
         ],
         { transform: true, draggable: true, color: 'red' }
      );
      addToMap( layerRef.current );
      
      scalingRectangle([
         { key: 'transformed', action: onTransformedRectangle },
         { key: 'transformstart', action: closePopup },
         { key: 'click', action: openPopup },
      ]);
      openPopup();
   }

   useEffect(() =>{
      setRectanglePosition(
         [ area.min_lat, area.min_lng ],
         [ area.max_lat, area.max_lng ]
      );
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ area ]);

   useEffect( () =>{
      createLayer();
      return () => { 
         layerRef.current?.transform.disable();
         map.removeLayer( layerRef.current ); 
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps 
   }, []);

   if ( !popup ) return null;

   return (
      <Rectangle
         bounds={[
            [ area.min_lat, area.min_lng ],
            [ area.max_lat, area.max_lng ]
         ]}
         fillColor="#ff000000" color="#A9A9A933" // reference rectangle to popup 
         ref={ rectangleRef }
      >
         <Popup autoClose={false} closeOnClick={ false } keepInView={ true }  >
            <div id = "QPopup" className="MapPopup">
               <b>Coordenadas</b> <br/>
               Latitud: { fixedNumbers( area.min_lat ) }° { fixedNumbers( area.max_lat ) }° <br/>
               Longitud: { fixedNumbers( area.min_lng  ) }° { fixedNumbers( area.max_lng ) }°
            </div>
         </Popup>
      </Rectangle>
   )
}
export default DraggableRectangleLayer