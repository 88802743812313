import Level1 from "assets/icons/EQViewer/LevelOfVolcanicActivity/LevelOne.svg";
import Level2 from "assets/icons/EQViewer/LevelOfVolcanicActivity/LevelTwo.svg";
import Level3 from "assets/icons/EQViewer/LevelOfVolcanicActivity/LevelThree.svg";
import Level4 from "assets/icons/EQViewer/LevelOfVolcanicActivity/LevelFour.svg";
import volcanoMarker from "assets/icons/Volcanos/volcan_negro_trasn.svg";
import { useEffect } from "react";
import { reqResApi } from "api/reqRes";
import { useDispatch, useSelector } from "react-redux";
import { loadVolcanoLevels } from "redux/actionsCreators";
import { ALERT_LEVELS } from "utils/urls";

export const defaultVolcanoLevels = [
   {
       "description": "Volcán con cambios menores sin superar el umbral base de los parámetros monitoreados (Sismicidad, deformación de la superficie volcánica, geoquímica, actividad superficial). Manifestaciones superficiales: Niveles bajos de desgasificación pasiva en el cráter y en fumarolas, actividad fumarólica y olores, presencia de fuentes termales.",
       "icon": Level4,
       "index_number": 4,
       "index_text": "VI",
       "short_description": "Volcán activo en reposo.",
       "title": "Alerta Verde"
   },
   {
       "description": "Los diferentes parámetros que se monitorean presentan cambios que pueden incluir: Sismicidad con niveles moderados, enjambres sísmicos, incrementos de sismicidad de fracturamiento de roca y ocurrencia de sismicidad asociada a la dinámica de fluidos. Deformación leve a moderada. Niveles moderados de desgasificación y cambios en  los parámetros fisicoquímicos de fuentes termales y fumarolas. Aumento en la altura y densidad de la columna de vapor de agua y gases. Manifestaciones superficiales: pueden ocurrir emisiones esporádicas de ceniza (erupciones menores)  cuyo alcance y efectos son restringidos y la dispersión de la ceniza depende de la dirección del viento, presencia de algunas incandescencias, pequeñas explosiones en el cráter, anomalías térmicas de baja energía, ruidos, sismos sentidos, olores, precipitación de azufre elemental en inmediaciones al cráter y en fuentes termales y fumarolas, desgasificación en zonas diferentes al cráter.",
       "icon": Level3,
       "index_number": 3,
       "index_text": "III",
       "short_description": "Volcán activo con cambios en el comportamiento del nivel base de los parámetros monitoreados y otras manifestaciones.",
       "title": "Alerta Amarilla"
   },
   {
       "description": "Todos o varios de los parámetros monitoreados presentan cambios importantes, por encima del nivel base de actividad, entre los cuales se incluyen: Sismicidad con niveles moderados a altos puede ser  asociada a la dinámica de fluidos (enjambres de LP, HB y/o tremor volcánico) o sismicidad de fractura. Deformación de la superficie considerable. Cambios importantes en los niveles de desgasificación (Incremento o disminución drástica en la frecuencia de emisiones de SO2 u otros gases magmáticos). Aumento de las alturas de la columna de vapor de agua y gases. Manifestaciones superficiales: Pueden ocurrir algunos de estos fenómenos: emisiones de ceniza frecuentes (erupciones menores) cuyo volumen no afecta de manera importante el diario vivir, explosiones o incandescencias, anomalías térmicas importantes, ruidos, sismos sentidos, olores fuertes e irritantes en zonas cercanas al volcán, precipitación de azufre elemental en inmediaciones del edificio volcánico., liberación de gases magmáticos de manera importante que pueden causar la muerte de pequeños animales que estén cerca al volcán, agrietamientos en el terreno. Se puede presentar el ascenso y destrucción de domos con poco alcance de afectación, proyectiles balísticos, lahares con volúmenes pequeños, corrientes de densidad piroclástica restringidas a la parte proximal del edificio volcánico.",
       "icon": Level2,
       "index_number": 2,
       "index_text": "II",
       "short_description": "Volcán con cambios importantes en los parámetros monitoreados.",
       "title": "Alerta Naranja"
   },
   {
       "description": "Erupción  mayor (considerable) en curso, cuyos efectos pueden afectar y poner en riesgo la vida de las personas que viven en zonas de amenaza volcánica alta y/o media, dependiendo del fenómeno. Las redes de monitoreo pueden registrar señales sísmicas asociadas a erupciones, enjambres superficiales de sismos de fractura y de fluidos localizados en el cráter principalmente, aumento de la energía sísmica liberada, deformación coeruptiva, alta desgasificación, ondas de presión, ondas de choque,  entre otros. Liberación de altos niveles de gases magmáticos y vapor de agua. Se pueden presentar fenómenos como emplazamiento y destrucción de domos, explosiones o colapsos de domo, colapsos de columnas eruptivas, generación de corrientes de densidad piroclástica de volúmenes importantes, formación de nuevos cráteres o grietas, lahares de gran extensión y volumen, columnas de gases, vapor de agua y/o ceniza altas y muy concentradas en gases azufrados y halogenados, presencia de tormentas eléctricas y rayos en la zona cercana al cráter, entre otros.",
       "icon": Level1,
       "index_number": 1,
       "index_text": "I",
       "short_description": "Volcán en erupción",
       "title": "Alerta Roja"
   }
] 

export function useVolcanoIcon( level ){

   const volcanoLevels = useSelector( state => state.volcanoLevels );
   const volcanoLevelIcon = volcanoLevels.at( -level )?.icon || null;

   return volcanoLevelIcon ?? volcanoMarker;
}

export const useVolcanoLevelsRequest = () => {

   const dispatch = useDispatch();

   const getVolcanoLevels =  async () => {
      try {
         const { data } = await reqResApi
           .get( ALERT_LEVELS );
         if ( data ) {
            dispatch( loadVolcanoLevels( data ) );
         }
       } catch {
         dispatch( loadVolcanoLevels( defaultVolcanoLevels ) );
       }
   }

   return { getVolcanoLevels }
}

export function useVolcanoLevels( ){

   let volcanoLevels = useSelector( state => state.volcanoLevels );
   const { getVolcanoLevels } = useVolcanoLevelsRequest();

   useEffect( () => {
      if ( volcanoLevels.length < 1 ){
         getVolcanoLevels();
      }
      // eslint-disable-next-line
   }, [ ] )


   return { volcanoLevels }
}

