import React, { useEffect } from "react";
import { VolcanoNews } from "Components/Molecules/M_EQDetailsVolcanos/VolcanoNews";
import { VolcanoTable } from "Components/Molecules/M_EQDetailsVolcanos/VolcanoTable";
import EQSummary from "Components/Molecules/M_Reusable/EQSummary";
import { VolcanoCarrousel } from "Components/Molecules/M_EQDetailsVolcanos/VolcanoCarrousel";
import { VolcanoBasicInfo } from "Components/Molecules/M_EQDetailsVolcanos/VolcanoBasicInfo";
import { VolcanoMap } from "Components/Molecules/M_EQDetailsVolcanos/VolcanoMap";

export const EQContentDetail = ({ actualVolcano }) => {

	useEffect(() => {
		document.querySelector("main")?.scrollTo( 0, 0 );
	}, [ actualVolcano ]);

	return (
		<div className = "EQContentDetail">
			<EQSummary
				title = { actualVolcano.properties.VolcanoName }
				body = { <VolcanoBasicInfo properties = { actualVolcano.properties } /> }
			/>
			<div className = "VContentManager GrayBordered">
				{
					actualVolcano.properties.images && (
						<VolcanoCarrousel
							images = { actualVolcano.properties.images }
						/>
					)
				}
				
				<div className = "generalities-volcanos">
					<h6>
						Generalidades
					</h6>
					<p>
						{ actualVolcano.properties.description }
					</p>
				</div>
            <div className="see-more-volcan">
               <a
                  href = { actualVolcano.properties.link }
                  target = "_blank"
                  rel = "noopener noreferrer"
               >
                  <button>
                     Información especializada e histórica del volcán
                  </button>
               </a>
            </div>
				{
					actualVolcano.properties.bulletins && actualVolcano.properties.bulletins[0]
					&& (
						<div className="newsletters-volcanos">
							<h6>Boletines</h6>
							<VolcanoTable
								properties={actualVolcano.properties}
							/>
						</div>
					)
				}
				<div className = "localization-volcanos">
					<h6>Localización</h6>
					<p>
						{actualVolcano.properties.location}
					</p>
				</div>
				<VolcanoMap
					actualVolcano = { actualVolcano }
					activityLevel = { actualVolcano.properties.activityLevel }
					coordinates = { actualVolcano.geometry.coordinates }
					volcano3d = { actualVolcano.properties.volcano3d }
				/>
				{
					actualVolcano.properties.news && (
						<div className = "news-volcanos">
							<h6>Últimas Noticias</h6>
							<VolcanoNews actualVolcano = { actualVolcano } />
						</div>
					)
				}
			</div>
		</div>
	)
}