import React from 'react'
import { FormControl } from '@material-ui/core';
import { useStyles } from 'Components/Atoms/A_Query/QFormStyles';
import { QValidates } from './QinputsValidates';
import { getQuery } from 'Components/Atoms/A_Query/QActive';
import { UseNumberField } from 'hooks/UseTextField';

export const QTmInputs = () => {
    
    const classes = useStyles();
    const {
        min_mag_tm, max_mag_tm, handleTmMagnitude,
        updateWithErrors, deleteTmFilters
    } = getQuery();

    const handleMaxTm = value => {
        const { validate, errors } = QValidates[
            "max_mag_tm"
        ](
            value, min_mag_tm.data, max_mag_tm.label,
            min_mag_tm.label, max_mag_tm.limits
        );
        validate ? handleTmMagnitude (
            "max_mag_tm", value, "max", false, ""
        ) : updateWithErrors (
            "max_mag_tm", {
                ...max_mag_tm, data: value,
                validate: true, message: errors[0]
            }, errors
        );
    }

    const handleMinTm = ( value ) => {
        const { validate, errors } = QValidates[
            "min_mag_tm"
        ](
            value, max_mag_tm.data, min_mag_tm.label,
            max_mag_tm.label, max_mag_tm.limits
        );
        validate ? handleTmMagnitude (
            "min_mag_tm", value, "min"
        ) : updateWithErrors(
            "min_mag_tm", {
                ...min_mag_tm, data: value,
                validate: true, message: errors[0]
            }, errors
        );
    }

    const validateMaxValue = ( validation, key ) => {
        if ( validation.error === true ) {
            min_mag_tm.data === ""
                ? deleteTmFilters( "max_mag_tm" )
                : handleTmMagnitude(
                    "max_mag_tm", "", "max", false, ""
                )
        } else { handleMaxTm( validation.value ) }
    }

    const validateMinValue = ( validation, key ) => {
        if ( validation.error === true ) {
            max_mag_tm.data === ""
                ? deleteTmFilters( "min_mag_tm" )
                : handleTmMagnitude(
                    "min_mag_tm", "", "min", false, ""
                )
        } else { handleMinTm( validation.value ) }
    }

    return (
        <div className = "Qselections" style = {{ display: 'block' }}>
            <h4>Magnitud Tensor Momento</h4>
            <div style = {{ display: 'flex' }}>
                <div id = "min_mag_tm" className = "Qselector selector1">
                    <div className = "Qselector_input">
                        <FormControl className = { classes.formControl }>
                            <UseNumberField
                                init = { min_mag_tm.data }
                                placeholder = "0"
                                aditionalConf = {{
                                    step: "0.1"    
                                }}
                                id = "min_magnitude_tm_input"
                                name = "min_magnitude_tm"
                                label = { min_mag_tm.label }
                                variant = "outlined"
                                err = { min_mag_tm.validate }
                                helpText = { min_mag_tm.message }
                                trigger = { validateMinValue }
                            />
                        </FormControl>
                    </div>
                </div>
                <div className = "Qselector" id = "max_mag_tm" >
                    <div className = "Qselector_input">
                        <FormControl className = { classes.formControl }>
                            <UseNumberField
                                init = { max_mag_tm.data }
                                placeholder = "0"
                                aditionalConf = {{
                                    step: '0.1'
                                }}
                                id = "max_magnitude_tm_input"
                                name = "max_magnitude_tm"
                                label = { max_mag_tm.label }
                                variant = "outlined"
                                err = { max_mag_tm.error }
                                helpText = { max_mag_tm.message }
                                trigger = { validateMaxValue }
                            />
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
    )
}
