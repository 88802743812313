import { getQuery } from 'Components/Atoms/A_Query/QActive';
import { QLatLng } from './QLatLng';
import { QCircle } from 'Components/Organisms/O_Query/QCircle';
import { QRectangle } from 'Components/Organisms/O_Query/QRectangle';
import MobileAdvice from 'Components/Atoms/A_Reusable/MobileAdvice';
import { QValidateBrowser } from 'Components/Atoms/A_Query/QValidateBrowser';

import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { FormLabel } from '@mui/material';
import SgcRadio from 'Components/Atoms/A_Reusable/SgcRadio';

export const QCoordinates = () => {

    const { browserName } = QValidateBrowser();
    const { map_options, handleArea } = getQuery();

    const mobileMessage = "Para ubicar el área a consultar en dispositivos móviles se deben especificar los valores de latitud y longitud."
    
    const handleStorage = ( filter, key, value ) => handleArea (
        filter, { ...map_options, [ key ] : value }
    );

    const selectArea = ({ target }) => {
        const newState = { ...map_options, area: target.value }
        switch ( target.value ) {
            case "circle":
               handleArea({
                   type: "geo_distance",
                   geometry: {
                       lat: map_options.circle.lat.data,
                       lon: map_options.circle.lng.data
                   },
                   distance: `${ map_options.circle.radio }km`
               }, newState );
               break;
            case "rectangle":
                handleArea({
                    type: "geo_bounding_box",
                    geometry: { 
                        top_left: {
                            lat: map_options.rectangle.max_lat.data,
                            lon: map_options.rectangle.max_lng.data
                        },
                        bottom_right: {
                            lat: map_options.rectangle.min_lat.data,
                            lon: map_options.rectangle.min_lng.data
                        }
                    }
                }, newState );
                break;
            default:
                handleArea( {}, newState );
                break;
        }
    }

    return (
        <>
            <div className = "Qselector QselectLayer">
                <FormControl component = "fieldset">
                    <FormLabel>Área de Consulta</FormLabel>
                    <RadioGroup
                        aria-label = "consult_area"
                        name = "consult_area"
                        className = "radio_area"
                        value = { map_options.area }
                        onChange = { selectArea }
                    >
                        <FormControlLabel
                            value = "circle"
                            control = {
                                <SgcRadio value = "circle" />
                            }
                            label = "Área Circular"
                        />
                        <FormControlLabel
                            value = "rectangle"
                            control = {
                                <SgcRadio value = "rectangle" />
                            }
                            label = "Área Rectangular"
                        />
                        <FormControlLabel
                            value = "empty"
                            control = {
                                <SgcRadio value = "empty" />
                            }
                            label = "Sin Área"
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            {
                map_options.area && map_options.area !== 'empty' && (
                    <div
                        className = "QselectionsArea"
                        id = "Qselectionsarea"
                    >
                        <div className = "coordinatesArea">
                            <QLatLng />
                        </div>
                        <MobileAdvice 
                            message = { mobileMessage } 
                        />
                        <div className = "mapArea">
                            {
                                map_options.area === 'rectangle' ?
                                <QRectangle
                                    rectangle = { map_options.rectangle }
                                    handleStorage = { handleStorage }
                                    validateFullScreen = {
                                        !browserName === "Mobile Safari"
                                    }
                                /> :
                                <QCircle
                                    circle = { map_options.circle }
                                    handleStorage = { handleStorage }
                                    validateFullScreen = {
                                        !browserName === "Mobile Safari"
                                    }
                                />
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}