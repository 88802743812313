import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, TableSortLabel } from "@mui/material";
import { Collapse, useMediaQuery } from "@mui/material";
import { SeismicityContext } from "context/SeismicityContext/SeismicityContext";
import { useState, useContext, useMemo, useRef } from "react"
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableRow from "./EnhancedTableBody";
import { styled } from "@mui/material";
import { GREEN } from "utils/styles";

export const StyledTableCell = styled( TableCell )(({ about }) => ({
   "&.MuiTableCell-head": {
      // position: "relative",
      backgroundColor: GREEN,
      color: "white",
      fontSize: 12,
      padding: about === "sort" ? "5px" : "7px",
      textAlign: "center",
      '@media(max-width: 600px)': {
         fontSize: 9,
         padding:"5px"
      },
   },
   "&.MuiTableCell-body": {
      fontSize: 11,
      padding: "10px 8px",
      borderRight: "1px solid #667f00",
      borderBottom: "none",
      "&:last-child":{
         borderRight: "none",
      },
      '@media(max-width: 600px)': {
         fontSize: 9,
         padding:"5px"
      } 
   },
 }));
 
 
export const StyledTableRow = styled(TableRow)((theme) => ({
   borderBottom: "none",
   backgroundColor: "#fff",
   '&:nth-of-type(even)': {
      backgroundColor: "rgb(250, 252, 248)",
      '&.Mui-selected': {
         backgroundColor: "rgba(25, 118, 210, 0.08)"
      }
   },
 }));

export const Mui5StyledTableSortLabel = styled(TableSortLabel)(() => ({
   "&:hover":{
      "& .MuiTableSortLabel-icon":{
         width: "auto"
      }
   },
   "&:focus":{
      color: "white",
   },
   "& .MuiTableSortLabel-icon":{
      width: 0,
      fontSize: "15px",
      margin: 0,
   },
}))


const descendingComparator = (a, b, orderBy) => {
   if (b[orderBy] < a[orderBy]) {
     return -1;
   }
   if (b[orderBy] > a[orderBy]) {
     return 1;
   }
   return 0;
 }
 
const getComparator = (order, orderBy) => {
   return order === 'desc'
     ? (a, b) => descendingComparator(a, b, orderBy)
     : (a, b) => -descendingComparator(a, b, orderBy);
 }

function SeismEnhancedTable({ rows, showTable }) {

   const { setSelectedEvent, initSimulation, resetSimulation, stopSimulation } = useContext( SeismicityContext );

   const [ selectedRow, setSelectedRow ] = useState( null );
   const [ showHeader, setShowHeader ] = useState( false );
   const [ order, setOrder ] = useState('asc');
   const [ orderBy, setOrderBy ] = useState('time');
   const tableBodyRef = useRef();
   
   const responsive = useMediaQuery( '(max-width: 600px)' );

   const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

   const visibleRows = useMemo(
      () => rows.slice().sort( getComparator( order, orderBy )),
   [ rows, order, orderBy ] );

   const handleSelectRow = (row) => (e) => {
      if ( selectedRow !== row.id ){
         setSelectedEvent( row );
         setSelectedRow( row.id );
      }
   }

   const handleShowHeader = ( show ) => {
      if ( show ) {
         !showHeader && setShowHeader( true );
      } else {
         showHeader && setShowHeader( false );
      }

   }

   return (
      <TableContainer className="seismicity-table" sx={{ maxHeight: responsive ? "25vh" : "40vh" }}>
         {
            rows.length > 0 &&
            <Collapse in={ showTable }>
               <Table aria-label="simple table" size="small" stickyHeader>

                  <TableHead onClick={ () => setSelectedRow(null) } >
                     {
                        showHeader &&
                           <EnhancedTableHead
                              order={ order }
                              orderBy={ orderBy }
                              onRequestSort={ handleRequestSort }
                           />
                     }
                  </TableHead>

                  <TableBody ref={ tableBodyRef } sx={{ bgcolor: "white" }}>
                     {
                        initSimulation && visibleRows?.map((row) => (
                           <EnhancedTableRow
                              key={ row.id }
                              row={ row } 
                              onClickRow={ handleSelectRow }
                              selectedRow={ selectedRow }
                              responsive={ responsive }
                              reset = { resetSimulation }
                              stop = { stopSimulation }
                              onShow={ handleShowHeader }
                           />      
                        ))
                     }
                  </TableBody>
               </Table>
            </Collapse>
         }
         
      </TableContainer>
   )
}
export default SeismEnhancedTable