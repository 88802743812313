import React from 'react'

export const SmTable = () => {

    const buildTableWithDivs = ( columns ) => (
      <div className="SmContainer">
        {
          columns.map(( column, index ) => {
            return (
              <div key = { `column${ index }` } className = "SmColumns">
                <span>
                  { column[0] }
                </span>
                <span>
                  { column[1] }
                </span>
              </div>
            )
          })
        }
      </div>
    )

    return (
        <div className='descripcion-sm'>
            <p>
          Los valores que se muestran en el portal de aceleraciones del SGC son obtenidos a partir del procesamiento automático del módulo scwfparam, 
          revisados por un sismólogo. scwfparam es un módulo de <a href="https://www.seiscomp3.org/doc/applications/scwfparam.html">SeisComP3</a> que calcula en tiempo real o por demanda los siguientes parámetros:
          </p>
          <ul>
            <li>Aceleración pico del suelo (PGA por sus siglas en inglés)</li>
            <li>Espectro de respuesta elástico de desplazamiento relativo (DRS)</li>
            <li>Espectro de respuesta elástica de aceleración pseudo - absoluta (PSA)</li>
          </ul>
          <p>
            Los parámetros de configuración para el procesamiento automático de los eventos se describen a continuación:
          </p>
          <ul>
            <li>La magnitud de evento mínima para generar el cálculo automático de aceleraciones es M {'>'}= 4.0</li>
            <li>La cantidad de estaciones a procesar automáticamente se definen a partir de la magnitud del evento, considerando la siguiente relación de magnitud vs. distancia.</li>
            { /**Sm tables */ }
            {
              buildTableWithDivs(
                [
                  [ "Magnitud", "Distancia (km)" ],
                  [ "4.0 - 4.3", "500" ],
                  [ "4.31 - 4.7", "600" ],
                  [ "4.71 - 5.0", "800" ],
                  [ "5.01 - 6.0", "1000" ],
                  [ "6.01 - 7.0", "2000" ],
                  [ "> 7.0", "3500" ],
                ]
              )
            }
          </ul>
          
          
          <ul>
            <li>La ventana de tiempo empleada para el procesamiento es variable de acuerdo a la magnitud del evento, la cual incluye una ventana de pre-evento de 60s </li>
            {
              buildTableWithDivs(
                [
                  [ "Magnitud",  "Ventana de tiempo (s)" ],
                  [ "4.0 - 4.7", "300" ],
                  [ "4.7 - 5.0", "360" ],
                  [ "5.0 - 6.0", "420" ],
                  [ "6.0 - 6.8", "480" ],
                  [ ">= 6.8"   , "540" ],
                ]
              )
            }
          </ul>

          <ul>
            <li>Los filtros empleados para el procesamiento automático están definidos a partir de la magnitud del evento de la siguiente manera</li>
            {
              buildTableWithDivs(
                [
                  [ "Rango de Magnitud", "Filtro Empleado (Hz)" ],
                  [ "4.0 - 6.9", "0.05 - 0.8fNyquist" ],
                  [ ">= 7.0", "0.025 - 0.8fNyquist" ]
                ]
              )
            }
          </ul>

          <p>Códigos de redes sismológicas</p>
          <ul>
            <li>CM: Red sismológica Nacional de Colombia</li>
          </ul>
          <p>Descriptor de ID de localización: Corresponde a la clasificación según la banda de respuesta del instrumento instalado.</p>
          <ul>
            <li>00 - 09: Sensor de velocidad de banda ancha (={'>'} 120s)</li>
            <li>10 - 19: Acelerómetro</li>
            <li>20 - 29: Sismómetro de corto período</li>
            <li>30 - 39: Sismómetro de banda intermedia (40 – 60 s)</li>
            <li>40 - 49: Sismómetro de movimiento fuerte (TSM-1)</li>
          </ul>
          <p>Descriptor de canales de estación: Corresponde a la clasificación según la banda de respuesta del instrumento y el muestreo de la señal</p>
          <ul>
            <li>HH: Sensor de banda ancha. Muestreo: 100sps. Sensor de velocidad de alta ganancia; convención estándar: HH[ZNE]</li>
            <li>HN: Sensor de banda ancha. Muestreo: 200sps. Sensor de aceleración; convención estándar: HN[ZNE]</li>
            <li>HL: Sensor de banda ancha. Muestreo: 200sps. Sensor de velocidad de baja ganancia (Velocity Type Strong Motion Seismometer TSM-1); convención estándar: HL[ZNE]</li>
            <li>EH: Corto período. Muestreo: 100sps. Sensor de velocidad de alta ganancia; convención: EH[ZNE]</li>
          </ul>
          <p>Descriptor de gráficas</p>
          <ul>
            <li>DRS 5%: Gráfica del espectro de respuesta elástico de desplazamiento relativo (DRS) con el 5% de amortiguamiento.</li>
            <li>PSA 5%: Espectro de respuesta elástica de aceleración pseudo - absoluta (PSA) con el 5% de amortiguamiento. Se calculan los valores para 200 períodos estructurales a partir de 0.01 - 5 segundos.</li>
            <li>Las señales que se muestran son representativas para poder identificar claramente el evento sísmico, en m/s2 vs. tiempo en s</li>
          </ul>
          <p>Distribución PGA</p>
          <ul>
            <li>Gráficas de distribución de PGA vs. distancia por componente para todas las estaciones que registraron el evento.</li>
          </ul>
          <p>Mapa</p>
          <ul>
            <li>Distribución de las aceleraciones calculadas para el evento.</li>
          </ul>
        </div>
    );
}
