function HmSectionHeader({ title, description, ariaLevel }) {
   return (
      <div className="SectionTitleContainer">
         <div className="SectionTitle Title" role="heading" aria-level={ariaLevel}>{ title }</div>
         <div className="SectionTitleInfo">
            {
               description.split(".").map( (txt, i) => {
                  if (txt.at(-1) !== ".") txt = txt + ". ";
                  if ( txt === ". " ) return null;
                  return (
                     <span key={ title + i }>
                        { txt }
                     </span> 
                  )
               })
            }
         </div>
      </div>
   )
}
export default HmSectionHeader