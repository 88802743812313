import React, { useEffect, useRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { QEvents } from "Components/Molecules/M_Query/QEvents";
import { UseStack } from "hooks/UseStack";
import { QueryContext } from "context/QueryContext";
import LoaderModal from "Components/Atoms/A_Reusable/LoaderModal";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			margin: theme.spacing(1),
			width: theme.spacing(16),
			height: theme.spacing(16),
		},
	},
}));

export const EventsCatalog = ({ buildPage, totalPageRef }) => {

	const {
		activeEvents, selectedEvent, setSelectItem, actualPage,
		isLoadOrderEvents
	} = useContext(QueryContext);
	const classes = useStyles();
	const pagComponentRef = useRef();
	const containerRef = useRef();
	const [sizePage, setSize] = useState('medium');

	const active = ({ id }, index) => {
		if (selectedEvent && id === selectedEvent.id) {
			containerRef.current && containerRef.current
				.scrollTo(0, index * 66);
			return "active"
		}
		return "";
	}

	const show = (id, index) => setSelectItem(activeEvents[index]);

	const handleWidthPage = () => {
		if (pagComponentRef.current.clientWidth < 374) {
			setSize('small')
		} else if (pagComponentRef.current.clientWidth > 500) {
			setSize('large');
		} else { setSize('medium') }
	}

	useEffect(() => {
		window.addEventListener('resize', handleWidthPage)
		return () => window.removeEventListener('resize', handleWidthPage)
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, []);

	return (
		<>
			<LoaderModal isLoading={ isLoadOrderEvents } />
			<div className="grid-events" ref={containerRef}>
				{
					activeEvents[0] && totalPageRef > 0 ? (
						activeEvents.map((event, index) => {
							return <QEvents
								event={event}
								index={index}
								active={active}
								key={event.id}
								show={show}
							/>
})
					) : (
						<div className={classes.root}>
							Cargando...
						</div>
					)
				}
			</div>

			<div className="events-pagination" ref={pagComponentRef}>
				<UseStack
					count={totalPageRef}
					action={buildPage}
					page={actualPage}
					size={sizePage}
				/>
			</div>
		</>
	);
}