import React, { createContext, useReducer } from 'react';
import { queryReducer } from './QueryReducer';

export const queryInitState = {
    activeEvents: [],
    filterEvents: [],
    queryCount: 0,
    selectedEvent: null,
    mapBounds: { north: 0, south: 0, east: 0, west: 0 },
    actualPage: 1,
    tatalPages: 0,
    isLoadOrderEvents: false,
    settingsIsOpen: false,
    loadingRequest: true, // control the request status and fix the bad setState
    moreInfoAssets : {
        loading : false,
        hasData : false
    }
}

export const QueryContext = createContext({});

export const QueryProvider = ({ children }) => {
    const [ queryState, dispatch ] = useReducer (
        queryReducer, queryInitState   
    );
    const loadQuery = value => {
        dispatch({ type: 'load', payload: value })
    }
    const filterQuery = value => {
        dispatch({ type: 'filter', payload: value });
    }
    const setCountQuery = value => {
        dispatch({ type: 'count', payload: value });
    }
    const setSelectItem = value => {
        dispatch({ type: 'selected', payload: value });
    }
    const setTotalPages = value => {
        dispatch({ type: 'totalPages', payload: value });
    }
    const loadSelectedEvent = value => {
        dispatch({ type: 'loadSelected', payload: value })
    }
    const setActiveFilter = value => {
        dispatch({ type: 'setActiveFilter', payload: value })
    }
    const updateMapBounds = value => {
        dispatch({ type: 'updateBounds', payload: value })
    }
    const changeStateOrder = value => {
        dispatch({ type: 'loadOrder', payload: value })
    }
    const openSettingsPanel = value => {
        dispatch({ type: 'loadSettings', payload: value })
    }
    const chageLoadingStatus = ( payload = false ) => {
        dispatch({ type : 'chageLoadingStatus', payload });
    }
    const selectedFind = event => {
        dispatch({
            type: 'selectedFind',
            payload: {
                event,
                index: queryState.activeEvents
                    .findIndex( e => e.id === event.id )
            }
        })
    }
    const pushPage = ( page, events ) => {
        dispatch({
            type: 'pushPage',
            payload: { actualPage: page, activeEvents: events, selectedEvent: events[0] }
        });
    }
    const updateMoreInfo = ({ loading, hasData }) => {
        dispatch({ type : 'updateMoreInfo', payload : { loading, hasData } })
    }
    return (
        <QueryContext.Provider
            value = {{
                ...queryState,
                loadQuery,
                filterQuery,
                setCountQuery,
                setSelectItem,
                setTotalPages,
                loadSelectedEvent,
                setActiveFilter,
                updateMapBounds,
                selectedFind,
                changeStateOrder,
                pushPage,
                openSettingsPanel,
                chageLoadingStatus,
                updateMoreInfo
            }}
        >
            { children }
        </QueryContext.Provider>
    )
}