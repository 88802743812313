import { useDispatch, useSelector } from "react-redux";
import { setMapLayer } from "redux/actionsCreators";
import SwitchesGroup from "Components/Molecules/M_SeismicitySummary/SwitchesGroup";

function SettingsMapLayers({ title }) {

   const dispatch = useDispatch();
   const mapLayers = useSelector(state => state.mapLayers);

   const handleLayerSwitches = ( mapLayer ) => {
      dispatch( setMapLayer( mapLayer ) );
   }

   return (
      <div className="SettingsPanelItem" style={{ maxWidth: "250px" }}>
         <div className="SettingsItemTitle">
            { title }
         </div>
         <div className="SettingsItemContent" style={{ marginBottom: "12px", marginTop: "6px" }}>
            <SwitchesGroup
               items={ mapLayers }
               labelValue="name"
               onChange={ handleLayerSwitches } />
         </div>
      </div>
   )
}

export default SettingsMapLayers;
