import { useState } from 'react'
import PopupSmMarker from 'Components/Atoms/A_EQViewer/PopupSmMarker';
import DefineIconsRules from '../M_Reusable/DefineIconsRules';
import SmMarker from '../M_Reusable/SmMarker';
import EpicenterMarker from './EpicenterMarker';
import { Button, Popover, Typography } from '@material-ui/core';
import InteractiveMapLegend from '../M_Reusable/InteractiveMapLegend';
import { makeStyles } from "@material-ui/core/styles";
import { UseLeafletMap } from 'hooks/UseLeafletMap';
import { TileLayer, LayersControl } from "react-leaflet";
const { BaseLayer } = LayersControl;

const useStyles = makeStyles((theme) => ({
    root: {
      fontFamily: "Roboto",
    },
    appBar: {
      background: "#F1F1F1",
      boxShadow: "none",
      color: "#667f00"
    },
    fases: {
      borderLeft: "1.5px solid #ffffff",
      borderRight: "1.5px solid #ffffff",
      margin: "10px 0px 10px 0px"
    },
    base: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
    button: {
      backgroundColor: "#fafafa",
      zIndex: 1000,
      fontSize: "100%",
    },
    popup: {
      zIndex: 1000,
      backgroundColor: "fafafa",
      marginBottom: "20px",
    },
    minimapContainer: {
      position: "absolute",
      top: "0",
      right: "0",
      paddingTop: "10px",
      paddingRight: "10px",
      zIndex: "1000",
    },
    legendLabel: {
      fontSize: "80%",
    },
  }));

export const SmMap = ({ event, accelerations }) => {
  
    const { rulesSm } = DefineIconsRules;

    const styles = useStyles();
    const [ anchorLegend, setAnchorLegend ] = useState(null);
    const openLegend = Boolean( anchorLegend );
    
    const legendId = openLegend ? "legend" : undefined;


    const handleOnClickLegend = (event) => {
      setAnchorLegend(event.currentTarget);
    };

    const handleCloseLegend = () => {
      setAnchorLegend(null);
    };

    let allPga = [];
    const pgaKeys = [ "pgaE", "pgaN", "pgaZ" ]; 

   accelerations.forEach(element => {
      pgaKeys.forEach( pgaKey => {
         if ( element[ pgaKey ] !== null && element[ pgaKey ] !== undefined ){
            allPga.push( element[ pgaKey ] );
         }
      });
   });

    const maxPga = Math.max( ...allPga );
    const minPga = Math.min( ...allPga );

    const getRange = () => {
        const interval = ( maxPga - minPga ) / 9;
        let start = minPga;
        return [0,1,2,3,4,5,6,7,8,9].map(() => {
            const element = [ 
                start - 0.0000000000001, ( start + interval ) + 0.0000000000001
            ];
            start += interval;
            return element;
        });
    }

    return (
        <>
          <UseLeafletMap
            id = "interactive_map"
            position = {
              [
                event[0].geometry.coordinates[0],
                event[0].geometry.coordinates[1]
              ]
            }
            mapOptions = {{ maxZoom: 13 }}
            zoom = { 7 }
            bodyMap = {(
              <>
                <EpicenterMarker
                  event = { event[0] }
                  onSelect = { event => "" }
                />
                {
                  accelerations.map(( acceleration, index ) => {
                    return (
                      <SmMarker
                        key = { `Marker${index}` }
                        event = { acceleration }
                        name = { acceleration.stationCode }
                        onSelect = { () => "" }
                        moreRecent = "false"
                        color = { rulesSm( getRange(), acceleration ) }
                        aceleraciones = { accelerations }
                        popupMarker = { <PopupSmMarker event = { acceleration }/> }
                        isHeader = { true }
                      />
                    )
                  })
                }
                <LayersControl position = "topright">
                  <BaseLayer name = "Escala de grises">
                    <TileLayer
                      url = "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.jpg"
                    />
                  </BaseLayer>
                  <BaseLayer name = "Topográfico">
                    <TileLayer                  
                      url = "https://server.arcgisonline.com/arcgis/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}"
                    />
                  </BaseLayer>
                  <BaseLayer checked name = "Calles">
                    <TileLayer                  
                      url = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                    />
                  </BaseLayer>
                  <BaseLayer name = "Satélite">
                    <TileLayer                  
                      url = "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    />
                  </BaseLayer>              
                </LayersControl>
              </>
            )}
            isFullScreen
            isPrint
            zoomControl
          />
          <div id = "interactiveMapLegendContainer">
              <Button
                aria-describedby = { legendId }
                size = "small"
                onClick = { handleOnClickLegend }
                className = { styles.button }
                variant = "contained"
              >
                <Typography className={styles.legendLabel}>
                  { openLegend ? "Ocultar Leyenda" : "Mostrar Leyenda" }
                </Typography>
              </Button>
              <Popover
                id = { legendId }
                className = { styles.popup }
                open = { openLegend }
                onClose = { handleCloseLegend }
                anchorEl = { anchorLegend }
                anchorOrigin = {{ vertical: "top", horizontal: "center" }}
                transformOrigin = {{ vertical: "bottom", horizontal: "center" }}
              >
                <InteractiveMapLegend rangos = { getRange() }/>
              </Popover>
          </div>
        </>
    )
}