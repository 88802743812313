import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useRef } from 'react';

function BasicTable({ 
   columns, rows, stickyHeader=false, children, 
   bodyLeft=false, className="", size="small", mobileResponsive,
}) 
{
   const tableRef = useRef();

   const TableHeadRow = () => (
      <TableRow role="heading">
         {  columns?.map( (col, i) => (
               <th key={`${i}-${col}`}>{ col }</th>
            ))
         }
      </TableRow>
   )

   useEffect(()=>{
      mobileResponsive && 
         tableRef.current.classList.add( "ResponsiveTable" );
   },[mobileResponsive])

   return (
      <TableContainer className={ className }>
         <Table size={ size } className="BasicTable" stickyHeader={ stickyHeader } ref={ tableRef } aria-label="basic table">
            
            {
               !mobileResponsive && (
                  <TableHead>
                     <TableHeadRow />
                  </TableHead>
               )
            }

            <TableBody className={ bodyLeft ? "BodyLeft" : "" }>
               {
                  mobileResponsive && (
                     <TableHeadRow />
                  )
               }
               { !rows ? children : rows.map( (row, index) => (
                  <TableRow key={`row-${index}`}>
                     {
                        row.map( (e, i) => <TableCell key={`cell-${index}-${i}`} >{ e }</TableCell> )
                     }
                  </TableRow>
               ) ) }
            </TableBody>
         </Table>
      </TableContainer>
   )
}
export default BasicTable