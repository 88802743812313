// export const CLOUD_URL = "https://s3.amazonaws.com/testing.sae/";
export const TESTING_CLOUD_URL = "https://s3.amazonaws.com/sgc.sites.gov.co/";
export const CLOUD_URL = "https://archive.sgc.gov.co/";
export const BACKUP_URL = "https://xylon.sgc.gov.co/eqevents/events/";
export const www2 = "https://www2.sgc.gov.co";
export const REPORT_URL = "https://sismosentido.sgc.gov.co"
export const SGC_API_URL = "https://api.sgc.gov.co/"

// HOME ENDPOINTS
export const HOME_MENU = `/feed/v1.0.1/home/data/menus/sgc_menus.json`;
export const HOME_BANNER = `/feed/v1.0.1/home/data/banner/banner.json`;
export const HOME_EVENTS = "/feed/v1.0/notifications/notifications.json";
export const HOME_SECTIONS = "/feed/v1.0.1/home/data/home_sections/home_sections.json";
export const HOME_SERVICES = "/feed/v1.0.1/home/data/home_tema/home_tema.json";
export const HOME_TOPIC = "/feed/v1.0.1/home/data/month_topic/month_topic.json";
export const HOME_NEWS = "/feed/v1.0.1/home/data/news/news.json";

export const ALERT_LEVELS = '/feed/v1.0/volcano/alert_leves.json'

// CATALOGADOR URLS
export const queryUrl = "https://apicatalogador.sgc.gov.co:89/"
export const geoFilter = "https://apicatalogador.sgc.gov.co:90/geoserver/sgc/wms/"
export const geoserver = "https://apicatalogador.sgc.gov.co:90/geoserver/sgc/wms/";
export const tensorServer = "https://xylon.sgc.gov.co/eqevents/events/"

/* // PRE-PROD URLS
export const queryUrl = "http://catalogadorp.sgc.gov.co:89/"
export const geoFilter = "http://catalogadorp.sgc.gov.co:17000/geoserver/sgc/wms"
export const geoserver = "http://catalogadorp.sgc.gov.co:17000/geoserver/sgc/wms/";
// export const queryUrl = "http://190.121.155.199:89/"
// export const tensorServerTwo = "https://xylon.sgc.gov.co/eqevents2/events2/"
*/