import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import HamburgerMenu from 'react-hamburger-menu';
import HomeProducts from './HomeProducts';

function HomeMenu () {
    
    const [ openMenu, setOpenMenu ] = useState( false );
    const responsive = useMediaQuery( '(max-width: 1200px)' );

   const setMenuDisplay = () => {
      if (responsive) {
         return openMenu ? "block" : "none";
      }
      return "block";
   }

   const handleClickBurger = () => {
      setOpenMenu(prev => !prev);
   }

    return (
      <>
         <div className = "HmMenuBurger" role="button" aria-label="Abrir/Cerrar Menú">
            <HamburgerMenu
               isOpen = { openMenu }
               menuClicked = { handleClickBurger }
               width = { 30 }
               height = { 20 }
               strokeWidth = { 3 }
               rotate = { 0 }
               color = 'white'
               animationDuration = { 0.5 }
            />
         </div>
         
         <nav 
            role="navigation"
            aria-label="Menú Principal"
            className = "HmProductsContent size_standard" 
            style={{ display: setMenuDisplay() }} 
         >
            <HomeProducts />
         </nav>
      </>
    )
}

export default HomeMenu;