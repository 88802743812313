import PerceivedTwo from "assets/icons/EQViewer/PerceivedIntensity/PerceivedTwo.svg";
import PerceivedThree from "assets/icons/EQViewer/PerceivedIntensity/PerceivedThree.svg";
import PerceivedFour from "assets/icons/EQViewer/PerceivedIntensity/PerceivedFour.svg";
import PerceivedFive from "assets/icons/EQViewer/PerceivedIntensity/PerceivedFive.svg";
import PerceivedSix from "assets/icons/EQViewer/PerceivedIntensity/PerceivedSix.svg";
import PerceivedSeven from "assets/icons/EQViewer/PerceivedIntensity/PerceivedSeven.svg";
import PerceivedSevenMore from "assets/icons/EQViewer/PerceivedIntensity/PerceivedSevenMore.svg";
import Epicenter from "assets/icons/EQViewer/PerceivedIntensity/Epicenter.svg";

const reportItems = {
    //  "0": Inactive,
    "1": { icon: PerceivedFive},
    "2": { icon: PerceivedTwo},
    "3": { icon: PerceivedThree},
    "4": { icon: PerceivedFour},
    "5": { icon: PerceivedFive},
    "6": { icon: PerceivedSix},
    "7": { icon: PerceivedSeven},
    "8": { icon: PerceivedSevenMore},
    "9": { icon: PerceivedSevenMore},
    "10": { icon: PerceivedSevenMore},
    "100": { icon: Epicenter},
};
Object.freeze(reportItems);


const epicenterItems = {
    
    "100": { icon: Epicenter},
};

Object.freeze(epicenterItems);

const reportIcon = (level) => {
    let icon = null;
    if (reportItems.hasOwnProperty(level)) {
        icon = reportItems[level].icon;
    }
    return icon;
};

const epicenterIcon = (level) => {
    let icon = null;
    if (epicenterItems.hasOwnProperty(level)) {
        icon = epicenterItems[level].icon;
    }
    return icon;
};

export { reportIcon, epicenterIcon };
