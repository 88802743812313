import React from "react"
import CloseIcon from '@material-ui/icons/Close';

function CloseButton({ onClick, size="small", fontSize=null }) {

   const handleClickIcon = () => {
      onClick();
   }

   return (
      <button className="CloseButton" type="button" title="Cerrar" onClick={ handleClickIcon }>
         <CloseIcon fontSize={ size } style={{ fontSize: fontSize ? fontSize : "" }} />
      </button>
   )
}

export default CloseButton;
