
const EQSummary = ({ id = null, title, body }) => {
  return (
    <div className="EQSummary TitleText">
      <div className="EQSummaryTitle">
        <h2>
          { title }
        </h2>
      </div>
      { id && (
        <p className="summary_hidden">
          <span>
            <strong>{ id.header }:  </strong>
          </span>
          <span>
            { id.body }
          </span>
        </p>
        )
      }
      { body }
    </div>
  );
}

export default EQSummary;