import { memo, useEffect, useRef, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import L from 'leaflet';
import { pixelCalculator } from "Components/Atoms/A_Reusable/CalculatorPixelRadiusForEQ";
import { calculateColor } from "Components/Atoms/A_Reusable/CalculatorDepthColors";
import { formatCdi } from "utils/seismicityUtils";

function SeismRelevantMarker({ 
   lat, lng, depth, time, mag, place, cdi, 
   markerDelay, relevant, reset, stop, onShowEvent, selected,
}) {

   const [ show, setShow ] = useState( false );
   const markerTimeoutRef = useRef();
   const markerRef = useRef();
   const map = useMap();

   const iconPixel = pixelCalculator( Math.floor( mag ) )*2.5;
   const iconColor = calculateColor( depth );
   
   const eventIcon =  L.divIcon({
      iconSize: [ iconPixel, iconPixel ],
      className: "animated-marker blinking relevant-marker", 
      html:`
      <div class="marker-pulsate" style="box-shadow: 0 0 2px 3px ${ iconColor };">
      </div><div class="relevant-marker-text"><span>${ relevant }</span></div>`,
   });

   const handleSelectedMarker = () => {
      let latitude = lat;
      if ( window.innerWidth <= 600 ){ latitude = latitude - 0.150; }

      map && map.setView([latitude, lng], 10);
      markerRef.current && markerRef.current.openPopup();
   }
   
   // To show the marker timeout animation
   useEffect( () => {
      show && setShow( false ); // to apply reset

      markerTimeoutRef.current = window.setTimeout( () => {
         setShow( true );
         onShowEvent( time );
      }, markerDelay )

      return () => window.clearTimeout( markerTimeoutRef.current );
      // eslint-disable-next-line
   }, [ markerDelay, reset ] );

   // To stop the marker timeout animation
   useEffect( () => {
      if ( stop ){ 
         window.clearTimeout( markerTimeoutRef.current );
         !show && setShow( true );
      }
      // eslint-disable-next-line
   }, [ stop ] );

   // To handle the selected marker
   useEffect( () => {
      if ( selected ){
         handleSelectedMarker();
      } else if ( markerRef.current?.isPopupOpen() ){
         markerRef.current.closePopup();
      }
      // eslint-disable-next-line
   }, [selected])

   return (
      show && (
         <Marker 
            ref={ markerRef }
            position={[ lat, lng ]}
            icon={ eventIcon }
            eventHandlers={{
               add: function(e){
                  var myIcon =  e.target._icon;
                  myIcon.style.backgroundColor = iconColor;
               },
            }}
         >
            {
               <Popup className="seismicity-popup" maxWidth={400} autoPan={ false } closeButton={false} autoClose={false} closeOnClick={false}>
                     <span className="title">{ `${ relevant }. ${ place ?? "" }` }</span>
                     <span>{ `Magnitud: ${ mag.toFixed(1) }` }</span> < br/>
                     <span>{ `Profundidad: ${ depth < 30 ? "Superficial" : depth + " km" }` }</span> < br/>
                     <span>
                        { cdi > 0 && `Intensidad máxima reportada: ${ formatCdi( cdi ) }` }
                     </span>
               </Popup>   
            }
         </Marker>
      )
   )      
}

export default memo( SeismRelevantMarker );