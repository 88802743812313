import React, { useState, useEffect } from 'react';
import { FormControl, TextField } from '@mui/material';

export const UseText = (
    { name, init, trigger, variant, InputProps, className = "defaultTextField", label = "" }
) => {

    const [ value, setValue ] = useState( init );

    const handleChange = ({ target }) => {
        setValue( target.value );
        trigger && trigger( target.value, name );
    }

    useEffect(() => {
        init !== undefined && setValue( init );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ init ]);

    return (
        <FormControl fullWidth>
            <TextField
                className = "TextinField"
                value = { value }
                variant = { variant || "standard" }
                name = { name }
                onChange = { handleChange }
                label = { label }
                InputProps = { InputProps }
            />
        </FormControl>
    )    
}
