import { useSelector } from 'react-redux';
import back_arrow from 'assets/icons/EQDetail/ModalImg/back_arrow.svg';
import next_arrow from 'assets/icons/EQDetail/ModalImg/next_arrow.svg';
import close_icon from 'assets/icons/EQDetail/ModalImg/close_icon.svg';
import InitModal from 'Components/Atoms/A_Reusable/InitModal';

function ModalImg() {

   const modalImg = useSelector( state => state.modalImg );
   const { closeModal, handleNext, handlePrev } = InitModal;

    return(
        <div className="ModalImg animated fadeIn" id="ModalImg">
            <div className="ModalControllers">
                <span onClick={closeModal} className="ModalClose">
                    <img src={close_icon} alt="modal_icon"></img>
                </span>
                <span id="PrevModal" onClick={handlePrev} className="PrevInvisibleModal">
                    <img src={back_arrow} alt="modal_icon"></img>
                </span>
                <span id="NextModal" onClick={handleNext} className="NextArrow">
                    <img src={next_arrow} alt="modal_icon"></img>
                </span>
                <div className="ModalImgContainer">
                    <div className="ImgContainer" id="ImgContainer">
                        {
                            modalImg.map((element, index)=>{
                                return(
                                    <img className="ModalSlider" src={element} alt={`modal${index}`} key={`modal${index}`} id={`modal${index}`}></img>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalImg;
