import SeismRelevantMarker from "Components/Molecules/M_SeismicitySummary/SeismRelevantMarker";
import { SeismicityContext } from "context/SeismicityContext/SeismicityContext";
import { useEffect } from "react";
import { memo } from "react";
import { useContext } from "react";
import SeismSimpleMarker from "./SeismSimpleMarker";

function BuildSeismMarkers({ setEventDate, insularEvents = null }) {

   const { currentEvents, selectedEvent, initSimulation, stopSimulation, resetSimulation } = useContext( SeismicityContext );

   const events = !insularEvents ? currentEvents : insularEvents;

   useEffect( () => {
      stopSimulation && !insularEvents &&
         setEventDate( currentEvents?.at(-1)?.time ?? null );
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ stopSimulation ]);

   return (
      initSimulation &&
         events?.map( event => {
            if ( event.relevant === 0 ){
               return (
                  <SeismSimpleMarker 
                     key={ event.id }
                     lat={ event.latitude }
                     lng={ event.longitude }
                     depth={ event.depth }
                     mag={ event.magnitude }
                     time={ event.time }
                     markerDelay={ event.delay }
                     onShowEvent={ setEventDate }
                     reset={ resetSimulation }
                     stop={ stopSimulation }
                  />
               )
            } else {
               const selected = selectedEvent && event.id === selectedEvent.id;
               return (
                  <SeismRelevantMarker
                     key={ event.id }
                     lat={ event.latitude }
                     lng={ event.longitude }
                     depth={ event.depth }
                     mag={ event.magnitude }
                     time={ event.time }
                     markerDelay={ event.delay }
                     relevant={ event.relevant }
                     place={ event.place }
                     cdi={ event.cdi }
                     reset={ resetSimulation }
                     stop={ stopSimulation }
                     onShowEvent={ setEventDate }
                     selected={ selected }
                  /> 
               )
            }
         })
   )
}
export default memo( BuildSeismMarkers );