
export const months = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];

export const ActualDate = () => {
   const date = new Date();
	return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
}

export const colorsMap = ({ localTime }, date ) => {
	/**
	 *  day: 	'#48C9B0',
        week: 	'#52BE80',
        month: 	'#F4D03F',
        year: 	'#D35400',
        more: 	'#99A3A4'
	 */
	localTime = localTime.replace(" ", "T")
	
	const serv_date = new Date( localTime )
	
	const result = date.getTime() - serv_date.getTime();
	const time = result / 1000 / 60 / 60 / 24;

	if ( time < 7 ) {
		return '#48C9B0' 
	}
	else if ( time < 30 ){
		return '#52BE80'
	}
	else if ( time < 365 ){
		return '#F4D03F'
	}
	else if ( time === 365 ){
		return '#D35400'
	}
	return '#99A3A4';
}

export const getLocalTime = time => {
	time = time.includes( " " )
		? time.replace( " ", "T" ) : time;
	const date = new Date( time );
	return `${ date.getFullYear() }-${ date.getMonth() + 1 }-${ date.getDate() } ${ date.getHours() }:${ date.getMinutes() }`;
}

export const onLyLocalTime = time => {
	const date = new Date( time );
	date.setHours( date.getHours() - 5 );
	return date;
}