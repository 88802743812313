import { buildCarousel } from "Components/Atoms/A_Home/HmBuildCarrousel"
import { buildArrowNext, buildArrowPrev } from "Components/Atoms/A_Home/HmCarouselArrows"
import { useSelector } from "react-redux"
import { Carousel } from "react-responsive-carousel"
import SGCMiniLoader from "Components/Atoms/A_Reusable/SGCMiniLoader"

function HomeEventsCarousel() {

   const events = useSelector( state => state.notifications )

   return (
      events[0] ?
         <div className="EventsCarouselContainer" role="presentation">
            <Carousel
               className="EventsCarousel"
               renderArrowPrev={ buildArrowPrev }
               renderArrowNext={ buildArrowNext }
               infiniteLoop={ true }
               autoPlay={ false }
               interval={ 10000 }
               showThumbs={ false }
               showStatus={ false }
               swipeable={false}
            > 
               { buildCarousel( events ) }

            </Carousel>
         </div>
         : <SGCMiniLoader boxHeight="75vh" text="Cargando eventos" />
   )
}
export default HomeEventsCarousel