import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { loadDetailEvent, show_menu, changeMenuIcon, loadVolcanos } from "redux/actionsCreators";
import EQMenuVolcanos from "Components/Organisms/O_EQDetailsVolcanos/EQMenuVolcanos";
import NotFound from "Components/Pages/NotFound";
import Loader from "Components/Pages/Loader";
import BlockUi from "@availity/block-ui";
import ModalImg from "Components/Molecules/M_Reusable/ModalImg";
import { EQContentManagerVolcano } from "Components/Organisms/O_EQDetailsVolcanos/EQContentManagerVolcano";
import { FilterVolcanos } from "Components/Molecules/M_VViewer/FilterVolcanos";
import { reqResApi } from "api/reqRes";
// import LegalHeader from "Components/Atoms/A_Home/LegalHeader";
import { removeAccents } from "Components/Atoms/A_Reusable/filterEvents";
import { Link, useParams } from "react-router-dom";
import { useVolcanoLevelsRequest } from "hooks/useVolcanoLevels";
import EQDetailHeader from "Components/Templates/T_EQDetail/EQDetailHeader";
import EQViewerFooter from "Components/Templates/T_EQViewer/EQViewerFooter";

const notFound_component = notFound => (
	notFound === true ? <NotFound /> : <Loader />
)

const EQDetailVolcano = (
	{ menuIcon, loadVolcanos, listVolcanos, showMenu, match }
) => {

	const [ state, setState ] = useState({
		active: menuIcon, blocking: true, notFound: false
	});
	const [ order, setOrder ] = useState( 10 );
	const historyRef = useRef([])
	const [ volcanosFilter, setFilter ] = useState( historyRef.current )
   const { getVolcanoLevels } = useVolcanoLevelsRequest();
	const txtRef = useRef( "" );
   const params = useParams();

	const laodInitialData = async () => {
		document.title = "SGC - Volcán Generalidades";
      getVolcanoLevels();
		// window.screen.width < 1237 && setState({ active: false });
		try {
			const { data } = await reqResApi.get (
				`feed/v1.0/volcano/volcanos.json`
			);
			if ( data ) {
				historyRef.current = data.features;
				setFilter( historyRef.current );
				loadVolcanos( historyRef.current );
				setState({ ...state, blocking: !state.blocking });
			}
			else { setState({ ...state, notFound: true }) }

		} catch { setState({ ...state, notFound: true }) }

	}

	const setShownItems = ( filterValue = null ) => {
		filterValue = filterValue?.toLowerCase() || txtRef.current;
		const volcanos = historyRef.current
			.filter(({ properties }) => removeAccents(
				properties.VolcanoName.toLowerCase()
			).includes( removeAccents( filterValue ) ));
		setFilter( volcanos )
	}

	const handleOrder = ({ target }) => setOrder( target.value );

	const handleFilters = ( value, name ) => {
		txtRef.current = value;
		setShownItems( value )
	}

	useEffect(() => {
		laodInitialData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		listVolcanos[0] ? (
			<BlockUi
				tag = "div"
				blocking = { state.blocking }
            className="SgcContent"
            message="Cargando..."       
			>
            <ModalImg />
            <div className="EQcontainer">
               <header className="EQheader" id="div">
                  {/* <LegalHeader /> */}
                  <EQDetailHeader />
               </header>
               <main
                  className = { `EQmain ${ showMenu }` }
               >
                  <div className="EQmenu">
                     <ul className="products-list">
                        <div className="menuheader">
                           <Link to="/volcanes">
                              <h2>Volcanes de Colombia</h2>
                           </Link>
                        </div>
                        <div className="EQMenu-list-container TitleText">

                           <div className="volcanos-list-filter">
                              <FilterVolcanos
                                 handleFilter = { handleFilters }
                                 handleOrder = { handleOrder }
                                 orderEvent = { order }
                                 txtFilter = { txtRef.current }
                              />
                           </div>
                           <div id="EQMenu_list_content_volcano">
                                 <EQMenuVolcanos
                                    items = { volcanosFilter }
                                    order = { order }
                                    active = { params.id }
                                    id = { params.id }
                                 />
                              
                           </div>
                        </div>
                     </ul>
                  </div>

                  <div className="EQproduct">
                     <EQContentManagerVolcano
                        id = { params.id }
                        list = { listVolcanos }
                     />
                  </div>
               </main>
               
               <footer className="footer">
					   <EQViewerFooter />
				   </footer>

            </div>
			</BlockUi>
		) : notFound_component(state.notFound)
	)
}

const mapStateToProps = state => ({
	eventDetail: state.detailEvent,
	showMenu: state.showMenu,
	menuIcon: state.menuIcon,
	listVolcanos: state.volcanos
});
const mapDispatchToProps = {
	loadDetailEvent,
	show_menu,
	changeMenuIcon,
	loadVolcanos
};
export default connect(mapStateToProps, mapDispatchToProps)(EQDetailVolcano);