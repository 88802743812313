import axios from 'axios';
import { BACKUP_URL, CLOUD_URL, TESTING_CLOUD_URL } from 'utils/urls';

export const convertApi = axios.create({
    baseURL: '/convert'
})

export const reqResApi = axios.create({
    baseURL: CLOUD_URL
});

export const reqResBackUp = axios.create({
    baseURL: BACKUP_URL
});

export const reqResTestApi = axios.create({
   baseURL: TESTING_CLOUD_URL
});
