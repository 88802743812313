// Cdi imports
import PerceivedTwo from "assets/icons/EQViewer/PerceivedIntensity/PerceivedTwo.svg";
import PerceivedThree from "assets/icons/EQViewer/PerceivedIntensity/PerceivedThree.svg";
import PerceivedFour from "assets/icons/EQViewer/PerceivedIntensity/PerceivedFour.svg";
import PerceivedFive from "assets/icons/EQViewer/PerceivedIntensity/PerceivedFive.svg";
import PerceivedSix from "assets/icons/EQViewer/PerceivedIntensity/PerceivedSix.svg";
import PerceivedSeven from "assets/icons/EQViewer/PerceivedIntensity/PerceivedSeven.svg";
import PerceivedSevenMore from "assets/icons/EQViewer/PerceivedIntensity/PerceivedSevenMore.svg";
// Mmi imports
import InstrumentalOne from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalOne.svg";
import InstrumentalTwoThree from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalTwoThree.svg";
import InstrumentalFour from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalFour.svg";
import InstrumentalFive from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalFive.svg";
import InstrumentalSix from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalSix.svg";
import InstrumentalSeven from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalSeven.svg";
import InstrumentalEight from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalEight.svg";
import InstrumentalNine from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalNine.svg";
import InstrumentalTen from "assets/icons/EQViewer/InstrumentalIntensity/InstrumentalTen.svg";


const BuildMmiIcons = (mmi) => {
    if (!mmi){
        return ''
    }
    switch(mmi) {
        case 2:
            return {
                img: InstrumentalOne,
                data: 1,
                alt:'Intensidad Instrumental - No sentido'
            } 
        case 3:
            return  {
                img: InstrumentalTwoThree,
                data: 2,
                alt: 'Intensidad Instrumental - Débil'
            }
        case 4:
            return {
                img: InstrumentalFour,
                data: 4,
                alt: 'Intensidad Instrumental - Leve'
            }
        case 5:
            return {
                img: InstrumentalFive,
                data: 5,
                alt: 'Intensidad Instrumental - Moderado'
            }
        case 6:
            return {
                img: InstrumentalSix,
                data: 6,
                alt: 'Intensidad Instrumental - Fuerte'
            }
        case 7:
            return {
                img: InstrumentalSeven,
                data: 7,
                alt: 'Intensidad Instrumental - Muy Fuerte'
            }
        case 8:
            return {
                img: InstrumentalEight,
                data: 8,
                alt: 'Intensidad Instrumental - Severo'
            }
        case 9:
            return {
                img: InstrumentalNine,
                data: 9,
                alt: 'Intensidad Instrumental - Violento'
            }
        case 10:
            return {
                img: InstrumentalTen,
                data: 10,
                alt: 'Intensidad Instrumental - Extremo'
            }
        default: 
            return {
                className: 'shCell',
                data: mmi
            }
    }
}

const BuildCdiIcons = (cdi) => {
    if (!cdi){
        return
    }
    switch(cdi){
        case 2:
            return {
                img: PerceivedTwo,
                data: 2,
                alt:'Intensidad Percibida – Apenas Sentido'
            }
        case 3:
            return {
                img: PerceivedThree,
                data: 3,
                alt: 'Intensidad Percibida – Sentido Levemente'
            }
        case 4:
            return {
                img: PerceivedFour,
                data: 4,
                alt: 'Intensidad Percibida – Sentido Ampliamente'
            }
        case 5:
            return {
                img: PerceivedFive,
                data: 5,
                alt: 'Intensidad Percibida – Sentido Fuertemente'
            }
        case 6:
            return {
                img: PerceivedSix,
                data: 6,
                alt: 'Intensidad Percibida – Daño Leve'
            }
        case 7:
            return {
                img: PerceivedSeven,
                data: 7,
                alt: 'Intensidad Percibida – Daño Moderado'
            }
        case 8:
            return {
                img: PerceivedSevenMore,
                data: 8,
                alt: 'Intensidad Percibida – Daño Severo'
            }
        default:
            return {
                className: 'shCell',
                data: cdi
            }
    }
}
const BuildIcons = { BuildMmiIcons, BuildCdiIcons }
export default BuildIcons;