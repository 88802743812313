import { useState } from "react";
import { AutoStories as LegendIcon } from "@mui/icons-material";
import { Fade } from "@mui/material";

import events_legend from "assets/icons/Reusable/events_legend.svg";

function SeismLegend({ title }) {

   const [ openLegend, setOpenLegend ] = useState( false );

   const handleClickIcon = () =>{
      setOpenLegend( (prev) => !prev );
   }

   return (
      <div className="SettingsItemContainer">
         <div onClick={ handleClickIcon } className={`SettingsIcon ${ openLegend ? "active" : "" }`} title={ title }>
            <LegendIcon />
         </div>
         <Fade in={ openLegend }>
            <div className="MapPanel SeismicityLegend">
               <img src={ events_legend } alt="Leyenda" width={120} />
            </div>
         </Fade>
      </div>
   )
}
export default SeismLegend