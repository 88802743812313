import { TileLayer } from "react-leaflet"
import { useSelector } from "react-redux";

function SgcMapTileLayer({ attribution }) {

   const mapLayer = useSelector( state => state.appliedMapLayer );

   return (
      <TileLayer
         attribution={ attribution ?? ""}
         url={ mapLayer }
      />
   )
}
export default SgcMapTileLayer;