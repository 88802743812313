export const removeAccents = string =>
    string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");


export const buildLevel1 = volcanos => volcanos
    .filter(({ properties }) => properties.activityLevel === 1)
    .sort((a, b) => (
        ('' + a.properties.VolcanoName).localeCompare(
            b.properties.VolcanoName
        )
    ))

export const buildLevel2 = volcanos => volcanos
    .filter(({ properties }) => properties.activityLevel === 2)
    .sort((a, b) => (
        ('' + a.properties.VolcanoName).localeCompare(
            b.properties.VolcanoName
        )
    ))

export const buildLevel3 = volcanos => volcanos
    .filter(({ properties }) => properties.activityLevel === 3)
    .sort((a, b) => (
        ('' + a.properties.VolcanoName).localeCompare(
            b.properties.VolcanoName
        )
    ))

export const buildLevel4 = volcanos => volcanos
    .filter(({ properties }) => properties.activityLevel === 4)
    .sort((a, b) => (
        ('' + a.properties.VolcanoName).localeCompare(
            b.properties.VolcanoName
        )
    ))

export const filterByLatN = volcanos => volcanos
    .filter(({ properties }) => properties.latitude >= 3.4 && properties.latitude <= 15)
    .sort((a, b) => (
        ('' + a.properties.VolcanoName).localeCompare(
            b.properties.VolcanoName
        )
    ))

export const filterByLatC = volcanos => volcanos
    .filter(({ properties }) => properties.latitude >= 1.6 && properties.latitude < 3.4)
    .sort((a, b) => (
        ('' + a.properties.VolcanoName).localeCompare(
            b.properties.VolcanoName
        )
    ))

export const filterByLatS = volcanos => volcanos
    .filter(({ properties }) => properties.latitude >= -2 && properties.latitude < 1.4)
    .sort((a, b) => (
        ('' + a.properties.VolcanoName).localeCompare(
            b.properties.VolcanoName
        )
    ))

    
const filterEvents = (
    mapBounds,
    magBounds,
    depthBounds,
    dateBounds,
    searchString,
    filterCode,
    events
) => {
    const mapBoundsFilter = (event) => {
        const lat = event.geometry.coordinates[0];
        const lng = event.geometry.coordinates[1];
        const intoLngBounds = lng >= mapBounds.west && lng <= mapBounds.east;
        const intoLatBounds = lat >= mapBounds.south && lat <= mapBounds.north;
        return intoLngBounds && intoLatBounds;
    };

    const magBoundsFilter = (event) => {
        const mag = event.properties.mag;
        const minBound = magBounds[0];
        const maxBound = magBounds[1];
        return mag >= minBound && mag <= maxBound;
    };

    const depthBoundsFilter = (event) => {
        const depth = event.geometry.coordinates[2];
        const minBound = depthBounds[0];
        const maxBound = depthBounds[1];
        return depth >= minBound && depth <= maxBound;
    };

    const dateBoundsFilter = event => {
        const date = new Date(event.properties.localTime.replace(" ", "T"));;
        const minBound = dateBounds[0];
        const maxBound = dateBounds[1];
        return date >= minBound && date <= maxBound;
    };

    const propertiesFilter = (events, filterCode, searchString) => {
        const removeAccents = (string) =>
            string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const filterByName = (event) =>
            removeAccents(event.properties.place.toLowerCase()).includes(
                searchString.toLocaleLowerCase()
            );

        const filterByCdi = (event) =>
            event.properties.cdi !== 0 && event.properties.cdi != null;

        const filterByMmi = (event) =>
            event.properties.mmi !== 0 && event.properties.mmi != null;

        const filterBySm = (event) => event.properties.sm === true;

        let newEvents = events.filter(filterByName);

        switch (filterCode) {
            case 10:
                return newEvents.sort((a, b) => {
                    const date1 = a.properties.localTime.replace(" ", "T");
                    const date2 = b.properties.localTime.replace(" ", "T");
                    return ("" + date2).localeCompare(date1);
                });
            case 20:
                return newEvents.sort((a, b) => {
                    return ("" + a.properties.localTime).localeCompare(b.properties.localTime)
                });
            case 30:
                return newEvents.sort((a, b) => {
                    return ("" + b.properties.mag).localeCompare(a.properties.mag);
                });
            case 40:
                return newEvents.sort((a, b) => {
                    return ("" + a.properties.mag).localeCompare(b.properties.mag)
                });
            case 50:
                newEvents = newEvents.filter(filterByCdi);
                return newEvents.sort((a, b) => {
                    return ("" + b.properties.cdi).localeCompare(a.properties.cdi)
                });
            case 60:
                newEvents = newEvents.filter(filterByMmi);
                return newEvents.sort((a, b) => {
                    return ("" + a.properties.mmi).localeCompare(b.properties.mmi)
                });
            case 70:
                return newEvents.filter(filterBySm);
            default:
                return newEvents;
        }
    };

    let filteredEvents = events;
    filteredEvents = propertiesFilter(filteredEvents, filterCode, searchString);

    if (mapBounds.north !== mapBounds.south) {
        filteredEvents = filteredEvents.filter(mapBoundsFilter);
    }
    if (magBounds.length > 0) {
        filteredEvents = filteredEvents.filter(magBoundsFilter);
    }
    if (depthBounds.length > 0) {
        filteredEvents = filteredEvents.filter(depthBoundsFilter);
    }
    if (dateBounds.length > 0) {
        filteredEvents = filteredEvents.filter(dateBoundsFilter);
    }

    return filteredEvents;
};

export { filterEvents };
