import { useSelector } from "react-redux";
import EQmmiTabs from "Components/Molecules/M_EQDetails/EQMmiTabs";

function EQMmi () {

   const event = useSelector( state => state.detailEvent );

   const mmi = event[0].properties.products.mmi;
   document.body.scrollTop = 0; // For Safari
   document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
   
   return (
      <div className="EQMmi">
         <div className="EQSectionContainer GrayBordered">
            <EQmmiTabs
               mmi = { mmi }
            />
         </div>
      </div>
   )
}

export default EQMmi;
