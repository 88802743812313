import React, { useRef, Fragment, useState, useContext, useEffect } from "react";
import { TableConsult } from "Components/Organisms/O_Query/TableConsult";
import { makeStyles, Typography, Chip, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'; 
import LegalHeader from "Components/Atoms/A_Home/LegalHeader";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { sizeRequest } from "hooks/useCatalogRequest";
import { QueryContext } from "context/QueryContext";

const useStyles = makeStyles( theme => (
    {
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: "bold",
            color: "#626262",
            minHeight: 'max-content',
            margin: '10px 0'
        },
        chip: {
            margin: theme.spacing(0.5),
        },
    }
));

const SIZE_MEASURES = {
    minHeight: 500,
    restTableHeight: 320,
    bannerHeight: 70  
};

export const TableConsultation = (
    { userQueryRef, buildPage, searchByID, legal }
) => {
    
    const {
        activeEvents, queryCount, actualPage
    } = useContext( QueryContext );
    const totalPageRef = Math.ceil( queryCount / sizeRequest );
    const classes = useStyles();
    const orderRef = useRef(0);
    const [ expanded, setExpanded ] = useState( false );
    
    const getTableBodyHeight = ( restHeight ) => {
        if (window.innerHeight > SIZE_MEASURES.minHeight) return window.innerHeight - restHeight;
        return window.innerHeight - SIZE_MEASURES.bannerHeight;
    } 

    const [ tableHeight, setTableHeight ] = useState( getTableBodyHeight( SIZE_MEASURES.restTableHeight ) );

    const validateKeyText = key => (
        key === 'Tensor Momento'
            ? userQueryRef[ key ]
                .map(({ text }) => text ).join('. ')
            : userQueryRef[ key ]
    )

    const eventsDownload = () => activeEvents.map( e => ({
        id: e.id,
        estado: e.properties.status,
        region: e.properties.place,
        tiempo_origen: e.properties.localTime,
        magnitud: e.properties.mag,
        latitud: e.geometry.coordinates[0],
        longitud: e.geometry.coordinates[1],
        profundidad: e.geometry.coordinates[2],
        rms: e.properties.rms,
        gap: e.properties.gap,
        error_magnitud: e.properties.magnitudeError,
        error_profundidad: e.properties.depthError,
        error_latitud: e.properties.latitudeError,
        error_longitud: e.properties.longitudeError,
        intensidad_instrumental: e.properties.cdi,
        mas_informacion: `www.sgc.gov.co/detallesismo/${ e.id }/resumen`
    }));

    useEffect(() => {
        function onResize() {
            setTableHeight( getTableBodyHeight( SIZE_MEASURES.restTableHeight ) );
        }
        window.addEventListener('resize', onResize );
        return () => window.removeEventListener( 'resize', onResize )
    }, [])


    return (
        <div className="TableContainer">
            { legal && <LegalHeader /> } 
            <div className = "container-table-consultation">
                <h3>
                    Resultados de la búsqueda
                    <span>
                        Total de sismos en esta consulta: { queryCount < 100 ? activeEvents.length : queryCount }
                    </span>
                </h3>
                {
                    userQueryRef && (
                        <Accordion
                            expanded = { expanded }
                            onChange = { () => setExpanded( !expanded ) }
                            className = "accordion-table-consultation"
                        >
                            <AccordionSummary
                                aria-controls = "panel1a-content"
                                id = "panel1a-header"
                                expandIcon = { <ExpandMoreIcon style = {{ color: "#667f00" }} /> }
                            >
                                <Typography className = { classes.heading }>
                                    La información presentada fue filtrada de acuerdo a los siguientes
                                    parámetros:
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className = "info-accordion">
                                    {
                                        Object.keys( userQueryRef )
                                            .map( key => (
                                                userQueryRef[ key ].length === 0 ? (
                                                    <Fragment key = { key } />
                                                ) : (
                                                    <Chip
                                                        id = "chip" key = { key }
                                                        className = { classes.chip }
                                                        label = {
                                                            `${ key } : ${ validateKeyText( key ) }`
                                                        }
                                                    />
                                                )
                                            ))
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )
                }
            </div>
            <div className = "QBodyAvailable">
                {
                    activeEvents[0] && (
                        <TableConsult
                            tableHeight = { tableHeight }
                            eventsData = { activeEvents }
                            actualPage = { actualPage }
                            userQuery = { userQueryRef }
                            total = { queryCount }
                            maxPages = { totalPageRef }
                            buildPage = { buildPage }
                            orderRef = { orderRef.current }
                            eventsDownload = { eventsDownload }
                            searchByID = { searchByID }
                        />
                    )
                }
            </div>            
        </div>
    )
}