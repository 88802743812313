import { useRef, useEffect, useContext, useState } from 'react';
import { QTotalRequest, userQuery, QRequest } from "Components/Atoms/A_Query/QRequest";
import { QueryContext } from 'context/QueryContext';
import { initialQuery } from 'Components/Atoms/A_Query/QinitQuery';
import { MOBILE_SIZE } from 'Components/Organisms/O_Query/QueryBody';
import { saveToStorage } from 'Components/Atoms/A_Query/QueryControl';

const typesModalError = {
    error0: {
        state: false,
        message: 'No se encontraron Registros',
        type: 0
    },
    error50000: {
        state: false,
        message: 'Se han encontrado mas de 50000 resgistros',
        type: 50000
    },
    errorFalse: {
        state: false,
        message: 'Hubo un problema en la respuesta del servidor ofrecemos disculpas',
        type: -1
    }
}
/**
 * @returns validate if the view is for mobile or desktop
 */
export const defineDevice = () => {
    if ( window.innerWidth > 1200 ){ return 'desktop' }
    return window.innerWidth > 698 ? 'tablet' : 'movil';
}

export const sizeRequest = 100;

/**
 * the request and the tabulation is 
 * created by multiples of 1 - 101 - 201 - 301 ...
 */
 export const calculateFromRequest = page => 1 + ( page * sizeRequest )

export const handleRangeTab = ( tab, queryCount ) => {
    const tempTop = parseInt( `${ tab + 1 }00` );
    const top = queryCount < tempTop ? queryCount : tempTop;
    return tab === 0
      ? ` [${ tab }-${ top }]`
      : ` [${ tab }00-${ top }]`
}

export const useCatalogRequest = () => {

    const {
        setCountQuery, loadSelectedEvent,
        queryCount, activeEvents, pushPage,
        filterQuery, filterEvents, setTotalPages,
        loadQuery, actualPage, changeStateOrder
    } = useContext( QueryContext );

    const queryRef = useRef( initialQuery() );
    const totalPageRef = useRef(0);
    const fromRef = useRef(1);
    const sortRef = useRef([]);
    const userQueryRef = useRef( userQuery() );
    const [ modal, setModal ] = useState( false );
    const modalContent = useRef({
        message: '',
        closeModal: () => setModal( false ),
        btn: 'Recargar Página'
    });
    
    const updateUserQuery = () => {
        totalPageRef.current = 0;
        queryRef.current = initialQuery();
        userQueryRef.current = userQuery();
    };

    const validateCountEvents = response => {
        if ( response === 0 ) {
            return { ...typesModalError.error0 }
        }
        return !response
            ? { ...typesModalError.errorFalse }
            : { state: true, message: '', type: 1 }
    }

    const closeModalError = () => window.location.reload();

    const defineSort = value => {
        switch ( value ) {
            case 10:
                return [{ local_date: 'desc' }]
            case 20:
                return [{ local_date: 'asc' }]
            case 30:
                return [{ magnitude : 'desc' }]
            case 40:
                return [{ magnitude: 'asc' }]
            case 50:
                return [{ depth: 'desc' }]
            case 60:
                return [{ depth: 'asc' }]
            default: return []
        }
    }

    const sortData = async typeSort => {
        sortRef.current = defineSort( typeSort );
        sortRef.current[0] && saveToStorage( 'querySort', JSON.stringify( sortRef.current ))
        const tempForm = new FormData();
        tempForm.append(
            'data', JSON.stringify({
                pager: { from: fromRef.current, size: sizeRequest },
                source: [3],
                properties: ["*"],
                filter: queryRef.current,
                sort: { event: sortRef.current }
            })
        );
        changeStateOrder( true );
        const out = await QRequest( tempForm );
        changeStateOrder( false );
        out !== false
            ? pushPage( actualPage, out )
            : updateModal({
                closeModal,
                btn: 'Cerrar',
                message: typesModalError.errorFalse.message
            });
    }

    const loadTotalData = async () => {

        const totalForm = new FormData();
        totalForm.append(
            'data', JSON.stringify({
                pager: { from: 1, size: sizeRequest },
                source: [3],
                properties: ["*"],
                filter: queryRef.current,
            }
        ));
        const request = await QTotalRequest( totalForm, 1 );
        const validation = validateCountEvents( request );
        
        if ( validation.state ) {
            const pagesCounter = Math.ceil( request / sizeRequest );
            totalPageRef.current = pagesCounter;
            setTotalPages(pagesCounter);
            setCountQuery( request );
        }
        return { ...validation };

    }

    const closeModal = () => setModal( false );

    const updateModal = content => modalContent.current = { ...content }

    const makeRequest = async ( form, currentPage ) => {
        const request = await QRequest( form );
        if ( request !== false ) {
            pushPage( currentPage, request );
        } else {
            updateModal({
                closeModal,
                btn: 'Cerrar',
                message: typesModalError.errorFalse.message
            });
            setModal( true );
        }
    }

    const filterRequest = async ( form, currentPage ) => {
        changeStateOrder( true );
        const request = await QRequest( form );
        changeStateOrder( false );
        if ( request !== false ) {
            pushPage( currentPage, request );
        } else {
            updateModal({
                closeModal,
                btn: 'Cerrar',
                message: typesModalError.errorFalse.message
            });
            setModal( true );
        }
    }
    /**
     * @param {filter} queryRef param 
     * @param {userQueryForm} userQuery param 
     * @param {stateRef} stateRef param 
     */
    const filterData = ( filter, userQueryForm, stateRef ) => {
        queryRef.current = filter;
        userQueryRef.current = userQueryForm;
        saveToStorage( 'query', JSON.stringify( queryRef.current ));
        saveToStorage( 'userQuery', JSON.stringify( userQueryRef.current ));
        saveToStorage( 'QState', JSON.stringify( stateRef ));
        const tempForm = new FormData();
        tempForm.append(
            'data', JSON.stringify({
                pager: { from: fromRef.current, size: sizeRequest }, 
                source: [3],
                properties: ["*"],
                filter: queryRef.current,
                sort: { event: sortRef.current },
            })
        );
        filterRequest( tempForm, actualPage );
    }

    const initActiveEvents = async () => {
        const tempForm = new FormData();
        tempForm.append(
            'data', JSON.stringify({
                pager: { from: 1, size: sizeRequest },
                source: [3],
                properties: ["*"],
                filter: queryRef.current,
            }
        ));
        const request = await QRequest( tempForm );
        if ( request !== false ) {
            window.innerWidth < MOBILE_SIZE
                ? loadQuery( { activeEvents: request })
                : loadSelectedEvent (
                    { activeEvents: request, selectedEvent: request[0] }
                );
        } else {
            updateModal({
                closeModal: closeModalError,
                message: typesModalError.errorFalse,
                btn: 'RECAGAR PÁGINA'
            });
            setModal( true )
        }
    }

    const initData = () => !activeEvents[0] && initActiveEvents();

    const searchByID = async target => {
        if ( target ) {
            const form = new FormData();
            form.append(
                'data', JSON.stringify({
                    source: [ 3 ],
                    filter: {
                        event: {
                            id : { value : [ target ] }
                        }
                    }
                })
            );
            const out = await QRequest( form );
            return out && out[0]
                ? { updateFilter: filterQuery, out }
                : { out: false }
        }
        return filterEvents[0]
            ? { updateFilter: filterQuery, out: [] }
            : { out: false }
    }

    const buildPage = async page => {
        fromRef.current = calculateFromRequest( page - 1 );
        const tempForm = new FormData();
        tempForm.append( 'data', JSON.stringify({
            pager: { from: fromRef.current, size: sizeRequest },
            source: [3],
            properties: ["*"],
            filter: initialQuery(),
            sort: { event: sortRef.current },
        }))
        makeRequest( tempForm, page );
    }

    useEffect(() => {
        queryCount > 0 && initData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ queryCount ]);

    return {
        modal, userQueryRef, totalPageRef, loadTotalData,
        queryRef, closeModal, buildPage, closeModalError,
        modalContent: modalContent.current, setModal,
        updateModal, updateUserQuery, searchByID,
        sortData, filterData
    }
}