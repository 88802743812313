import React, { useState, useRef, useEffect } from 'react'
import { formatDateInit, initEndDefault, initStartDefault } from 'Components/Atoms/A_Query/QInitState';
import ReactShadowScroll from 'react-shadow-scroll/dist/ReactShadowScroll'
import { useViewerStyles } from '../O_EQViewer/MapSettingsPanel';
import SliderFilter from 'Components/Atoms/A_EQViewer/SliderFilter';
import { Qlimits } from 'Components/Atoms/A_Query/QueryMaxMin';
import { initialQuery } from 'Components/Atoms/A_Query/QinitQuery';
import { CatalogDateFilter } from 'Components/Atoms/A_EQViewer/CatalogDateFilter';
import { formatLocalTime } from 'Components/Atoms/A_Query/QueryControl';
import build_settings_label from "Components/Atoms/A_Reusable/SettingsSwith"
import { useDispatch, useSelector } from 'react-redux';
import { setMapLayer } from 'redux/actionsCreators';
import SwitchesList from 'Components/Atoms/A_EQViewer/SwitchesList';

const verifyStorage = key => {
    const value = sessionStorage.getItem( key );
    return value ? JSON.parse( value ): {}
}

export const CatalogSettings = ({ filterData, userQuery }) => {

   const dispatch = useDispatch();
   const mapLayers = useSelector(state => state.mapLayers);

   const handleLayerSwitches = ( mapLayer ) => {
      dispatch( setMapLayer( mapLayer ) );
   }

    const actualQueryRef = useRef( initialQuery() );
    const stateRef = useRef( verifyStorage( 'QState' ));
    const userQueryRef = useRef( verifyStorage( 'userQuery' ));
    const styles = useViewerStyles();

    const initValues = () => {
        return {
            startDate   : stateRef.current.startTime !== undefined && stateRef.current.startTime !== null
                ? formatDateInit( stateRef.current.startTime ) : stateRef.current.startTime,
            endDate     : stateRef.current.endTime !== undefined && stateRef.current.endTime !== null
                ? formatDateInit( stateRef.current.endTime ) : stateRef.current.endTime,
            minMag      : stateRef.current.min_magnitude || Qlimits.min_magnitude,
            maxMag      : stateRef.current.max_magnitude || Qlimits.max_magnitude,
            minDepth    : stateRef.current.min_depth || Qlimits.min_depth,
            maxDepth    : stateRef.current.max_depth || Qlimits.max_depth,
        }
    }

    const [{ startDate, endDate, minMag, maxMag, minDepth, maxDepth }, setState ] = useState(
        initValues()
    )
    const [ isErrors, setError ] = useState(false);

    const applyFilters = () => {
        filterData(
            actualQueryRef.current,
            userQueryRef.current,
            stateRef.current
        )
    }

    const updateStorage = () => {
        stateRef.current = verifyStorage( 'QState' );
        userQueryRef.current = verifyStorage( 'userQuery' );
        actualQueryRef.current = initialQuery();
    }

    const handleMagUpdate = value => {
        stateRef.current = {
            ...stateRef.current,
            min_magnitude: value[0],
            max_magnitude: value[1]
        }
        userQueryRef.current = {
            ...userQueryRef.current,
            'Magnitud Mínima': value[0],
            'Magnitud Máxima': value[1],
        }
        actualQueryRef.current.event = {
            ...actualQueryRef.current.event,
            magnitude: {
                min: value[0],
                max: value[1]
            }
        }
    };

    const handledepthUpdate = value => {
        stateRef.current = {
            ...stateRef.current,
            min_depth: value[0],
            max_depth: value[1]
        }
        userQueryRef.current = {
            ...userQueryRef.current,
            'Profundidad Mínima': value[0],
            'Profundidad Máxima': value[1],
        }
        actualQueryRef.current.event = {
            ...actualQueryRef.current.event,
            depth: {
                min: value[0],
                max: value[1]
            }
        }
    };

    const handleDateError = err => setError(err);

    const handleDate = value => {
        const initValue = new Date( value[0].getTime() - 18000000 );
        const endValue = new Date( value[1].getTime() - 18000000 );

        stateRef.current = {
            ...stateRef.current,
            startTime: initValue,
            endTime: endValue,
        }
        userQueryRef.current = {
            ...userQueryRef.current,
            'Fecha Inicial': formatLocalTime( value[0] ),
            'Fecha Final': formatLocalTime( value[1] ),
        }
        actualQueryRef.current.event = {
            ...actualQueryRef.current.event,
            local_date: {
                min: initValue,
                max: endValue
            }
        }
        setState ({
            minDepth, maxDepth, minMag, maxMag,
            startDate   : value[0],
            endDate     : value[1],
        });
    }

    useEffect(() => {
        updateStorage();
    }, [ userQuery ]);

    useEffect(() => {
        setState( initValues() );
    },[
        // eslint-disable-next-line
        userQuery[ "Fecha Inicial" ], userQuery[ "Fecha Final" ],
        // eslint-disable-next-line
        userQuery[ "Magnitud Mínima" ], userQuery[ "Magnitud Máxima" ],
        // eslint-disable-next-line
        userQuery[ "Profundidad Mínima" ], userQuery[ "Profundidad Máxima" ],
        // eslint-disable-next-line
    ]);

    return (
        <ReactShadowScroll
            scrollColor = '#667f00'
            scrollColorHover = '#667f00'
            scrollPadding = { 0 }
        >
            <div className = { styles.base }>
                <div className = { styles.sectionTitleContainer }>
					<h6 className = { styles.title }>Configuración</h6>
				</div>
                <div className = { styles.sectionInfo }>
					<p className = { styles.infoText }>
						En esta sección puede modificar la visualización de datos en el mapa y la lista
					</p>
				</div>
            <div className = { styles.section }>
					<div className = { styles.sectionTitleContainer }>
						<h6 className = { styles.title }>Capas del mapa</h6>
					</div>
					<SwitchesList
						items = { mapLayers }
						labelValue='name'
						label_switch = { build_settings_label }
						onChange = { handleLayerSwitches }
						unique
						required
					/>
				</div>
            <div className = { styles.section }>
					<div className = { styles.sectionTitleContainer }>
						<h6 className = { styles.title }>Filtros de Búsqueda</h6>
					</div>
				</div>
                <div className = { styles.sectionDates }>
					<p className = { styles.sliderText }>Por Fecha</p>
					<CatalogDateFilter
						onChange = { handleDate }
                  onError = { handleDateError }
						minDate = { initStartDefault }
						maxDate = { initEndDefault }
						startDate = { startDate }
						endDate = { endDate }
					/>
				</div>
                <div className = { styles.sectionSlider }>
					<p className = { styles.sliderText }>Por Magnitud</p>
					<SliderFilter
                        minLimit = { Qlimits.min_magnitude }
                        maxLimit = { Qlimits.max_magnitude }
                        step = { 0.1 }
                        onChange = { handleMagUpdate }
                        defaultValue = {[ minMag, maxMag ]}
                    />
				</div>
                <div className = { styles.sectionSlider }>
                    <p className={styles.sliderText}>Por Profundidad</p>
					<SliderFilter
                        minLimit = { Qlimits.min_depth }
                        maxLimit = { Qlimits.max_depth }
                        step = { 10 }
                        onChange = { handledepthUpdate }
                        defaultValue = {[ minDepth, maxDepth ]}
                    />
				</div>
                <div className = { styles.buttonContainer }>
					<button 
                        className = { styles.filterButton }
                        onClick = { applyFilters }
                        disabled = { isErrors }
                    >
                        Aplicar Filtro
                    </button>
				</div>
            </div>
        </ReactShadowScroll>
    )
}
