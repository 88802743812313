//Valores minimos y máximos en los rangos
export const Qlimits = {
    min_magnitude_tm: 0,
    max_magnitude_tm: 10,
    min_magnitude : -10,
    max_magnitude : 10,
    min_depth : -5400,
    max_depth : 6900,
    min_gap : -360,
    max_gap : 360,
    min_rms : -1000,
    max_rms : 1000,
    min_latitude_error : -90,
    max_latitude_error : 90,
    min_longitude_error : -360,
    max_longitude_error : 360,
    min_depth_error : -8000,
    max_depth_error : 8000,
    min_mag_error : -10,
    max_mag_error : 10,
    min_level_intensity : 1,
    max_level_intensity : 10,
    min_lat : -90,
    max_lat : 90,
    min_lng : -180,
    max_lng : 180,
}
