import { useState, useEffect } from 'react';
import { UseLeafletMap } from 'hooks/UseLeafletMap';
import { Volcano3D } from './Volcano3D';
import L from 'leaflet';
import { TileLayer, LayersControl, Marker, useMap } from 'react-leaflet';
import { UseSwitch } from "hooks/UseSwitch";
import { useVolcanoIcon } from 'hooks/useVolcanoLevels';
const { BaseLayer } = LayersControl;

const UpdateLocationMap = ({ position }) => {
   const map = useMap();

   useEffect( () => {
      map.setView( position );
   }, [ position, map ] )
}

export const VolcanoMap = ({ coordinates, volcano3d, activityLevel, actualVolcano }) => {

    const [ zoom, setZoom ] = useState( 16 );
    const [ showText, setShow ] = useState( true );
    const [ active, setActive ] = useState( true );
    const volcanoIcon = useVolcanoIcon( activityLevel );

    const onZoomChange = ({ target }) => setZoom(target._zoom);

    const validateModel3d = () => {
        return volcano3d === null || volcano3d === undefined || volcano3d === "";
    }

    const markerIcon = L.icon({
        iconUrl: volcanoIcon,
        className: 'leafletDivIcon'
    });

    const showHiddenIcon = () => {
        const icon = document.querySelector(
            '.volcano_map .leafletDivIcon'
        );
        icon.style.display = showText ? 'none' : 'unset';
        setShow(!showText);
    }

    useEffect(() => {
        setActive( true );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ actualVolcano ]);

    return (
        <>
            <div className="volcano_map">
                <h6>Mapa interactivo</h6>
                <div id = "interactiveMapContainer">
                    {
                        active &&
                            <div className = "VolcanoMapHid">
                                <div className = "HidContent">
                                    <div className = "HidMask"/>
                                    <div className = "HidControls">
                                        <p className="TitleText mb-1">
                                            Para activar el movimiento del mapa hacer clic en el botón.
                                        </p>
                                        <button
                                            onClick = { () => setActive( !active ) }
                                        >
                                            Activar
                                        </button>
                                    </div>
                                </div>
                            </div>
                    }
                    <UseLeafletMap
                        id="VolcanoMap"
                        position={coordinates}
                        mapOptions={{ maxZoom: 17 }}
                        zoom={zoom}
                        scrollWheelZoom={true}
                        {
                        ...navigator.userAgent.match(/iPhone/i) === null && {
                            isFullScreen: true
                        }
                        }
                        onzoomend={onZoomChange}
                        bodyMap={(
                            <>
                                <Marker
                                    position={coordinates}
                                    icon={markerIcon}
                                />
                                <div id="show_location">
                                    <span>Mostrar ubicación</span>
                                    <UpdateLocationMap position={ coordinates } />
                                    <UseSwitch
                                        checked={showText}
                                        onChange={showHiddenIcon}
                                        name="legend"
                                    />
                                </div>
                                <LayersControl position="topright">
                                    <BaseLayer checked name="Escala de grises">
                                        <TileLayer
                                            url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.jpg"
                                        />
                                    </BaseLayer>
                                    <BaseLayer name="Topográfico">
                                        <TileLayer
                                            url="https://server.arcgisonline.com/arcgis/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}"
                                        />
                                    </BaseLayer>
                                    <BaseLayer name="Calles">
                                        <TileLayer
                                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                                        />
                                    </BaseLayer>
                                    <BaseLayer checked name="Satélite">
                                        <TileLayer
                                            url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                        />
                                    </BaseLayer>
                                </LayersControl>
                            </>
                        )}
                    />
                </div>
            </div>
            {
                !validateModel3d() && (
                    <div className="volcan3D_model">
                        <h6>Modelo 3D del volcán</h6>
                        <Volcano3D volcano3d={volcano3d} />
                    </div>
                )
            }
        </>
    )
}
