import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import products from "json/HomeProducts.json";
import { HomeService } from 'api/Services/ReqHome';

const displayList = props => {
    if ( window.innerWidth <= 1200 ) {
        const display_list = document.querySelector(`#${ props }`);
        if( display_list ) {
            display_list.style.display = display_list
                .style.display === "block" ? "none" : "block";
        } 
    }
}

const defineClassLv = ( level = 0, id = -1, childs = undefined ) => {
   switch ( level ) {
       case 0:
           return id === 0 ? "HmPrList HmPrListWeare" : "HmPrList";
       case 1:
           return childs !== undefined ? "third animated fadeDown" : "Second animated fadeDown"
       default:
           return "lastLevel";
   }
}

// const validateLink = ( link = "" ) => {
//    return link.includes( 'http://' ) || link.includes( 'https://' )
//        ? link : `${ www2 }${ link }`
// }

const rebuildProducts = ( menu, level=0 ) => {
   const actualLevel = level + 1;
   return menu.map(({ texto, enlace, submenus }, idx ) => {
      const id = idx;

      return {
         id,
         label     : texto,
         link      : enlace,
         className : defineClassLv( level, id, submenus ),
         submenus  : submenus[0] ? rebuildProducts( submenus, actualLevel ) : null
      }
       
   })
}

const ArrowBottomIcon = () => <ExpandMoreIcon className="ArrowIcon" />

function HomeProducts () {
    
    const [ homeProducts, setProducts ] = useState( null );

    const requestProducts = async () => {
        await HomeService.getMenu()
        .then( res => {
            res?.data[0] && setProducts( rebuildProducts( res.data ));
        })
        .catch( e => {
            setProducts( rebuildProducts( products ) );
        })
    }

    useEffect(() => {
        requestProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
         <ul className = "HmProducts animated fadeIn">
            {
               homeProducts?.map(( item, index ) => (
                     <li
                        className = { item.className }
                        key = { `first_level${ index }` }
                     >
                        <p
                           className = "HmPrListIcon"
                           onClick = {
                                 item.submenus
                                    ? () => displayList( `second${ index }_${ item.id }` )
                                    : () => ""
                           }
                        >
                           {
                              item.submenus ? (
                                 <span className = "HmPrListTXT" tabIndex="0">{ item.label }</span>
                               ) :  (
                                    <a className ="HmPrListTXT" href = { item.link }>
                                       { item.label }
                                    </a>            
                               )
                           }
                           {
                              item.submenus && <ArrowBottomIcon />
                              }
                        </p>
                        {
                           item.submenus && (
                                 <ul
                                    className = "Second_level animated fadeIn"
                                    key = { `second${ index }` }
                                    id = { `second${ index }_${ item.id }` }
                                 >
                                    {
                                       item.submenus.map(( second, idx ) => (
                                             <li
                                                className = { second.className }
                                                key = { `second_level${ idx }` }
                                             >
                                                <div
                                                   className = { "HmPrListIcon" }
                                                   onClick = {
                                                         second.submenus
                                                            ? () => displayList( `third${ idx }_${ item.id }` )
                                                            : () => ""
                                                   }
                                                >
                                                   {
                                                         second.submenus
                                                            ?  <span className ="HmPrListTXT" tabIndex="0">{ second.label }</span> 
                                                            :  <a className ="HmPrListTXT" href = { second.link }>
                                                                  { second.label }
                                                               </a>    
                                                   }
                                                   {
                                                      second.submenus && <ArrowBottomIcon />
                                                      }
                                                </div>
                                                {
                                                   second.submenus && (
                                                         <ul
                                                            className = "Third_level"
                                                            id = { `third${ idx }_${ item.id }` }
                                                         >
                                                            {
                                                               second.submenus.map(( third, id ) => (
                                                                     <li
                                                                        key = { `third_level${ id }` }
                                                                     >
                                                                        { 
                                                                           <a className ="HmPrListTXT" href = { third.link }>
                                                                              { third.label }
                                                                           </a> 
                                                                        }
                                                                     </li>
                                                               ))
                                                            }
                                                         </ul>
                                                   )
                                                }
                                             </li>
                                       ))
                                    }
                                 </ul>
                           )
                        }
                     </li>
               ))
            }
         </ul>
    )
}

export default HomeProducts;