export const seismicityReducer = ( state, action ) => {
   switch ( action.type ){
      case 'SET_CURRENT_EVENTS':
         return {
            ...state, currentEvents: action.payload,
         }
      case 'SET_SELECTED_EVENT':
         return {
            ...state, selectedEvent: action.payload,
         }
      case 'SET_REQUEST_TIMERANGE':
         return {
            ...state, requestTimeRange: action.payload,
         }
      case 'SET_INIT':
         return {
            ...state, initSimulation: true,
         }
      case 'SET_STOP':
         return {
            ...state, stopSimulation: action.payload,
         }
      case 'SET_RESET':
         return {
            ...state, resetSimulation: !state.resetSimulation,
         }
      default: 
         return state;
   }
}