import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GREEN } from 'utils/styles';

const OriginPhase = ({ status }) => {
    const useStyles = makeStyles((theme)=>({
        statusGreen: {
            color:GREEN
        },
        statusRed: {
            color:'#DF240A'
        }   
    }));
    const classes = useStyles();
    return(
        <Fragment>
            {
                status === 'Manual' || 'M' ? (
                    <p className={classes.statusGreen}>
                        <strong>{status}</strong>
                    </p>
                ) : (
                    <p className={classes.statusRed}>
                        <strong>{status}</strong>
                    </p>
                )
            }
        </Fragment>
    )
}
export default OriginPhase;