import React, { useState, useRef } from 'react'
import { TextField } from '@mui/material';

export const validateFloat = info => /^-?\d+(?:[.,]\d*?)?$/.test( info );

export const UseNumberField = (
    {
        init, trigger, label, placeholder, helpText,
        variant, aditionalConf, name, id, err
    }
) => {

    const [ value, setData ] = useState( init || '' );
    
    const valueRef = useRef({
        value: init || '', error: err || false, helperText: helpText || ''
    });

    const handleChange = event => {
        const info = event.target.value;
        if ( info[ 0 ] === '0' && info[ 1 ] === '0' ) {
            valueRef.current = {
                value: info, error: true, helperText: 'Solo se admiten valores numéricos'
            }
        } else if ( info === '-' ) {
            valueRef.current = {
                value: info, error: false, helperText: ''
            }
        } else if ( validateFloat( info ) ) {
            valueRef.current = {
                value: info, error: false, helperText: ''
            }
        } else if ( info[ info.length - 1 ] === '.' && validateFloat( info.substring( 0, info.length - 1 ))) {
            valueRef.current = {
                value: info, error: false, helperText: ''
            }
        } else if ( info === '' ) {
            valueRef.current = {
                value: info, error: false, helperText: ''
            }
        } else {
            valueRef.current = {
                value: info, error: true, helperText: 'Solo se admiten valores numéricos'
            }
        }
        setData( valueRef.current.value );
        trigger && trigger( valueRef.current, name );
    }

    return (
        <TextField
            id = { id }
            variant = { variant }
            type = "text"
            name = { name }
            label = { label }
            placeholder = { placeholder || '' }
            value = { value }
            onChange = { handleChange }
            error = { err }
            helperText = { helpText }
            { ...aditionalConf }
        />
    )
}