const MANUAL_STATUS = "Manual";
const AUTO_STATUS = "Automático";

export const round = (num, decim) => {
   return parseFloat(num).toFixed(decim);
}

export const validateValue = ( value ) => {
   return value !== undefined && value !== null && value !== "";
}

export const valStatus = ( status ) => {
   return status.toLowerCase() === "manual" ? 
      MANUAL_STATUS : AUTO_STATUS;
}

export const valDepth = ( depth, status ) => {
   
   if (status === MANUAL_STATUS && depth < 30) {
      return "Superficial";
   }
   if (status === AUTO_STATUS && depth < 70) {
      return "Superficial";
   }

   return round( depth );

}