import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import InsularMiniMap from "Components/Atoms/A_Reusable/InsularMiniMap";
import BuildSeismMarkers from "Components/Molecules/M_SeismicitySummary/BuildSeismMarkers";
import { SeismicityContext } from "context/SeismicityContext/SeismicityContext";
import { useContext, useState } from "react";

function SeismicityMiniMap({ title, setEventDate }) {

   const [ expanded, setExpanded ] = useState( window.screen.width > 900 );
   const { currentEvents } = useContext( SeismicityContext );

   const insularEvents = currentEvents.filter(e => e.insular);
   
   return (
      <div className="SeismicityMiniMapContainer">
         <Accordion
            expanded={ expanded }
            onChange={ () => setExpanded( !expanded ) }
         > 
            <AccordionSummary style={{ padding: "0 15px", minHeight: "50px" }} expandIcon={ <ExpandMoreIcon /> } >
               <Typography className="MiniMapTitle">
                  { title }
               </Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: 0 }}>
               <InsularMiniMap 
                  events={
                     insularEvents[0] && 
                        <BuildSeismMarkers
                           setEventDate={ setEventDate }
                           insularEvents={ insularEvents }
                        />
                  }
               />
         </AccordionDetails>

      </Accordion>
      </div>
   )
}
export default SeismicityMiniMap