import { Alert, Box } from "@mui/material"

function SeismicityFooter({ isError }) {
   return (
      <Box
         position="fixed"
         right={0}
         bottom={10}
         zIndex={2}
         style={{ pointerEvents: "none", textAlign: "right" }}
      >
         { 
            isError && <Alert severity="error">Ha ocurrido un error, por favor intente nuevamente</Alert>
         }
      </Box>
   )
}
export default SeismicityFooter