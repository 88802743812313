
// see _Colors.sass
export const DEPTH_GREEN = "#667f00"; // Used in sismos
export const CORP_GREEN = "#069169";

export const GREEN = "#005833";
export const ORANGE_YELLOW = "#D7A53C"; 
export const LIGHT_GRAY = "#F6F6F6";
export const DARK_GRAY = "#686868";
export const WHITE = "#FFF"

export const SGC_COLOR = ORANGE_YELLOW; // NEW! Orange