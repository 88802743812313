import React from "react";
import { connect } from "react-redux";
import { ProductsCards } from "Components/Molecules/M_EQDetails/Cards";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

const EQDefault = ({ event }) => {

	const location = useLocation();
	const types = location.pathname.includes( "detalleevento" )
		?	event.properties.types.filter( type => type !== "cdi" )
		:	event.properties.types

	return event.properties &&
		<Grid
			container
			direction = "row"
			justifyContent = "center"
			alignItems = "center"
		>
			<div className="EQCardsContainer">
				<ProductsCards
					id = { event.id } data = { types }
				/>
			</div>
		</Grid>
}


const mapStateToProps = ({ detailEvent }) => ({
	event: detailEvent[0],
});

export default connect( mapStateToProps )( EQDefault );
