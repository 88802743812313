import { useEffect, useRef } from 'react';
import { MapContainer, ScaleControl, useMapEvents } from "react-leaflet";
import Fullscreen from 'react-leaflet-fullscreen-plugin';
import L from "leaflet";
import "leaflet-easyprint";
import { LeafLetGrid } from 'Components/Molecules/M_Reusable/LeafLetGrid';

export const formatBounds = ({ _northEast, _southWest }) => ({
    north: _northEast.lat,
    south: _southWest.lat,
    east: _northEast.lng,
    west: _southWest.lng,
});

export const layers = {
    greyScales: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.jpg",
    topographic: "https://server.arcgisonline.com/arcgis/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}",
    streets: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
    satelite: "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
};

const ControlMap = ({ onClick, onzoomend, onzoomstart, onMoveEnd, getRef }) => {
    const map = useMapEvents({
        click: () => onClick( map ),
        zoomstart: zoom => onzoomstart( zoom ),
        zoomend: zoom => onzoomend( zoom ),
        moveend: ({ target }) => onMoveEnd( map, target.getCenter() )
    });
    useEffect(() => {
        getRef( map );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return null;
}

export const UseLeafletMap = ({
    id,
    position,
    mapOptions,
    inertia,
    boxZoom,
    dragging,
    zoom,
    zoomSnap,
    bodyMap,
    layer,
    isLayer,
    isOverlays,
    isFullScreen,
    onzoomend,
    isPrint,
    zoomControl,
    onMoveEnd,
    onClick,
    scrollWheelZoom,
    getMap,
    onzoomstart,
    scaleControl = true
}) => {

    const mapRef = useRef();
    const eventRef = useRef();
    const fullScreenFlag = false; // to use Full Screen Plugin in React18
    const addToMap = element => element.addTo( mapRef.current );
    
    const createLayer = keyLayer => L.tileLayer(
        layers[ keyLayer ],
        { id: keyLayer, tileSize: 512, zoomOffset: -1 }
    );

    const addOverlays = () => {
        const grids = L.layerGroup();
        L.VirtualGrid = LeafLetGrid();
        L.virtualGrid = ( url, options ) => (
            new L.VirtualGrid( options )
        )
        L.virtualGrid({ cellSize: 64 }).addTo( grids );
        return grids;
    }

    const loadMapOptions = () => {
        /**
         * L.easyPrint
         * title: the print title
         * position: topright, topleft, bottomright, bottomleft
         * elementsToHide: hide html element
         */
        isPrint && addToMap( L.easyPrint({ position: "topleft" }));
        layer  && addToMap( L.tileLayer( layers[ layer ], mapOptions ));
        const newLayers = isLayer ? {
            "Grises": createLayer( "greyScales" ),
            "Topografico": createLayer( "topographic" ),
            "Calles": createLayer( "streets" ),
            "Satelite": createLayer( "satelite" )
        } : null;

        const overlay = isOverlays ? {
            "Grilla de Coordenadas": addOverlays()
        } : null;
        L.control.layers();
        if ( newLayers !== null || overlay !== null ) {
            addToMap( L.control.layers( newLayers, overlay ) );
        }
    }

    const getRef = ref => {
        mapRef.current = ref;
        getMap && getMap( ref );
        eventRef.current = setInterval(() => {
            mapRef.current.invalidateSize();
        }, 1000);
        loadMapOptions();
    };

    useEffect(() => {
        return () => eventRef.current && clearInterval(
            eventRef.current
        )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <MapContainer 
            id = { id }
            trackResize = { true }
            worldCopyJump = { true }
            inertia = { inertia || true }
            center = { position }
            zoom = { zoom }
            {
               ...zoomSnap && { zoomSnap: 0.1 }
            }
            {
                ...mapOptions.maxBounds && {
                    maxBounds: mapOptions.maxBounds
                }
            }
            maxZoom = { mapOptions.maxZoom }
            minZoom = { mapOptions.minZoom || 5 }
            zoomControl = { zoomControl || false }
            boxZoom = { boxZoom || false }
            dragging = { dragging || false }
            scrollWheelZoom = { scrollWheelZoom || false }
            fullscreenControl = { isFullScreen || false }
            fullscreenControlOptions={{
               position: "topleft", forceSeparateButton: true, 
            }}
        >
            <ControlMap
                onClick = { onClick ? onClick : () => '' }
                onzoomend = { onzoomend ? onzoomend : () => '' }
                onMoveEnd = { onMoveEnd ? onMoveEnd : () => '' }
                onzoomstart = { onzoomstart ? onzoomstart : () => '' }
                getRef = { getRef }
            />
            { fullScreenFlag && <Fullscreen /> }
            { scaleControl && <ScaleControl position="bottomleft" /> }
            { bodyMap && bodyMap  }
        </MapContainer>
    )
}
