import SgcButton from "Components/Atoms/A_Reusable/SgcButton";
import BuildLevel from "Components/Molecules/M_Reusable/BuildLevelVolcano";
import { analyticsEventTracker, EVENT_CATEGORIES } from "hooks/UseAnalyticsTracker";

const volcanoLevelClasses = {
   1: "Red",
   2: "Orange",
   3: "Yellow",
   4: "Green"
}

function VListItem({ index, id, volcano, onSelectItem=()=>"", animateImg=false, activeClass="", content=true }) {

   return (
      <div id={`item-${ index }`} className={`AccordionContainer ${activeClass}`}>
         <div className={`RoundedAccordion ${ volcanoLevelClasses[ volcano.activityLevel ] }`} role="listitem">

            <div className="AccordionContent" onClick={ () => onSelectItem(index) }>
               <div className="ImgLeft">
                  <img className={animateImg ? "Animate" : "" } src={ volcano.images[0] } alt="volcano" />
               </div>
               <div className="HeaderContainer">
                  <div className="HeaderText">
                     <h2 className="HeaderTitle Title">
                        { volcano.VolcanoName }
                     </h2>
                     <p>
                        <b>Elevación: </b>
                        { volcano.msnm }
                     </p>
                     <p>
                        <b>Ubicación: </b>
                        { volcano.latitude }&#176;, { volcano.longitude }&#176;
                     </p>
                  </div>

               </div>
               <div className="IconRight">
                  <div className = "volcano-level-activity">
                     <BuildLevel
                     level = { volcano.activityLevel }
                     description = { volcano.activityLevel }
                     />
                  </div>
               </div>
               {
                  content && (
                     <div className="ContentInfo">
                        <p>
                           <b>Estado de alerta: </b>
                           { volcano.activityLevelDescription }
                        </p>
                        <p>
                           <b>Tipo de volcán: </b>
                           { volcano.composition } 
                        </p>
                        {
                           volcano.lastEruption !== null && (
                              <p><b>Última erupción conocida: </b>{ volcano.lastEruption }</p>
                           )
                        }
                        <div className="Buttons">
                           <SgcButton 
                              variant="Nunito"
                              href={`/detallevolcan/${ id }/`}
                              label="Más información"
                              onClick = {             
                                 () => analyticsEventTracker({
                                    category: EVENT_CATEGORIES.vulcans,
                                    action: `Más Información from VViewer List`,
                                 }) 
                              }
                           />
                        </div>
                     </div>
                  )
               }
            </div>

         </div>
      </div>
   )
}
export default VListItem