import React from "react";
import { Alert } from "@material-ui/lab";
import { useState, useEffect } from "react";

function detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    var isMobile = toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });

    var isMobileWidth = ( window.innerWidth <= 825 )

    return isMobile && isMobileWidth
}


const MobileAdvice = ({ message }) => {

    const [ isMobile, setMobile ] = useState(false);

    useEffect(() => {
        setMobile(detectMob());
        window.onresize = function(){
            setMobile(detectMob());
        }
    }, [])

    return (
        isMobile ?
            <div className="coordinatesArea" style={{ marginBottom: "10px" }}>
                <Alert variant="outlined" severity="info">{ message }</Alert>
            </div>
        : <></>
    )
}

export default MobileAdvice;