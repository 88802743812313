export const LOAD_LIST_FIVE_DAYS_2 = "LOAD_LIST_FIVE_DAYS_2";
export const LOAD_LIST_FIVE_DAYS_ALL = "LOAD_LIST_FIVE_DAYS_ALL";
export const LOAD_LIST_SIXTY_DAYS_4 = "LOAD_LIST_SIXTY_DAYS_4";
export const LOAD_LIST_THIRTY_DAYS_IMPORTANT =
  "LOAD_LIST_THIRTY_DAYS_IMPORTANT";
export const LOAD_ACTIVE_EVENTS = "LOAD_ACTIVE_EVENTS";
export const LOAD_ACTIVE_EVENTS_DOWN = "LOAD_ACTIVE_EVENTS_DOWN";
export const APPEND_ACTIVE_EVENTS_DOWN = "APPEND_ACTIVE_EVENTS_DOWN";

export const LOAD_DETAILS_EVENT = "LOAD_DETAILS_EVENT";
export const LOAD_HOME_EVENT = "LOAD_HOME_EVENT";
export const LOAD_SM_EVENT = "LOAD_SM_EVENT";
export const RESIZE_EVENT = "RESIZE_EVENT";
export const MODAL_IMG = "MODAL_IMG";
export const LOAD_QUERY_MAP = "LOAD_QUERY_MAP";

export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const SET_MUNICIPALITIES = "SET_MUNICIPALITIES";
export const SET_STATIONS = "SET_STATIONS";
export const SET_QUERY = "SET_QUERY";

export const NETWORK_DATA = "NETWORK_DATA";
export const NETWORK_FILTERS = "NETWORK_FILTERS";
export const MAKE_FILTER_NETWORK = "MAKE_FILTER_NETWORK";

export const UPDATE_ACTIVE_EVENTS = "UPDATE_ACTIVE_EVENTS";
export const SELECT_EVENTS_LIST = "SELECT_EVENTS_LIST";
export const SELECT_EVENTS_LIST_TITLE = "SELECT_EVENTS_LIST_TITLE";

export const SELECT_EVENT = "SELECT_EVENT";
export const SELECT_VOLCANO = "SELECT_VOLCANO";

export const EVENT_STRING_FILTER_DATA = "EVENT_STRING_FILTER_DATA";
export const SET_MAP_LAYER = "SET_MAP_LAYER";
export const SET_FILTER_BOUNDS = "SET_FILTER_BOUNDS";
export const SET_MAP_BOUNDS = "SET_MAP_BOUNDS";
export const SHOW_MENU = "SHOW_MENU";
export const LOAD_CDI = "LOAD_CDI";
export const LOAD_LIST_INTERNATIONAL_EVENTS = "LOAD_LIST_INTERNATIONAL_EVENTS";
export const CHANGE_MENU_ICON = "CHANGE_MENU_ICON";

export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const LOAD_VOLCANOS = "LOAD_VOLCANOS";
export const LOAD_VOLCANO_LEVELS = "LOAD_VOLCANO_LEVELS";
export const LOAD_VOLCANOS_SORT = "LOAD_VOLCANOS_SORT";
export const LOAD_VOLCANOS_FILTER = "LOAD_VOLCANOS_FILTER";
export const ORDER_BY = "ORDER_BY";
export const FILTER_BY = "FILTER_BY";
export const FILTER_EVENTS_BY = "FILTER_EVENTS_BY";
export const LOAD_ACELERATIONS = "LOAD_ACELERATIONS";
export const LOAD_VOLCANO_ACTUAL = "LOAD_VOLCANO_ACTUAL";

export const VISIBLE_NOTIFICATIONS = "VISIBLE_NOTIFICATIONS";

export const SET_SHOWN_VOLCANOS = "SET_SHOWN_VOLCANOS" // made by Alejandro
export const SET_SHOWN_EVENTS = "SET_SHOWN_EVENTS" 
export const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER" 
export const SET_BADGE = "SET_BADGE"

export const LOAD_NEW_ACTUAL = "LOAD_NEW_ACTUAL"
export const LOAD_NEWS_LIST = "LOAD_NEWS_LIST"

export const LOAD_ALL_NOTIFICATIONS = "LOAD_ALL_NOTIFICATIONS"

export const START_EVENTS = "START_EVENTS"
export const YEAR_EVENT_DETAIL = "YEAR_EVENT_DETAIL"

export const IS_VALID_CONSULTATION = "IS_VALID_CONSULTATION"

export const LOAD_SEGMENTO_ACTUAL = "LOAD_SEGMENTO_ACTUAL"
export const LOAD_SEGMENTO_NUEVO = "LOAD_SEGMENTO_NUEVO"

export const DISABLED_FORM_INPUT = "DISABLED_FORM_INPUT"
