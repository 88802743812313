export const order_list_eq = ( list ) => {
    let initializer = true;
    // used describe si la lista no se ha inicializado correctamente
    list = list.sort(( item1, item2 ) => {
        initializer = item1.selected === true || item2.selected === true
        ? false : initializer;
        if( item1[ 'id' ] < item2[ 'id' ] ){
            return -1
        }
        if( item1[ 'id' ] > item2[ 'id' ] ){
            return 1
        }
        return 0
    });
    if ( list.length > 0 && initializer === true ) {
        list[0].selected = true;
    }
    return list;
}

