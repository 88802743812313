import { MenuItem, Select, FormControl } from '@material-ui/core'
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { UseText } from "hooks/UseText";
import { InputAdornment } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: "0rem 0rem",
		width: "100%",
	},
	textField: {
		width: "100%",
	},
	select: {
		color: "rgba(0, 0, 0, 0.54)",
		fontSize: "9pt"
	},
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: "100%",
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		height: 28,
		margin: 4,
	},
}));

export const FilterVolcanos = (
	{ handleFilter, handleOrder, orderEvent, txtFilter }
) => {

	const classes = useStyles();

	return (
		<div className = "volcanos-list-filter-main">
			<div className = "search-filter">
            <UseText
               name = "textFieldSearch"
               className = "VolcanoFilterName"
               init = { txtFilter }
               trigger = { handleFilter }
               label = "Buscar volcán"
               InputProps = {{
                  endAdornment: (
                     <InputAdornment position="end">
                           <SearchIcon />
                     </InputAdornment>
                  )
               }}
            />
			</div>
			<div className="order-filter">
            <FormControl className={classes.formControl}>
               <Select
                  labelId = "demo-simple-select-filled-label"
                  id = "selectBox"
                  value = { orderEvent }
                  onChange = { handleOrder }
                  name = "filter"
                  className = { classes.select }
               >
                  <MenuItem value = { 10 }>Alfabéticamente</MenuItem>
                  <MenuItem value = { 20 }>Estado de Alerta</MenuItem>
                  <MenuItem value = { 30 }>Segmento Norte</MenuItem>
                  <MenuItem value = { 40 }>Segmento Central</MenuItem>
                  <MenuItem value = { 50 }>Segmento Sur</MenuItem>
               </Select>
            </FormControl>
			</div>
		</div>
	)
}