export const queryReducer = ( state, action ) => {
    switch ( action.type ) {
        case 'load':
            return {
                ...state, activeEvents: action.payload.activeEvents
            }
        case 'filter':
            return {
                ...state, filterEvents: action.payload
            }
        case 'count':
            return {
                ...state, queryCount: action.payload
            }
        case 'totalPages':
            return {
                ...state, totalPages: action.payload
            }
        case 'selected':
            return {
                ...state,
                selectedEvent: action.payload
            }
        case 'loadSelected':
            return {
                ...state,
                selectedEvent: action.payload.selectedEvent,
                activeEvents: action.payload.activeEvents,
                loadingRequest: false
            }
        case 'chageLoadingStatus':
            return {
                ...state,
                loadingRequest: action.payload
            }
        case 'setActiveFilter':
            return {
                ...state,
                activeFilter: action.payload
            }
        case 'updateBounds':
            return {
                ...state,
                mapBounds: action.payload
            }
        case 'pushPage':
            return {
                ...state,
                actualPage: action.payload.actualPage,
                activeEvents: action.payload.activeEvents,
                selectedEvent: action.payload.selectedEvent
            }
        case 'loadOrder':
            return {
                ...state,
                isLoadOrderEvents: action.payload
            }
        case 'loadSettings':
            return {
                ...state,
                settingsIsOpen: action.payload
            }
        case 'updateMoreInfo':
            return {
                ...state,
                moreInfoAssets: {
                    loading : action.payload.loading,
                    hasData : action.payload.hasData
                }
            }
        default:
            return state;
    }
}