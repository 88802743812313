import React, { useRef, useContext, useEffect } from "react";
import { EventsSummary } from "Components/Atoms/A_EQViewer/EventsSummary";
import EQViewerFooter from "Components/Templates/T_EQViewer/EQViewerFooter";
import MapWMS from "Components/Organisms/O_EQCatalogoMapa/MapWMS";
import { UseCatalogViewer } from "hooks/UseCatalogViewer";
import { makeStyles } from "@material-ui/core";
import { EventsCatalog } from "../O_EQViewer/EventsCatalog";
import { CTensorMap } from "Components/Organisms/O_EQCatalogoMapa/CTensorMap";
import { mapControls, resize_map } from "Components/Atoms/A_Reusable/ControlsMap";
import { QueryContext } from "context/QueryContext";
import Loader from "Components/Pages/Loader";
import { CatalogNav } from "Components/Molecules/M_Reusable/CatalogNav";
import { CatalogFilters } from "./CatalogFilters";
import { CatalogSettings } from "./CatalogSettings";
// import { mapControls } from "Components/Atoms/A_Reusable/ControlsCatalogMap";

const useStyles = new makeStyles(theme => ({
	base: { position: "relative", width: "100%", height: "100%" },
}));

export const CatalogContainerMap = (
	{
		buildPage, userQueryRef,
		totalPageRef, updateUserQuery, searchByID,
		requestProgress, sortData, filterData
	}
) => {

	const {
		queryCount, selectedEvent, setSelectItem, activeEvents,
	} = useContext( QueryContext );
	const styles = useStyles();
	const mapBoundsRef = useRef([]);
	const widthRef = useRef(window.innerWidth);
	const { modes, list_selected, map_selected } = UseCatalogViewer(
		"SGC - Visor Catalogador", true
	);

	const updateMapBounds = bounds => mapBoundsRef.current = bounds;

	const clickEvent = ({ target }) => {
		return target.id !== 'btnNotifications'
			&& target.id !== 'notificationsDiv'
	}

	const resizeEvent = () => {
		const width = window.innerWidth;
		if (widthRef.current !== width) {
			widthRef.current = width;
			resize_map();
		}
	}

	const loadWindowEvents = () => {
		window.addEventListener('resize', resizeEvent);
		document.addEventListener('click', clickEvent);
	}

	useEffect(() => {
		loadWindowEvents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ activeEvents ]);

	return (
		<div className="CatalogPriority">
			<div id="ui-viewer-container" className="ui-viewer-container">
				<CatalogNav
					list_selected={list_selected}
					map_selected={map_selected}
					mapControls={mapControls}
					updateUserQuery={updateUserQuery}
					userQueryRef={userQueryRef}
					searchByID={searchByID}
					buildPage={buildPage}
				/>
				<main id="view-modes" className={modes}>
					<div className="events-list">
						<div className="container-events-list catalog_list_map">
							<div className="summary-container">
								<EventsSummary
									title="Eventos en Colombia"
									description={`Total de Eventos: ${ queryCount < 100 ? activeEvents.length : queryCount }`}
								/>
								<CatalogFilters sortData={sortData} />
							</div>
							<div
								id="events-container"
								className="events-container events-container-simple"
							>
								<EventsCatalog
									buildPage={buildPage}
									totalPageRef={totalPageRef}
								/>
							</div>
						</div>
					</div>
					<div className="map">
						<div className={styles.base}>
							{
								activeEvents[0] ? !userQueryRef['Tensor Momento'] ||
									!userQueryRef['Tensor Momento'][0] ? (
									<MapWMS
										selectedEvent={selectedEvent}
										setSelectItem={setSelectItem}
										updateMapBounds={updateMapBounds}
										activeEvents={activeEvents}
									/>
								) : (
									<CTensorMap
										selectedEvent={selectedEvent}
										setSelectItem={setSelectItem}
										updateMapBounds={updateMapBounds}
										activeEvents={activeEvents}
										userQuery={userQueryRef}
										requestProgress={requestProgress}
									/>
								) : <Loader />
							}
						</div>
					</div>
					<div className="settings">
						<div className="settings_content">
							<CatalogSettings filterData={filterData} userQuery = { userQueryRef } />
						</div>
					</div>
				</main>
				<footer className="footer">
					<EQViewerFooter />
				</footer>
			</div>
		</div>
	);
};
