import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
	setMapLayer,
	selectEventsList,
	setFilterBounds,
	makeFilterNetwork,
} from "redux/actionsCreators"
import { makeStyles } from "@material-ui/core/styles"
import { Alert, AlertTitle } from "@material-ui/lab"
import SwitchesList from "Components/Atoms/A_EQViewer/SwitchesList"
import DateFilter from "Components/Atoms/A_EQViewer/DateFilter"
import SliderFilter from "Components/Atoms/A_EQViewer/SliderFilter"
import ReactShadowScroll from "react-shadow-scroll"
import build_settings_label from "Components/Atoms/A_Reusable/SettingsSwith"
import { order_list_eq } from "Components/Atoms/A_EQViewer/ListAvailable"
import { LIGHT_GRAY, GREEN as mainColor } from "utils/styles";

export const useViewerStyles = makeStyles((theme) => ({
	base: {
		backgroundColor: LIGHT_GRAY,
		width: "100%",
		height: "100%",
		padding: "0px 0px 0px 0px"
	},
	section: {
		padding: "0px"
	},
	title: {
		fontSize: "110%"
	},
	sectionInfo: {
		padding: "5px 15px 5px 15px",
		backgroundColor: "#dedede",
		color: "#666"
	},
	sectionSlider: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		padding: "0px 15px 0px 20px",
		borderBottom: "1px solid #fff"
	},
	sectionDates: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		padding: "5px 15px 5px 20px",
		borderBottom: "1px solid #fff"
	},
	sliderText: {
		color: "#333",
		fontSize: "72%",
		marginRight: "10px",
		textAlign: "left",
		width: "130px"
	},
	sectionTitleContainer: {
		backgroundColor: mainColor,
		color: "#fff",
		padding: "8px 15px 8px 15px",
		fontSize: "85%"
	},
	infoText: {
		color: "#666",
		lineHeight: "110%",
		fontSize: "75%"
	},
	buttonContainer: {
		padding: "25px 20px 25px 20px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: LIGHT_GRAY
	},
	button: {
		color: mainColor,
		backgroundColor: "#fff",
		border: "1px solid",
      borderColor: mainColor,
		boxShadow: "none",
		textAlign: "center",
		fontSize: "70%",
		paddingTop: "5px",
		paddingBottom: "5px",
		paddingLeft: "15px",
		paddingRight: "15px",
		cursor: "pointer",
		textDecoration: "none"
	},
	filterButton: {
		color: "#fff",
		backgroundColor: mainColor,
		border: "1px solid ",
      borderColor: mainColor,
      borderRadius: "10px",
		boxShadow: "2px 3px 7px #555",
		fontSize: "85%",
		paddingTop: "5px",
		paddingBottom: "5px",
		paddingLeft: "15px",
		paddingRight: "15px",
		cursor: "pointer",
		'&:disabled': {
			pointerEvents: 'none',
			cursor: 'default',
			opacity: 0.6,
		},
		'&:hover': {
         opacity: 0.9
		}
	},
	error: {
		fontSize: "80%",
		lineHeight: "80%",
		color: "red"
	}
}))

const MIN_DEPTH = -50
const MAX_DEPTH = 2000
const STEP_DEPTH = 10
const MIN_MAG = 0
const MAX_MAG = 10
const STEP_MAG = 0.1

const DEFAULT_EVENT_LIST_SELECTED_CODE = 0

const MapSettingsPanel = ({
	default_list,
	events,
	setMapLayer,
	setFilterBounds,
	selectEventsList,
	network_filters,
	makeFilterNetwork,
	available_list,
	networksEq,
	mapLayers
}) => {
	
	const styles = useViewerStyles();
	const [ dateBounds, setDateBounds ] = useState([])
	const [ magValue, setMagValue ] = useState([ MIN_MAG, MAX_MAG ])
	const [ depthValue, setDepthValue ] = useState([ MIN_DEPTH, MAX_DEPTH ])
	const [ dateValue, setDateValue ] = useState([])

	useEffect(() => {
		setMapLayer( mapLayers[2] )
		!default_list ? selectDefaultList() : notDefault()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(
		() => {
			setDefaultDateBounds()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ events ]
	)
	const notDefault = () => {
		return
	}
	const setDefaultDateBounds = () => {
		const eventsLength = events.length
		if (eventsLength) {
			const minDate = events[eventsLength - 1].properties.localTime.replace(" ", "T")
			const maxDate = new Date()

			const newDateBounds = [ new Date(minDate), new Date(maxDate) ]
			setDateBounds(newDateBounds)
		}
	}

	const selectDefaultList = () => {
		selectEventsList( DEFAULT_EVENT_LIST_SELECTED_CODE );
	}

	const handleMagRangeChange = magRange => {
		setMagValue( magRange );
	}

	const buildMagnitudeSlider = () => (
		<SliderFilter
			minLimit = { MIN_MAG }
			maxLimit = { MAX_MAG }
			step = { STEP_MAG }
			onChange = { handleMagRangeChange }
			defaultValue = { magValue }
		/>
	)

	const handleDepthRangeChange = (depthRange) => {
		setDepthValue(depthRange)
	}

	const buildDepthSlider = () => (
		<SliderFilter
			minLimit={MIN_DEPTH}
			maxLimit={MAX_DEPTH}
			step={STEP_DEPTH}
			onChange={handleDepthRangeChange}
			defaultValue={depthValue}
		/>
	)

	const applyFilters = () => {
		const date = dateValue.length > 0 ? dateValue : dateBounds
		setFilterBounds( magValue, depthValue, date );
	}

	const handleSelectEventLists = eventList => {
		selectEventsList( eventList.id );
		setDateValue(dateBounds[0], dateValue[1]);
	}

	const handleSelectLayerOption = layerItem => {

		setMapLayer( layerItem );
	}
  
	const handleSelectDate = dateValues => {
		setDateValue( dateValues );
	}

	const areValidDates = () => {
		const minDate = dateValue[0] || dateBounds[0]
		const maxDate = dateValue[1] || dateBounds[1];
		const minBoundTime = new Date( dateBounds[0] ).getTime()
		const maxBoundTime = new Date( dateBounds[1] ).getTime()
		const minTime = new Date( minDate ).getTime();
		const maxTime = new Date( maxDate ).getTime();
		return minTime >= minBoundTime && minTime <= maxBoundTime && maxTime >= minBoundTime && maxTime <= maxBoundTime
	}


	const buildButtonSection = () =>
		areValidDates() === true ? (
			<button className={styles.filterButton} onClick={applyFilters}>
				Aplicar filtro
			</button>
		) : (
			<Alert severity='error'>
				<AlertTitle>Error</AlertTitle>
				<p className={styles.error}>Las fechas seleccionadas son inválidas</p>
			</Alert>
		)

	const handleNetwork_filters = item_filter => {
		item_filter.selected === false
			? makeFilterNetwork({
				type: true,
				value: item_filter.value,
				function: item_filter.function,
				index: item_filter.id - 1
			})
			: makeFilterNetwork({
				type: false,
				value: item_filter.value,
				index: item_filter.id - 1
			});
	}

	return (
		<ReactShadowScroll scrollColor={ mainColor } scrollColorHover={mainColor} scrollPadding={0}>
			<div className={styles.base}>
				<div className={styles.sectionTitleContainer}>
					<h6 className={styles.title}>Configuración</h6>
				</div>
				<div className={styles.sectionInfo}>
					<p className={styles.infoText}>
						En esta sección puede modificar la visualización de datos en el mapa y la lista
					</p>
				</div>
				{!default_list && (
					<div className={styles.section}>
						<div className={styles.sectionTitleContainer}>
							<h6 className={styles.title}>Sismos</h6>
						</div>
						<SwitchesList
							items = { available_list }
							labelValue = 'name'
							label_switch = { build_settings_label }
							initializerComponent = { order_list_eq }
							onChange = { handleSelectEventLists }
							unique
							required
						/>
					</div>
				)}
				<div className = { styles.section }>
					<div className = { styles.sectionTitleContainer }>
						<h6 className = { styles.title }>Capas del mapa</h6>
					</div>
					<SwitchesList
						items = { mapLayers }
						labelValue='name'
						label_switch = { build_settings_label }
						onChange = { handleSelectLayerOption }
						unique
						required
					/>
				</div>
				{ network_filters.filters && (
					<div className={styles.section} style={{ display: "grid" }}>
						<div className={styles.sectionTitleContainer}>
							<h6 className={styles.title}>Red de monitoreo</h6>
						</div>
						<SwitchesList
							items = { networksEq }
							labelValue = 'name'
							label_switch = { build_settings_label }
							onChange = { handleNetwork_filters }
							unique = { false }
							required = { false }
						/>
						<a
							className='stations_list_download'
							href='https://s3.amazonaws.com/sgc.sites.gov.co/feed/v1.0/stations/RED_DE_MONITOREO_SGC.xlsx'
						>
							Descargar Listado de Estaciones
						</a>
					</div>
				)}
				<div className={styles.section}>
					<div className={styles.sectionTitleContainer}>
						<h6 className={styles.title}>Filtros de Búsqueda</h6>
					</div>
				</div>
				<div className={styles.sectionDates}>
					<p className={styles.sliderText}>Por Fecha</p>
					<DateFilter
						onChange = { handleSelectDate }
						minDate = { dateBounds[0] }
						maxDate = { dateBounds[1] }
						startDate = { dateValue[0] }
						endDate = { dateValue[1] }
					/>
				</div>
				{/**/}
				<div className={styles.sectionSlider}>
					<p className={styles.sliderText}>Por Magnitud</p>
					{ buildMagnitudeSlider() }
				</div>
				<div className={styles.sectionSlider}>
					<p className={styles.sliderText}>Por Profundidad</p>
					{ buildDepthSlider() }
				</div>
				<div className={styles.buttonContainer}>
					{events.length > 0 && dateBounds.length > 0 ? buildButtonSection() : null}
				</div>
			</div>
		</ReactShadowScroll>
	)
}

const mapStateToProps = state => ({
	events: state.selectedEventsList,
	network_filters: state.network_filters,
	networksEq: state.networksEq,
	available_list: state.available_list,
	mapLayers: state.mapLayers
})

const mapDispatchToProps = {
	setMapLayer,
	selectEventsList,
	setFilterBounds,
	makeFilterNetwork
}

export default connect( mapStateToProps, mapDispatchToProps )( MapSettingsPanel );
