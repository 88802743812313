import HmSectionHeader from "Components/Atoms/A_Home/HmSectionHeader";
import AnimateOnScroll from "Components/Atoms/A_Reusable/AnimateIn"
import { HomeService } from "api/Services/ReqHome"
import { analyticsEventTracker, EVENT_CATEGORIES } from 'hooks/UseAnalyticsTracker';
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import bg from "assets/icons/Home/bg/nature-services.jpg";

// Validates it is a react link or an external link
const BuiltLink = ({ link, children }) => (
   link?.includes("www.") ? 
      <Link to={link.substring(22)}>{ children }</Link>   
   :
      <a href={ link } target = "_blank"
         rel = "noopener noreferrer"
      >
         { children }
      </a>
)

function HomeServices({ title, description }) {

   const [ services, setServices ] = useState( null );

   const handleAnalytics = ( name ) => 
      analyticsEventTracker({
         category: EVENT_CATEGORIES.home,
         action: `${ name } from Home Page`,
   })

   useEffect(() => {
      HomeService.getServices().then(( res ) => {
         setServices( res.data );
      }).catch( e => {}); 
   }, []);

   if ( !services || !services.themas ) return null;

   return (
      <AnimateOnScroll>

         <HmSectionHeader
            title={ title || "Conoce Más" }
            description={ description } 
            ariaLevel={ 3 }
         />

         <div className="SectionContent HmServicesContainer">
            
            <img 
               className="HmServicesBg" 
               src={ services.images ? services.images[0]?.src : bg }
               alt="Services Bg" 
               loading="lazy"
            />
            <div className="HmServicesContent">
               <div className="CardGrid">
                  {
                     services.themas.map( (service, index) => (
                           <div className="SgcCard" key={index} onClick={ () => handleAnalytics( service.title ) }>
                              <BuiltLink link={ service.link } >
                                 <div className="SgcCardContent">
                                    <div className="SgcCardImg">
                                       <img src={ service.icon } alt={ service.title } />
                                    </div>
                                    <div className="SgcCardTitle Title">
                                       { service.title }
                                    </div>
                                 </div>
                                 <div className="SgcCardText">
                                    { service.description }
                                 </div>
                              </BuiltLink>
                           </div>
                     ) )
                  }
               </div>
            </div>
         </div>

      </AnimateOnScroll>
   )
}
export default HomeServices