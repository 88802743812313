import SlideEqEvent from 'Components/Molecules/M_Home/SlideEqEvent';
import SlideVolcanoEvent from 'Components/Molecules/M_Home/SlideVolcanoEvent';   
import SlideWeekBulletins from 'Components/Molecules/M_Home/SlideWeekBulletins';
import 'moment/locale/es';

const checkKeys = ( obj ) => {
   return Object.keys( obj ).some( key => obj[key].length > 0 )
}

export const buildCarousel = data => {

   let out_carrousel = [];
   let weeklyBulletins = {
      OVSM: [],
      OVSPP: [],
      OVSP: []
   };
   let bulletinsDate = ""

   data.forEach(( element, index ) => {
       if ( element.type === 'earthquake' || element.properties.type === 'earthquake' || element.type === 'Feature' ) {
           out_carrousel.push (
               <SlideEqEvent
                  key = { `eq${index}` }
                  event={ element }
                  date = { element.properties.localTime } 
               />
           )
       }
       // boletines
       else if ( element.type === 'volcano' || element.properties.type === 'volcano' ) {
            element.properties.bulletins.forEach(( bulletin, i ) => {
               if ( bulletin?.type === "Semanales" ) {
                  bulletinsDate = bulletin.update > bulletinsDate ? bulletin.update : bulletinsDate; 
                  weeklyBulletins[ element.properties.ovs ].push( {
                     volcanoId: element.id,
                     VolcanoName: element.properties.VolcanoName,
                     activityLevel: element.properties.activityLevel,
                     ...bulletin
                  } );
               } 
               else if ( bulletin?.type === "Extraordinarios" ) {
                  out_carrousel.push( 
                     <SlideVolcanoEvent 
                        key={ `volcano${i}` }
                        volcano={ element } 
                        bulletin={ bulletin }
                        date = { bulletin.update } 
                     />
                  )
               }
            })
       }
   });

   checkKeys( weeklyBulletins ) && out_carrousel.push( 
      <SlideWeekBulletins 
         key="volcanoBulletins" 
         date={ bulletinsDate } 
         bulletins={ weeklyBulletins } 
      />
   );

   // order by last date
   return out_carrousel.sort(( first_date, second_date ) => {
       if( first_date.props.date < second_date.props.date ){
           return 1
       }
       if( first_date.props.date > second_date.props.date ){
           return -1
       }
       return 0
   });   

}