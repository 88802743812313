function MoreInfoButton({ title, href, target="_blank", variant="none", color="orange" }) {

   return (
      <a 
         href={ href }
         target={ target }
         rel="noopener noreferrer"
         role="button"
         aria-label={ title }
         className={ variant === "filled" ? color === "green" ? "EventButtonFilled Green" : "EventButtonFilled" : "EventButton" }
      >
         { title ?? "Más información" }
      </a>
   )
}
export default MoreInfoButton