import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { epicenterIcon } from 'Components/Atoms/A_Reusable/ReportInfo';
import PopupEarthquakeMarker from "../../Atoms/A_EQViewer/PopupEarthquakeMarker";
import { useLocation } from 'react-router-dom';
import { REPORT_URL } from 'utils/urls';

const EpicenterMarker = ({event, isSelected, onSelect, }) => {

    const lat = event.geometry.coordinates[0];
    const lng = event.geometry.coordinates[1];
    const level = 100;

    const location = useLocation();
    const directory = location.pathname.split( '/' );

    const validateDirectory = () => directory[1] === 'detallesismo'
        ? 'detallesismo' : 'detalleevento';

    const iconE = epicenterIcon(level);
    
    const handlePopupOpen = () => {
        onSelect(event);
    };

    const handlePopupClose = () => {
        onSelect(null);
    };

    const markerIcon = L.icon({
        iconUrl: iconE,
        className: 'leafletDivIcon'
    });


    return (
        <Marker position={[lat, lng]} icon={markerIcon} zIndexOffset={1000}
        className={isSelected ? 'selectedEventMap' : 'unselectedEventMap'} eventHandlers={{ click: handlePopupOpen }}>
            <Popup closeButton={false} autoPan={true} eventHandlers={{ popupclose: handlePopupClose }}>
                {
                    <PopupEarthquakeMarker
                        id={event.id}
                        lat={event.geometry.coordinates[0]}
                        lng={event.geometry.coordinates[1]}
                        place={event.properties.place}
                        agency={event.properties.agency}
                        localTime={event.properties.localTime}
                        utcTime={event.properties.utcTime}
                        status={event.properties.status}
                        closerTowns={event.properties.closerTowns}
                        magnitude={event.properties.mag}
                        mmi={event.properties.mmi}
                        depth={event.geometry.coordinates[2]}
                        cdi={event.properties.cdi}
                        validatorDates = { () => true }
                        urlMoreInfo={`/${ validateDirectory() }/${event.id}/resumen`}
                        urlReport={`${REPORT_URL}/EvaluacionIntensidadesServlet?idSismo=${event.id}&metodo=irASismoEspecifico`}
                    />
                }
            </Popup>
        </Marker>
    );
};

export default EpicenterMarker;