import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px'
  },
}));
export const QLoad = () => {
  const classes = useStyles();
  return (
    <div id = "QLoading" className = { classes.root }>
      <CircularProgress />
      <CircularProgress color="secondary" />
    </div>
  );
}
