import { useEffect, useRef } from 'react';
import { initControls, resize_map } from 'Components/Atoms/A_Reusable/ControlsMap';

export const UseCatalogViewer = ( title, isCatalog = false ) => {

    const modesRef = useRef( "" );
    const list_selectedRef = useRef( "" );
    const map_selectedRef = useRef( "" );

    const loadInitialState = () => {
        initControls( "view-modes" );
        resize_map();
        window.addEventListener("resize", resize_map);
        document.title = title;
    }
    
    if ( window.innerWidth < 1100 ) {
        modesRef.current = "content mode-map";
        list_selectedRef.current = "modes-view-item selected";
        map_selectedRef.current = "modes-view-item";
    } else {
        modesRef.current = isCatalog
            ? "content mode-map" : "content mode-map mode-settings";
        list_selectedRef.current = "modes-view-item selected";
        map_selectedRef.current = "modes-view-item selected";
    }

    useEffect(() => {
        loadInitialState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        modes: modesRef.current,
        list_selected: list_selectedRef.current,
        map_selected: map_selectedRef.current
    }
}