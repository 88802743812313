import { useState, useEffect, useRef } from "react";
import { StyledTableRow, StyledTableCell } from "./SeismEnhancedTable";

function EnhancedTableRow({ row, onClickRow, selectedRow, onShow, responsive, reset, stop }){

   const [ showRow, setShowRow ] = useState( false );
   const rowTimeoutRef = useRef();

   useEffect(() => {
      showRow && setShowRow( false ); // to apply reset

      rowTimeoutRef.current = window.setTimeout(()=>{
         setShowRow( true )
         
      }, row.delay );

      return () => window.clearTimeout( rowTimeoutRef.current );
      // eslint-disable-next-line
   }, [ row.delay, reset ])

   useEffect( () => {
      if ( stop ){ 
         window.clearTimeout( rowTimeoutRef.current );
         !showRow && setShowRow( true );
      }
      // eslint-disable-next-line
   }, [ stop ] );

   
   useEffect( () => { // to show header
      if ( row.relevant === 1 ){
         if ( showRow ){
            onShow( true );
         } else {
            onShow( false );
         }
      }
      // eslint-disable-next-line
   }, [ showRow, reset ] );
   

   return (
      showRow &&
         <StyledTableRow
            key={row.id} hover 
            onClick={ onClickRow(row) } 
            selected={ selectedRow === row.id }
         >
            <StyledTableCell align="center" component="th" scope="row" width={responsive ? 28 : 35}>
               {row.relevant}
            </StyledTableCell>
            <StyledTableCell align="center">
               { row.place }
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ minWidth: responsive ? 100 : 120 }} >
               { row.time }
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ minWidth: responsive ? 50 : 70 }}> 
               { row.depth < 30 ? "Superficial" : `${ row.depth } km` }
            </StyledTableCell>
            <StyledTableCell align="center" width={responsive ? 45 :60}>
               { row.magnitude } 
            </StyledTableCell>

         </StyledTableRow>
   )
}

export default EnhancedTableRow