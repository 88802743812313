import React from 'react';
import convert from '../../Atoms/A_EQDetails/product_assign';
import { useLocation, useNavigate } from 'react-router-dom';

export const ProductsCards = ({ data, id }) => {

  const { assing } = convert;
  const location = useLocation();
  const navigate = useNavigate();

  let cards_items = []
  if ( data.length > 0 ){
    cards_items = assing( data );
  }
  const handleToggle = value => {
    const path = location.pathname.includes( 'detallesismo' )
      ? 'detallesismo' : 'detalleevento'
    window.scrollTo(0,0);
    navigate(`/${ path }/${ id }/${ value }`);
  };

  return(
    <div className = "EQCards">
      {
        cards_items.map(( element, index )=>{
          return(
            <div
              onClick = {
                () => handleToggle( element[ "link" ] )
              }
              className = "EQCard"
              key = { `card${ index }` }
            >
              <div className = "EQCardImg">
                <img src = { element.icon } alt = { `card${ index }` }/>
              </div>
              <div className="EQCardContent">
               <div style={{ height: "100%" }}>
                { element.product }
               </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}