import React from 'react';

const EQmfContent = ({focalmechanism}) => {
    return(
                <div className="EQmf-main">
                    <div className="EQmfTable-container">
                        <table className="EQmfTable">
                        <thead className="EQmfTable-head">
                            <tr>
                                <th><div>Planos</div></th>
                                <th><div>Strike</div></th>
                                <th><div>Dip</div></th>
                                <th className="right-item"><div>Rake</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(focalmechanism).map(key => {
                                    if(key !== 'img'){
                                        return(    
                                            <tr key={`mf${key}_row`} id={`mf${key}_row`}>
                                                <td>{key}</td>
                                                <td>{focalmechanism[key].Strike}</td>
                                                <td>{focalmechanism[key].Dip}</td>
                                                <td className="right-item">{focalmechanism[key].Rake}</td>
                                            </tr>    
                                        )
                                    }
                                    else {
                                        return null
                                    }
                                })
                            }
                        </tbody>
                    </table>
                    </div>
                    <div className="EQBeachBall">
                        <img alt="beachBall" src={focalmechanism.img}></img>
                    </div>
                </div>
    )
}
export default EQmfContent;