import { useState } from "react";
import SeismicityViewer from "Components/Organisms/O_SeismicitySummary/SeismicityViewer";
import SeismicityDialog from "Components/Organisms/O_SeismicitySummary/SeismicityDialog";
import SeismicityOptions from "Components/Organisms/O_SeismicitySummary/SeismicityOptions";
import SeismicityPanelInfo from "Components/Organisms/O_SeismicitySummary/SeismicityPanelInfo";

import { SeismicityProvider } from "context/SeismicityContext/SeismicityContext";

export const SGC_Color = "#667f00";
// font-size: .9rem, mobile: .8rem

function SeismicitySummary() {

   const [ showInitialAd, setShowInitialAd ] = useState( false );
   const [ start, setStart ] = useState( false );

   const handleCloseAd = () => {
      setShowInitialAd( false );
      setStart( true );
   }

   document.title = "SGC - Histórico Semanal";

   return (
      <>
         {/* Initial Dialog */}
         <SeismicityDialog 
            title = "Sismicidad Histórica Semanal"
            show = { showInitialAd }
            setShow = { setShowInitialAd }
            onClose = { handleCloseAd }
         />

         {/* To handle context */}
         <SeismicityProvider> 

            {/* Query, Map & Header */}
            <SeismicityViewer start={ start } />

            {/* Settings Filter, Control & Legend */}
            <SeismicityOptions />

            {/* Report Title, Featured Table */}
            <SeismicityPanelInfo />

         </SeismicityProvider>
         {/* End of context */}
      </>
   )
}
export default SeismicitySummary;