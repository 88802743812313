import { useSelector } from "react-redux";
import { Fragment } from "react";

const PopupSmMarker = (props) => {
    
    var arrayAceleraciones = useSelector( state => state.aceleraciones );
    var arrayRepetidos = [];
    arrayAceleraciones.map(function (item) {
        if(item.stationCode === props.event.stationCode){
            return arrayRepetidos.push(item)
        }
        return ""
    });

    function colorPgaE(){
        if(props.event.pgaE > props.event.pgaN && props.event.pgaE > props.event.pgaZ){
            return ("red")
        }
        else if(props.event.pgaE < props.event.pgaN && props.event.pgaE < props.event.pgaZ){
            return ("green")
        }
        else {
            return ("black")
        }
    }

    function colorPgaN(){
        if(props.event.pgaN > props.event.pgaE && props.event.pgaN > props.event.pgaZ){
            return ("red")
        }
        else if(props.event.pgaN < props.event.pgaE && props.event.pgaN < props.event.pgaZ){
            return ("green")
        }
        else {
            return ("black")
        }
    }

    function colorPgaZ(){
        if(props.event.pgaZ > props.event.pgaE && props.event.pgaZ > props.event.pgaN){
            return ("red")
        }
        else if(props.event.pgaZ < props.event.pgaE && props.event.pgaZ < props.event.pgaN){
            return ("green")
        }
        else {
            return ("black")
        }
    }

    
    function buildItem(){
        const totalItems = arrayRepetidos.map(( e, index ) => {

            const splitIndex = props.event.description?.indexOf(",");
        
            return (
               <Fragment key={`smPopup${index}`}>
                  <div className="PopupHeader" >
                     <div className="PopupTitleContainer">
                        <h6 className="PopupTitle Title">
                        {
                           props.event.description?.substring(0, splitIndex)
                        }
                        </h6>
                        <h6 className="PopupTitle Title">
                        {
                           props.event.description?.substring(splitIndex + 2)
                        }
                        </h6>
                     </div>
                     <p>{e.networkCode}.{e.stationCode}.{e.locationCode}</p>
                  </div>
                  <div className="EQPopupContent">
                     <div className="PopupInfoGrid">
                        <div>
                           <div><b>Ubicación: </b></div>
                           <div>{e.latitude}&#176;, {e.longitude}&#176;</div>
                        </div>
                        <div>
                           <div><b>Elevación: </b></div>
                           <div>{e.elevation} m.s.n.m</div>
                        </div>
                        <div>
                           <div><b>Dist. Epic.: </b></div>
                           <div>{e.distanceEpicentral} km</div>
                        </div>
                        <div>
                           <div><b>Dist. Hipo.: </b></div>
                           <div>{e.distanceHipocentral} km</div>
                        </div>
                        <div>
                           <div style={{color: colorPgaE()}}><b>PGA EW: </b></div>
                           <div>{e.pgaE} cm/s^2</div>
                        </div>
                        <div>
                           <div style={{color: colorPgaN()}}><b>PGA NS: </b></div>
                           <div>{e.pgaN} cm/s^2</div>
                        </div>
                        <div>
                           <div style={{color: colorPgaZ()}}><b>PGA Z: </b></div>
                           <div>{e.pgaZ} cm/s^2</div>
                        </div>
                     </div>
                  </div>
                </Fragment>
            );
        
        });
        return totalItems;
    };


    return (
        <div className="EQPopup MapPopup">
            {buildItem()}
        </div>
    );

};
  
  export default PopupSmMarker;