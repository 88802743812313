import logo_header from 'assets/icons/Home/logo_gov_header.png';

function LegalHeader() {
   return (
      <div className="LegalHeader">
         <a
            href="https://www.gov.co" target="_blank"
            rel="noopener noreferrer"
            aria-label="Logo Gubernamental"
         >
            <img src={logo_header} alt="Logo Gubernamental" />
         </a>
      </div>
   )
}
export default LegalHeader;