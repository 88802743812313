import circle from 'assets/icons/Home/slider/4_inte-perc.svg'
import { useVolcanoIcon } from 'hooks/useVolcanoLevels';
import Moment from 'react-moment';
import { Tooltip } from '@mui/material';
import MoreInfoButton from 'Components/Atoms/A_Home/MoreInfoButton';

export const textEllipsis = ( text, max=280 ) => {
   const subText = text.substr(0, max);
   return text.length > max ? subText + "..." : subText
}

function SlideVolcanoEvent({ volcano, bulletin }) {

   const volcanoIcon = useVolcanoIcon( volcano.properties.activityLevel );
   
   return (
      <div className="SlideEventContainer"> 
         <div className="SlideEvent">
            <div className="SlideEventTitle">
               <span className="HomeTitle">
                  Eventos Geológicos Destacados
               </span>
            </div>
            <div className="SlideEventContent">

               <div className="EventInfo">
                  <span className="EventEmphasisText Title">
                     Volcanes - <Moment fromNow>{ bulletin?.update }</Moment>
                  </span>
                  <span className="Title EventTitle mb-1">
                     { volcano.properties.VolcanoName }
                  </span>

                  <div className="EventDescription">
                     <div className="Title EventSubTitle">
                        Boletín Extraordinario
                     </div>
                     <span>
                        { bulletin?.name }
                     </span>
                  </div>

                  <div className="EventDescription">
                     <div className="Title EventSubTitle">
                        Ubicación:
                     </div>
                     <span>
                        { textEllipsis( volcano.properties.location ) }
                     </span>
                  </div>


               </div>

               <div className="EventData VolcanoData">

                  <div className="ResponsiveGrid VolcanosGrid">

                     <div className="GridItem">
                        <div className="GridItemTitle Title" >
                           <span>Estado <span className="no-wrap">de Alerta</span> </span>
                        </div>
                        <div className="GridItemContent animate">
                           <img className="hidden" src={ circle } alt="mmi" />
                           <Tooltip enterTouchDelay={50}  title={ volcano.properties.activityLevelDescription }>
                              <div className="ItemText" >
                                 <img className="ImgVolcano" src={volcanoIcon} alt="volcanoIcon" />
                              </div>
                           </Tooltip>
                        </div>
                     </div>

                     { 
                        volcano.properties.reference_point && (

                           <div className="GridItem Flex">
                              <div className="GridItemTitle Title">
                                 Departamentos Cercanos
                              </div>
                              <div className="GridItemContent Flex">
                                 <div>
                                    {
                                       volcano.properties.reference_point[0]?.referencepoint
                                          .split(", ").map( (place, i) =>
                                             <span key={i} className="Title Small">{ place }</span>
                                       )
                                    }
                                 </div>
                              </div>
                           </div>
                        ) 
                     }


                     <div className="GridItem">
                        <div className="GridItemTitle Title">
                           Ubicación
                        </div>
                        <div className="GridItemContent">
                           <img src={circle} alt="distance" />
                           <div className="ItemText">
                              <span className="Title">{ volcano.geometry.coordinates[0].toFixed(2) }&#176;</span>
                              <span className="Title">{ volcano.geometry.coordinates[1].toFixed(2) }&#176;</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="EventAction">
               <MoreInfoButton
                  href={`/detallevolcan/${volcano.id}/`}
                  target="_blank"
               />
            </div>

         </div>
      </div>
   )
}
export default SlideVolcanoEvent