import { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { EQCdiTabs } from "Components/Molecules/M_EQDetails/EQCdiTabs";
import BuildCdi from "Components/Molecules/M_Reusable/BuildCdi";
import { loadCdiData } from "redux/actionsCreators";
import Loader from "Components/Pages/Loader";
import EQDefault from "Components/Organisms/O_EQDetails/EQDefault";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// import { cdiDataExample } from "data/cdi";

const EQCdi = ({ event, cdiData, loadCdiData, handleNotEvent }) => {

	const [{ loading }, setState ] = useState({ loading: <Loader /> });
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const currentLocation = location.pathname.includes( 'detalleevento' )
    	? 'detalleevento' : 'detallesismo';

	const noEvent = () => {
		setState({ loading: <EQDefault /> });
		handleNotEvent( params.product );
		navigate( `/${ currentLocation }/${ params.id }/resumen` );
      // loadCdiData( cdiDataExample )
	}

	const loadData = async () => {
		try {
			const { data } = await axios.get( event.properties.products.cdi );
			data ? loadCdiData( data ) : noEvent();
		} catch ( err ) {
			noEvent();
		}

	}

	useEffect(() => {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		Object.entries( cdiData ).length > 0 ? (
			<div className="EQCdi">
            <div className="EQSectionContainer">
               <div className="max-intensity-cdi">
                  <h3 className="TitleText">Intensidad Máxima Percibida</h3>
                  <BuildCdi cdi={cdiData.cdi.intensity} />
               </div>
               <div className="reports-cdi">
                  <p>
                     { cdiData.cdi.reports } formularios de { cdiData.cdi.municipality } centros poblados
                  </p>
                  <a href={`https://sismosentido.sgc.gov.co/EvaluacionIntensidadesServlet?idSismo=${event.id}&metodo=irASismoEspecifico`}>
                     ¿Sentiste este sismo? ¡Repórtalo aquí!
                  </a>
               </div>
            </div>
            
            <div className="EQSectionContainer GrayBordered">
					<EQCdiTabs cdi={cdiData} event={event} />
            </div>
            
			</div>
		) : loading
	)

}


const mapStateToProps = ({ detailEvent, cdiData }) => ({
	event: detailEvent[0], cdiData: cdiData
});

const mapDispatchToProps = {
	loadCdiData
};

export default connect(mapStateToProps, mapDispatchToProps)(EQCdi);
