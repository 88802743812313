import { SeismicityContext } from "context/SeismicityContext/SeismicityContext"
import { useContext, useMemo } from "react"
import { Box, useMediaQuery } from "@mui/material";
import SeismicityReport from "Components/Molecules/M_SeismicitySummary/SeismReport";
import SeismicityFeaturedTable from "Components/Molecules/M_SeismicitySummary/SeismicityTable/SeismicityFeaturedTable";
import { formatDateToText } from "../../../utils/seismicityUtils";

function SeismicityPanelInfo() {

   const { currentEvents, requestTimeRange } = useContext( SeismicityContext );

   const responsive =  useMediaQuery( '(max-width:600px)' );

   const formattedDateSeismicity = useMemo(() => { 
      if ( requestTimeRange ){
         return formatDateToText( requestTimeRange );
      }
      return null;
   }, [ requestTimeRange ] 
   );

   return (
      currentEvents[0] &&
         <div className="seismicity-panel-info">  

            <Box title="Reporte de sismicidad" mb={responsive ? 1 : 2} textAlign="center" position="relative" top={0}>
               <SeismicityReport
                     formattedDateRange={ formattedDateSeismicity } 
                     totalEvents={ currentEvents.length }
                     responsive={ responsive }
                  />
            </Box>

            <Box title="Tabla de Eventos" position="relative" bottom={0} left={0} marginBottom={ responsive ? 1 : 2 }>
               <SeismicityFeaturedTable 
                  rows={ 
                     currentEvents?.filter( event => event.relevant > 0 )
                     .sort( (a,b) => a.relevant - b.relevant ) 
                  }
               />
            </Box>
            
         </div>     
   )
}
export default SeismicityPanelInfo