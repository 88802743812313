import EQtmpTensor from './EQtmpTensor';
import { TabViews } from "Components/UI/TabViews";

const EQtmpTabs = ({tmp}) => {

   if (!tmp) return <h1 style={{ margin: 20 }}>Lo sentimos. No hay registros de Tensor Momento para este sismo.</h1> 

    return (
         <TabViews 
            fullWidth
            tabs={
               tmp?.map( t =>{
                  return { label: t.methodname }
               })
            }
            views={
               tmp?.map( element => (
                  <>
                     <span>
                        <strong>Agencia:</strong> Servicio Geológico Colombiano
                     </span>
                     <div className="container-tmpTensor">
                     <EQtmpTensor 
                        locationcentroid = { element.locationcentroid }
                        momenttensor = { element.momenttensor }
                        nodalplanes = { element.nodalplanes }
                        principalaxes = { element.principalaxes }
                        img = { element.img }
                     />
                     </div>
                  </>
               ) )
            }
         
         />
    );

}
export default EQtmpTabs;