import React from 'react'
import BuildDepth from "Components/Molecules/M_Reusable/BuildDepth";

export const EQSummaryTemplate = ({ event }) => (
   <div className="DetailsInfoGrid Summary">

      <div className="InfoColumn">
         <div className="InfoRow">
            <div className="InfoTitle">Magnitud:</div>
            <div className="InfoText">{ event.properties.mag }</div>
         </div>
         <div className="InfoRow">
            <div className="InfoTitle">Profundidad:</div>
            <div className="InfoText">
               {
                  <BuildDepth
                     status = { event.properties.status }
                     depth = { event.geometry.coordinates[2] }
                     root = "detail"
                  />
               }
            </div>
         </div>
      </div>

      <div className="InfoColumn">
         <div className="InfoRow">
            <div className="InfoTitle">Hora Local:</div>
            <div className="InfoText">{ event.properties.localTime }</div>
         </div>
         <div className="InfoRow">
            <div className="InfoTitle">Hora UTC:</div>
            <div className="InfoText">{ event.properties.utcTime }</div>
         </div>
      </div>

      <div className="InfoColumn">
         <div className="InfoRow Small">
            <div className="InfoTitle">Latitud:</div>
            <div className="InfoText">{ event.geometry.coordinates[0].toFixed(2) }°</div>
         </div>
         <div className="InfoRow Small">
            <div className="InfoTitle">Longitud:</div>
            <div className="InfoText">{ event.geometry.coordinates[1].toFixed(2) }°</div>
         </div>
      </div>
   </div>
)
