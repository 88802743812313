let template = {}

const render_table = (...params) => {
    let param = params.filter((element)=>{
      return element !== null && element !== undefined && element !== false
    })
    return param.length === params.length ? true : false
  }

const render_exist = param => {
  return param !== null && param > 1;
}

const validateSimpleProps = ( props, key ) => {
  if ( props !== undefined && props !== null ) {
    template[ key ] = 'EQOriginTd';
    return true
  }
  else {
    template[ key ] = 'EQHide'
    return false
  }
}

const validateMultiProps = ( props, key ) => {
  let out = props.filter((element)=>{
    return element !== null && element !== undefined && element !== false
  })
  if ( out.length === props.length ) {
    template[key] = 'EQOriginTd';
    return true
  }
  else {
    template[key] = 'EQHide';
    return false
  }
}

const getTemplate = key => template[ key ];

const TableValidate = { render_table, render_exist, validateSimpleProps, validateMultiProps, getTemplate }
export default TableValidate;