import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import EQDefault from "Components/Organisms/O_EQDetails/EQDefault";
import Loader from "Components/Pages/Loader";
import EQshMap from 'Components/Molecules/M_EQDetails/EQshMap';
import { loadSmEvent } from "redux/actionsCreators";
import axios from 'axios'
import EQShPop from "Components/Molecules/M_EQDetails/EQShPop";
import { EQShTable } from "Components/Molecules/M_EQDetails/EQShTable";
import { useParams } from 'react-router-dom';

const initialState = {
    legend: 'MOSTRAR LEYENDA',
    popup: false,
    anchor: null,
    map_options:{
        position:[],
        zoom:5.7
    }
}

const EQNearbyseismos = ({ event, loadSmEvent, sh, handleNotEvent }) => {

    const eq = useRef([]);
    const mapZoom = useRef( 5.7 );
    const params = useParams();
    const [ state, setState ] = useState( initialState );
    const [ loading, setLoading ] = useState( <Loader /> );

    const loadInitialData = async () => {
        try {
            const { data } = await axios
                .get( event.properties.products.sh );
            if ( data ) {
                eq.current = data;
                loadSmEvent({
                    data,
                    legend: 'MOSTRAR LEYENDA',
                    popup: false
                });
            }
            
        } catch {
            setLoading( <EQDefault/> );
            handleNotEvent( params.product );
        }
    }

    useEffect(() => {
        loadInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectMarker = eq => {
        setState({
            ...state,
            map_options: {
                position: [ eq.geometry.coordinates[0], eq.geometry.coordinates[1] ],
                zoom: mapZoom.current
            }
        });
    }

    const handleZoom = event => {
        mapZoom.current = event.target._zoom;
    }

    const handleCloseLegend = () => {
        setState({
            anchor: null,
            popup: false,
            legend: 'MOSTRAR LEYENDA'
        })
    }

    const handleLegend = event => {
        setState({
            legend: 'OCULTAR LEYENDA',
            popup: true,
            anchor: event.currentTarget
        })
    }

    return sh.data ? (
        <div className="EQsh">
            <div className="ShMap">
                <div className="ShMapContent">
                    <EQshMap
                        map_options = { state.map_options } 
                        handleSelectMarker = { handleSelectMarker }
                        handleZoom = { handleZoom }
                    />
                    <EQShPop
                        popup = { state.popup }
                        legend = { state.legend }
                        handleLegend = { handleLegend }
                        handleCloseLegend = { handleCloseLegend }
                        anchorLegend = { state.anchor }
                    />
                </div>
                <EQShTable data = { sh.data.features } />
            </div>
        </div>
    ) : loading;
}

const mapStateToProps = state => ({
    event:state.detailEvent[0],
    sh: state.smEvent
})

const mapDispatchToProps = { loadSmEvent };

export default connect(mapStateToProps, mapDispatchToProps)(EQNearbyseismos);
