import { useMemo, useEffect, useContext } from "react";

import BuildMmi from "Components/Molecules/M_Reusable/BuildMmi";
import BuildCdi from "Components/Molecules/M_Reusable/BuildCdi";
import { makeDetailsRequest } from "hooks/UseDetailsRequest";
import { QueryContext } from 'context/QueryContext';

import { analyticsEventTracker, EVENT_CATEGORIES } from "hooks/UseAnalyticsTracker";
import { CircularProgress } from "@mui/material";
import { round, valDepth, validateValue, valStatus } from "utils/earthquakes";
import { GREEN } from "utils/styles";

const EarthquakePopup = (
	{
		lat, lng, place, hiddenCdi,
		localTime, utcTime, status,
		closerTowns, magnitude, mmi,
		depth, cdi, urlMoreInfo,
		urlReport, maxPGA, agency,
		beachBall, buildBeachBall, 
      id, validateMoreInfo=true
	}
) => {

	const eqStatus = useMemo( () => valStatus( status ), [ status ] ) 

   const { updateMoreInfo, moreInfoAssets } = useContext( QueryContext );
	const { loading, hasData } = moreInfoAssets;

	const isInTerritory = lat >= -5 && lat <= 15
		&& lng >= -83 && lng <= +67;

	const buildDepth = () => {

      const eqDepth = valDepth( depth, eqStatus );

      if ( eqDepth === "Superficial" ){
         return <p className="DataValue DepthText">{ eqDepth }</p>;
      } else {
         return (
            <>
               <p className="DataValue">{ eqDepth }</p>
               <p className="DataMeasure">km</p>
            </> 
         )
      }
	};

   const moreInfoRequest = async () => {
		updateMoreInfo({ loading : true, hasData : false });
		const request = await makeDetailsRequest( id );
		updateMoreInfo({ loading : false, hasData : request });
	}

	useEffect(() => {
		validateMoreInfo && moreInfoRequest();
      // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return (
		<div className="EQPopup">
			<div className="EQPopupHeader">
				<h6 className="EQTitle">{ place }</h6>
				<div className="EQTimeContainer">
					<p>
						<b>Tiempo de origen:</b>
					</p>
					<div className="EQTimeText">
						<p>{`${ localTime } Hora Local`}</p>
						<p>{`${ utcTime } UTC `}</p>
					</div>
				</div>
				{
					validateValue( maxPGA ) && (
						<p>
							<b>PGA Máximo: </b>
							{round(maxPGA, 6)} cm/s^2
						</p>
					)
				}
				<p> <b>Estado: </b> {eqStatus} </p>

				<p> <b>Agencia: </b> {agency} </p>
			</div>

         <div className="EQPopupContent">

            <div className="EQInfoTable">
               <div className="EQInfoCell">
                  <div className="CellTitleContainer">
                     <p className="CellTitle">Magnitud</p>
                  </div>
                  <div className="CellDataContainer">
                     <div className="CellDataCircle">
                        <p className="DataValue">{round(magnitude, 1)}</p>
                        <p className="DataMeasure">M</p>
                     </div>
                  </div>
               </div>
               <div className="EQInfoCell">
                  <div className="CellTitleContainer">
                     <p className="CellTitle">Profundidad</p>
                  </div>
                  <div className="CellDataContainer">
                     <div className="CellDataCircle">{buildDepth()}</div>
                  </div>
               </div>

               <div className="EQInfoCell">
                  <div className="CellTitleContainer">
                     <p className="CellTitle">Localización</p>
                  </div>
                  <div className="CellDataContainer">
                     <div className="CellDataCircle">
                        <p className="DataValue Small">{ round(lat, 2) }°</p>
                        <p className="DataMeasure Small">Latitud</p>
                        <p className="DataValue Small">{ round(lng, 2) }°</p>
                        <p className="DataMeasure Small">Longitud</p>
                     </div>
                  </div>
               </div>
            </div>

            <div className="EQInfoTable">
               {/* {Si el valor de la intensidad es >=2 se pinta el ícono de intensidad} */}
               {
                  validateValue( mmi ) && mmi >= 2 && (
                     <div className="EQInfoCell">
                        <div className="CellTitleContainer">
                           <p className="CellTitle">Intensidad instrumental</p>
                        </div>
                        <div className="CellDataContainer">
                           <div className="CellDataCircle">
                              <BuildMmi style={{ flex: "1" }} mmi={ mmi } />
                           </div>
                        </div>
                     </div>
                  )
               }
               {
                  !hiddenCdi && validateValue( cdi ) && cdi >= 2 && (
                     <div className="EQInfoCell">
                        <div className="CellTitleContainer">
                           <p className="CellTitle">Intensidad percibida</p>
                        </div>
                        <div className="CellDataContainer">
                           <div className="CellDataCircle">
                              <BuildCdi cdi={ cdi } />
                           </div>
                        </div>
                     </div>
                  )
               }
               {
                  validateValue( beachBall ) && (
                     <div className="EQInfoCell">
                        <div className="CellTitleContainer">
                           <p className="CellTitle">Tensor Momento Sísmico</p>
                        </div>
                        <div className="CellDataContainer">
                           <div className="CellDataCircle">
                              {
                                 buildBeachBall
                                    ? buildBeachBall : (
                                       <img alt="beachball" src={beachBall} />
                                    )
                              }
                           </div>
                        </div>
                     </div>
                  )
               }
            </div>

            <div className="EQLocationData">
               {
                  validateValue( closerTowns ) && (
                     <p className="EQLocationText">
                        <b>Municipios Cercanos: </b>
                        {closerTowns}
                     </p>
                  )
               }
            </div>
         </div>

			<div className="EQButtons">
				{
					isInTerritory && urlReport && (
						<a
							className="InfoButton"
							href={urlReport}
							target="_blank"
							rel="noopener noreferrer"
							onClick={e =>
								analyticsEventTracker({
									category: EVENT_CATEGORIES.eartquakes,
									action: `¿Sentiste este sismo? from Map Popup`,
								})
							}
						>
							¿Sentiste este sismo?
						</a>
					)
				}
            { loading && 
               <CircularProgress sx={{ color: GREEN }} size = { 20 } /> }
				{
					hasData && (
                  <a
                     className="InfoButton"
                     href={urlMoreInfo}
                     target="_blank"
                     rel="noopener noreferrer"
                     onClick={e =>
                        analyticsEventTracker({
                           category: EVENT_CATEGORIES.eartquakes,
                           action: `Más Información from Popup del Mapa`,
                        })
                     }
                  >
                     Más información
                  </a>
				) 
				}
			</div>
		</div>
	);
};

export default EarthquakePopup;
