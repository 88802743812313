import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")( Highcharts );

const charts = [
   {
      key: "pgaE",
      name: "PGA EW"
   },
   {
      key: "pgaN",
      name: "PGA NS"
   },
   {
      key: "pgaZ",
      name: "PGA Z"
   }
]

const setChartSeries = ( key, accelerations ) => {
   
   return accelerations.map( acc => {
      return {
         name: acc.stationCode,
         data: [ [ acc.distanceEpicentral, acc[ key ] ] ],
      }
   })
} 

const setChartOptions = ( key, data, chartTitle, xAxisTitle, yaxisTitle  ) => {
   
   const chartSeries = setChartSeries( key, data );

   return {
      title: {
        text: chartTitle,
      },
      accessibility: {
         enabled: false,
      },
      series: chartSeries,
      chart: {
         height: 500,
         type: 'scatter',
         zooming: {
            enabled: true,
            type: 'xy'
         },
         spacing: [30, 15, 15, 15],
      },
      xAxis: {
         title: {
            text: xAxisTitle,
         },
      },
      yAxis: {
         minorTickInterval: 0.5,
         title: {
            text: yaxisTitle,
         },
   
      },
      tooltip: {
         headerFormat: '<b>{series.name}</b> <br/>',
         pointFormat: '{point.x} km, {point.y} PGA',
      },
      plotOptions:{
         series: {
            cursor: "pointer",
            marker:{
               radius: 6
            },
            states:{
               inactive:{
                  opacity: 1
               }
            }
         }
      },
      legend:{
         layout: "vertical",
         align: "right",
         verticalAlign: "bottom",
         y: -43,
         maxHeight: 350,
         borderWidth: 1,
         backgroundColor: "white",
         itemMarginBottom: 3,
         shadow: {
            enabled: true,
         }
      },
      credits: {
         enabled: true,
         position: {
            y: -10,
         }
      },
      responsive: {
         rules: [{
            condition: {
               maxWidth: 500
            },
            chartOptions: {
               plotOptions:{
                  series: {
                     marker:{
                        radius: 5
                     }
                  }
               },
               chart: {
                  height: 400
               },
               legend: {
                  maxHeight: 250
               }
            }
         }],
      },
   }
}


function HighChartSm({ accelerations }) { 

   return (
      <div id="highcharts">
         {
            charts.map( chart => (
               <HighchartsReact
                  key={ chart.key }
                  highcharts={ Highcharts }
                  options={ setChartOptions( 
                     chart.key,
                     accelerations,
                     chart.name + ' vs Distancia Epicentral',
                     "Distancia Epicentral (km)",
                     chart.name + ' (cm/s^2)'
                  )}
               />
            ))
         }
      </div>
   )
}

export default HighChartSm