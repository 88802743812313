import { TextField } from '@mui/material';
import { useRef } from 'react'

export const validateFloat = info => /^-?\d+(?:[.,]\d*?)?$/.test( info );

export const UseNumberField = (
    {
        init, trigger, label, placeholder, helpText,
        variant, aditionalConf, name, id, err
    }
) => {
    
    const valueRef = useRef({
        value: init || '', error: err || false, helperText: helpText || ''
    });

    const handleChange = event => {

        const info = event.target.value;
        if ( validateFloat( info ) ) {
            valueRef.current = {
                value: info, error: false, helperText: ''
            }
        } else if ( info === '' ) {
            valueRef.current = {
                value: info, error: true, helperText: 'Valor inválido'
            }
        } else {
            valueRef.current = {
                value: info, error: true, helperText: 'Solo se admiten valores numéricos'
            }
        }
        trigger && trigger( valueRef.current, name );
    }

    function isNumberKey(evt){
        var charCode = (evt.which) ? evt.which : evt.keyCode
        var inputValue = evt.target.value 
        if (charCode > 31 && (charCode !== 46 && charCode !== 45 &&(charCode < 48 || charCode > 57)))
            evt.preventDefault()
        if (charCode === 46  && inputValue.includes('.')){
            evt.preventDefault()
        }
        if (charCode === 45 && inputValue.includes('-')){
            evt.preventDefault()
        }
    }

    return (
        <TextField
            id = { id }
            variant = { variant }
            type = "number"
            name = { name }
            label = { label }
            placeholder = { placeholder || '' }
            value = { init }
            onChange = { handleChange }
            onKeyPress = { isNumberKey }
            error = { err }
            helperText = { helpText }
            { ...aditionalConf }
        />
    )
}