import React from 'react';
import MUIDataTable from 'mui-datatables';
import BuildCdi from 'Components/Molecules/M_Reusable/BuildCdi';

export const CdiMuiTable = ({ reports }) => {
    return (
      <div className="main-table-reports">

        <div className = "SgcMUIDataTable mb-1">
                <MUIDataTable
                    title = "Respuestas"
                    data = {
                        reports.map(( report ) => ({
                            towncenter: report.towncenter,
                            municipality: report.municipality,
                            intensity: report.intensity,
                            reports: report.reports,
                            distance: report.distance,
                            latitude: report.latitude,
                            longitude: report.longitude
                        }))
                    }
                    columns = {[
                        {
                            name: 'towncenter',
                            label: 'Centro Poblado',
                            options: {
                                filter: true, sort: true,
                            }
                        },
                        {
                            name: 'municipality',
                            label: 'Municipio',
                            options: {
                                filter: true, sort: true,
                                setCellHeaderProps: () => ({ style: { minWidth: "150px", } })
                            }
                        },
                        {
                            name: 'intensity',
                            label: 'Intensidad',
                            options: {
                                filter: true,
                                sort: true,
                                customBodyRender: (value) => <BuildCdi cdi = { value } />
                            }
                        },
                        {
                            name: 'reports',
                            label: 'No. Formularios',
                            options: {
                                filter: false, sort: true
                            }
                        },
                        {
                            name: 'distance',
                            label: 'Distancia al hipocentro (km)',
                            options: {
                                filter: false, sort: true,
                                setCellHeaderProps: () => ({ style: { minWidth: "150px", } })
                            }
                        },
                        {
                            name: 'latitude',
                            label: 'Latitud(°)',
                            options: {
                                filter: false, sort: true
                            }
                        },
                        {
                            name: 'longitude',
                            label: 'Longitud(°)',
                            options: {
                                filter: false, sort: true
                            }
                        }
                    ]}
                    options = {{
                        selectableRows: 'none',
                        filter: true,
                        responsive: "standard",
                        fixedSelectColumn: true,
                        fixedHeader: true,
                        sortOrder: {
                           name: "intensity",
                           direction: "desc"
                        },
                        filterType: 'multiselect',
                        onDownload: ( buildHead, buildBody, columns, data ) => {
                            const onlyValues = data.map(( value, index ) => {
                                value.data[2] = reports[ index ]?.intensity ?? '-';
                                return value;
                            });
                            return buildHead( columns ) + buildBody( onlyValues );
                        },
                        textLabels: {
                            body: {
                                noMatch: "Lo sentimos, no se encontraron registros coincidentes",
                                toolTip: "Ordenar",
                                columnHeaderTooltip: column => `Ordenar por ${column.label}`
                            },
                            pagination: {
                                next: "Sig. Pag.",
                                previous: "Ant. Pag",
                                rowsPerPage: "Filas por pag.:",
                                displayRows: "de",
                            },
                            toolbar: {
                                search: "Buscar",
                                downloadCsv: "Descargar CSV",
                                print: "Imprimir",
                                viewColumns: "Columnas",
                                filterTable: "Filtrar Tabla",
                            },
                            filter: {
                                all: "Todos",
                                title: "FILTROS",
                                reset: "REINICIAR",
                            },
                            viewColumns: {
                                title: "Mostar Columnas",
                                titleAria: "Mostar/Ocultar Columnas",
                            },
                            selectedRows: {
                                text: "Fila(s) seleccionadas",
                                delete: "Eliminar",
                                deleteAria: "Eliminar Filas Seleccionadas",
                            },
                        }
                    }}
                />
         </div>
         <div className = "intensity-info">
               <p>
                  En esta tabla se incluyen los municipios 
                  para los cuales se reportaron efectos y se evaluó 
                  su intensidad. Se presenta el número de formularios 
                  que se diligenciaron por municipio, sus coordenadas 
                  y la distancia al hipocentro (punto en profundidad 
                  donde se origina el sismo)
               </p>
         </div>
      </div>
    )
}
