import axios from "axios";
import { SGC_API_URL } from "utils/urls";

const seismicityInstance = axios.create({
   baseURL: SGC_API_URL
})

export const SeismicityService = {
   getEvents: ( params ) => {
      return seismicityInstance.get("/biweekly/biweekly_earthquakes", { params: params })
   },
   getEventsCount: ( params ) => {
      return seismicityInstance.get("/biweeklycount/biweekly_earthquakes", { params: params })
   },
}