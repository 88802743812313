import React from "react";
//import Tooltip from "@material-ui/core/Tooltip";

const BuidDepth = ({ status, depth, eventList, root, eventTipe, round = 0 }) => {

  if (status !== null) status = status.toLowerCase();
  depth = Number.parseFloat(depth).toFixed( round );

  if ( eventList ) {

    if ( status === "automatic" && depth < 70 ) {
      return (
        <div>
          <h2 className="depth-superficial">Superficial</h2>
        </div>
      );
    } else if (status === "manual" && depth < 30) {
      return (
        <div>
          <h2 className="depth-superficial">Superficial</h2>
        </div>
      );
    } else {
      return (
        <div>
          <h2 className="indicators-text">{depth}</h2>
          <h3 className="measures-text">km</h3>
        </div>
      );
    }

  }
  else if ( root && root === 'detail' ) {
    if ( eventTipe && eventTipe === 'volcanic eruption' ) {
      return `${depth} km`;
    }
    else if (status === "automatic" && depth < 70) {
      return <>Superficial (Menor a 70 km)</>;
    } else if ( status === "manual" && depth < 30 ) {
      return <>Superficial (Menor a 30 km)</>;
    } else {
      return <>{depth} km</>;
    }

  }

  else {

    if (status === "automatic" && depth < 70) {
      return <>Superficial</>;
    } else if (status === "manual" && depth < 30) {
      return <>Superficial</>;
    } else {
      return <>{depth} km</>;
    }

  }
}

export default BuidDepth;
