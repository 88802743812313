import { useSelector } from "react-redux";
import Loader from "Components/Pages/Loader";
import EQDefault from 'Components/Organisms/O_EQDetails/EQDefault';
import { EQOverTable } from "Components/Molecules/M_EQDetails/EQOverTable";
import { useNavigate } from "react-router-dom";

function EQOverview() {
   
   const event = useSelector( state => state.detailEvent[0] );
   const navigate = useNavigate();

   const handleHref = path => navigate( path );

   return event.properties ? (
      <div className = "EQoverview TitleText">
         
         <div className = "EQSectionContainer GrayBordered" id = "container-resumen-1">
            <div className = "CardsTitle">
               <h3>Resumen del Evento</h3>
            </div>
            <div className = "CardsContent">
               <div className = "container-eventmap-overview">
                  <img
                  className = "eventmap"
                  src = { event.properties.products.staticmap }
                  alt = "eventmap"
                  />
               </div>
               <div className = "overViewGrid">
                  <EQOverTable event = { event } handleHref = { handleHref } />
               </div>
            </div>
         </div>

         <div className = "EQSectionContainer GrayBordered" id="container-resumen-products">
            <div className = "CardsTitle">
               <h3>Productos Asociados</h3>
            </div>
            <div className = "CardsContent">
               <EQDefault/>
            </div>
         </div>
      </div>
   ) : <Loader />
}

export default EQOverview;
