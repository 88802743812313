import { useState, useEffect } from "react";

import BuildMmi from "Components/Molecules/M_Reusable/BuildMmi";
import BuildCdi from "Components/Molecules/M_Reusable/BuildCdi";
import { makeDetailsRequest } from "hooks/UseDetailsRequest";
import { CircularProgress } from "@material-ui/core";

import { analyticsEventTracker, EVENT_CATEGORIES } from "hooks/UseAnalyticsTracker";

// Sismos Popup
const PopupEarthquakeMarker = (
	{
		lat, lng, place, hiddenCdi,
		localTime, utcTime, status,
		closerTowns, magnitude, mmi,
		depth, cdi, urlMoreInfo,
		urlReport, maxPGA, agency,
		beachBall, buildBeachBall,
		hidButtons, id
	}
) => {

	status = status.toLowerCase();

	const [isMoreDetails, setIsMoreDetails] = useState(null);

	// function to round values
	const round = (num, decim) => {
		num = parseFloat(num);
		return num.toFixed(decim)
	}

	const isInTerritory = lat >= -5 && lat <= 15
		&& lng >= -83 && lng <= +67;

	const buildDepth = () => {
		let text = (
			<>
				<p className="DataValue">{parseInt(depth)}</p>
				<p className="DataMeasure">km</p>
			</>
		);
		if (status === "manual" && depth < 30) {
			text = <p className="DataValue DepthText">Superficial</p>;
		}
		if (status === "automatic" && depth < 70) {
			text = <p className="DataValue DepthText">Superficial</p>;
		}
		return text;
	};

	useEffect(() => {
		const moreInfoButton = async () => {
			const result = await makeDetailsRequest(id);
			setIsMoreDetails(result);
		}
		moreInfoButton();

		return function cleanup() {
			setIsMoreDetails(null);
		};
	}, [id])

	return (
		<div className="EQPopup">
			<div className="EQPopupHeader">
				<h6 className="EQTitle">{place}</h6>
				<div className="EQTimeContainer">
					<p>
						<b>Tiempo de origen:</b>
					</p>
					<div className="EQTimeText">
						<p>
							{`${localTime} `}
							Hora Local
						</p>
						<p>{`${utcTime} UTC `}</p>
					</div>
				</div>
				{
					maxPGA !== undefined && maxPGA !== null && maxPGA !== "" && (
						<p>
							<b>PGA Máximo: </b>
							{round(maxPGA, 6)} cm/s^2
						</p>
					)
				}
				<p>
					<b>Estado: </b>
					{status === "manual" ? "Manual" : "Automático"}
				</p>
				<p>
					<b>Agencia: </b>
					{agency}
				</p>
			</div>

         <div className="EQPopupContent">

            <div className="EQInfoTable">
               <div className="EQInfoCell">
                  <div className="CellTitleContainer">
                     <p className="CellTitle">Magnitud</p>
                  </div>
                  <div className="CellDataContainer">
                     <div className="CellDataCircle">
                        <p className="DataValue">{round(magnitude, 1)}</p>
                        <p className="DataMeasure">M</p>
                     </div>
                  </div>
               </div>
               <div className="EQInfoCell">
                  <div className="CellTitleContainer">
                     <p className="CellTitle">Profundidad</p>
                  </div>
                  <div className="CellDataContainer">
                     <div className="CellDataCircle">{buildDepth()}</div>
                  </div>
               </div>

               <div className="EQInfoCell">
                  <div className="CellTitleContainer">
                     <p className="CellTitle">Localización</p>
                  </div>
                  <div className="CellDataContainer">
                     <div className="CellDataCircle">
                        <p className="DataValue Small">{lat.toFixed(2)}°</p>
                        <p className="DataMeasure Small">Latitud</p>
                        <p className="DataValue Small">{lng.toFixed(2)}°</p>
                        <p className="DataMeasure Small">Longitud</p>
                     </div>
                  </div>
               </div>
            </div>

            <div className="EQInfoTable">
               {/* {Si el valor de la intensidad es >=2 se pinta el ícono de intensidad} */}
               {
                  mmi !== undefined && mmi !== null && mmi >= 2 && (
                     <div className="EQInfoCell">
                        <div className="CellTitleContainer">
                           <p className="CellTitle">Intensidad instrumental</p>
                        </div>
                        <div className="CellDataContainer">
                           <div className="CellDataCircle">
                              <BuildMmi style={{ flex: "1" }} mmi={ mmi } />
                           </div>
                        </div>
                     </div>
                  )
               }
               {
                  !hiddenCdi && cdi !== null && cdi !== undefined && cdi >= 2 && (
                     <div className="EQInfoCell">
                        <div className="CellTitleContainer">
                           <p className="CellTitle">Intensidad percibida</p>
                        </div>
                        <div className="CellDataContainer">
                           <div className="CellDataCircle">
                              <BuildCdi cdi={ cdi } />
                           </div>
                        </div>
                     </div>
                  )
               }
               {
                  beachBall !== undefined && beachBall !== null && beachBall !== '' && (
                     <div className="EQInfoCell">
                        <div className="CellTitleContainer">
                           <p className="CellTitle">Tensor Momento Sísmico</p>
                        </div>
                        <div className="CellDataContainer">
                           <div className="CellDataCircle">
                              {
                                 buildBeachBall
                                    ? buildBeachBall : (
                                       <img alt="beachball" src={beachBall} />
                                    )
                              }
                           </div>
                        </div>
                     </div>
                  )
               }
            </div>

            <div className="EQLocationData">
               {
                  closerTowns !== "" && closerTowns !== undefined && closerTowns !== null && (
                     <p className="EQLocationText">
                        <b>Municipios Cercanos: </b>
                        {closerTowns}
                     </p>
                  )
               }
            </div>
         </div>

			<div className="EQButtons">
				{
					isInTerritory && !hidButtons && (
						<a
							className="InfoButton"
							href={urlReport}
							target="_blank"
							rel="noopener noreferrer"
							onClick={e =>
								analyticsEventTracker({
									category: EVENT_CATEGORIES.eartquakes,
									action: `¿Sentiste este sismo? from Map Popup`,
								})
							}
						>
							¿Sentiste este sismo?
						</a>
					)
				}
				{
					isMoreDetails === null ?
						<CircularProgress size={20} /> : isMoreDetails ? (
							<a
								className="InfoButton"
								href={urlMoreInfo}
								target="_blank"
								rel="noopener noreferrer"
								onClick={e =>
									analyticsEventTracker({
										category: EVENT_CATEGORIES.eartquakes,
										action: `Más Información from Popup del Mapa`,
									})
								}
							>
								Más información
							</a>
				) : <></>
				}
			</div>
		</div>
	);
};

export default PopupEarthquakeMarker;
