import React, { useEffect, useState } from 'react';
import EQDefault from "Components/Organisms/O_EQDetails/EQDefault";
import axios from 'axios';
import Loader from "Components/Pages/Loader";
import EQHmChart from 'Components/Molecules/M_EQDetails/EQHmChart';
import { connect } from 'react-redux';
import { mag_chart } from 'Components/Atoms/A_EQDetails/BuildChartData';
import { EQHmTable } from 'Components/Molecules/M_EQDetails/EQHmTable';
import { useParams } from 'react-router-dom';

const EQoriginHistory = ({ event, handleNotEvent }) => {
    const [ historic, setHistoric ] = useState([]);
    const [ loading, setLoading ] = useState(<Loader/>);
    const params = useParams();

    const loadInitalState = async () => {
        try {
            const { data } = await axios
                .get( event.properties.products.originHistory );
            data && setHistoric( data.originhistory );
        } catch {
            setLoading( <EQDefault/> );
            handleNotEvent( params.product );
        }
    }

    useEffect(() => {
        loadInitalState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return historic.length > 0 ? (
        <div className = "EQHistoric">
            <div className = "HistoricContent">
                <div className = "HistoricGrid">
                    <EQHmChart
                        info = { mag_chart( historic, 'magnitude', 'Magnitud', 'Magnitud' ) }
                        index = { 1 }
                    />
                    <EQHmChart
                        info = { mag_chart( historic, 'depth', 'Profundidad', 'Profundidad' ) }
                        index = { 2 }
                    />
                </div>
                <EQHmTable info = { historic }/>
            </div>
        </div>
    ) : loading;
}

const mapStateToProps = state => ({
    event:state.detailEvent[0]
})

export default connect( mapStateToProps )( EQoriginHistory );