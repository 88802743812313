import { SeismicityService } from "api/Services/ReqSeismicity"

export const useSeismicityRequest = () => {

   const getEvents = async ( startDate, endDate ) => {
      
      const params = { 
         startdate: startDate, 
         enddate: endDate,
      }

      return SeismicityService.getEvents( params )
         .then( ({ data }) => {
            return data.features.map( event => {
               return {
                  id: event.id,
                  latitude: event.geometry.coordinates[1],
                  longitude: event.geometry.coordinates[0],
                  time: event.properties.localTime,
                  depth: event.properties.depth,
                  magnitude: event.properties.mag,
                  cdi: event.properties.cdi,
                  place: event.properties.place.split(",")[0],
               }
            } )
         })
         .catch( err => {
            console.log( err );
            return false;
         })
   }

   const getEventsCount = async ( startDate, endDate ) => {
      
      const params = { 
         startdate: startDate, 
         enddate: endDate,
      }

      return SeismicityService.getEventsCount( params )
         .then( ({ data }) => data.metadata.count )
         .catch( err => {
            console.log( err );
            return false;
         })

   }
   
   return {
      getEvents, getEventsCount
   }
}