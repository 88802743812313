import pdf from '../../../assets/icons/Reusable/pdf.svg'
import defaultImg from "../../../assets/icons/Reusable/img_01.png";

export const CardsNews = ({ news, volcanoName, handleNews, itemRef }) => {
    const validateItem = index => (
        index === itemRef ? 'card-new Vcard-selected' : 'card-new'
    );
    return (
        <div className = "cards-news">
            { 
                news.map(( info, index ) => (
                    <div 
                        className = { validateItem( index ) }
                        key = { `card${ index }` }
                        onClick = { () => handleNews( index ) }
                    >
                        <div
                            className = "img-new-card"
                            style = {{
                                background: `url(${ info.images && info.images[0] ? info.images[0] : defaultImg }) center center`
                            }}
                        />
                        <div className = "content-new-card">
                            <div className = "content-new">
                                <p>{ info.date }</p>
                                <hr />
                                <p className = "content-trunk">
                                    { info.description }
                                </p>
                                <p>{ volcanoName }</p>
                            </div>
                            <div className = "circles">
                                {
                                    info.files && info.files[0]
                                    && info.files.map(( file, index ) => (
                                        <a
                                            href = { file }
                                            key = { `pdf${ index }` }
                                        >
                                            <div className = "circles">
                                                <img src = { pdf } alt = "pdf" />
                                            </div>
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}