import BuildDepth from 'Components/Molecules/M_Reusable/BuildDepth';

const EQtmpTensor = ({ locationcentroid, momenttensor, nodalplanes, principalaxes, img }) => {
    return (
         <div className = "EQTensorContainer">
            <div className="EQTmItem">
               <div className="EQTmTitle Left">
                  <h3 className="TitleText">Solución Tensor Momento</h3>
               </div>
               <div className="EQTmContent">
                  <div className="TmBeachball">                  
                     <img src={img} alt="beachball"></img>
                  </div>
                  <div className="EQTmTable">
                     <table className="MainTmTable">
                        <tbody>
                           <tr>
                              <th>Latitud centroide</th>
                              <td>{locationcentroid.latitude}&deg;</td>
                           </tr>
                           <tr>
                              <th>Longitud centroide</th>
                              <td>{locationcentroid.longitude}&deg;</td>
                           </tr>
                           <tr>
                              <th>M<sub>0</sub></th>
                              <td>
                                 <span className="TextEllipsis" title={locationcentroid.M0}>
                                    {locationcentroid.M0} {locationcentroid.unity}
                                 </span>
                              </td>
                           </tr>
                           <tr>
                              <th>Magnitud</th>
                              <td>{`${locationcentroid.mag} ${locationcentroid.magType}`}</td>
                           </tr>
                           <tr>
                              <th>Profundidad</th>
                              <td><BuildDepth status="" depth={locationcentroid.depth} /></td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>

            <div className="EQTmItem">
               <div className="EQTmTitle">
                  <h3 className="TitleText">Planos Nodales</h3>
               </div>
               <div className="EQTmContent">
                  <div className="EQTmTable">
                     <table>
                        <thead>
                           <tr>
                              <th>Plano</th>
                              <th>Strike</th>
                              <th>Dip</th>
                              <th>Rake</th>
                           </tr>
                        </thead>
                        <tbody>
                        {
                           Object.keys(nodalplanes).map((key, index) => {
                              return (
                                    <tr key={`row${key}_${index}`} id={`row${key}_${index}`}>
                                       <th>{key}</th>
                                       <td>{nodalplanes[key].Strike}&deg;</td>
                                       <td>{nodalplanes[key].Dip}&deg;</td>
                                       <td>{nodalplanes[key].Rake}&deg;</td>
                                    </tr>
                              )
                           })
                        }
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>

            <div className="EQTmItem">
               <div className="EQTmTitle">
                  <h3 className="TitleText">Ejes Principales</h3>
               </div>
               <div className="EQTmContent">
                  <div className="EQTmTable">
                     <table>
                        <thead>
                           <tr>
                              <th>Eje</th>
                              <th>Plunge</th>
                              <th>Azimuth</th>
                           </tr>
                        </thead>
                        <tbody>
                        {
                           Object.keys(principalaxes).map((key, index) => {
                              return (
                                    <tr key={`row${key}_${index}`} id={`row${key}_${index}`}>
                                       <th key={`column${key}_${0}`} id={`column${key}_${0}`}>{key}</th>
                                       <td key={`column${key}_${1}`} id={`column${key}_${1}`}>{principalaxes[key].Plg}&deg;</td>
                                       <td className="no-border" key={`column${key}_${2}`} id={`column${key}_${2}`}>{principalaxes[key].Azm}&deg;</td>
                                    </tr>
                              )
                           })
                        }
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>

            <div className="EQTmItem">
               <div className="EQTmTitle">
                  <h3 className="TitleText">Tensor de Momento</h3>
               </div>
               <div className="EQTmContent">
                  <div className="EQTmTable">
                     <table className="TmLastTable">
                        <tbody>
                        {
                           momenttensor.map((element) => {
                              return (
                                    <tr key={`Tensor${element.name}_row`} id={`Tensor${element.name}_row`}>
                                       <th key={`header${element.name}`} id={`header${element.name}`}>
                                          {element.name}
                                       </th>
                                       <td key={`column${element.name}`} id={`column${element.name}`}>
                                          {element.value} {locationcentroid.unity}
                                       </td>
                                    </tr>
                              )
                           })
                        }
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            
        </div>
    )
}
export default EQtmpTensor