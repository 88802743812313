import BuildLocalization from "Components/Molecules/M_Reusable/BuildLocalization";
import BuildDepth from "Components/Molecules/M_Reusable/BuildDepth";
import TableValidate from 'Components/Atoms/A_Reusable/ValidateTable';
import BasicTable from "Components/UI/BasicTable";

const validateNullValues = value => value !== undefined  && value !== null

export const OriginDetails = ({ properties, updateTime, status }) => {

  const { validateSimpleProps, validateMultiProps, getTemplate } = TableValidate;

  return (
    <div className = "EQorigindetail">
      <BasicTable 
         columns={[ "Información", "Datos" ]} 
         className="TableDetails" 
         bodyLeft
      >
         <tr>
            <td>
              {
                validateSimpleProps( properties.magnitude, 'magnitude' ) ? (
                  <><span>Magnitud</span><br /></>
                ) : <span/>
              }
              {
                validateSimpleProps(properties.magnitudeError, 'magnitudeError') ? (
                  <span className = "Emphasis-Gray">Incertidumbre</span>
                ) : <span/>
              }
            </td>
            <td>
              <span className={getTemplate('magnitude')}>
                { properties.magnitude }
              </span>
              <br></br>
              <span className={getTemplate('magnitudeError')}>
                { properties.magnitudeError }
              </span>
            </td>
         </tr>
         <tr>
            <td>
              Localización <br></br>{" "}
              {
                validateMultiProps([properties.latitudeError, properties.longitudeError], 'locateError') ? (
                  <span className = "Emphasis-Gray"> Incertidumbre</span>
                ) : (
                  <span />
                )
              }
            </td>
            <td>
              <BuildLocalization
                eventList = { false }
                status = { status }
                latitude = { properties.latitude }
                longitude = { properties.longitude }
              />
              <>
                <br />
                <span className={getTemplate('locateError')}>
                  {`${properties.latitudeError}, ${properties.longitudeError}`} km
                </span>
              </>
            </td>
          </tr>
          {
            validateNullValues( properties.depth ) && validateNullValues( status ) && (
              <tr>
                <td>
                  Profundidad <br></br>{" "}
                  {
                    validateSimpleProps(properties.depthError, 'depthError') && (
                      <span className = "Emphasis-Gray">Incertidumbre</span>
                    )
                  }
                </td>
                <td>
                  <BuildDepth className={getTemplate('depth')} status={status} depth={properties.depth} />
                  <br />
                  <span className={getTemplate('depthError')}>{properties.depthError} km</span>
                </td>
              </tr>
            )
          }
          {
            ( validateNullValues( properties.utctime ) || validateNullValues( properties.localTime ) ) && (
              <tr>
                <td>Tiempo de Origen</td>
                <td>
                  {`${properties.localtime} Hora Local`}
                  <br></br>
                  {`${properties.utctime} UTC`}
                </td>
              </tr>
            )
          }
          {
            validateNullValues( properties.numStationsUsed ) && (
              <tr>
                <td>Número de estaciones</td>
                <td>{properties.numStationsUsed}</td>
              </tr>
            )
          }
          {
            validateNullValues( properties.numPhasesUsed ) && (
              <tr>
                <td>Número de Fases</td>
                <td>{properties.numPhasesUsed}</td>
              </tr>
            )
          }
          {
            validateNullValues( properties.magnitudeAzimuthalGap )  && (
              <tr>
                <td>Azimuthal Gap</td>
                <td>{properties.magnitudeAzimuthalGap}</td>
              </tr>
            )
          }
          {
            validateNullValues( properties.title ) && (
              <tr>
                <td>Región</td>
                <td>{properties.title}</td>
              </tr>
            )
          }
          {
            validateNullValues( status ) && (
              <tr>
                <td>Estado</td>
                <td style={{textTransform: 'capitalize'}} > {status} </td>
              </tr>              
            )
          }
          {
            validateNullValues( updateTime ) && (
              <tr>
                <td>Última Actualización</td>
                <td>{ updateTime } UTC</td>
              </tr>
            )
          }

      </BasicTable>
    </div>
  );
};